<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<div class="main-container main-nav-component" *ngIf="!loading">
  <header class="header-6 main-nav-background">

    <div class="branding main-nav-branding">
      <a routerLink="/intake" class="nav-link main-nav-branding-container">
        <div class="main-nav-branding-assets">
          <img src="../../../assets/images/sentinel-logo-white-common.png" class="main-nav-sentinel-logo" />
          <p class="main-nav-branding-text main-nav-hex-intake">Intake</p>
        </div>
      </a>
    </div>

    <div class="header-nav">
      <a *ngFor="let item of getNavItems()" 
        appRouterLinkMatch 
        [match]="item.routerLinkMatch" 
        [routerLink]="[item.url]"
        [routerLinkActiveOptions]="{exact: item.routerLinkExact}" 
        [attr.data-cy]="item.cy"
        [translate]="item.translateId" 
        routerLinkActive="active" 
        class="nav-link nav-text main-nav-link">
      </a>
    </div>

    <div class="header-actions">
      <a class="logo-link" href="https://newmont.com" target="_blank">
        <img class="logo" src="../../../assets/images/newmont-logo-white-alt.png" />
      </a>
    </div>

  </header>
  <router-outlet></router-outlet>
</div>