import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface UserAuthStateModel {
  impId: string;
  impLoaded: boolean;
}

export class UserAuthStateImpersonate {
  static readonly type = '[UserAuth] Impersonate';

  constructor(public impersonateAsEmail: string) {
  }
}

export class UserAuthStateImpersonateComplete {
  static readonly type = '[UserAuth] Impersonate Loaded';

  constructor() {
  }
}


export class UserAuthStateImpersonateRestore {
  static readonly type = '[UserAuth] Impersonate Restore';

  constructor(public impersonateAsEmail: string) {
  }
}

@State<UserAuthStateModel>({
  name: 'userAuthState',
  defaults: { impId: null, impLoaded: false } as UserAuthStateModel
})
@Injectable()
export class UserAuthState {

  @Selector()
  static userAuth(state: UserAuthStateModel) {
    return state;
  }

  @Action(UserAuthStateImpersonate)
  impersonate(ctx: StateContext<UserAuthStateModel>, action: UserAuthStateImpersonate) {
    ctx.patchState({
      impId: action.impersonateAsEmail,
      impLoaded: false
    });
  }

  @Action(UserAuthStateImpersonateComplete)
  impersonateComplete(ctx: StateContext<UserAuthStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      impLoaded: true
    });
  }

  @Action(UserAuthStateImpersonateRestore)
  impersonateRestore(ctx: StateContext<UserAuthStateModel>, action: UserAuthStateImpersonateRestore) {
    ctx.patchState({
      impId: action.impersonateAsEmail,
      impLoaded: true
    });
  }

}
