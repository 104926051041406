import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmButtonModel, ConfirmDialogModel, ConfirmDialogTypeEnum } from 'app/components/common/models/dialog.model';
import { IntakeFileModel, IntakeFileUploadModel, IntakeForms } from 'app/components/common/models/intake.model';
import { CleanHtml } from 'app/services/clean-html.helper';
import { DialogService } from 'app/services/dialog.service';
import { Snippet } from 'app/utils/processFiles';
import { Guid } from 'guid-typescript';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-intake-attachments',
  templateUrl: './intake-attachments.component.html',
  styleUrls: ['./intake-attachments.component.css']
})
export class IntakeAttachmentsComponent implements OnInit {
  public model: IntakeForms;
  displayUpload: boolean = false;
  public file: NgxFileDropEntry;
  public name: string;
  public description: string;
  public showFileInfo: boolean = false;
  public saving: boolean;

  constructor(private dialogService: DialogService,
    private zone: NgZone,
    private storage: AngularFireStorage) {
  }

  ngOnInit(): void {
  }

  @Input()
  set datasource(model: IntakeForms) {
    this.model = model;
  }

  displayAddAttachment(): void {
    this.displayUpload = true;
    this.name = "";
    this.description = "";
    this.showFileInfo = false;
  }

  closeAddAttachments(): void {
    this.displayUpload = false;
    this.name = "";
    this.description = "";
  }

  dropped(files: NgxFileDropEntry[]) {
    if (files.length === 0) {
      return;
    }
    this.file = files[0];
    if (!this.file.fileEntry.isFile) {
      return;
    }
    this.showFileInfo = true;
  }

  setContent(data: any, file: string, fileExtension): void {

    let mimeType = 'text/plain';

    const regex = new RegExp('data:.+?;');
    const match = regex.exec(file);
    if (!match || match.length === 0) {
      data.file.file_content_type = mimeType;
      return;
    }

    mimeType = match[0];
    if (mimeType !== `data:application/octet-stream;`) {
      data.file.file_content_type = mimeType.replace(';', '').replace('data:', '');
      return;
    }

    if (fileExtension === '.xls') {
      mimeType = 'application/vnd.ms-excel';
    } else if (fileExtension === '.xlsx') {
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (fileExtension === '.doc') {
      mimeType = 'application/msword';
    } else if (fileExtension === '.docx') {
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (fileExtension === '.ppt') {
      mimeType = 'application/vnd.ms-powerpoint';
    } else if (fileExtension === '.pptx') {
      mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    } else if (fileExtension === '.pdf') {
      mimeType = 'application/pdf';
    } else if (fileExtension === '.csv') {
      mimeType = 'text/csv';
    } else {
      mimeType = match[0];
    }

    data.file.file_content_type = mimeType;
  }

  getFileExtension(fileName: string): string {
    const regex = new RegExp('\\.[0-9a-z]{1,5}$');
    const match = regex.exec(fileName);
    if (!match || match.length === 0) {
      return '';
    }
    return match[0];
  }

  isValid(): boolean {
    if (!this.file.fileEntry.isFile) {
      return false;
    }
    if ((!this.name || this.name.trim().length === 0) || (!this.description || this.description.trim().length === 0)) {
      return false;
    }
    return true;
  }

  save(): void {
    if (!this.isValid()) {
      return;
    }

    const clean = new CleanHtml();
    const description = clean.removeNonApprovedASCII(this.description);
    const name = clean.removeNonApprovedASCII(this.name).trim();

    if (!name.length || !this.description.length) {
      return;
    }

    this.saving = true;
    const data = {
      file: {
        file_name: this.file.relativePath.toLowerCase(),
        file_title: name,
        file_description: description,
        file_content_type: '',
        file_extension: this.getFileExtension(this.file.relativePath.toLowerCase())
      } as IntakeFileModel
    } as IntakeFileUploadModel;

    const fileEntry = this.file.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        const selectedFile = new Snippet(event.target.result, file);
        this.setContent(data, selectedFile.src, data.file.file_extension);
        const ref = this.storage.ref('files');
        const metadata = {
          contentType: data.file.file_content_type
        };
        data.file.file_id = `${Guid.create()}`;
        const storageId = `${data.file.file_id}${data.file.file_extension}`;
        ref.child(storageId).put(file, metadata).then(() => {
          //add attachments 
          if (!this.model.attachments || !this.model.attachments.length) {
            this.model.attachments = [];
          }
          this.model.attachments.push(data);
          this.name = "";
          this.description = "";
          this.saving = false;
          this.zone.run(() => {
            this.displayUpload = false;
          });
        })
          .catch((error) => {
            this.saving = false;
            this.displayUpload = false;
            const dialogRef = this.dialogService.openConfirmDialog({
              titleTranslateId: 'exploration-file-upload-error',
              type: ConfirmDialogTypeEnum.Warning,
              message: 'xploration-file-upload-error-message',
              subMessage: 'error uploading to GS ' + error.error.message,
              confirmButton: { show: true, textTranslateId: 'datatools-ok-button' } as ConfirmButtonModel
            } as ConfirmDialogModel);
            dialogRef.afterClosed.subscribe(() => {
              dialogRef.dialog.isOpen = false;
              this.dialogService.displayModal.current.size = "xxl";
            });
          });
      });
      reader.readAsDataURL(file);
    });
  }
}
