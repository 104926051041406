<div class="clr-row">
  <div class="clr-col-8"></div>
  <div class="clr-col">
    <div class="clr-row">
      <div class="clr-col add-value">
<!--        <span class="add-value-label" [translate]="'target-column-add-value'"></span>-->
<!--        <clr-input-container >-->
<!--          <input name="new-value" [(ngModel)]="newValue" clrInput />-->
<!--        </clr-input-container>-->
        <button (click)="addValue()" class="btn btn-link add-value-button"><span [translate]="'target-column-add-value-btn'">Add</span></button>
      </div>
    </div>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col">
    <ng-container>
      <clr-datagrid [clrDgLoading]="loading" >
        <clr-dg-column>
          <span [translate]="'evaluations-grid-col-options'">Options</span>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'value_model_name'">
          <span [translate]="'column-value'">column-value</span>
        </clr-dg-column>
        <clr-dg-column *ngFor="let column of columnMappings" [clrDgField]="column.source.table.data_model.data_model_id">
          <span>{{column.source.table.data_model.data_model_name}}</span>
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let value of columnValues">
          <clr-dg-cell>
            <div class="actions">

              <button (click)="editValue(value)" class="btn btn-link btn-sm">
                <span [translate]="'evaluations-grid-btn-edit'">Edit</span>
              </button>

              <button (click)="deleteValue(value)" class="btn btn-link btn-sm">
                <span [translate]="'evaluations-grid-btn-del'">Delete</span>
              </button>
            </div>
          </clr-dg-cell>

          <clr-dg-cell>{{ value.value_model_name }}</clr-dg-cell>
          <clr-dg-cell *ngFor="let column of columnMappings">
            <div [innerHTML]="value[column.source.table.data_model.data_model_id]"></div></clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                              [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
            <span [translate]="'evaluations-grid-foot-label-1'"></span>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            <span [translate]="'evaluations-grid-foot-label-2'"></span>
            {{ pagination.totalItems }}
            <span [translate]="'category-values-project-footer'"></span>
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </ng-container>
  </div>

</div>
<div class="clr-row">
  <div class="clr-col">
    <div class="modal-footer">
      <button (click)="close()" type="button" class="btn btn-outline" data-cy="form-close">
        <span [translate]="'submodal-btn-close'"></span>
      </button>
    </div>
  </div>
</div>

