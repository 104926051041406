import { Component, OnInit } from '@angular/core';
import { DialogService } from '../../../services/dialog.service';
import { ConfirmDialog, ConfirmDialogModel, ConfirmDialogResult, ConfirmDialogTypeEnum } from '../models/dialog.model';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {

  public confirmDialog: ConfirmDialog;
  public confirmModal: ConfirmDialogModel;
  public confirmType = ConfirmDialogTypeEnum;
  public showSubMessage: boolean;

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.confirmDialog = this.dialogService.confirmModal;
    this.confirmModal = this.confirmDialog.model;
  }

  public confirmed(): void {
    this.confirmDialog.ref.action(ConfirmDialogResult.Confirm);
  }

  public cancel(): void {
    this.confirmDialog.ref.action(ConfirmDialogResult.Cancel);
  }

  public toggleSubMessage(): void {
    if (!this.confirmDialog.model.subMessage) {
      return;
    }
    this.showSubMessage = !this.showSubMessage;
  }

}
