<div class="clr-row">
  <div class="clr-col">

    <ngx-file-drop [multiple]="false" (onFileDrop)="dropped($event)">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="clr-row">
          <div class="clr-col">
            <span class="drag-label" [translate]="'exploration-attachment-upload-label'">You can drag an attachment from your local computer</span>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col">
            <button class="btn btn-primary" type="button" (click)="openFileSelector()"><span
              [translate]="'exploration-attachment-upload-browse'">Browse</span></button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>
<div class="footer">
  <div class="fill-space"></div>
  <button  type="button" class="btn btn-outline"  data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
</div>
