import { RegionModel } from '../components/common/models/region.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';


export interface MetadataRegionStateModel {
  regions: RegionModel[];
}

export class MetadataRegionLoaded {
  static readonly type = '[MetadataRegions] loaded';

  constructor(public regions: RegionModel[]) {
  }
}

@State<MetadataRegionStateModel>({
  name: 'metadataRegionState',
  defaults: { regions: [] }
})
@Injectable()
export class MetadataRegionState {

  constructor() {
  }

  @Selector()
  static regions(state: MetadataRegionStateModel) {
    return state.regions;
  }

  @Action(MetadataRegionLoaded)
  loaded(ctx: StateContext<MetadataRegionStateModel>, action: MetadataRegionLoaded) {

    ctx.patchState({
      regions: action.regions
    });

  }
}
