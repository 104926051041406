import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ExplorationSummaryStatusEnum } from '../components/common/models/exploration-monthly-reports.model';

export enum LevelStateEnum {
  expand = 'expand',
  collapsed = 'collapsed'
}

export interface ExplorationViewStateLevel {
  id: string;
  state: LevelStateEnum;
  statusId: ExplorationSummaryStatusEnum;
}

export interface ExplorationViewStateModel {
  currentSummaryId: string;
  context: string;
  levelStates: ExplorationViewStateLevel[];
}


export class ExplorationViewStateUpdated {
  static readonly type = '[ExplorationViewState] Updated';

  constructor(public levelId: string, public levelState: LevelStateEnum) {
  }
}

export class ExplorationSummaryStatusUpdated {
  static readonly type = '[ExplorationViewState] StatusUpdated';

  constructor(public levelId: string, public statusId: ExplorationSummaryStatusEnum) {
  }
}


export class ExplorationViewStateSelected {
  static readonly type = '[ExplorationViewState] Selected';

  constructor(public levelId: string, public context: string) {
  }
}


@State<ExplorationViewStateModel>({
  name: 'explorationViewState',
  defaults: { context: '', currentSummaryId: undefined, levelStates: [] }
})
@Injectable()
export class ExplorationViewState {

  @Selector()
  static context(state: ExplorationViewStateModel) {
    return state.context;
  }

  @Selector()
  static activeItem(state: ExplorationViewStateModel) {
    return state.currentSummaryId;
  }

  @Selector()
  static levelViewState(state: ExplorationViewStateModel) {
    return (levelId: string) => {
      return state.levelStates.find((level) => level.id === levelId);
    };
  }

  @Action(ExplorationViewStateUpdated)
  update(ctx: StateContext<ExplorationViewStateModel>, action: ExplorationViewStateUpdated) {
    const state = ctx.getState();

    const targetLevel = state.levelStates.find(x => x.id === action.levelId);
    if (!targetLevel) {
      ctx.patchState({
        levelStates: [
          ...state.levelStates,
          { id: action.levelId, state: action.levelState } as ExplorationViewStateLevel
        ]
      });
      return;
    }

    const updatedLevel = {
      ...targetLevel,
      state: action.levelState
    };
    const otherLevels = state.levelStates.filter(x => x.id !== action.levelId);
    ctx.patchState({
      levelStates: [
        ...otherLevels,
        updatedLevel
      ]
    });
  }

  @Action(ExplorationSummaryStatusUpdated)
  updateStatus(ctx: StateContext<ExplorationViewStateModel>, action: ExplorationSummaryStatusUpdated) {
    const state = ctx.getState();

    const targetLevel = state.levelStates.find(x => x.id === action.levelId);
    if (!targetLevel) {
      ctx.patchState({
        levelStates: [
          ...state.levelStates,
          { id: action.levelId, statusId: action.statusId, state: LevelStateEnum.expand } as ExplorationViewStateLevel
        ]
      });
      return;
    }

    const updatedLevel = {
      ...targetLevel,
      statusId: action.statusId
    };
    const otherLevels = state.levelStates.filter(x => x.id !== action.levelId);
    ctx.patchState({
      levelStates: [
        ...otherLevels,
        updatedLevel
      ]
    });
  }


  @Action(ExplorationViewStateSelected)
  select(ctx: StateContext<ExplorationViewStateModel>, action: ExplorationViewStateSelected) {
    const state = ctx.getState();

    ctx.patchState({
      currentSummaryId: action.levelId,
      context: action.context,
      levelStates: [
        ...state.levelStates
      ]
    });
  }

}
