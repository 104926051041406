import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngxs/store";
import { AddManifest, AsyncState } from "app/components/common/models/asyncState.model";
import { DatasourceModel, DatasourceResponse } from "app/components/common/models/datasources.model";
import { VacuumDatasetModel, VacuumDatasetResponse } from "app/components/common/models/vacuum.datasets.model";
import { ManifestFunction, VacuumExecutionsModel, VacuumExecutionsResponse, VacuumTablesModel, VacuumTablesResponse } from "app/components/common/models/vacuum.tables.model";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { CountryModel, CountryResponse } from "../components/common/models/country.model";
import { DepartmentModel, DepartmentResponse } from "../components/common/models/department.model";
import { DomainGroup, DomainGroupMap, DomainSite, DomainSiteMap } from "../components/common/models/domain-site-map.model";
import { DomainModel, DomainResponse } from "../components/common/models/domain.model";
import { DomainsEnum } from "../components/common/models/domains.enum";
import { GroupModel, GroupsResponse } from "../components/common/models/group.model";
import { LanguageModel, LanguageResponse } from "../components/common/models/language.model";
import { LocalMetadataResponse, MonthModel } from "../components/common/models/local-metadata.model";
import { RegionModel, RegionResponse } from "../components/common/models/region.model";
import { RempSiteIdsModel } from "../components/common/models/remp-siteids.model";
import { ReportingMonthModel, ReportingMonthResponse } from "../components/common/models/reporting-month.model";
import { RoleModel, RoleResponse } from "../components/common/models/role.model";
import { SiteModel, SitesResponse } from "../components/common/models/site.model";
import { SubRegionModel, SubRegionResponse } from "../components/common/models/sub-region.model";
import { TableauJwtModel } from "../components/common/models/tableau-jwt.model";
import { TimezoneModel } from "../components/common/models/timezone.model";
import { MetadataCountryLoaded } from "../store/metadata-country.state";
import { MetadataDepartmentLoaded } from "../store/metadata-department.state";
import { MetadataDomainLoaded } from "../store/metadata-domain.state";
import { MetadataRegionLoaded } from "../store/metadata-region.state";
import { MetadataRoleLoaded } from "../store/metadata-role.state";
import { MetadataSiteLoaded } from "../store/metadata-sites.state";
import { MetadataSubregionLoaded } from "../store/metadata-subregions.state";
import { MetadataTagsLoaded } from "../store/metadata-tags.state";
import { UserTableauUpdated } from "../store/tableau-jwt.state";
import { endpoints } from "../utils/endpoints";
import { extractData } from "../utils/extractdata";
import { BaseService } from "./base.service";

export const metadata = {
  authState: {
    domains: [],
    department: {},
    sites: [],
    language_id: "",
    loaded: false
  },
  authSites: [],
  departments: [],
  domainGroups: {} as DomainGroupMap,
  groups: [],
  domains: [],
  domainsAll: [],
  rolesAll: [],
  domainsList: [],
  languagesAll: [],
  languagesUx: [],
  locations: [],
  metrics: [],
  months: [],
  regions: [],
  riskCategories: [],
  risks: [],
  roles: {
    auditor: "",
    evaluations: "",
    productivity: "",
  },
  domainSites: {} as DomainSiteMap,
  standards: [],
  userState: {},
  users: [],
  reportingMonths: [],
  metadataTagsLoaded: false,
  datasources: [],
  datasets: []
};

@Injectable({ providedIn: "root" })
export class MetadataService extends BaseService {

  constructor(protected http: HttpClient,
              protected store: Store,
              private translateService: TranslateService) {
    super(http, store);
  }

  checkMetadata($whichMetadata) {
    let isLoaded = false;
    if (metadata[$whichMetadata].length) {
      isLoaded = true;
    }
    console.log($whichMetadata, isLoaded);
    return isLoaded;
  }

  public isMetadataTagsLoaded(): boolean {
    return metadata.metadataTagsLoaded;
  }

  public validateTranslation(): void {
    if (this.translateService.store.translations[this.translateService.currentLang]) {
      metadata.metadataTagsLoaded = true;
    } else {
      this.translateService.onLangChange.subscribe((data) => {
        this.store.dispatch(new MetadataTagsLoaded(data.translations));
        metadata.metadataTagsLoaded = true;
      });
    }
  }

  getReportingMonthsByYear(languageId: string, year: number): Observable<Array<ReportingMonthModel>> {

    return this.http.get<ReportingMonthResponse>(`${endpoints.common}/reporting_months/${languageId}?reporting_year=${year}`,
      this.httpOptions).pipe(
        map((response: ReportingMonthResponse) => {
          return response.reporting_months;
        })
      );
  }

  getReportingMonth(languageId: string, id): Observable<ReportingMonthModel> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ReportingMonthModel>(`${endpoints.common}/reporting_months/${languageId}/${id}?limit=1000`, this.httpOptions);
  }

  getRoles(languageId: string): Observable<Array<RoleModel>> {
    if (metadata.rolesAll && metadata.rolesAll.length > 0) {
      return of(metadata.rolesAll);
    }

    return this.http.get<RoleResponse>(`${endpoints.common}/roles/${languageId}`, this.httpOptions)
      .pipe(
        map((response: RoleResponse) => {
          metadata.rolesAll = response.roles;
          const state = JSON.parse(JSON.stringify(response.roles));
          this.store.dispatch(new MetadataRoleLoaded(state));
          return metadata.rolesAll;
        })
      );
  }


  getCountries(): Observable<Array<CountryModel>> {
    return this.http.get<CountryResponse>(`${endpoints.common}/countries/${this.getProfileLanguageId()}`, this.httpOptions)
      .pipe(
        map((response: CountryResponse) => {
          const state = JSON.parse(JSON.stringify(response.countries));
          this.store.dispatch(new MetadataCountryLoaded(state));
          return response.countries;
        })
      );
  }

  saveCountry(country: CountryModel): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.put<CountryModel>(`${endpoints.common}/countries/${this.getLanguageId()}/${country.country_id}`, country, this.httpOptions);
  }

  saveNewCountry(country: CountryModel): Observable<any> {
    return this.http.post<CountryModel>(`${endpoints.common}/countries/${this.getLanguageId()}`, country, this.httpOptions);
  }

  getRegions(flush: boolean = false): Observable<Array<RegionModel>> {

    if (flush === true) {
      metadata.regions = [];
    }

    if (metadata.regions && metadata.regions.length > 0) {
      return of(metadata.regions);
    }

    return this.http.get<RegionResponse>(`${endpoints.common}/regions/${this.getProfileLanguageId()}`, this.httpOptions)
      .pipe(
        map((response: RegionResponse) => {
          metadata.regions = response.regions;
          const state = JSON.parse(JSON.stringify(response.regions));
          this.store.dispatch(new MetadataRegionLoaded(state));

          return metadata.regions;
        })
      );
  }

  saveRegion(languageId: string, region: RegionModel): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.put<RegionResponse>(`${endpoints.common}/regions/${this.getLanguageId()}/${region.region_id}`, region, this.httpOptions);
  }

  saveNewRegion(languageId: string, region: RegionModel): Observable<any> {
    return this.http.post<RegionResponse>(`${endpoints.common}/regions/${this.getLanguageId()}`, region, this.httpOptions);
  }

  getSubRegions(): Observable<SubRegionModel[]> {
    return this.http.get<SubRegionResponse>(`${endpoints.sub_regions}/${this.getProfileLanguageId()}`, this.httpOptions).pipe(
      map((response: SubRegionResponse) => {

        const state = JSON.parse(JSON.stringify(response.sub_regions));
        this.store.dispatch(new MetadataSubregionLoaded(state));

        return response.sub_regions;
      }));
  }

  saveNewSubRegion(data: SubRegionModel): Observable<any> {
    return this.http.post<SubRegionModel>(`${endpoints.sub_regions}/${this.getLanguageId()}`, data, this.httpOptions);
  }

  saveSubRegion(id: string, data: SubRegionModel): Observable<any> {
    return this.http.put<SubRegionModel>(`${endpoints.sub_regions}/${this.getLanguageId()}/${id}`, data, this.httpOptions);
  }

  getDomains(): Observable<Array<DomainModel>> {
    if (metadata.domainsAll && metadata.domainsAll.length > 0) {
      return of(metadata.domainsAll);
    }

    return this.http.get<DomainResponse>(`${endpoints.domains}/${this.getProfileLanguageId()}`, this.httpOptions)
      .pipe(
        map((response: DomainResponse) => {
          metadata.domainsAll = response.domains;
          const state = JSON.parse(JSON.stringify(response.domains));
          this.store.dispatch(new MetadataDomainLoaded(state));
          return metadata.domainsAll;
        })
      );
  }

  getSite(siteId: string): Observable<SiteModel> {
    return this.http.get<SiteModel>(`${endpoints.sites}/${this.getProfileLanguageId()}/${siteId}`);
  }

  saveSite(site: SiteModel): Observable<any> {
    return this.http.put<SiteModel>(`${endpoints.common}/sites/${this.getLanguageId()}/${site.site_id}`, site);
  }

  saveNewSite(site: SiteModel): Observable<any> {
    return this.http.post<SiteModel>(`${endpoints.common}/sites/${this.getLanguageId()}`, site);
  }

  saveDomain(domain: DomainModel): Observable<any> {
    return this.http.put<DomainModel>(`${endpoints.common}/domains/${this.getLanguageId()}/${domain.domain_id}`, domain);
  }

  getDomainSites(domainId: string, clear?: boolean): Observable<Array<SiteModel>> {

    if (!clear && metadata.domainSites[domainId]) {
      return of(metadata.domainSites[domainId].sites);
    }

    const url = (domainId === DomainsEnum.COMMON || domainId === DomainsEnum.DATATOOL) ? `${endpoints.sites}/${this.getProfileLanguageId()}?limit=500`
      : `${endpoints.domains}/${this.getProfileLanguageId()}/${domainId}/sites`;

    return this.http.get<SitesResponse>(url)
      .pipe(
        map((response: SitesResponse) => {
          if (clear === true) {
            metadata.domainSites[domainId] = undefined;
          }
          metadata.domainSites[domainId] = { domainId, sites: response.sites } as DomainSite;

          const state = JSON.parse(JSON.stringify(response.sites));
          this.store.dispatch(new MetadataSiteLoaded(domainId, state));
          return response.sites;
        })
      );
  }

  getDomainGroups(domainId: string, clear?: boolean): Observable<Array<GroupModel>> {

    if (!clear && metadata.domainGroups[domainId]) {
      return of(metadata.domainGroups[domainId].groups);
    }

    const url = `${endpoints.common}/groups/${this.getProfileLanguageId()}?domain_id=${domainId}`;

    return this.http.get<GroupsResponse>(url)
      .pipe(
        map((response: GroupsResponse) => {
          if (clear === true) {
            metadata.domainGroups[domainId] = undefined;
          }
          metadata.domainGroups[domainId] = { domainId, groups: response.groups } as DomainGroup;
          return response.groups;
        })
      );


  }


  getAllLanguages(userId: string) {
    if (metadata.languagesAll && metadata.languagesAll.length > 0) {
      return of(metadata.languagesAll);
    }
    // tslint:disable-next-line:max-line-length
    return this.http.get<LanguageResponse>(`${endpoints.languages}/${this.getProfileLanguageId()}?login_user_id=${userId}`, this.httpOptions)
      .pipe(
        map((response: LanguageResponse) => {
          metadata.languagesAll = response.languages;
          return response.languages;
        })
      );
  }


  getSentinelLanguages(userId: string): Observable<Array<LanguageModel>> {
    if (metadata.languagesUx && metadata.languagesUx.length > 0) {
      return of(metadata.languagesUx);
    }
    // tslint:disable-next-line:max-line-length
    return this.http.get<LanguageResponse>(`${endpoints.languages}/${this.getLanguageId()}?login_user_id=${userId}&sentinel_support=True`, this.httpOptions)
      .pipe(
        map((response: LanguageResponse) => {
          metadata.languagesUx = response.languages;
          return response.languages;
        })
      );
  }

  getDepartments(): Observable<Array<DepartmentModel>> {
    return this.http.get<DepartmentResponse>(`${endpoints.departments}/${this.getProfileLanguageId()}?sentinel_support=true`).pipe(
      map((response) => {
        const state = JSON.parse(JSON.stringify(response.departments));
        this.store.dispatch(new MetadataDepartmentLoaded(state));
        return response.departments;
      })
    );
  }

  getUserAuthorization(email: string, override?: boolean): Observable<any> {
    if (metadata.authState && metadata.authState.loaded === true && !override) {
      return of(metadata.authState);
    }
    return this.http.get(`${endpoints.userAuth}/en/${email}`, this.httpOptions).pipe(
      map((data: any) => {
        metadata.authState = data;
        metadata.authState.loaded = true;
        return data;
      })
    );
  }

  getUserAuthorizationSiteList(email: string, domainId: string): Observable<Array<RempSiteIdsModel>> {
    return this.http.get<Array<RempSiteIdsModel>>(`${endpoints.userAuth}/en/${email}/domains/${domainId}/sites`, this.httpOptions).pipe(
      map((data: any) => {
        metadata.authSites = JSON.parse(data);
        return metadata.authSites;
      })
    );
  }

  getUserTableauJWT(email: string): Observable<TableauJwtModel> {
    return this.http.get<TableauJwtModel>(`${endpoints.userAuth}/en/${email}/tableau_jwt`, this.httpOptions).pipe(
      map((data: TableauJwtModel) => {
        this.store.dispatch(new UserTableauUpdated(data.tableau_jwt));
        return data;
      })
    );
  }

  getUser(email: string): Observable<any> {
    return this.http.get(`${endpoints.users}/${email}?login_user_id=${email}`, this.httpOptions);
  }

  public downloadUserList(domain: string): Observable<any> {
    return this.http.get(`${endpoints.common}/domains/${this.getProfileLanguageId()}/${domain}/user_list`,
      { observe: "response", responseType: "text" });
  }

  public getDownloadFileUrl(fileId: string, fileName: string): string {
    return `${endpoints.files}/${fileId}?file_name=${fileName}`;
  }

  private cleanFileName(fileName: string): string {
    if (!fileName.includes("&")) {
      return fileName;
    }

    return this.cleanFileName(fileName.replace("&", "-"));
  }

  public downloadFile(fileId: string, fileName: string): Observable<any> {
    const cleanFileName = this.cleanFileName(fileName);
    return this.http.get(`${endpoints.files}/${fileId}?file_name=${cleanFileName}`,
      { observe: "response", responseType: "blob" as "json" });
  }

  public downloadFilePath(fileId: string, path: string): Observable<any> {
    return this.http.get(`${endpoints.files}/${fileId}?path=${path}`,
      { observe: "response", responseType: "blob" as "json" });
  }

  private scrubFile(file: any): string {
    const regex = new RegExp("data:.+?;base64,");
    const match = regex.exec(file);
    if (!match || match.length === 0) {
      return file;
    }

    return file.replace(match, "");
  }

  getFile(file: string, path: string): Observable<any> {
    return this.http.get(`${endpoints.files}/${file}?path=${path}`).pipe(
      map((data: any) => {
        if (data.file) {
          data.exists = true;
          data.file = this.scrubFile(data.file);
          const binary = atob(data.file);
          const length = binary.length;
          const buffer = new ArrayBuffer(length);
          const view = new Uint8Array(buffer);
          for (let i = 0; i < length; i++) {
            view[i] = binary.charCodeAt(i);
          }
          data.blob = new Blob([view], { type: "application/pdf" });
        } else {
          data.exists = false;
        }
        return data;
      })
    );
  }

  async getMonths(): Promise<Array<MonthModel>> {
    if (metadata.months) {
      return metadata.months;
    }

    await this.loadLocalMetadata();
    return metadata.months;
  }

  async loadLocalMetadata(): Promise<void> {
    const results: any = await this.http.get<LocalMetadataResponse>(endpoints.localMetadata, this.httpOptions).toPromise();
    metadata.months = results.months;
    metadata.riskCategories = results.riskCategories;
  }

  getMetadata($endpoint): Observable<any> {
    return this.http.get($endpoint, this.httpOptions).pipe(
      map(extractData).bind(this));
  }

  postFeedback($endpoint, $body): Observable<any> {
    return this.http.post($endpoint, $body, this.httpOptions).pipe(
      map(extractData).bind(this));
  }

  putUser($endpoint, $body): Observable<any> {
    return this.http.put($endpoint, $body, this.httpOptions).pipe(
      map(extractData).bind(this));
  }

  getTimezones(): Array<TimezoneModel> {
    return [
      { timezone_id: "ACDT", timezone_name: "Australian Central Daylight Time", timezone_offset: "UTC+1" },
      { timezone_id: "ACST", timezone_name: "Australian Central Standard Time", timezone_offset: "UTC" }
    ];
  }

  truncateRedis(collection: string): Observable<any> {
    return this.http.post<any>(`${endpoints.common}/redis/${collection}/truncate`, {});
  }

  public getVacuumTables(databaseId: string, datasetId: string): Observable<Array<VacuumTablesModel>> {
    // ?datasource.datasource_id=${databaseId}&vacuum_dataset.vacuum_dataset_id=${datasetId}
    return this.http.get<VacuumTablesResponse>(`${endpoints.vacuum_tables}`, this.httpOptions).pipe(
      map((data: VacuumTablesResponse) => {
        return data.manifests;
      })
    );
  }

  public getVacuumTable(tableID: string): Observable<VacuumTablesModel> {
    return this.http.get<VacuumTablesModel>(`${endpoints.vacuum_tables}/${tableID}`, this.httpOptions).pipe(
      map((data: VacuumTablesModel) => {
        return data;
      })
    );
  }

  public saveVacuumNewTable(data: VacuumTablesModel): Observable<any> {
    return this.http.post<VacuumTablesModel>(`${endpoints.vacuum_tables}`, data, this.httpOptions);
  }

  public saveVacuumTable(tableId, data: VacuumTablesModel): Observable<any> {
    return this.http.put<VacuumTablesModel>(`${endpoints.vacuum_tables}/${tableId}`, data, this.httpOptions);
  }

  public deleteVacuumTable(tableID: string): Observable<any> {
    return this.http.delete(`${endpoints.vacuum_tables}/${tableID}`, this.httpOptions);
  }

  public runExtractNow(tableID: string): Observable<any> {
    return this.http.post(`${endpoints.vacuum_tables}/${tableID}/run_now`, null, this.httpOptions);
  }

  public cancelExtract(vacuumExecutionID: string): Observable<any> {
    return this.http.patch(`${endpoints.vacuum_executions}/${vacuumExecutionID}`, {status: "cancel"}, this.httpOptions);
  }

  public vacuumExecutions(manifestId: string): Observable<Array<VacuumExecutionsModel>> {
    return this.http.get<VacuumExecutionsResponse>(`${endpoints.vacuum_executions}?manifest_id=${manifestId}`, this.httpOptions).pipe(
      map((data: VacuumExecutionsResponse) => {
        return data.vacuum_executions;
      })
    );
  }

  public getDataSources(cache: boolean = true): Observable<Array<DatasourceModel>> {
    if (metadata.datasources && metadata.datasources.length > 0) {
      return of(metadata.datasources);
    }

    return this.http.get<DatasourceResponse>(`${endpoints.datasources}?cache=${cache}`, this.httpOptions).pipe(
      map((data: DatasourceResponse) => {
        metadata.datasources = data.datasources;
        return metadata.datasources;
      })
    );
  }

  public getVacuumDataSets(): Observable<Array<VacuumDatasetModel>> {
    if (metadata.datasets && metadata.datasets.length > 0) {
      return of(metadata.datasets);
    }

    return this.http.get<VacuumDatasetResponse>(endpoints.vacuum_datasets, this.httpOptions).pipe(
      map((data: VacuumDatasetResponse) => {
        metadata.datasets = data.vacuum_datasets;
        return metadata.datasets;
      })
    );
  }

  public addManifest(data: AddManifest): Observable<any> {
    return this.http.post<AddManifest>(`${endpoints.vacuum_tables}/add`, data, this.httpOptions);
  }

  public getManifestStatus(transactionId: string): Observable<AsyncState> {
    return this.http.get<AsyncState>(`${endpoints.async_states}/en/${transactionId}`, this.httpOptions).pipe(
      map((data: AsyncState) => {
        return data;
      })
    );
  }

  public addSelectedManifests(data: ManifestFunction): Observable<any> {
    return this.http.post<ManifestFunction>(`${endpoints.vacuum_tables}`, data, this.httpOptions);
  }
}
