import { AuthService } from '../services/auth.service';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserAuthState, UserAuthStateImpersonate } from '../store';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public authSvc: AuthService,
              public route: Router,
              private store: Store) {
  }

  // Intercepts all http service calls
  // 404: silent, keep the app going
  // 401 or 403: token is expired or unauthorized... log the user out
  // Logout also clears LS data from browser, helpful for token expiry
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const uidToken = this.authSvc.getToken();
    if (uidToken) {
      const imp = this.store.selectSnapshot(UserAuthState.userAuth);

      const impdHeaders = new HttpHeaders()
        .set('Authorization', `Bearer ${uidToken}`)
        .set('ImpId', imp.impId);

      const authReq = imp.impId ?
        req.clone({ headers: impdHeaders }) :
        req.clone({ setHeaders: { Authorization: `Bearer ${ uidToken }` } });

      return next.handle(authReq).pipe(
        catchError(
          err =>
            new Observable<HttpEvent<any>>(observer => {
              if (err instanceof HttpErrorResponse) {
                const errResp = err as HttpErrorResponse;
                if (errResp.status === 401 || errResp.status === 403) {
                  console.log('auth error - logout user');
                  this.authSvc.logout(true);
                }
              }
              observer.error(err);
              observer.complete();
            })
        )
      );
    } else if (!uidToken && this.route.url !== '/login') {
      const authReq = req.clone({ setHeaders: { Authorization: `Bearer ${ uidToken }` } });
      return next.handle(authReq).pipe(
        catchError(
          err =>
            new Observable<HttpEvent<any>>(observer => {
              if (err instanceof HttpErrorResponse && err.status === 404) {
                return;
              }
              if (err instanceof HttpErrorResponse) {
                const errResp = err as HttpErrorResponse;
                if (errResp.status === 401 || errResp.status === 403) {
                  console.log('auth error - logout user');
                  this.authSvc.logout(true);
                }
              }
              observer.error(err);
              observer.complete();
            })
        )
      );
    } else {
      return next.handle(req);
    }
  }

}
