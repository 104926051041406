import {Component, Input} from '@angular/core';
import {ColumnValuesMappingBaseComponent} from '../ColumnValuesMappingBaseComponent';
import {Router} from '@angular/router';
import {LandService} from '../../../../../../services/land.service';
import {DialogService} from '../../../../../../services/dialog.service';
import {
  ColumnMappingModel, DataModel,
  MappingQueryTypes,
  MappingTypes, MapTargetValues,
  ValueDataModel,
  ValueMappingModel,
  ValueMappingQueryTypes
} from '../../../../../common/models/data.model';
import {forkJoin} from 'rxjs';
import {ModalStates} from '../../../../../common/models/modal-state.model';
import {DataModelService} from '../../../../../../services/data-model.service';
import {FormDataModelValueComponent} from '../../form-data-model-value/form-data-model-value.component';
import {LogService} from '../../../../../../services/log.service';

@Component({
  selector: 'app-column-category-values-source',
  templateUrl: './column-category-values-source.component.html',
  styleUrls: ['./column-category-values-source.component.css']
})
export class ColumnCategoryValuesSourceComponent extends ColumnValuesMappingBaseComponent {
  public selectedDataModelId: string;
  public mappedDataModels: DataModel[];

  public allColumnMappings: ColumnMappingModel[];
  public targetValues: { [key: string]: MapTargetValues };

  public loadingDataModels: boolean;

  public state: ModalStates;
  public stateEnum = ModalStates;
  public editable: boolean;

  private allValueMaps: ValueMappingModel[];

  constructor(
    protected router: Router,
    protected landService: LandService,
    protected dataModelService: DataModelService,
    protected dialogService: DialogService,
    protected logService: LogService) {
    super(
      router,
      landService,
      dataModelService,
      dialogService, logService);
  }

  @Input()
  set modal_state(state: ModalStates) {
    this.state = state;
    this.editable = this.state !== this.stateEnum.VIEW;
  }

  protected setup(): void {
    if (!this.user || !this.column || !this.table || !this.dataModel) {
      return;
    }

    this.getColumnMappings();
  }

  private getColumnMappings(): void {
    this.loading = true;
    this.loadingDataModels = true;

    this.landService.getColumnMappings(MappingQueryTypes.source,
      this.column.column_model_id).subscribe((results: ColumnMappingModel[]) => {
      this.loading = false;
      this.setupMappedDataModels(results);
      this.changeDataModel();
      this.loadingDataModels = false;
    }, () => {
      this.loading = false;
      this.loadingDataModels = false;
      this.columnMappings = [];
    });
  }

  private setupMappedDataModels(columnMappings: ColumnMappingModel[]): void {
    this.mappedDataModels = [];
    this.selectedDataModelId = undefined;
    this.allColumnMappings = [];
    this.columnMappings = [];

    if (!columnMappings || columnMappings.length === 0) {
      return;
    }
    this.allColumnMappings = columnMappings;
    columnMappings.forEach((columnMapping) => {
      const dataModel = columnMapping.target.table.data_model;
      const existingModel = this.mappedDataModels.find(d => d.data_model_id === dataModel.data_model_id);
      if (existingModel) {
        return;
      }
      this.mappedDataModels.push(dataModel);
    });
    this.selectedDataModelId = this.mappedDataModels[0].data_model_id;
  }

  public changeDataModel(): void {
    this.columnMappings = this.allColumnMappings.filter(c => c.target.table.data_model.data_model_id === this.selectedDataModelId);
    this.getColumnValues();
    this.getTargetValues();
  }

  private getTargetValues(): void {

    this.columnMappings.forEach(columnMapping => {

      const split = columnMapping.target.column_model_id.split('::');
      const dataModelId = columnMapping.target.table.data_model.data_model_id;
      const tableId = split[split.length - 2];
      const columnId = split[split.length - 1];

      this.loadTargetValue(columnMapping.target.column_model_id, dataModelId, tableId, columnId);

    });
  }

  private loadTargetValue(columnModelId: string, dataModelId: string, tableId: string, baseColumnId: string): void {

    if (!this.targetValues) {
      this.targetValues = {};
    }

    if (!this.targetValues[columnModelId]) {
      this.targetValues[columnModelId] = {values: [], columnId: baseColumnId, loading: false} as MapTargetValues;
    }

    if (this.targetValues[columnModelId].values.length > 0) {
      return;
    }

    this.targetValues[columnModelId].loading = true;
    this.dataModelService.getColumnValues(dataModelId, tableId, baseColumnId).subscribe(results => {
      this.targetValues[columnModelId].values = results;
      this.targetValues[columnModelId].loading = false;
    }, () => {
      this.targetValues[columnModelId].values = [];
      this.targetValues[columnModelId].loading = false;
    });
  }

  private getColumnValues(): void {
    if (this.columnValues?.length > 0) {
      return;
    }
    this.loading = true;

    const calls = [this.dataModelService.getColumnValues(
      this.dataModel.data_model_id, this.table.table_model_base_id, this.column.column_model_base_id),
      this.landService.getValueMappings(ValueMappingQueryTypes.source, this.column.column_model_id)
    ];

    forkJoin(calls).subscribe(results => {
      const values: any[] = results[0];
      this.columnValues = values;

      const allValueMaps: any[] = results[1];
      this.allValueMaps = allValueMaps;

      console.log('allValueMaps');
      console.log(this.allValueMaps);

      const maps = {};
      allValueMaps.forEach((valueMap) => {
        if (!maps[valueMap.source.value_model_id]) {
          maps[valueMap.source.value_model_id] = [];
        }
        maps[valueMap.source.value_model_id].push(valueMap);
      });

      values.forEach((value: ValueDataModel) => {

        if (!maps[value.value_model_id]) {
          return;
        }
        const valueMaps = maps[value.value_model_id];
        if (!valueMaps || valueMaps.length === 0) {
          return;
        }

        valueMaps.forEach(valueMap => {
          const targetColumnId = valueMap.target.column.column_model_id;
          value[targetColumnId + '_mapping_id'] = valueMap.mapping_id;
          console.log(`target:${targetColumnId}:${valueMap.target.value_model_name}`);
          value[targetColumnId] = valueMap.target.value_model_name;
          value[targetColumnId + '_id'] = valueMap.target.value_model_id;
        });
        console.log(value);
      });
      this.loading = false;
    }, () => {
      this.loading = false;
    });

  }

  public edit(): void {
    setTimeout(() => {
      this.state = ModalStates.EDIT;
    });
  }

  public save(): void {
    this.saving = true;
    this.loading = true;
    const saveCalls = [];

    this.columnValues.forEach(columnValue => {

      this.allColumnMappings.forEach(columnMapping => {
        const targetColId = columnMapping.target.column_model_id;

        if (!columnValue[targetColId + '_id']) {
          return;
        }

        if (!this.targetValues[targetColId]
          || !this.targetValues[targetColId].values
          || this.targetValues[targetColId].values.length === 0) {
          return;
        }

        const existingMappingId = columnValue[targetColId + '_mapping_id'];
        const targetValueModelId = columnValue[targetColId + '_id'];
        const targetValue = this.targetValues[targetColId].values.find(t => t.value_model_id === targetValueModelId);

        const isNew: boolean = !existingMappingId;

        if (isNew) {
          const map = this.createNewColumnMapping(columnValue, targetValue);

          saveCalls.push(this.landService.saveNewValueMapping(map));

        } else {
          const existingMap = this.allValueMaps.find(m => m.mapping_id === existingMappingId);
          if (!existingMap || existingMap.target.value_model_id === targetValue.value_model_id) {
            return;
          }
          existingMap.target = targetValue;
          saveCalls.push(this.landService.saveValueMapping(existingMap.mapping_id, existingMap));
        }

      });

    });

    forkJoin(saveCalls).subscribe(() => {
      this.columnValues = undefined;
      this.saving = false;
      this.loading = false;
      this.state = ModalStates.VIEW;
      this.getColumnMappings();
    }, () => {
    });
  }

  private createNewColumnMapping(value: ValueDataModel, targetValue: ValueDataModel): ValueMappingModel {
    return {
      mapping_type: MappingTypes.value,
      source: value,
      target: targetValue,
    } as ValueMappingModel;
  }

  public editValue(value: ValueDataModel): void {
    const dialogRef = this.dialogService.openDialog('FormLandValueComponent',
      FormDataModelValueComponent,
      'land-view-value', 'xxl', {
        displayMode: ModalStates.EDIT,
        value
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }
}
