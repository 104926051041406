import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ThingModel } from '../components/common/models/group.model';


export interface MetadataThingStateModel {
  things: ThingModel[];
}

export class MetadataThingLoaded {
  static readonly type = '[MetadataThing] loaded';

  constructor(public things: ThingModel[]) {
  }
}

@State<MetadataThingStateModel>({
  name: 'metadataThingState',
  defaults: { things: [] }
})
@Injectable()
export class MetadataThingState {

  constructor() {
  }

  @Selector()
  static things(state: MetadataThingStateModel) {
    return state.things;
  }

  @Action(MetadataThingLoaded)
  loaded(ctx: StateContext<MetadataThingStateModel>, action: MetadataThingLoaded) {

    ctx.patchState({
      things: action.things
    });

  }
}
