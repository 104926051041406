import { Component } from '@angular/core';
import {ConfirmDialogResult, DisplayModalDialogRef} from '../../../../common/models/dialog.model';
import {Router} from '@angular/router';
import {DialogService} from '../../../../../services/dialog.service';
import {BaseComponent} from '../../../../common/base-component';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-link-component-form',
  templateUrl: './link-component-form.component.html',
  styleUrls: ['./link-component-form.component.css']
})
export class LinkComponentFormComponent extends BaseComponent {
  protected modalRef: DisplayModalDialogRef;

  public text: string;
  public url: string;

  constructor(protected router: Router,
              protected dialogService: DialogService,
              protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
  }

  protected setUser(user: any): void {
  }

  public isValid(): boolean {

    if (this.url?.length <= 0) {
      return false;
    }

    return true;
  }

  public finished(): void {
    if (!this.isValid()) {
      return;
    }
    this.modalRef.close(ConfirmDialogResult.Confirm, {text: this.text, url: this.url});
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }
}
