import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';

export enum MessageObject {
  dataTool = 'dataTool',
  summary = 'summary',
  land = 'land'
}

export enum MessageType {
  report = 'report',
  rollup = 'rollup',
  dataDictionary = 'dataDictionary'
}

export interface Message {
  object: MessageObject;
  type: MessageType;
  id: string;
  sourceId: string;
  handled: boolean;
  timeout: boolean;
  sentOn: Date;
  receivedOn: Date;
  data: any;
}

export class SendMessage {
  static readonly type = '[Message] Send';

  constructor(public message: Message) {
    message.timeout = false;
    message.sentOn = new Date();
    message.receivedOn = null;
    message.data = null;
    message.handled = false;
  }
}

export class ReceivedMessage {
  static readonly type = '[Message] Received';

  constructor(public object: MessageObject, public id: string, public data: any) {
  }
}

export class ReceivedSourceMessage {
  static readonly type = '[Message] Received Source';

  constructor(public object: MessageObject, public sourceId: string, public data: any) {
  }
}

export class HandledMessage {
  static readonly type = '[Message] Handled';

  constructor(public id: string) {
  }
}

export class TimeoutMessage {
  static readonly type = '[Message] Timeout';

  constructor(public id: string) {
  }
}


export interface MessagesStateModel {
  messages: Message[];
}

@State<MessagesStateModel>({
  name: 'messages',
  defaults: { messages: [] }
})
@Injectable()
export class MessagesState {

  @Selector()
  static dataTool(state: MessagesStateModel) {
    return (id: string) => {
      return state.messages.filter(obj => obj.object === MessageObject.dataTool).find(msg => msg.id === id);
    };
  }

  @Selector()
  static summary(state: MessagesStateModel) {
    return (id: string) => {
      return state.messages.filter(obj => obj.object === MessageObject.summary).find(msg => msg.id === id);
    };
  }

  @Selector()
  static dataDictionary(state: MessagesStateModel) {
    return (id: string) => {
      return state.messages.filter(obj => obj.object === MessageObject.land).find(msg => msg.id === id);
    };
  }

  @Selector()
  static notReceivedMessages(state: MessagesStateModel) {
    return state.messages.filter(msg => !msg.receivedOn && !msg.timeout);
  }

  @Action(SendMessage)
  send(ctx: StateContext<MessagesStateModel>, action: SendMessage) {
    const state = ctx.getState();
    const targetMessage = state.messages.find(x => x.id === action.message.id);

    if (!targetMessage) {
      ctx.patchState({
        messages: [
          ...state.messages,
          action.message,
        ]
      });
      return;
    }

    const updatedMessage = {
      ...targetMessage,
      handled: false,
      timeout: false,
      sentOn: new Date(),
      receivedOn: null,
    };
    const otherMessages = state.messages.filter(x => x.id !== action.message.id);
    ctx.patchState({
      messages: [
        ...otherMessages,
        updatedMessage
      ]
    });

  }

  @Action(ReceivedMessage)
  received(ctx: StateContext<MessagesStateModel>, payload: ReceivedMessage) {
    const state = ctx.getState();
    const messages = state.messages;
    const targetMessage = messages.find(x => x.id === payload.id);
    const otherMessages = messages.filter(x => x.id !== payload.id);

    if (targetMessage) {

      if (targetMessage.handled) {
        return;
      }

      const updatedMessage = {
        ...targetMessage,
        data: payload.data,
        receivedOn: new Date(),
        timeout: false
      };

      ctx.patchState({
        messages: [
          ...otherMessages,
          updatedMessage
        ]
      });
    }
  }

  @Action(ReceivedSourceMessage)
  receivedSource(ctx: StateContext<MessagesStateModel>, payload: ReceivedSourceMessage) {
    const state = ctx.getState();
    const messages = state.messages;
    const targetMessage = messages.find(x => x.sourceId === payload.sourceId);
    const otherMessages = messages.filter(x => x.sourceId !== payload.sourceId);

    if (targetMessage) {
      if (targetMessage.handled) {
        return;
      }

      const updatedMessage = {
        ...targetMessage,
        data: payload.data,
        receivedOn: new Date()
      };

      ctx.patchState({
        messages: [
          ...otherMessages,
          updatedMessage
        ]
      });
    }
  }


  @Action(HandledMessage)
  handled(ctx: StateContext<MessagesStateModel>, payload: HandledMessage) {
    const state = ctx.getState();
    const messages = state.messages;
    const targetMessage = messages.find(x => x.id === payload.id);
    const otherMessages = messages.filter(x => x.id !== payload.id);

    if (targetMessage) {
      // const updatedMessage = {
      //   ...targetMessage,
      //   handled: true
      // };

      ctx.patchState({
        messages: [
          ...otherMessages,
          //    updatedMessage
        ]
      });
    }
  }

  @Action(TimeoutMessage)
  timeout(ctx: StateContext<MessagesStateModel>, payload: HandledMessage) {
    const state = ctx.getState();
    const messages = state.messages;
    const targetMessage = messages.find(x => x.id === payload.id);
    const otherMessages = messages.filter(x => x.id !== payload.id);

    if (targetMessage) {
      const updatedMessage = {
        ...targetMessage,
        timeout: true
      };

      ctx.patchState({
        messages: [
          ...otherMessages,
          updatedMessage
        ]
      });
    }
  }


}


