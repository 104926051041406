import {Component, Input} from '@angular/core';
import {DataModelTypes, RichTextModel, ValueDataModel} from '../../../../common/models/data.model';
import {DisplayModalDialogRef} from '../../../../common/models/dialog.model';
import {BaseComponent} from '../../../../common/base-component';
import {Router} from '@angular/router';
import {DialogService} from '../../../../../services/dialog.service';
import {ModalStates} from '../../../../common/models/modal-state.model';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-data-model-value-details',
  templateUrl: './data-model-value-details.component.html',
  styleUrls: ['./data-model-value-details.component.css']
})
export class DataModelValueDetailsComponent extends BaseComponent {
  protected modalRef: DisplayModalDialogRef;

  public value: ValueDataModel;
  public state: ModalStates;
  public modalStates = ModalStates;


  constructor(
    protected router: Router,
    private dialogService: DialogService,
    protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.value = this.dialogService.displayModal.current.data.value;
    this.dialogService.displayModal.current.secondHeader = ` for ${this.value.column.table.table_model_name}::${this.value.column.column_model_name}::${this.value.value_model_name}`;
  }

  protected setUser(user: any): void {
  }

  @Input()
  set datasource_value(value: ValueDataModel) {

    if (!value.value_description) {
      value.value_description = {
        html_text: '',
        plain_text: '',
        markdown_text: ''
      } as RichTextModel;
    }

    this.value = value;
  }

  @Input()
  set modal_state(state: ModalStates) {
    this.state = state;
  }

  public canEditValue(): boolean {

    if (!this.value) {
      return false;
    }

    return this.value.column.table.data_model.data_model_type === DataModelTypes.TARGET;
  }

}
