import { Component, Input, OnInit } from '@angular/core';
import { ColumnDataModel } from '../../../../common/models/data.model';

@Component({
  selector: 'app-data-model-column-statistics',
  templateUrl: './data-model-column-statistics.component.html',
  styleUrls: ['./data-model-column-statistics.component.css']
})
export class DataModelColumnStatisticsComponent implements OnInit {
  public column: ColumnDataModel;
  constructor() { }

  ngOnInit(): void {
  }


  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
  }
}
