import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FleetModel } from '../components/common/models/fleet.model';


export interface MetadataFleetStateModel {
  fleets: FleetModel[];
}

export class MetadataFleetLoaded {
  static readonly type = '[MetadataFleet] loaded';

  constructor(public fleets: FleetModel[]) {
  }
}

@State<MetadataFleetStateModel>({
  name: 'metadataFleetState',
  defaults: { fleets: [] }
})
@Injectable()
export class MetadataFleetState {

  constructor() {
  }

  @Selector()
  static fleets(state: MetadataFleetStateModel) {
    return state.fleets;
  }

  @Action(MetadataFleetLoaded)
  loaded(ctx: StateContext<MetadataFleetStateModel>, action: MetadataFleetLoaded) {

    ctx.patchState({
      fleets: action.fleets
    });

  }
}
