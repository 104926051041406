<div class="modal-body">
  <div class="clr-row" *ngIf="value?.column.table.data_model.data_model_type === dataModelTypes.TARGET">
    <div class="clr-col">
      <ng-container>
        <clr-datagrid [clrDgLoading]="loadingSourceValues" >
          <clr-dg-column [clrDgField]="'source.column.table.data_model.data_model_name'">
            <span [translate]="'land-map-target-datamodel'">column-value</span>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'source.column.table.table_model_name'">
            <span [translate]="'land-map-target-table'">column-value</span>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'source.column.column_model_name'">
            <span [translate]="'land-map-target-column'">column-value</span>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'source.value_model_name'">
            <span [translate]="'column-value'">column-value</span>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let value of sourceValueMaps">
            <clr-dg-cell>{{ value.source.column.table.data_model.data_model_name }}</clr-dg-cell>
            <clr-dg-cell>{{ value.source.column.table.table_model_name }}</clr-dg-cell>
            <clr-dg-cell>{{ value.source.column.column_model_name }}</clr-dg-cell>
            <clr-dg-cell>
                {{value.source.value_model_name}}
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                                [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
              <span [translate]="'evaluations-grid-foot-label-1'"></span>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              <span [translate]="'evaluations-grid-foot-label-2'"></span>
              {{ pagination.totalItems }}
              <span [translate]="'category-values-project-footer'"></span>
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </ng-container>
    </div>
  </div>
  <div class="clr-row" *ngIf="value?.column.table.data_model.data_model_type === dataModelTypes.SOURCE">

    <div class="clr-col-4">
      <clr-select-container>
        <label [translate]="'column-target-datamodel'" class="select-label"></label>
        <select
          (change)="changeSelectedMapping()"
          clrSelect
          name="datamodel"
          [(ngModel)]="selectedDataModelId"
          [disabled]="loadingDataModels">
          <option *ngFor="let mapping of columnMappings" [ngValue]="mapping.target.table.data_model.data_model_id">
            {{ mapping.target.table.data_model.data_model_name }}
          </option>
        </select>
      </clr-select-container>
    </div>
    <div class="clr-col-4">
      <clr-select-container *ngIf="!loadingTargetValues && targetColumnValues?.length > 0">
        <label [translate]="'target-column-values'" class="select-label">target-column-values</label>
        <select clrSelect name="target-value" [(ngModel)]="selectedValueId">
          <option *ngFor="let value of targetColumnValues" [ngValue]="value.value_model_id">
            {{ value.value_model_name }}
          </option>
        </select>
      </clr-select-container>
      <div class="value-row" *ngIf="loadingTargetValues" >
        <label [translate]="'target-column-values'" class="select-label">target-column-values</label>
        <span class="select-label" [translate]="'column-value-loading-target-values'"></span>
      </div>
      <div class="value-row" *ngIf="!loadingTargetValues && targetColumnValues?.length === 0">
        <label [translate]="'target-column-values'" class="select-label">target-column-values</label>
        <span class="select-label" [translate]="'column-value-no-target-values'"></span>
      </div>
    </div>

  </div>

</div>
<div class="modal-footer">
  <button (click)="close()" type="button" class="btn btn-outline" data-cy="form-close">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
  <button *ngIf="value?.column.table.data_model.data_model_type === dataModelTypes.SOURCE" [disabled]="!targetColumnValues || targetColumnValues.length === 0 || saving" (click)="save()" type="button" class="btn btn-primary"
          data-cy="form-submit">
    <span [translate]="'land-column-btn-save'"></span>
  </button>
</div>
