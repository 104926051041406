import { Observable } from 'rxjs';
import { DialogComponentUnloadComponent } from '../../../store/dialog.state';
import { ComponentRef } from '@angular/core';
import {LogService} from '../../../services/log.service';
import {Router} from '@angular/router';

export interface GenericComponentInteraction {
  isOpen: boolean;
  ref: GenericComponentDialogRef;
}


export class GenericComponentDialogRef {
  private observer: any;
  public dialog: GenericComponentInteraction;
  public afterClosed: Observable<DisplayModalDialogResults>;

  constructor(model: GenericComponentInteraction) {
    this.dialog = model;
    this.afterClosed = new Observable<DisplayModalDialogResults>((observer) => {
      this.observer = observer;
    });
  }

  public close(action: ConfirmDialogResult, data?: any) {
    this.dialog.isOpen = false;
    if (!this.observer) {
      return;
    }
    const result = {
      action,
      data
    };
    this.observer.next(result);
    this.observer.complete();
  }
}


export interface ConfirmDialog {
  isOpen: boolean;
  model: ConfirmDialogModel;
  ref: ConfirmDialogRef;
}

export interface ConfirmDialogModel {
  titleTranslateId: string;
  type: ConfirmDialogTypeEnum;
  message: string;
  html?: string;
  subMessage: string;
  cancelButton: ConfirmButtonModel;
  confirmButton: ConfirmButtonModel;
  loading?: boolean;
}


export class ConfirmDialogRef {
  private observer: any;
  public dialog: ConfirmDialog;
  public afterClosed: Observable<ConfirmDialogResult>;

  constructor(model: ConfirmDialog) {
    this.dialog = model;
    this.afterClosed = new Observable<ConfirmDialogResult>((observer) => {
      this.observer = observer;
    });
  }

  public action(result: ConfirmDialogResult) {
    if (!this.observer) {
      return;
    }
    this.observer.next(result);
    this.observer.complete();
    this.dialog.isOpen = false;
  }
}

export interface DialogComponent {
  id: string;
  component: any;
  header: string;
  secondHeader: string;
  size: string;
  activeTab: string;
  data: any;
  ref: any;
  componentRef: ComponentRef<any>;
}

export interface DisplayDialog {
  current: DialogComponent;
  components: DialogComponent[];
}

export class DisplayModalDialogRef {
  private observer: any;
  public dialog: DisplayDialog;
  public afterClosed: Observable<DisplayModalDialogResults>;

  constructor(model: DisplayDialog, private store, private logService: LogService, private router: Router) {
    this.dialog = model;
    this.afterClosed = new Observable<DisplayModalDialogResults>((observer) => {
      this.observer = observer;
    });
  }

  public close(action: ConfirmDialogResult, data?: any) {
    this.logService.logUsage(this.router.url, 'Dialog', `Close ${action}`, null);

    this.store.dispatch(new DialogComponentUnloadComponent(this.dialog.current.id));

    if (!this.observer) {
      return;
    }
    const result: DisplayModalDialogResults = {
      action,
      data
    };
    this.observer.next(result);
    this.observer.complete();
  }

}

export interface DisplayModalDialogResults {
  data: any;
  action: ConfirmDialogResult;
}


export enum ConfirmDialogResult {
  Confirm = 0,
  Cancel = 1,
  Reload = 2
}

export enum ConfirmDialogTypeEnum {
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

export interface ConfirmButtonModel {
  show: boolean;
  textTranslateId: string;
}
