<div class="main-container main-nav-component">
  <header class="header-6 main-nav-background">
    <div class="header-actions">
      <a href="https://www.newmontgoldcorp.com/" target="_blank">
        <img src="../../../assets/images/newmont-logo-white-alt.png"
             style="width: 115px; padding: 21px 0px; margin-right: 32px;" />
      </a>
    </div>
  </header>
  <div>
    <h3>Sentinel Support</h3>
  </div>
</div>


