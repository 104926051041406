import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StartShiftModel } from '../components/common/models/start-shift.model';


export interface MetadataShiftsStateModel {
  shifts: StartShiftModel[];
}

export class MetadataShiftsLoaded {
  static readonly type = '[MetadataShifts] loaded';

  constructor(public shifts: StartShiftModel[]) {
  }
}

@State<MetadataShiftsStateModel>({
  name: 'metadataShiftsState',
  defaults: { shifts: [] }
})
@Injectable()
export class MetadataShiftsState {

  constructor() {
  }

  @Selector()
  static shifts(state: MetadataShiftsStateModel) {
    return state.shifts;
  }

  @Action(MetadataShiftsLoaded)
  loaded(ctx: StateContext<MetadataShiftsStateModel>, action: MetadataShiftsLoaded) {

    ctx.patchState({
      shifts: action.shifts
    });

  }
}
