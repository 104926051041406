import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../services/dialog.service';
import { LandService } from '../../../../../services/land.service';
import { ColumnDataModel, DataModel, ModelLevels, TableDataModel, ValueDataModel } from '../../../../common/models/data.model';
import { ModalStates } from '../../../../common/models/modal-state.model';
import { ConfirmDialogResult, DisplayModalDialogRef } from '../../../../common/models/dialog.model';
import { BaseComponent } from '../../../../common/base-component';
import { Observable } from 'rxjs';
import {DataModelService} from '../../../../../services/data-model.service';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-form-data-model-value',
  templateUrl: './form-data-model-value.component.html',
  styleUrls: ['./form-data-model-value.component.css']
})
export class FormDataModelValueComponent extends BaseComponent {
  private modalRef: DisplayModalDialogRef;

  public activeTab: string;
  public saving: boolean;
  public value: ValueDataModel;
  public column: ColumnDataModel;
  public table: TableDataModel;
  public dataModel: DataModel;
  public modalStates = ModalStates;
  public displayMode: ModalStates;
  public modelLevels = ModelLevels;

  constructor(
    protected router: Router,
    protected dialogService: DialogService,
    private landService: LandService,
    private dataModelService: DataModelService,
    protected logService: LogService) {
    super(router, logService);
  }


  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.value = this.dialogService.displayModal.current.data.value;
    this.column = this.value.column;
    this.table = this.column.table;
    if (!this.table.data_model) {
      // For adding a new value, the data model is available in the dialog service (not the table object though)
      this.dataModel = this.dialogService.displayModal.current.data.dataModel;
    } else {
      // For editing an existing value, it is not in the dialog service, so pull from table object
      this.dataModel = this.table.data_model;
    }
    this.displayMode = this.dialogService.displayModal.current.data.displayMode;

    this.switchTabs('details');
  }

  protected setUser(user: any): void {
  }

  public switchTabs(tabId: string): void {
    this.activeTab = tabId;
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

  public edit(): void {
    this.displayMode = ModalStates.EDIT;
  }

  public save(): void {
    if (!this.value || this.value?.value_model_name?.trim().length === 0) {
      return;
    }

    this.saving = true;

    this.value.value_model_name = this.value.value_model_name.trim();

    const call = this.getSaveCall();

    call.subscribe(() => {
      this.saving = false;
      this.close();
    });

  }

  private getSaveCall(): Observable<any> {

    if (this.displayMode === ModalStates.ADD) {
      const columnSplit = this.value.column.column_model_id.split('::');

      const baseTableId = columnSplit[columnSplit.length - 2];
      const baseColumnId = columnSplit[columnSplit.length - 1];

      return this.dataModelService.saveNewColumnValue(this.dataModel.data_model_base_id, baseTableId, baseColumnId, this.value);
    }

    const valueId = this.value.value_model_id;
    const valueSplit = valueId.split('::');
    const tableId = valueSplit[valueSplit.length - 3];
    const columnId = valueSplit[valueSplit.length - 2];
    const baseValueId = valueSplit[valueSplit.length - 1];
    return this.dataModelService.saveColumnValue(this.dataModel.data_model_base_id, tableId, columnId, baseValueId, this.value);
  }

}
