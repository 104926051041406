import { Component } from '@angular/core';
import { MappingQueryTypes } from '../../../../../common/models/data.model';
import { LandService } from '../../../../../../services/land.service';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../../services/dialog.service';
import { ColumnMappingBaseComponent } from '../ColumnMappingBaseComponent';
import {LogService} from '../../../../../../services/log.service';

@Component({
  selector: 'app-data-model-column-mapping-target',
  templateUrl: './data-model-column-mapping-target.component.html',
  styleUrls: ['./data-model-column-mapping-target.component.css']
})
export class DataModelColumnMappingTargetComponent extends ColumnMappingBaseComponent {

  constructor(
    protected router: Router,
    protected landService: LandService,
    protected dialogService: DialogService,
    protected logService: LogService) {
    super('land-column-map-target-to-source',
      MappingQueryTypes.target,
      router,
      landService,
      dialogService, logService);
  }

}
