import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth.service';
import { AppAuthResponseModel, FirebaseSSORedirectResponseModel } from '../models/firebase-sso-model';
import { UpdateUser } from '../models/update-user.model';
import { RedirectService } from 'app/services/intake-redirect.service';

@Component({
  selector: 'app-login-fb',
  templateUrl: './login-fb.component.html',
  styleUrls: ['./login-fb.component.css']
})
export class LoginFbComponent implements OnInit {
  public error: string;
  public deviceInfo = null;
  public loading = false;
  public email: string;
  public password: string;

  constructor(private route: Router,
    private router: Router,
    private authSvc: AuthService,
    private deviceService: DeviceDetectorService,
    private activeRoute: ActivatedRoute,
    private redirectService: RedirectService) {
  }

  ngOnInit(): void {
    if (environment.production) {
      this.router.navigate(['/login']);
      return;
    }
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }


  private reset() {
    this.error = '';
  }

  // Fires on template button click
  public login() {
    if (this.deviceInfo.browser !== 'Chrome') {
      return;
    }
    if (!this.email || !this.password) {
      return;
    }
    this.loading = true;
    this.authSvc.signInFirebase(this.email, this.password).subscribe((signInResponse: FirebaseSSORedirectResponseModel) => {

      if (!signInResponse.validated) {
        this.error = 'Not Authorized';
        this.loading = false;
        return;
      }

      const redirectUrl = this.redirectService.getRedirectUrl();
      this.redirectService.clearRedirectUrl();

      if (redirectUrl === "/intake") {
        localStorage.setItem('uidToken', signInResponse.uidToken);
        localStorage.setItem('version', signInResponse.version);
        const nameInfo = this.extractNameFromEmail(signInResponse.email);
        const intake = {
          external_id: "",
          name_first: nameInfo.firstName,
          name_last: nameInfo.lastName,
          email: signInResponse.email,
          user_id: signInResponse.email,
          language_id: "en"
        } as UpdateUser;
        localStorage.setItem('intake', JSON.stringify(intake));
        this.route.navigate(['/intake']);
      }
      else {
        this.authSvc.validateAuth(signInResponse).then((authResponse: AppAuthResponseModel) => {
          if (!authResponse.validated) {
            this.error = 'Not Authorized';
            this.loading = false;
            return;
          }
          this.activeRoute.queryParams.subscribe((params: Params) => {
            let redirectUrl: string = params.redirectUrl;
            if (redirectUrl && redirectUrl.includes('login')) {
              redirectUrl = undefined;
            }
            if (redirectUrl) {
              this.route.navigateByUrl(redirectUrl);
            } else {
              this.authSvc.navigateToPrimaryDomain();
            }
          });
        });
      }
    });

  }

  //Extract Name from email - Intake Form - Local
  public extractNameFromEmail(email: string): { fullName: string, firstName: string, lastName: string } {
    const [localPart, domain] = email.split('@');
    const [firstName, lastName] = localPart.split('.');
    const fullName = `${firstName} ${lastName}`;

    return { fullName, firstName, lastName };
  }
  // Resets error status after user acknowledges error modal
  public closeErrorModal() {
    this.reset();
  }
}
