import { Component, Input } from '@angular/core';
import { BaseEditorComponent } from '../base-editor-component';
import { RichTextModel } from '../models/data.model';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css'],
})
export class TextEditorComponent extends BaseEditorComponent {

  @Input() model: RichTextModel;
  @Input() editable: boolean;

}
