<button class="btn btn-link download-file-btn" (click)="download()" [ngClass]="{'disabled': !isValid() || downloading}" >
  <span *ngIf="translateId" [translate]="translateId"></span>
  <span *ngIf="!translateId">{{fileNameProp}}</span>
  <span *ngIf="downloading" class="spinner spinner-inline"></span>
</button>
<clr-tooltip *ngIf="showDownloadError">
  <clr-icon clrTooltipTrigger shape="exclamation-triangle"></clr-icon>
  <clr-tooltip-content clrPosition="top-right" clrSize="xs" *clrIfOpen>
    <span  [translate]="'file-download-not-found'">file-download-not-found</span>
  </clr-tooltip-content>
</clr-tooltip>
