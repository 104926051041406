import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ColumnDataModel,
  DataModel,
  DataModelNavigationEvent,
  ModelLevels,
  TableDataModel,
  ValueDataModel
} from '../../../../common/models/data.model';
import { ModalStates } from '../../../../common/models/modal-state.model';
import { LandService } from '../../../../../services/land.service';
import {DataModelService} from '../../../../../services/data-model.service';

@Component({
  selector: 'app-data-model-model-navigation',
  templateUrl: './data-model-model-navigation.component.html',
  styleUrls: ['./data-model-model-navigation.component.css']
})
export class DataModelModelNavigationComponent {
  public dataModel: DataModel;
  public dataModels: DataModel[];
  public table: TableDataModel;
  public column: ColumnDataModel;
  public value: ValueDataModel;
  public modalState: ModalStates;
  public modalStates = ModalStates;
  public level: ModelLevels;
  public modelLevels = ModelLevels;

  @Output() ModelChanged = new EventEmitter();

  constructor(private landService: LandService, private dataModelService: DataModelService) {
  }

  @Input()
  set datasource_level(level: ModelLevels) {
    this.level = level;
  }


  @Input()
  set datasource_datamodels(dataModels: DataModel[]) {
    this.dataModels = dataModels;
  }

  @Input()
  set datasource_datamodel(dataModel: DataModel) {
    this.dataModel = dataModel;
    this.initDataModel();
  }

  @Input()
  set datasource_table(table: TableDataModel) {
    this.table = table;
  }

  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
  }

  @Input()
  set datasource_value(value: ValueDataModel) {
    this.value = value;
  }

  @Input()
  set datasource_modal_state(state: ModalStates) {
    this.modalState = state;
  }

  private initDataModel(): void {

    if (!this.dataModel || this.dataModel.tablesLoading) {
      return;
    }

    if (this.dataModel.tables && this.dataModel.tables.length > 0) {
      return;
    }

    this.dataModel.tablesLoading = true;
    this.dataModelService.getTables(this.dataModel.data_model_id).subscribe((tables) => {
      this.dataModel.tables = tables;
      this.dataModel.tablesLoading = false;
      if (this.table) {
        this.table = this.dataModel.tables.find(t => t.table_model_id === this.table.table_model_id);
        this.loadTableColumns(this.table, this.column?.column_model_id);
      }
    }, () => {
      this.dataModel.tables = [];
      this.dataModel.tablesLoading = false;
    });
  }

  public dataModelChanged(dataModel: DataModel): void {
    this.dataModel = dataModel;
    if (!dataModel.tables || dataModel.tables.length === 0) {
      this.table = undefined;
      this.column = undefined;
      this.onModelChanged();
      return;
    }
    this.tableChanged(dataModel.tables[0]);
  }

  public tableChanged(table: TableDataModel): void {
    this.table = table;
    if (this.level === ModelLevels.TABLE) {
      this.onModelChanged();
      return;
    }
    if (this.modalState === ModalStates.ADD) {
      this.column.table = table;
      this.columnChanged(this.column);
    } else {

      if (!table.columns || table.columns.length === 0) {
        this.loadTableColumns(table);
        return;
      }
      this.columnChanged(table.columns[0]);
    }

  }

  private loadTableColumns(table: TableDataModel, selectedColumnId?: string): void {
    table.columnsLoading = true;

    this.dataModelService.getColumns(this.dataModel.data_model_id, table.table_model_base_id).subscribe((columns) => {
      table.columnsLoading = false;
      table.columns = columns;
      if (columns?.length > 0) {
        this.column = selectedColumnId ? columns.find(c => c.column_model_id === selectedColumnId) : columns[0];
      } else {
        this.column = undefined;
      }
      this.onModelChanged();
    }, () => {
      setTimeout(() => {
        this.column = undefined;
        table.columns = [];
        table.columnsLoading = false;
        this.onModelChanged();
      });
    });
  }

  public columnChanged(column: ColumnDataModel): void {
    this.column = column;
    this.onModelChanged();
  }

  private onModelChanged(): void {

    const data = {
      dataModel: this.dataModel,
      table: this.table,
      column: this.column
    } as DataModelNavigationEvent;
    this.ModelChanged.emit(data);
  }
}
