import { Component, Input, OnInit } from '@angular/core';
import { ColumnDataModel, DataModel, DataModelTypes } from '../../../../common/models/data.model';

@Component({
  selector: 'app-data-model-column-mappings',
  templateUrl: './data-model-column-mappings.component.html',
  styleUrls: ['./data-model-column-mappings.component.css']
})
export class DataModelColumnMappingsComponent implements OnInit {

  public column: ColumnDataModel;
  public dataModel: DataModel;
  public dataModelTypes = DataModelTypes;

  constructor() {
  }

  ngOnInit(): void {
  }


  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
  }

  @Input()
  set datasource_datamodel(dataModel: DataModel) {
    this.dataModel = dataModel;
  }


}
