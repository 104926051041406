import { DomainModel } from '../components/common/models/domain.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SubRegionModel } from '../components/common/models/sub-region.model';

export interface MetadataSubregionStateModel {
  subregions: SubRegionModel[];
}

export class MetadataSubregionLoaded {
  static readonly type = '[MetadataSubregions] loaded';

  constructor(public subregions: SubRegionModel[]) {
  }
}

@State<MetadataSubregionStateModel>({
  name: 'metadataSubRegionState',
  defaults: { subregions: [] }
})
@Injectable()
export class MetadataSubregionState {

  constructor() {
  }

  @Selector()
  static subregions(state: MetadataSubregionStateModel) {
    return state.subregions;
  }

  @Action(MetadataSubregionLoaded)
  loaded(ctx: StateContext<MetadataSubregionStateModel>, action: MetadataSubregionLoaded) {

    ctx.patchState({
      subregions: action.subregions
    });

  }
}
