<div class="modal-body">
  <div class="clr-row">
    <div class="clr-col-2 step-container">

      <div data-cy="step-table-details" class="clr-row step-row">
        <div class="clr-col"
             [ngClass]="{'step-row-active': currentStep === 'step-details',
                'step-row-completed': detailsValid() }">
          <button (click)="showDetails()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'datatool-quality-datamodel-details'"></button>
        </div>
      </div>

      <div data-cy="step-table-details" class="clr-row step-row">
        <div class="clr-col" [ngClass]="{'step-row-active': currentStep === 'step-tables',
                'step-row-completed': tablesValid() }">
          <button (click)="showTables()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'datatool-quality-datamodel-tables'"></button>
        </div>
      </div>

      <div data-cy="step-table-details" class="clr-row step-row">
        <div class="clr-col" [ngClass]="{'step-row-active': currentStep === 'step-review' }">
          <button (click)="showReview()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'datatool-quality-datamodel-review'"></button>
        </div>
      </div>


    </div>
    <div class="clr-col-9">

      <div id="step-details" class="step-content" *ngIf="currentStep === 'step-details'">
        <div class="clr-row padded-row">
          <div class="clr-col">
            <span [translate]="'datatool-quality-datamodel-name'" class="title-label"></span>
            <clr-input-container style="margin-top: 0">
              <input clrInput
                     [(ngModel)]="model.name"
                     class="table_name"
                     name="table_name"/>
            </clr-input-container>
          </div>
          <div class="clr-col">
            <span class="frequency-header-label title-label" [translate]="'datatool-quality-frequency'"></span>
            <clr-select-container style="margin-top: 0">
              <select clrSelect [(ngModel)]="model.profile_frequency_type">
                <option></option>
                <option *ngFor="let frequency of frequencies" [value]="frequency" [translate]="'datatool-quality-frequency-type-' + frequency">
                </option>
              </select>
            </clr-select-container>
          </div>
        </div>
        <div class="clr-row padded-row">
          <div class="clr-col">
            <span [translate]="'datatool-quality-dataset'" class="title-label"></span>
            <ng-select name="dataset_id" [items]="datasets"
                       [bindLabel]="'dataset_name'"
                       [bindValue]="'dataset_name'"
                       [(ngModel)]="model.dataset_name"
                       [loading]="datasetLoading"
                       (change)="datasetChanged()"
                       class="datatool-select">
            </ng-select>
          </div>

        </div>
      </div>
      <div id="step-tables" class="step-content" *ngIf="currentStep === 'step-tables'">
        <div class="tables">
          <div class="clr-row table-header">
            <div class="clr-col table-name table-header">
              <clr-checkbox-wrapper>
                <input (change)="allTablesSelected()" [(ngModel)]="selectAllTables" type="checkbox" clrCheckbox>
                <!--                <label class="selectedLabel" [translate]="'datatool-quality-table-name'"></label>-->
              </clr-checkbox-wrapper>
              <span [translate]="'datatool-quality-table-name'"></span>
            </div>
            <!--            <div class="clr-col table-header">-->
            <!--              <span class="frequency-header-label" [translate]="'datatool-quality-frequency'"></span>-->
            <!--              <clr-select-container style="margin-top: 0">-->
            <!--                <select clrSelect [(ngModel)]="setAllFrequency" (change)="allFrequencySelected()">-->
            <!--                  <option></option>-->
            <!--                  <option *ngFor="let frequency of frequencies" [value]="frequency">-->
            <!--                    <span [translate]="'datatool-quality-frequency-type-' + frequency"></span>-->
            <!--                  </option>-->
            <!--                </select>-->
            <!--              </clr-select-container>-->
            <!--            </div>-->
          </div>
          <div class="table-body">
            <div class="clr-row table-row" *ngFor="let table of tables">
              <div class="clr-col table-name">
                <clr-checkbox-wrapper>
                  <input type="checkbox" clrCheckbox id="{{table.table_name}}"
                         [(ngModel)]="table.selected">
                  <label>{{table.table_name}}</label>
                </clr-checkbox-wrapper>
              </div>
<!--              <div class="clr-col">-->
<!--                <clr-select-container style="margin-top: 0">-->
<!--                  <select clrSelect name="dataType_options" [(ngModel)]="table.frequency">-->
<!--                    <option *ngFor="let frequency of frequencies" [value]="frequency">-->
<!--                      <span [translate]="'datatool-quality-frequency-type-' + frequency"></span>-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </clr-select-container>-->
<!--              </div>-->
            </div>
          </div>

        </div>
      </div>
      <div id="step-review" class="step-content" *ngIf="currentStep === 'step-review'">
        <div class="clr-row padded-row">
          <div class="clr-col">
            <span [translate]="'datatool-quality-datamodel-name'"
                  class="title-label"></span>: <span>{{model.name}}</span>
          </div>
        </div>
        <div class="clr-row padded-row">
          <div class="clr-col">
            <span [translate]="'datatool-quality-dataset'"
                  class="title-label"></span>: <span>{{model.dataset_name}}</span>
          </div>
          <div class="clr-col">
            <span [translate]="'datatool-quality-frequency'"
                  class="title-label"></span>: <span [translate]="'datatool-quality-frequency-type-' + model.profile_frequency_type"></span>
          </div>
        </div>
        <div class="clr-row">

          <div class="tables">
            <div class="clr-row table-header">
              <div class="clr-col table-name table-header">
                <span [translate]="'datatool-quality-table-name'"></span>
              </div>
            </div>
            <div class="table-body review-table">
              <div class="clr-row table-row" *ngFor="let table of model.tables">
                <div class="clr-col table-name">
                  <label>{{table.table_name}}</label>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline" data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>

  <button type="button" class="btn btn-outline" (click)="back()" [disabled]="!canGoBack()">
    <span [translate]="'land-wizard-btn-back'"></span>
  </button>

  <button type="button" class="btn btn-primary"
          (click)="next()"
          [disabled]="!canGoNext() || saving"
          data-cy="wizard-next">
    <span [translate]="currentStep === 'step-review' ? 'feedback-btn-submit' : 'land-wizard-btn-next'"></span>
  </button>

</div>
<ng-container *ngIf="loading || saving || tableLoading || datasetLoading">
  <app-loader [displayMode]=""></app-loader>
</ng-container>
