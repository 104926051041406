<div class="modal-body">
  <div class="clr-row">
    <div class="clr-col">
      <ng-container>
        <clr-datagrid [clrDgLoading]="model?.loading" data-cy="version-history-rows">
          <clr-dg-column *ngFor="let column of model.columns" [clrDgField]="column.attribute">
            <span [translate]="column.translateId"></span>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of model.data">
            <clr-dg-cell *ngFor="let column of model.columns">{{ row[column.attribute] }}</clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                                [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
              <span [translate]="'evaluations-grid-foot-label-1'"></span>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              <span [translate]="'evaluations-grid-foot-label-2'"></span>
              {{ pagination.totalItems }}
              <span [translate]="'exploration-project-footer'"></span>
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </ng-container>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="close()" class="btn btn-outline" data-cy="metadata-close">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
</div>
