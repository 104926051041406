import {DepartmentModel} from '../components/common/models/department.model';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

export interface MetadataDepartmentStateModel {
  departments: DepartmentModel[];
}

export class MetadataDepartmentLoaded {
  static readonly type = '[MetadataDepartment] loaded';

  constructor(public departments: DepartmentModel[]) {
  }
}

@State<MetadataDepartmentStateModel>({
  name: 'metadataDepartmentState',
  defaults: { departments: [] }
})
@Injectable()
export class MetadataDepartmentState {
  constructor() {
  }

  @Selector()
  static departments(state: MetadataDepartmentStateModel) {
    return state.departments;
  }

  @Action(MetadataDepartmentLoaded)
  loaded(ctx: StateContext<MetadataDepartmentStateModel>, action: MetadataDepartmentLoaded) {

    ctx.patchState({
      departments: action.departments
    });
  }
}
