import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppPrivacyComponent } from './components/common/app-privacy/app-privacy.component';
import { AppSupportComponent } from './components/common/app-support/app-support.component';
import { FileDownloadComponent } from './components/common/file/file-download/file-download.component';
import { LoginFbComponent } from './components/common/login-fb/login-fb.component';
import { LoginComponent } from './components/common/login/login.component';
import { LogoutComponent } from './components/common/logout/logout.component';
import { MainNavComponent } from './components/common/main-nav/main-nav.component';
import { NoDomainAccessComponent } from './components/common/no-domain-access/no-domain-access.component';
import { WildcardComponent } from './components/common/wildcard/wildcard.component';
import { IntakeHomeComponent } from './components/intake/intake-home/intake-home.component';
import { IntakeMainNavComponent } from './components/intake/intake-main-nav/intake-main-nav.component';
import { AuthGuard } from './guards/auth.guard';
import { DevOnlyGuard } from './guards/dev-only.guard';
import { IntakeAuthGuard } from './guards/intake-auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-fb',
    canActivate: [DevOnlyGuard],
    component: LoginFbComponent,
  },
  {
    path: 'logged-out',
    component: LogoutComponent,
  },
  {
    path: 'no-domain-access',
    component: NoDomainAccessComponent
  },
  {
    path: 'value-stream',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/value-stream/value-stream.module').then(m => m.ValueStreamModule)
  },
  {
    path: 'intake',
    canActivate: [IntakeAuthGuard],
    component: IntakeMainNavComponent,
    children: [
      {
        path: '',
        component: IntakeHomeComponent,
        canActivate: [IntakeAuthGuard]
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainNavComponent,
    children: [
      {
        path: 'download',
        component: FileDownloadComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'evaluations',
        loadChildren: () => import('./components/evaluations/evaluations.module').then(m => m.EvaluationsModule)
      },
      {
        path: 'auditor',
        loadChildren: () => import('./components/auditor/auditor.module').then(m => m.AuditorModule)
      },
      {
        path: 'datatool',
        loadChildren: () => import('./components/data/data-module').then(m => m.DataModule)
      },
      {
        path: 'productivity',
        loadChildren: () => import('./components/productivity/productivity.module').then(m => m.ProductivityModule)
      },
      {
        path: 'common',
        loadChildren: () => import('./components/metadata/common.module').then(m => m.CommonModule)
      },
      {
        path: 'livework',
        loadChildren: () => import('./components/livework/livework.module').then(m => m.LiveworkModule)
      },
      {
        path: 'exploration',
        loadChildren: () => import('./components/exploration/exploration.module').then(m => m.ExplorationModule)
      },
      {
        path: 'land',
        loadChildren: () => import('./components/land/land.module').then(m => m.LandModule)
      },
      // {
      //   path: 'start',
      //   loadChildren: () => import('./components/start/start.module').then(m => m.StartModule)
      // },
      {
        path: 'portal',
        loadChildren: () => import('./components/portal/portal.module').then(m => m.PortalModule)
      },
      {
        path: 'control',
        loadChildren: () => import('./components/control/control.module').then(m => m.ControlModule)
      }
    ]
  },

  // {
  //   path: 'mp',
  //   canActivate: [AuthGuard],
  //   component: MainNavComponent,
  //   children: [
  //     {
  //       path: 'evaluations',
  //       loadChildren: () => import('./components/evaluations/evaluations.module').then(m => m.EvaluationsModule)
  //     },
  //     {
  //       path: 'auditor',
  //       loadChildren: () => import('./components/auditor/auditor.module').then(m => m.AuditorModule)
  //     },
  //     {
  //       path: 'datatool',
  //       loadChildren: () => import('./components/data/data-module').then(m => m.DataModule)
  //     },
  //     {
  //       path: 'productivity',
  //       loadChildren: () => import('./components/productivity/productivity.module').then(m => m.ProductivityModule)
  //     },
  //     {
  //       path: 'common',
  //       loadChildren: () => import('./components/productivity/common.module').then(m => m.CommonModule)
  //     },
  //     {
  //       path: 'livework',
  //       loadChildren: () => import('./components/livework/livework.module').then(m => m.LiveworkModule)
  //     },
  //     {
  //       path: 'exploration',
  //       loadChildren: () => import('./components/exploration/exploration.module').then(m => m.ExplorationModule)
  //     },
  //     {
  //       path: 'xeras',
  //       loadChildren: () => import('./components/xeras/xeras.module').then(m => m.XerasModule)
  //     }
  //   ]
  // },

  {
    path: 'support',
    component: AppSupportComponent
  },
  {
    path: 'privacy',
    component: AppPrivacyComponent
  },
  {
    path: '**',
    component: WildcardComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
