<div class="view-value-container padded-container" *ngIf="state === modalStates.VIEW">

  <div class="clr-row padded-row">

    <div class="clr-col-8">
      <div class="clr-row">
        <div class="clr-col">
          <p [translate]="'value-details-name'" class="title-label"></p>
          <p class="label-overflow">{{ value?.value_model_name }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="clr-row padded-row">
    <div class="clr-col-7">
      <p [translate]="'value-description'" class="title-label"></p>
      <p *ngIf="value?.value_description?.html_text?.length > 0" [innerHTML]="value?.value_description?.html_text"></p>
      <p *ngIf="!value.value_description || value.value_description?.html_text?.length === 0">
        <span [translate]="'land-no-description'">land-no-description</span>
      </p>
    </div>
  </div>

</div>

<div class="edit-value-container padded-container" *ngIf="state === modalStates.EDIT">

  <div class="clr-row padded-row">
    <div class="clr-col">
      <p [translate]="'value-details-name'" class="title-label"></p>
      <clr-input-container *ngIf="canEditValue()">
        <input clrInput [(ngModel)]="value.value_model_name" class="value_name" name="value_name"/>
      </clr-input-container>
      <p *ngIf="!canEditValue()" class="label-overflow">{{ value.value_model_name }}</p>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col">
      <p [translate]="'value-description'" class="title-label"></p>
      <app-text-editor [editable]="true" [model]="value.value_description"></app-text-editor>
    </div>
  </div>

</div>

<div class="edit-value-container padded-container" *ngIf="state === modalStates.ADD">

  <div class="clr-row padded-row">
    <div class="clr-col">
      <p [translate]="'value-details-name'" class="title-label"></p>
      <clr-input-container>
        <input clrInput [(ngModel)]="value.value_model_name" class="value_name" name="value_name"/>
      </clr-input-container>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col">
      <p [translate]="'value-description'" class="title-label"></p>
      <app-text-editor [editable]="true" [model]="value.value_description"></app-text-editor>
    </div>
  </div>

</div>
