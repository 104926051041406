import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import {TreeHistory, TreeModel} from '../models/tree.model';
import { BaseComponent } from '../base-component';
import { CommonService } from '../../../services/common.service';
import { LogService } from '../../../services/log.service';

@Component({
  selector: 'app-vdt',
  templateUrl: './vdt.component.html',
  styleUrls: ['./vdt.component.scss']
})

export class VdtComponent extends BaseComponent implements OnInit {
  loading = false;
  public tree: TreeModel;
  treeData: TreeNode[];
  treeId: string;
  nodeName: string;
  startDate: string;
  endDate: string;

  @Input()
  set id(id: string) {
    this.treeId = id;
  }

  @Input()
  set metric(name: string) {
    this.nodeName = name;
  }
  @Input()
  set start(date: string) {
    this.startDate = date;
  }
  @Input()
  set end(date: string) {
    this.endDate = date;
  }

  constructor(protected router: Router, private commonSvc: CommonService, protected logService: LogService) {
    super(router, logService);
  }

  protected setUser(user: any): void {
  }

  protected init(): void {
  }

  public getTree(): void {
    this.loading = true;
    // tslint:disable-next-line:max-line-length
    this.commonSvc.getTree('en', this.treeId, this.startDate, this.endDate, this.nodeName).subscribe((data: TreeModel) => {
      this.treeData = [data];
      console.log(JSON.stringify(this.treeData));
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.logService.debug('Error: ', error);
    });
  }

  ngOnInit() {
    this.getTree();
  }
}
