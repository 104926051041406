import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '../../services/log.service';
import { metadata } from '../../services/metadata.service';
import { findPrimarySite } from '../../utils/finders';
import { AuthorizationDomainModel, AuthorizationSiteModel } from './models/authorization.models';
import { DomainsEnum } from './models/domains.enum';
import { SiteModel } from './models/site.model';


@Directive()
export abstract class BaseComponent implements OnInit {
  protected constructor(protected router: Router, protected logService: LogService) {
  }

  public collapsed: boolean;
  public user: any;

  ngOnInit(): void {
    this.init();
    this.initUser();
  }

  protected abstract init(): void;

  // This is called after the user has been pulled from localstorage.
  protected abstract setUser(user: any): void;

  private initUser(): any {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.setUser(this.user);
  }

  // Returns the current domain
  protected getCurrentDomain(): AuthorizationDomainModel {
    const url = this.router.url;
    return metadata.authState.domains.find(domain => {
      const domainId = domain.domain.domain_id;
      return url.includes(`/${domainId}`);
    });
  }

  protected getDomainByDomainId(domainId: string): AuthorizationDomainModel {
    return metadata.authState.domains.find(domain => domain.domain.domain_id === domainId);
  }

  protected isCurrentDomain(domain: DomainsEnum): boolean {
    const currentDomain = this.getCurrentDomain();
    return currentDomain.domain.domain_id === domain;
  }

  // Returns array of sites for the current domain
  protected getCurrentDomainSites(): AuthorizationSiteModel[] {
    const domain = this.getCurrentDomain();
    if (!domain) {
      return [];
    }
    return domain.sites;
  }

  protected getCurrentSites(): SiteModel[] {
    const sites = [];
    this.getCurrentDomainSites().forEach(s => sites.push(s.site));
    return sites;
  }

  protected getPrimarySite(): any {
    return findPrimarySite(this.getCurrentDomainSites());
  }
}


@Directive()
export class BaseHomeComponent extends BaseComponent {

  public display = {
    root: true,
    feedback: false,
    feedbackConfirm: false,
  };

  protected constructor(protected router: Router,
    protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
  }

  protected setUser(user) {
  }

  public updateDisplay($displayMode: string): void {
    switch ($displayMode) {
      case 'root':
        this.display = {
          root: true,
          feedback: false,
          feedbackConfirm: false,
        };
        break;
      case 'feedback':
        this.display.feedback = true;
        this.display.feedbackConfirm = false;
        break;
      case 'feedbackConfirm':
        this.display.feedbackConfirm = true;
        this.display.feedback = false;
        break;
      default:
        break;
    }
  }
}
