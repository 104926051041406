<ng-container *ngIf="isModal">
  <button (click)="displayPicker()" class="btn btn-link" [translate]="'exploration-reporting-month-picker-link'">Change
    Reporting Month
  </button>
  <clr-modal [(clrModalOpen)]="showPicker" [clrModalClosable]="true" [clrModalSize]="'md'">
    <div class="modal-title" style="display: flex; justify-content: space-between; align-items: flex-end;">
      <h3 class="modal-title text-notice" [translate]="'exploration-reporting-month-picker-title'"
          data-cy="form-task">exploration-reporting-month-picker-title</h3>
    </div>
    <div class="modal-body">
      <div class="clr-row">
        <div class="clr-col">
          <div class="clr-row-12">
            <div class="clr-col">
              <div class="year-selector">
                <button (click)="previousYear()" type="button" class="btn btn-link">
                  <clr-icon shape="angle left" role="none">
                  </clr-icon>
                </button>
                <span class="displayed-year">{{year}}</span>
                <button (click)="nextYear()" type="button" class="btn btn-link">
                  <clr-icon shape="angle right" role="none">
                  </clr-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col">
              <div class="months-selector">
                <div class="clr-row">
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[1])"
                            [ngClass]="{'present-month': presentMonth === 1, 'month-button-active': month === 1, 'btn-link': month !== 1}"
                            class="btn month-button" [disabled]="monthMap[1] === undefined">
                      {{monthMap[1] !== undefined ? monthMap[1].reporting_month_text : 'January'}}
                    </button>
                  </div>
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[2])"
                            [ngClass]="{'present-month': presentMonth === 2, 'month-button-active': month === 2, 'btn-link': month !== 2}"
                            class="btn month-button" [disabled]="monthMap[2] === undefined">
                      {{monthMap[2] !== undefined ? monthMap[2].reporting_month_text : 'February'}}
                    </button>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[3])"
                            [ngClass]="{'present-month': presentMonth === 3, 'month-button-active': month === 3, 'btn-link': month !== 3}"
                            class="btn month-button" [disabled]="monthMap[3] === undefined">
                      {{monthMap[3] !== undefined ? monthMap[3].reporting_month_text : 'March'}}
                    </button>
                  </div>
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[4])"
                            [ngClass]="{'present-month': presentMonth === 4, 'month-button-active': month === 4, 'btn-link': month !== 4}"
                            class="btn month-button" [disabled]="monthMap[4] === undefined">
                      {{monthMap[4] !== undefined ? monthMap[4].reporting_month_text : 'April'}}
                    </button>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[5])"
                            [ngClass]="{'present-month': presentMonth === 5, 'month-button-active': month === 5, 'btn-link': month !== 5}"
                            class="btn month-button" [disabled]="monthMap[5] === undefined">
                      {{monthMap[5] !== undefined ? monthMap[5].reporting_month_text : 'May'}}
                    </button>
                  </div>
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[6])"
                            [ngClass]="{'present-month': presentMonth === 6, 'month-button-active': month === 6, 'btn-link': month !== 6}"
                            class="btn month-button" [disabled]="monthMap[6] === undefined">
                      {{monthMap[6] !== undefined ? monthMap[6].reporting_month_text : 'June'}}
                    </button>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[7])"
                            [ngClass]="{'present-month': presentMonth === 7, 'month-button-active': month === 7, 'btn-link': month !== 7}"
                            class="btn month-button" [disabled]="monthMap[7] === undefined">
                      {{monthMap[7] !== undefined ? monthMap[7].reporting_month_text : 'July'}}
                    </button>
                  </div>
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[8])"
                            [ngClass]="{'present-month': presentMonth === 8, 'month-button-active': month === 8, 'btn-link': month !== 8}"
                            class="btn month-button" [disabled]="monthMap[8] === undefined">
                      {{monthMap[8] !== undefined ? monthMap[8].reporting_month_text : 'August'}}
                    </button>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[9])"
                            [ngClass]="{'present-month': presentMonth === 9, 'month-button-active': month === 9, 'btn-link': month !== 9}"
                            class="btn month-button" [disabled]="monthMap[9] === undefined">
                      {{monthMap[9] !== undefined ? monthMap[9].reporting_month_text : 'September'}}
                    </button>
                  </div>
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[10])"
                            [ngClass]="{'present-month': presentMonth === 10, 'month-button-active': month === 10, 'btn-link': month !== 10}"
                            class="btn month-button" [disabled]="monthMap[10] === undefined">
                      {{monthMap[10] !== undefined ? monthMap[10].reporting_month_text : 'October'}}
                    </button>
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[11])"
                            [ngClass]="{'present-month': presentMonth === 11, 'month-button-active': month === 11, 'btn-link': month !== 11}"
                            class="btn month-button" [disabled]="monthMap[11] === undefined">
                      {{monthMap[11] !== undefined ? monthMap[11].reporting_month_text : 'November'}}
                    </button>
                  </div>
                  <div class="clr-col">
                    <button (click)="selectDate(monthMap[12])"
                            [ngClass]="{'present-month': presentMonth === 12, 'month-button-active': month === 12, 'btn-link': month !== 12}"
                            class="btn month-button" [disabled]="monthMap[12] === undefined">
                      {{monthMap[12] !== undefined ? monthMap[12].reporting_month_text : 'December'}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" (click)="close()" class="btn btn-outline" data-cy="metadata-close">
        <span [translate]="'submodal-btn-close'"></span>
      </button>
    </div>
  </clr-modal>
</ng-container>
<ng-container *ngIf="!isModal">
  <div class="clr-row">
    <div class="clr-col">
      <div class="inline-container">
      <form clrForm  class="inline-container">
        <div class="filter-item">
          <p class="p4 form-heading"
             [translate]="'date-picker-year'">Year:</p>
          <clr-select-container style="width:100px; display: flex; flex-direction: column; margin-top: -12px;">
            <select (change)="yearChanged()" [(ngModel)]="year" clrSelect name="year">
              <option *ngFor="let year of years"
                      [ngValue]="year">{{ year }}</option>
            </select>
          </clr-select-container>
        </div>

        <div class="filter-item">
          <p class="p4 form-heading"
             [translate]="'date-picker-month'">Region:</p>
          <clr-select-container style="display: flex; flex-direction: column; margin-top: -12px;">
            <select (change)="monthChanged()" [(ngModel)]="month" clrSelect name="month">
              <option *ngFor="let month of reportingMonths"
                      [ngValue]="month.reporting_month">{{ month.reporting_month_text }}</option>
            </select>
          </clr-select-container>
        </div>

      </form>
      </div>

    </div>

  </div>
</ng-container>
