import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ReportingMonthModel } from 'app/components/common/models/reporting-month.model';

export interface ExplorationSearchMonthStateModel {
  reportingMonth: ReportingMonthModel;
}

export class UpdateMonth {
  static readonly type = '[ExplorationSearchMonth] Updated';

  constructor(public reportingMonth: ReportingMonthModel) {
  }
}

@State<ExplorationSearchMonthStateModel>({
  name: 'explorationSearchMonthState',
  defaults: {
    reportingMonth: null
   }
})
@Injectable()
export class ExplorationSearchMonthState {

  @Selector()
  static reportingMonthState(state: ExplorationSearchMonthStateModel) {
      return state.reportingMonth;
  }

  @Action(UpdateMonth)
  updateMonth(ctx: StateContext<ExplorationSearchMonthStateModel>, action: UpdateMonth) {
    const state = ctx.getState();
    ctx.patchState({
      reportingMonth: action.reportingMonth
    });
  }

}
