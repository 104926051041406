import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MetricModel } from '../components/common/models/metric.model';


export interface MetadataMetricStateModel {
  metrics: MetricModel[];
}

export class MetadataMetricLoaded {
  static readonly type = '[MetadataMetric] loaded';

  constructor(public metrics: MetricModel[]) {
  }
}

@State<MetadataMetricStateModel>({
  name: 'metadataMetricState',
  defaults: { metrics: [] }
})
@Injectable()
export class MetadataMetricState {

  constructor() {
  }

  @Selector()
  static productivity(state: MetadataMetricStateModel) {
    return state.metrics.filter(x=>x.sub_domain.sub_domain_id === 'productivity');
  }

  @Selector()
  static subDomain(state: MetadataMetricStateModel) {
    return (subDomainId: string) => {
      return state.metrics.filter(x=>x.sub_domain.sub_domain_id === subDomainId);
    };
  }

  @Action(MetadataMetricLoaded)
  loaded(ctx: StateContext<MetadataMetricStateModel>, action: MetadataMetricLoaded) {

    ctx.patchState({
      metrics: action.metrics
    });

  }
}
