<div class="search-summary-container">

  <div class="card">
    <div class="card-header" [translate]="!searched ? 'land-search-title-wizard':'land-search-title-summary'">
    </div>
    <div class="card-block">
      <div *ngIf="!searched" class="clr-row search-row">

        <div class="clr-col">
          <div>
            <clr-input-container style="display: flex; width: 275px;">
              <label class="query-label" [translate]="'land-query-label'"></label>
              <input clrInput
                     class="query-input"
                     [(ngModel)]="query"
                     data-cy="criteria-input"
                     name="query"/>
            </clr-input-container>
          </div>
        </div>

      </div>
      <div *ngIf="searched" class="clr-row">
        <div class="clr-col">
          <div>
            <label class="query-label" [translate]="'land-query-label'"></label>
            <div>{{query}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button *ngIf="!searched" (click)="search()" [disabled]="!query || query.length === 0"
              class="btn btn-sm btn-primary"
              data-cy="search-btn"
              [translate]="'land-search-btn-search'">
      </button>
      <button *ngIf="searched" (click)="reset()"
              class="btn btn-sm btn-primary"
              data-cy="reset-btn"
              [translate]="'land-search-btn-reset'">
      </button>
    </div>
  </div>

</div>
<ng-container *ngIf="searched">
  <clr-datagrid [clrDgLoading]="searching" data-cy="search-grid-models">
    <clr-dg-column [clrDgField]="'data_model_name'">
      <span [translate]="'data-model-search-name'"></span>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'model_name'">
      <span [translate]="'data-model-search-model-name'"></span>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'parent'">
      <span [translate]="'data-model-search-parent'"></span>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'class_name'">
      <span [translate]="'data-model-search-class-name'"></span>
    </clr-dg-column>
    <clr-dg-column>
      <span [translate]="'exploration-search-options-options-label'">Options</span>
    </clr-dg-column>
    <clr-dg-row *clrDgItems="let item of results">
      <clr-dg-cell>{{ item.data_model_name }}</clr-dg-cell>
      <clr-dg-cell data-cy="grid-model-name">{{ item.model_name }}</clr-dg-cell>
      <clr-dg-cell><div class="parent-name">{{ item.parent }}</div></clr-dg-cell>
      <clr-dg-cell>{{ item.class_name }}</clr-dg-cell>
      <clr-dg-cell>
        <div style="display: flex;">
          <a (click)="view(item)" class="btn btn-link action" data-cy="datagrid-view-button"><span [translate]="'data-model-search-view'"></span></a>
        </div>
      </clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="10">
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                          [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
        <span [translate]="'evaluations-grid-foot-label-1'"></span>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        <span [translate]="'evaluations-grid-foot-label-2'"></span>
        {{ pagination.totalItems }}
        <span [translate]="'datamodel-search-grid-foot-label'"></span>
      </clr-dg-pagination>
    </clr-dg-footer>
  </clr-datagrid>
</ng-container>
<ng-container *ngIf="searching">
  <app-loader [displayMode]=""></app-loader>
</ng-container>
