import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DatabaseData,
  DatasetData,
  ExtractModel,
  ExtractResponse,
  FieldData,
  SaveExtractModel,
  SchemaData,
  ServerData,
  TableData
} from '../components/common/models/datatool.model';
import { endpoints } from '../utils/endpoints';

import {
  DatabaseTypeModel,
  DatabaseTypeResponse,
  DatasourceDetailModel,
  DatasourceModel,
  DatasourceResponse
} from '../components/common/models/datasources.model';

import { Store } from '@ngxs/store';
import { DomainsEnum } from 'app/components/common/models/domains.enum';
import { IntakeFileUploadModel } from 'app/components/common/models/intake.model';
import { MetricModel, MetricResponse } from 'app/components/common/models/metric.model';
import { Tree, TreesModel, TreesResponse } from 'app/components/common/models/trees.model';
import * as moment from 'moment';
import { DataModel, DataModelInitiate } from '../components/common/models/data.model';
import {
  HaulerMachine,
  HaulerMachineLocationModel,
  MachinePlaybackModel,
  MinePaths
} from '../components/common/models/hauler-machine-location.model';
import { FactResult, ResultDateType, ResultsModel, ResultsResponse, ResultsTypeEnum } from '../components/common/models/results.model';
import { VacuumDatasetModel, VacuumDatasetResponse } from '../components/common/models/vacuum.datasets.model';
import { Message, MessageObject, SendMessage } from '../store';
import { MetadataDatabaseTypesLoaded } from '../store/metadata-database-types.state';
import { BaseService } from './base.service';
import { MessagingService } from './messaging.service';

@Injectable({ providedIn: 'root' })
export class DataService extends BaseService {

  constructor(protected http: HttpClient,
    protected store: Store,
    private messagingService: MessagingService) {
    super(http, store);
  }

  public getDatabaseTypes(): Observable<Array<DatabaseTypeModel>> {
    return this.http.get<DatabaseTypeResponse>(`${endpoints.databasetypes}/${this.getProfileLanguageId()}`, this.httpOptions).pipe(
      map((data: DatabaseTypeResponse) => {

        const state = JSON.parse(JSON.stringify(data.database_types));
        this.store.dispatch(new MetadataDatabaseTypesLoaded(state));
        return data.database_types;
      })
    );
  }

  public saveNewDatabaseType(data: DatabaseTypeModel): Observable<any> {
    return this.http.post<DatabaseTypeModel>(`${endpoints.databasetypes}/${this.getLanguageId()}`, data, this.httpOptions);
  }

  public saveDatabaseType(id: string, data: DatabaseTypeModel): Observable<any> {
    return this.http.put<DatabaseTypeModel>(`${endpoints.databasetypes}/${this.getLanguageId()}/${id}`, data, this.httpOptions);
  }

  public getDataSources(cache: boolean = true): Observable<Array<DatasourceModel>> {
    return this.http.get<DatasourceResponse>(`${endpoints.datasources}?cache=${cache}`, this.httpOptions).pipe(
      map((data: DatasourceResponse) => {
        return data.datasources;
      })
    );
  }

  public getDataSource(id: string): Observable<DatasourceDetailModel> {
    return this.http.get<DatasourceDetailModel>(`${endpoints.datasources}/${id}`, this.httpOptions);
  }

  public saveNewDataSource(data: DatasourceDetailModel, test: boolean): Observable<any> {
    const token = test === true ? `&registration_token=${this.messagingService.token}` : '';
    return this.http.post<DatasourceDetailModel>(`${endpoints.datasources}?test=${test}${token}`, data, this.httpOptions);
  }

  public saveDataSource(id: string, data: DatasourceDetailModel, test: boolean): Observable<any> {

    if (test) {
      const message = {
        id,
        object: MessageObject.dataTool
      } as Message;

      this.store.dispatch(new SendMessage(message));
    }

    const token = test === true ? `&registration_token=${this.messagingService.token}` : '';
    return this.http.put<DatasourceDetailModel>(`${endpoints.datasources}/${id}?test=${test}${token}`, data, this.httpOptions);
  }

  public deleteDataSource(id: string): Observable<any> {
    return this.http.delete<any>(`${endpoints.datasources}/${id}`, this.httpOptions);
  }

  public getDataSets(): Observable<Array<DatasetData>> {
    return this.http.get(`${endpoints.datasets}`, this.httpOptions).pipe(
      map((data: any) => {
        return data.datasets;
      })
    );
  }

  public getVacuumDataSets(): Observable<Array<VacuumDatasetModel>> {
    return this.http.get<VacuumDatasetResponse>(endpoints.vacuum_datasets, this.httpOptions).pipe(
      map((data: VacuumDatasetResponse) => {
        return data.vacuum_datasets;
      })
    );
  }

  public getVacuumDataSet(id: string): Observable<VacuumDatasetModel> {
    return this.http.get<VacuumDatasetModel>(`${endpoints.vacuum_datasets}/${id}`, this.httpOptions);
  }

  public saveVacuumNewDataSet(data: VacuumDatasetModel): Observable<any> {
    return this.http.post<VacuumDatasetModel>(`${endpoints.vacuum_datasets}`, data, this.httpOptions);
  }

  public saveVacuumDataSet(id: string, data: VacuumDatasetModel): Observable<any> {
    return this.http.put<VacuumDatasetModel>(`${endpoints.vacuum_datasets}/${id}`, data, this.httpOptions);
  }

  // public deleteVacuumDataSet(id: string): Observable<any> {
  //   return this.http.delete<any>(`${endpoints.vacuum_datasets}/${id}`, httpOptions);
  // }

  public getExtracts(): Observable<Array<ExtractModel>> {

    const url = `${endpoints.extracts}`;
    return this.http.get<ExtractResponse>(url, this.httpOptions).pipe(
      map((data: ExtractResponse) => {
        data.extracts.forEach((extract: ExtractModel) => {
          if (extract.update_user) {
            extract.update_user_name = `${extract.update_user.name_first} ${extract.update_user.name_last}`;
          }
        });
        return data.extracts;
      })
    );
  }

  public getExtract(extractId): Observable<ExtractModel> {
    const url = `${endpoints.extracts} /${extractId}`;
    return this.http.get<ExtractModel>(url, this.httpOptions);
  }

  public getBiqQueryTables(dataset: string): Observable<Array<TableData>> {
    return this.http.get(`${endpoints.datasets}/${dataset}/${endpoints.tables}`, this.httpOptions)
      .pipe(map((data: any) => {
        return data.tables;
      })
      );
  }

  public getBigQueryFields(dataset: string, table: string): Observable<Array<FieldData>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${endpoints.datasets}/${dataset}/${endpoints.tables}/${table}/${endpoints.fields}`, this.httpOptions)
      .pipe(map((data: any) => {
        return data.fields;
      })
      );
  }

  public getServers(): Observable<Array<ServerData>> {
    return this.http.get(endpoints.servers, this.httpOptions).pipe(
      map((data: any) => {
        return data.servers;
      })
    );
  }

  public getDatabases(server: string): Observable<Array<DatabaseData>> {
    return this.http.get(`${endpoints.servers}/${server}/${endpoints.databases}`, this.httpOptions).pipe(
      map((data: any) => {
        return data.databases;
      })
    );
  }

  public getSchemas(server: string, database: string): Observable<Array<SchemaData>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${endpoints.servers}/${server}/${endpoints.databases}/${database}/${endpoints.schemas}`, this.httpOptions)
      .pipe(map((data: any) => {
        return data.schemas;
      })
      );
  }

  public getSqlTables(server: string, database: string, schema: string): Observable<Array<TableData>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${endpoints.servers}/${server}/${endpoints.databases}/${database}/${endpoints.schemas}/${schema}/${endpoints.tables}`, this.httpOptions)
      .pipe(map((data: any) => {
        return data.tables;
      })
      );

  }

  public getSqlFields(server: string, database: string, schema: string, table: string): Observable<Array<FieldData>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${endpoints.servers}/${server}/${endpoints.databases}/${database}/${endpoints.schemas}/${schema}/${endpoints.tables}/${table}/${endpoints.fields}`, this.httpOptions)
      .pipe(map((data: any) => {
        return data.fields;
      })
      );
  }

  public saveExtract(data: SaveExtractModel): Observable<any> {
    return this.http.post(`${endpoints.extracts}`, data, this.httpOptions);
  }

  public deleteResult(resultId: string): Observable<any> {
    return this.http.delete<any>(`${endpoints.datatool}/results/${resultId}`, this.httpOptions);
  }

  public saveResult(result: ResultsModel): Observable<any> {
    return this.http.put<any>(`${endpoints.datatool}/results/${result.result_id}`, result, this.httpOptions);
  }

  public saveNewResult(result: ResultsModel): Observable<any> {
    this.formatResult(result);
    // result.result_date = moment(result.result_date).format('YYYY-MM-DDT00:00:00+00:00');
    result.result_date = moment(result.result_date).format('YYYY-MM-DD');

    return this.http.post<any>(`${endpoints.datatool}/results`, result, this.httpOptions);
  }

  // tslint:disable-next-line:max-line-length
  public getResult(type: ResultsTypeEnum, factId: string, site: string, scenarioType: string, cache: boolean = false): Observable<Array<ResultsModel>> {
    cache = true;
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResultsResponse>(`${endpoints.datatool}/results?result_type=${type}&fact.fact_id=${factId}&site.site_id=${site}&scenario_type=${scenarioType}&nocache=${cache}`, this.httpOptions).pipe(
      map((data: ResultsResponse) => {

        data.results.forEach(result => {
          this.formatResult(result);
        });

        return data.results;
      })
    );
  }

  public getFactTemplates(siteId: string): Observable<Array<ResultsModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResultsResponse>(`${endpoints.datatool}/results?result_type=${ResultsTypeEnum.template}&site.site_id=${siteId}`, this.httpOptions).pipe(
      map((data: ResultsResponse) => {
        return data.results.sort((a, b) => (a.fact.fact_name < b.fact.fact_name) ? 1 : ((b.fact.fact_name < a.fact.fact_name) ? -1 : 0));
      })
    );
  }

  public getFactResults(siteId: string): Observable<Array<FactResult>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ResultsResponse>(`${endpoints.datatool}/results?result_type=${ResultsTypeEnum.template}&site.site_id=${siteId}`, this.httpOptions).pipe(
      map((data: ResultsResponse) => {
        const response = [];
        const factHash = {};
        data.results.forEach(result => {

          this.formatResult(result);

          if (!factHash[result.fact.fact_id]) {
            const factResult = { fact: result.fact, templates: [] } as FactResult;
            response.push(factResult);
            factHash[result.fact.fact_id] = factResult;
          }

          factHash[result.fact.fact_id].templates.push(result);
        });

        return response;
      })
    );
  }

  private formatResult(result: ResultsModel): void {
    result.update_user_formatted = result.update_user ? `${result.update_user.name_first} ${result.update_user.name_last}` : '';

    if (result.result_date_type === ResultDateType.year) {
      result.period = moment(result.result_date).utc().format('YYYY');
    } else if (result.result_date_type === ResultDateType.month) {
      result.period = moment(result.result_date).utc().format('yyyy-MM');
    } else if (result.result_date_type === ResultDateType.week) {
      result.period = moment(result.result_date).utc().format('yyyy-MM-DD');
    } else if (result.result_date_type === ResultDateType.day) {
      result.period = moment(result.result_date).utc().format('yyyy-MM-DD');
    }
  }

  public initiateDataModel(data: DataModelInitiate): Observable<any> {
    return this.http.post<any>(`${endpoints.common}/data_models/initiate`, data, this.httpOptions);
  }

  public getDataModel(dataModelId: string): Observable<DataModel> {
    return this.http.get<DataModel>(`${endpoints.common}/data_models/${dataModelId}`, this.httpOptions);
  }

  public getHaulerLocations(): Observable<HaulerMachineLocationModel> {
    return this.http.get<HaulerMachineLocationModel>(`${endpoints.datatool}/machine_positions`, this.httpOptions)
      .pipe(map((response: any) => {
        // tslint:disable-next-line:max-line-length
        const sorted = response.machines.sort((a, b) => (a.machine_name > b.machine_name) ? 1 : ((b.machine_name > a.machine_name) ? -1 : 0));
        return {
          ...response,
          machines: sorted
        };
      })
      );
  }

  public getHaulerLocation(machinePositionId: string): Observable<HaulerMachine> {
    return this.http.get<HaulerMachine>(`${endpoints.datatool}/machine_positions/${machinePositionId}`, this.httpOptions);
  }

  public getHaulerPlayback(machinePositionId: string, start: string, end: string): Observable<MachinePlaybackModel> {
    return this.http.get<MachinePlaybackModel>(`${endpoints.datatool}/machine_positions/${machinePositionId}/playback?start=${start}&end=${end}`, this.httpOptions);
  }

  public getMinePaths(): Observable<MinePaths> {
    return this.http.get<MinePaths>(`${endpoints.datatool}/minestar/paths`, this.httpOptions);
  }

  //Trees Section
  public getTrees(languageId: string, domain: string): Observable<Array<TreesModel>> {
    let url = `${endpoints.trees}/${languageId}`;

    if (domain === DomainsEnum.DATATOOL) {
      url += `?sub_domain.sub_domain_id=safety`;
    } else if (domain === DomainsEnum.PORTAL) {
      url += `?sub_domain.sub_domain_id=portal-value-stream`;
    }

    return this.http.get<TreesResponse>(url, this.httpOptions).pipe(
      map((data: TreesResponse) => {
        return data.trees;
      })
    );
  }

  public getTree(languageId: string, treeId: string): Observable<Tree> {
    return this.http.get<Tree>(`${endpoints.trees}/${languageId}/${treeId}`, this.httpOptions).pipe(
      map((data: Tree) => {
        return data;
      })
    );
  }

  public saveNewTree(languageId: string, data: Tree): Observable<any> {
    return this.http.post<Tree>(`${endpoints.trees}/${languageId}`, data, this.httpOptions);
  }

  public saveTree(languageId: string, treeId ,data: Tree): Observable<any> {
    return this.http.put<Tree>(`${endpoints.trees}/${languageId}/${treeId}`, data, this.httpOptions);
  }

  getMetricsBySite(languageId: string, site: string): Observable<Array<MetricModel>> {
    return this.http.get<MetricResponse>(`${endpoints.metricsCommon}/${languageId}/${site}/`, this.httpOptions)
      .pipe(
        map((response: MetricResponse) => {
          return response.metrics;
        }));
  }

  getIntakeProjects(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${ endpoints.intakeForms }/projects`, this.httpOptions).pipe(
      map((data: any) => {
        return JSON.parse(data);
      })
    );
  }

  saveIntakeForm(intakeForm): Observable<any> {
    return this.http.post<any>(`${ endpoints.intakeForms }`, intakeForm, this.httpOptions);
  }

  uploadFile(intake_id: string, file: IntakeFileUploadModel): Observable<any> {
    return this.http.post<any>(`${ endpoints.intakeForms }/${ intake_id }/files`, file, this.httpOptions);
  }
}
