import { Component, Input, OnInit } from '@angular/core';
import { ColumnDataModel, DataModel, DataModelTypes, TableDataModel } from '../../../../common/models/data.model';
import { ModalStates } from '../../../../common/models/modal-state.model';

@Component({
  selector: 'app-data-model-column-category-values',
  templateUrl: './data-model-column-category-values.component.html',
  styleUrls: ['./data-model-column-category-values.component.css']
})
export class DataModelColumnCategoryValuesComponent implements OnInit {

  public column: ColumnDataModel;
  public table: TableDataModel;
  public dataModel: DataModel;
  public dataModelTypes = DataModelTypes;
  public state: ModalStates;

  constructor() {
  }

  ngOnInit(): void {
  }


  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
  }

  @Input()
  set datasource_table(table: TableDataModel) {
    this.table = table;
  }

  @Input()
  set datasource_datamodel(dataModel: DataModel) {
    this.dataModel = dataModel;
  }

  @Input()
  set modal_state(state: ModalStates) {
    this.state = state;
  }

}
