<div class="card">
  <div class="card-header">
    <span [translate]="routeGroup.translateId"></span>
  </div>
  <div class="card-block">
    <div class="card-text">
        <div *ngFor="let route of routeGroup.routes">
          <a   [attr.data-cy]="route.cy" [routerLink]="'/common/admin/' + route.path">
            <clr-icon  class="nav-item-icon" [attr.shape]="route.icon.clrName"></clr-icon>
            <span [translate]="route.translateId"></span>
          </a>
        </div>
    </div>
  </div>
</div>
