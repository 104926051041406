import { Component, Input, OnInit } from '@angular/core';
import {
  ColumnDataModel,
  ColumnDataTypes,
  ColumnModelTypes,
  DataModel,
  DataModelTypes,
  TableDataModel
} from '../../../../common/models/data.model';
import { ModalStates } from '../../../../common/models/modal-state.model';
import { LandService } from '../../../../../services/land.service';
import {DataModelService} from '../../../../../services/data-model.service';

@Component({
  selector: 'app-data-model-column-details',
  templateUrl: './data-model-column-details.component.html',
  styleUrls: ['./data-model-column-details.component.css']
})
export class DataModelColumnDetailsComponent implements OnInit {

  public column: ColumnDataModel;
  public table: TableDataModel;
  public dataModel: DataModel;
  public state: ModalStates;
  public stateEnum = ModalStates;
  public dataModelTypes = DataModelTypes;
  public columnDataTypes = ColumnDataTypes;
  public columnModelTypes = ColumnModelTypes;
  public columnModelTypesOptions: string[] = [];
  public columnDataTypesOptions: string[] = [];
  public editable: boolean;
  public reservedWords: string[];

  constructor(private landService: LandService, private dataModelService: DataModelService) {
  }

  ngOnInit(): void {
    this.reservedWords = this.dataModelService.getReservedWords();

    for (const key of Object.keys(this.columnDataTypes)) {
      this.columnDataTypesOptions.push(`${ key }`);
    }

    for (const key of Object.keys(this.columnModelTypes)) {
      this.columnModelTypesOptions.push(this.columnModelTypes[key]);
    }

  }

  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
    if (!this.column.column_description || !this.column.column_description.html_text) {
      this.column.column_description = {
        html_text: '',
        markdown_text: '',
        plain_text: ''
      };
    }
  }

  @Input()
  set datasource_table(table: TableDataModel) {
    this.table = table;
  }

  @Input()
  set datasource_datamodel(dataModel: DataModel) {
    this.dataModel = dataModel;
  }

  @Input()
  set modal_state(state: ModalStates) {
    this.state = state;
    this.editable = this.state !== this.stateEnum.VIEW;
  }

  public validateColumnName(): boolean {
    if (!this.column.column_model_name || this.column.column_model_name.length === 0) {
      return true;
    }
    return this.dataModelService.validateObjectName(this.column.column_model_name);
  }

  public checkColumnModelType(): void {
    if (this.column.column_model_type === this.columnModelTypes.CATEGORY) {
      this.column.column_data_type = this.columnDataTypes.STRING;
    }
  }
}
