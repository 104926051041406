<div class="clr-row details" *ngIf="activeDataModel">
  <div class="clr-col">
    <span class="datamodel-label" [translate]="'land-datamodel-type-label'"></span>
    <span class="datamodel-value">{{activeDataModel?.data_model_type}}</span>
  </div>
  <div class="clr-col">
    <span class="datamodel-label" [translate]="'land-datamodel-dataset'"></span>
    <span class="datamodel-value">{{activeDataModel?.dataset.dataset_model_name}}</span>
  </div>
  <div class="clr-col">
    <span class="datamodel-label" [translate]="'land-datamodel-table-count'"></span>
    <span class="datamodel-value">{{tables?.length || 0}}</span>
  </div>
  <div class="clr-col" *ngIf="!showMapping">
<!--    <button (click)="editDataModel()" class="btn btn-link"><span [translate]="'datatool-quality-datamodel-edit'"></span></button>-->
  </div>
</div>

<div class="clr-row">
  <div class="clr-col">
    <div class="clr-row">
      <div class="clr-col top-left">
      </div>
      <div class="clr-col-3 tables-header">
        <div>
        </div>
      </div>
      <div class="clr-col-5"></div>
      <div class="clr-col row-actions">
        <div *ngIf="activeDataModel?.data_model_type === 'target'" class=""><a class="btn btn-link"
                                                                               (click)="createTable()"><span
          [translate]="'land-model-tables-add'"></span></a></div>
      </div>
    </div>
  </div>
</div>
<div class="tables">
  <div *ngIf="tables?.length === 0" class="clr-row">
    <div class="clr-col">
      <span [translate]="'land-no-tables'"></span>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col">
      <div *ngIf="tables?.length > 0" class="clr-row table-header-row">
        <div class="clr-col-5 table-name">
        </div>
        <div class="clr-col-3 table-info-header">
          <div class="clr-row">
            <div class="clr-col columns-count">
              <span class="table-header" [translate]="'land-table-details-column-count'"></span>
            </div>
            <div class="clr-col row-count">
              <span class="table-header" [translate]="'land-table-details-row-count'"></span>
            </div>
          </div>
        </div>
        <div class="clr-col row-actions"></div>
      </div>
    </div>
  </div>

  <div class="clr-row" *ngFor="let table of tables">
    <div class="clr-col">
      <div class="clr-row table-row" [ngClass]="{'expanded': table.expanded}">
        <div class="clr-col top-left" (click)="toggleTable(table)">
          <a class="child-toggle">
            <clr-icon *ngIf="table.expanded" shape="angle down"></clr-icon>
            <clr-icon *ngIf="!table.expanded" shape="angle right"></clr-icon>
          </a>
        </div>
        <div (click)="toggleTable(table)" class="clr-col-5 table-name" data-cy="table-table-model-name">
          {{table.table_model_name}}
        </div>
        <div class="clr-col-3">
          <div class="clr-row">
            <div *ngIf="!table.expanded" class="clr-col columns-count ">
              <span *ngIf="table.column_count">{{table.column_count }}</span>
              <span *ngIf="!table.column_count">&nbsp;</span>
            </div>
            <div *ngIf="!table.expanded" class="clr-col row-count ">
              <span *ngIf="table.row_count">{{table.row_count | number}}</span>
              <span *ngIf="!table.row_count">&nbsp;</span>
            </div>
          </div>

        </div>
        <div class="clr-col row-actions">
          <a (click)="viewTable(table)" class="btn btn-link table-action" data-cy="table-view-detail"><span [translate]="'land-table-view'">land-table-view</span></a>
          <a (click)="editTable(table)" class="btn btn-link table-action" data-cy="table-edit-detail"><span [translate]="'land-table-modify'">land-table-modify</span></a>
          <a *ngIf="canDeleteTable()" (click)="deleteTable(table)" class="btn btn-link table-action"><span
            [translate]="'land-table-delete'">land-table-delete</span></a>
        </div>

      </div>
      <div class="clr-row columns" *ngIf="table.expanded && table.columnsLoading">
        <div class="clr-col">
          <div class="clr-row column-row">
            <div class="clr-col column-name">
              <span [translate]="'land-table-columns-loading'"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="clr-row columns" *ngIf="table.expanded">
        <div class="clr-col">
          <div *ngIf="!table.columnsLoading" class="clr-row column-row column-header-row">
            <div class="clr-col-3">
              <span *ngIf="table.columns?.length === 0"
                    [translate]="'land-table-no-columns'">land-table-no-columns</span>
            </div>
            <div class="clr-col-5">
              <div class="clr-row " *ngIf="table.columns?.length > 0">
                <div class="clr-col model-type">
                  <span class="column-header" [translate]="'table-header-data-type'">Data Type</span>
                </div>
                <div class="clr-col model-type ">
                  <span class="column-header" [translate]="'table-header-model-type'">Model Type</span>
                </div>
                <div *ngIf="showMapping" class="clr-col model-type ">
                  <span class="column-header" [translate]="'table-header-mapping-status'">Mapping Status</span>
                </div>
              </div>
            </div>
            <div class="clr-col row-actions">
              <a *ngIf="canCreateColumn(table)" (click)="createColumn(table)"
                 class="btn btn-link table-action"><span [translate]="'land-table-column-create'">land-table-column-create</span></a>
            </div>
          </div>
          <div class="clr-row column-row" *ngFor="let column of table.columns">
            <div class="clr-col-3" data-cy="column-column-model-name">
              {{column.column_model_name}}
            </div>
            <div class="clr-col-5">
              <div class="clr-row">
                <div class="clr-col model-type">
                  <span>{{column.column_data_type}}</span>
                </div>
                <div class="clr-col model-type ">
                  <span>{{column.column_model_type}}</span>
                </div>
                <div *ngIf="showMapping" class="clr-col model-type ">
                  <span>{{ column.mapped_indicator_text }}</span>
                </div>
              </div>
            </div>
            <div class="clr-col row-actions">
              <a
                *ngIf="activeDataModel.data_model_type === dataModelTypes.SOURCE && column.mapped_indicator === columnMapIndicator.not_mapped && showMapping"
                (click)="ignoreColumnMapping(column)"
                class="btn btn-link table-action">
                <span [translate]="'land-column-map-ignore'">land-column-map-ignore</span></a>
              <a
                *ngIf="activeDataModel.data_model_type === dataModelTypes.SOURCE &&  column.mapped_indicator === columnMapIndicator.not_mapped && showMapping"
                (click)="mapColumnTarget(column)"
                class="btn btn-link table-action">
                <span [translate]="'land-column-map-target'">land-column-map-target</span></a>

              <a (click)="viewColumn(column, table)" class="btn btn-link table-action" data-cy="column-view-details"><span
                [translate]="'land-table-view'">land-table-view</span></a>
              <a (click)="editColumn(column, table)" class="btn btn-link table-action" data-cy="column-edit-details"><span
                [translate]="'land-table-modify'">land-table-modify</span></a>
              <a *ngIf="canDeleteColumn(column)" (click)="deleteColumn(column, table)"
                 class="btn btn-link table-action" data-cy="column-delete-details"><span [translate]="'land-table-delete'">land-table-delete</span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="saving || tablesLoading">
  <app-loader [displayMode]=""></app-loader>
</ng-container>
