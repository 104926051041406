import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-no-domain-access',
  templateUrl: './no-domain-access.component.html',
  styleUrls: ['./no-domain-access.component.css']
})
export class NoDomainAccessComponent implements OnInit {
  public domain: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.domain = params.domain;
    });
  }

  public login(): void {
    this.router.navigateByUrl('/login');
  }

}
