import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  ConsequenceTypeModel,
  HazardModel,
  LikelihoodTypeModel,
  TaskActivityModel,
  ToolModel
} from '../components/common/models/livework.model';
import {SiteModel} from '../components/common/models/site.model';
import {DepartmentModel} from '../components/common/models/department.model';
import {FleetModel} from '../components/common/models/fleet.model';
import {RegionModel} from '../components/common/models/region.model';
import {CountryModel} from '../components/common/models/country.model';
import {StatusModel} from '../components/common/models/status.model';

export interface LiveworkSearchStateModel {
  showResults: boolean;
  activities: TaskActivityModel[];
  sites: SiteModel[];
  departments: DepartmentModel[];
  tools: ToolModel[];
  equipment: FleetModel[];
  regions: RegionModel[];
  countries: CountryModel[];
  status: StatusModel[];
  consequenceTypes: ConsequenceTypeModel[];
  hazardTypes: HazardModel[];
  likelihoodTypes: LikelihoodTypeModel[];
  onePageIndicator: boolean;
  fixedEquipment: boolean;
  mobileEquipment: boolean;
  solution_lead: string;
}

export class LiveworkSearchUpdateOnePage {
  static readonly type = '[LiveworkSearch] updateOnePage';

  constructor(public selected: boolean) {
  }
}

export class LiveworkSearchUpdateFixedEquipment {
  static readonly type = '[LiveworkSearch] updateFixedEquipment';

  constructor(public selected: boolean) {
  }
}

export class LiveworkSearchUpdateMobileEquipment {
  static readonly type = '[LiveworkSearch] updateMobileEquipment';

  constructor(public selected: boolean) {
  }
}

export class LiveworkSearchUpdateSolutionLead {
  static readonly type = '[LiveworkSearch] updateSolutionLead';

  constructor(public solutionLead: string) {
  }
}


export class LiveworkSearchAddLikelihoodType {
  static readonly type = '[LiveworkSearch] addHazardLikelihood';

  constructor(public likelihood: LikelihoodTypeModel) {
  }
}


export class LiveworkSearchAddHazardType {
  static readonly type = '[LiveworkSearch] addHazardType';

  constructor(public hazardType: HazardModel) {
  }
}

export class LiveworkSearchAddConsequenceType {
  static readonly type = '[LiveworkSearch] addConsequenceType';

  constructor(public consequenceType: ConsequenceTypeModel) {
  }
}


export class LiveworkSearchAddStatus {
  static readonly type = '[LiveworkSearch] addStatus';

  constructor(public status: StatusModel) {
  }
}

export class LiveworkSearchAddTool {
  static readonly type = '[LiveworkSearch] addTool';

  constructor(public tool: ToolModel) {
  }
}

export class LiveworkSearchAddRegion {
  static readonly type = '[LiveworkSearch] addRegion';

  constructor(public region: RegionModel) {
  }
}

export class LiveworkSearchAddCountry {
  static readonly type = '[LiveworkSearch] addCountry';

  constructor(public country: CountryModel) {
  }
}


export class LiveworkSearchAddEquipment {
  static readonly type = '[LiveworkSearch] addEquipment';

  constructor(public equipment: FleetModel) {
  }
}


export class LiveworkSearchAddSite {
  static readonly type = '[LiveworkSearch] addSite';

  constructor(public site: SiteModel) {
  }
}

export class LiveworkSearchAddDepartment {
  static readonly type = '[LiveworkSearch] addDepartment';

  constructor(public department: DepartmentModel) {
  }
}


export class LiveworkSearchAddActivity {
  static readonly type = '[LiveworkSearch] addActivity';

  constructor(public activity: TaskActivityModel) {
  }
}


export class LiveworkSearchRemoveHazardType {
  static readonly type = '[LiveworkSearch] removeHazardType';

  constructor(public hazardType: HazardModel) {
  }
}

export class LiveworkSearchRemoveLikelihoodType {
  static readonly type = '[LiveworkSearch] removeLikelihoodType';

  constructor(public likelihoodType: LikelihoodTypeModel) {
  }
}

export class LiveworkSearchRemoveConsequenceType {
  static readonly type = '[LiveworkSearch] removeConsequenceType';

  constructor(public consequenceType: ConsequenceTypeModel) {
  }
}


export class LiveworkSearchRemoveStatus {
  static readonly type = '[LiveworkSearch] removeStatus';

  constructor(public status: StatusModel) {
  }
}


export class LiveworkSearchRemoveRegion {
  static readonly type = '[LiveworkSearch] removeRegion';

  constructor(public region: RegionModel) {
  }
}

export class LiveworkSearchRemoveCountry {
  static readonly type = '[LiveworkSearch] removeCountry';

  constructor(public country: CountryModel) {
  }
}


export class LiveworkSearchRemoveActivity {
  static readonly type = '[LiveworkSearch] removeActivity';

  constructor(public activity: TaskActivityModel) {
  }
}

export class LiveworkSearchRemoveDepartment {
  static readonly type = '[LiveworkSearch] removeDepartment';

  constructor(public department: DepartmentModel) {
  }
}

export class LiveworkSearchRemoveSite {
  static readonly type = '[LiveworkSearch] removeSite';

  constructor(public site: SiteModel) {
  }
}

export class LiveworkSearchRemoveTool {
  static readonly type = '[LiveworkSearch] removeTool';

  constructor(public tool: ToolModel) {
  }
}

export class LiveworkSearchRemoveEquipment {
  static readonly type = '[LiveworkSearch] removeEquipment';

  constructor(public equipment: FleetModel) {
  }
}


export class LiveworkSearchExecute {
  static readonly type = '[LiveworkSearch] search';

  constructor(public show: boolean) {
  }
}


export class LiveworkSearchReset {
  static readonly type = '[LiveworkSearch] reset';

  constructor() {
  }
}


@State<LiveworkSearchStateModel>({
    name: 'liveworkSearchState',
    defaults: {
      showResults: false,
      activities: [],
      departments: [],
      sites: [],
      equipment: [],
      tools: [],
      countries: [],
      regions: [],
      status: [],
      hazardTypes: [],
      consequenceTypes: [],
      likelihoodTypes: [],
      onePageIndicator: false,
      mobileEquipment: false,
      fixedEquipment: false,
      solution_lead: ''
    }
  }
)
@Injectable()
export class LiveworkSearchState {
  constructor() {
  }

  @Selector()
  static showResults(state: LiveworkSearchStateModel): boolean {
    return state.showResults;
  }

  @Selector()
  static criteria(state: LiveworkSearchStateModel) {
    return state;
  }

  @Selector()
  static activities(state: LiveworkSearchStateModel) {
    return state.activities;
  }

  @Selector()
  static sites(state: LiveworkSearchStateModel) {
    return state.sites;
  }

  @Selector()
  static departments(state: LiveworkSearchStateModel) {
    return state.departments;
  }

  @Selector()
  static tools(state: LiveworkSearchStateModel) {
    return state.tools;
  }

  @Selector()
  static equipment(state: LiveworkSearchStateModel) {
    return state.equipment;
  }

  @Selector()
  static countries(state: LiveworkSearchStateModel) {
    return state.countries;
  }

  @Selector()
  static regions(state: LiveworkSearchStateModel) {
    return state.regions;
  }

  @Selector()
  static status(state: LiveworkSearchStateModel) {
    return state.status;
  }

  @Selector()
  static consequenceTypes(state: LiveworkSearchStateModel) {
    return state.consequenceTypes;
  }

  @Selector()
  static hazardTypes(state: LiveworkSearchStateModel) {
    return state.hazardTypes;
  }

  @Selector()
  static likelihoodTypes(state: LiveworkSearchStateModel) {
    return state.likelihoodTypes;
  }

  @Selector()
  static onePageIndicator(state: LiveworkSearchStateModel) {
    return state.onePageIndicator;
  }

  @Selector()
  static fixedEquipment(state: LiveworkSearchStateModel) {
    return state.fixedEquipment;
  }

  @Selector()
  static mobileEquipment(state: LiveworkSearchStateModel) {
    return state.mobileEquipment;
  }

  @Selector()
  static solutionLead(state: LiveworkSearchStateModel) {
    return state.solution_lead;
  }


  @Action(LiveworkSearchReset)
  reset(ctx: StateContext<LiveworkSearchStateModel>) {
    ctx.patchState({
      showResults: false,
      activities: [],
      sites: [],
      departments: [],
      tools: [],
      equipment: [],
      countries: [],
      regions: [],
      status: [],
      consequenceTypes: [],
      hazardTypes: [],
      likelihoodTypes: [],
      onePageIndicator: false,
      fixedEquipment: false,
      mobileEquipment: false,
      solution_lead: ''
    });
  }

  @Action(LiveworkSearchUpdateOnePage)
  updateOnePage(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchUpdateOnePage) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      showResults: false,
      onePageIndicator: action.selected
    });
  }

  @Action(LiveworkSearchUpdateFixedEquipment)
  updateFixedEquipment(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchUpdateFixedEquipment) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      showResults: false,
      fixedEquipment: action.selected
    });
  }

  @Action(LiveworkSearchUpdateMobileEquipment)
  updateMobileEquipment(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchUpdateMobileEquipment) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      showResults: false,
      mobileEquipment: action.selected
    });
  }

  @Action(LiveworkSearchUpdateSolutionLead)
  updateSolutionLead(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchUpdateSolutionLead) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      showResults: false,
      solution_lead: action.solutionLead
    });
  }

  @Action(LiveworkSearchAddConsequenceType)
  addConsequenceType(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddConsequenceType) {
    const state = ctx.getState();

    const existing = state.consequenceTypes.find(x => x === action.consequenceType);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      consequenceTypes: [
        ...state.consequenceTypes,
        action.consequenceType
      ]
    });
  }

  @Action(LiveworkSearchAddHazardType)
  addHazardType(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddHazardType) {
    const state = ctx.getState();

    const existing = state.hazardTypes.find(x => x.hazard === action.hazardType.hazard);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      hazardTypes: [
        ...state.hazardTypes,
        action.hazardType
      ]
    });
  }

  @Action(LiveworkSearchAddLikelihoodType)
  addlikelihoodType(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddLikelihoodType) {
    const state = ctx.getState();

    const existing = state.likelihoodTypes.find(x => x === action.likelihood);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      likelihoodTypes: [
        ...state.likelihoodTypes,
        action.likelihood
      ]
    });
  }


  @Action(LiveworkSearchAddStatus)
  addStatus(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddStatus) {
    const state = ctx.getState();

    const existing = state.status.find(x => x.status_id === action.status.status_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      status: [
        ...state.status,
        action.status
      ]
    });
  }

  @Action(LiveworkSearchAddRegion)
  addRegion(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddRegion) {
    const state = ctx.getState();

    const existing = state.regions.find(x => x.region_id === action.region.region_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      regions: [
        ...state.regions,
        action.region
      ]
    });
  }

  @Action(LiveworkSearchAddCountry)
  addCountry(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddCountry) {
    const state = ctx.getState();

    const existing = state.countries.find(x => x.country_id === action.country.country_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      countries: [
        ...state.countries,
        action.country
      ]
    });
  }


  @Action(LiveworkSearchAddTool)
  addTool(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddTool) {
    const state = ctx.getState();

    const existing = state.tools.find(x => x.tool_id === action.tool.tool_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      tools: [
        ...state.tools,
        action.tool
      ]
    });
  }

  @Action(LiveworkSearchAddEquipment)
  addEquipment(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddEquipment) {
    const state = ctx.getState();

    const existing = state.equipment.find(x => x.fleet_id === action.equipment.fleet_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      equipment: [
        ...state.equipment,
        action.equipment
      ]
    });
  }


  @Action(LiveworkSearchAddSite)
  addSite(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddSite) {
    const state = ctx.getState();

    const existing = state.sites.find(x => x.site_id === action.site.site_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      sites: [
        ...state.sites,
        action.site
      ]
    });
  }

  @Action(LiveworkSearchAddDepartment)
  addDepartment(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddDepartment) {
    const state = ctx.getState();

    const existing = state.departments.find(x => x.department_id === action.department.department_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      departments: [
        ...state.departments,
        action.department
      ]
    });
  }


  @Action(LiveworkSearchAddActivity)
  addActivity(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchAddActivity) {
    const state = ctx.getState();

    const existing = state.activities.find(x => x.activity_id === action.activity.activity_id);
    if (existing) {
      return;
    }
    ctx.patchState({
      ...state,
      showResults: false,
      activities: [
        ...state.activities,
        action.activity
      ]
    });
  }

  @Action(LiveworkSearchRemoveConsequenceType)
  removeConsequenceType(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveConsequenceType) {
    const state = ctx.getState();
    const minus = state.consequenceTypes.filter(x => x.consequence !== action.consequenceType.consequence);
    ctx.patchState({
      ...state,
      showResults: false,
      consequenceTypes: minus
    });
  }

  @Action(LiveworkSearchRemoveHazardType)
  removeHazardType(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveHazardType) {
    const state = ctx.getState();
    const minus = state.hazardTypes.filter(x => x.hazard !== action.hazardType.hazard);
    ctx.patchState({
      ...state,
      showResults: false,
      hazardTypes: minus
    });
  }

  @Action(LiveworkSearchRemoveLikelihoodType)
  removeLikelihoodType(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveLikelihoodType) {
    const state = ctx.getState();
    const minus = state.likelihoodTypes.filter(x => x.likelihood !== action.likelihoodType.likelihood);
    ctx.patchState({
      ...state,
      showResults: false,
      likelihoodTypes: minus
    });
  }


  @Action(LiveworkSearchRemoveStatus)
  removeStatus(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveStatus) {
    const state = ctx.getState();
    const minus = state.status.filter(x => x.status_id !== action.status.status_id);
    ctx.patchState({
      ...state,
      showResults: false,
      status: minus
    });
  }


  @Action(LiveworkSearchRemoveRegion)
  removeRegion(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveRegion) {
    const state = ctx.getState();
    const minus = state.regions.filter(x => x.region_id !== action.region.region_id);
    ctx.patchState({
      ...state,
      showResults: false,
      regions: minus
    });
  }

  @Action(LiveworkSearchRemoveCountry)
  removeCountry(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveCountry) {
    const state = ctx.getState();
    const minus = state.countries.filter(x => x.country_id !== action.country.country_id);
    ctx.patchState({
      ...state,
      showResults: false,
      countries: minus
    });
  }


  @Action(LiveworkSearchRemoveActivity)
  removeActivity(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveActivity) {
    const state = ctx.getState();
    const minusActivity = state.activities.filter(x => x.activity_id !== action.activity.activity_id);
    ctx.patchState({
      ...state,
      showResults: false,
      activities: minusActivity
    });
  }

  @Action(LiveworkSearchRemoveSite)
  removeSite(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveSite) {
    const state = ctx.getState();
    const minus = state.sites.filter(x => x.site_id !== action.site.site_id);
    ctx.patchState({
      ...state,
      showResults: false,
      sites: minus
    });
  }

  @Action(LiveworkSearchRemoveDepartment)
  removeDepartment(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveDepartment) {
    const state = ctx.getState();
    const minus = state.departments.filter(x => x.department_id !== action.department.department_id);
    ctx.patchState({
      ...state,
      showResults: false,
      departments: minus
    });
  }

  @Action(LiveworkSearchRemoveEquipment)
  removeEquipment(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveEquipment) {
    const state = ctx.getState();
    const minus = state.equipment.filter(x => x.fleet_id !== action.equipment.fleet_id);
    ctx.patchState({
      ...state,
      showResults: false,
      equipment: minus
    });
  }

  @Action(LiveworkSearchRemoveTool)
  removeTool(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchRemoveTool) {
    const state = ctx.getState();
    const minus = state.tools.filter(x => x.tool_id !== action.tool.tool_id);
    ctx.patchState({
      ...state,
      showResults: false,
      tools: minus
    });
  }


  @Action(LiveworkSearchExecute)
  search(ctx: StateContext<LiveworkSearchStateModel>, action: LiveworkSearchExecute) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      showResults: action.show
    });
  }


}
