import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RedirectService } from 'app/services/intake-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class IntakeAuthGuard implements CanActivate {
  constructor(public authSvc: AuthService,
              private redirectService: RedirectService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const uidToken = localStorage.getItem('uidToken');
    //const user = localStorage.getItem('user');
    const version = this.authSvc.version;
    if (uidToken == null || (version !== localStorage.getItem('version'))) {
      this.redirectService.setRedirectUrl(state.url);
      this.authSvc.logout(true);
      return false;
    } else {
      return true;
    }
  }
}
