import { AfterViewInit, Directive } from '@angular/core';
import { MarkdownService } from 'app/services/markdown.service';
import { GenericComponentInteraction } from './models/dialog.model';
import { Store } from '@ngxs/store';

@Directive()
export abstract class BaseEditorComponent implements AfterViewInit {
  public addAttachmentDisplay: GenericComponentInteraction;

  ngAfterViewInit(): void {
    document.execCommand('styleWithCSS', false, 'false');
  }

  public pasted(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    const type = 'text/plain';
    const text = this.getTextFromClipboard(event.clipboardData, type);

    MarkdownService.insertContent(text, type);
  }

  protected getTextFromClipboard(clipboard: DataTransfer, type: string): string {

    let text = clipboard.getData(type);

    if (!text || text.length === 0) {
      return undefined;
    }

    if (type === 'text/html') {
      text = MarkdownService.cleanHtml(text);
    }
    return text;
  }
}
