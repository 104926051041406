import {Component} from '@angular/core';
import {ConfirmDialogResult} from '../../../../common/models/dialog.model';
import {ModalStates} from '../../../../common/models/modal-state.model';
import {Router} from '@angular/router';
import {DialogService} from '../../../../../services/dialog.service';
import {ColumnMapIndicator, ColumnModelTypes, DataModelNavigationEvent} from '../../../../common/models/data.model';
import {LandService} from '../../../../../services/land.service';
import {DataModelService} from '../../../../../services/data-model.service';
import {DataModelFormBaseComponent} from '../data-model-form-base.component';
import {DomainsEnum} from '../../../../common/models/domains.enum';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-form-data-model-column',
  templateUrl: './form-data-model-column.component.html',
  styleUrls: ['./form-data-model-column.component.css']
})
export class FormDataModelColumnComponent extends DataModelFormBaseComponent {

  public activeTab: string;
  public saving: boolean;
  public showMapping: boolean;

  constructor(
    protected router: Router,
    protected dialogService: DialogService,
    private landService: LandService,
    private dataModelService: DataModelService,
    protected logService: LogService) {
    super(router, dialogService, logService);
  }


  protected init(): void {
    this.modalState = this.dialogService.displayModal.current.data.displayMode;
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.column = this.dialogService.displayModal.current.data.column;
    this.table = this.dialogService.displayModal.current.data.table;
    this.dataModel = this.dialogService.displayModal.current.data.dataModel;
    this.dataModels = this.dialogService.displayModal.current.data.dataModels;
    this.showMapping = this.dataModel.domain.domain_id === DomainsEnum.LAND;

    this.switchTabs(this.dialogService.displayModal.current.activeTab ? this.dialogService.displayModal.current.activeTab : 'details');
  }

  protected setUser(user: any): void {
  }

  public dataModelChanged(data: DataModelNavigationEvent): void {
    this.dataModel = data.dataModel;
    this.table = data.table;
    this.column = data.column;

    if (this.activeTab === 'category-values' &&
      (this.column.column_model_type !== ColumnModelTypes.CATEGORY || this.column.mapped_indicator !== ColumnMapIndicator.mapped)) {
      this.switchTabs('details');
    }
  }

  public switchTabs(tabId: string): void {
    this.dialogService.displayModal.current.activeTab = tabId;
    this.activeTab = tabId;
  }

  public edit(): void {
    this.modalState = ModalStates.EDIT;
    this.switchTabs('details');
  }

  public isValid(): boolean {

    if (!this.dataModelService.validateObjectName(this.column.column_model_name)) {
      return false;
    }

    if (!this.column.column_data_type) {
      return false;
    }

    if (!this.column.column_model_type) {
      return false;
    }

    return true;
  }

  public save(): void {
    if (!this.isValid()) {
      return;
    }
    this.saving = true;

    if (this.modalState === ModalStates.ADD) {
      this.saveNewColumn();
      return;
    }
    this.saveColumn();
  }

  private saveColumn(): void {
    this.dataModelService.saveColumn(this.column, this.dataModel.data_model_base_id,
      this.table.table_model_base_id).subscribe(() => {
      this.saving = false;
      this.modalRef.close(ConfirmDialogResult.Confirm, this.column);
    });
  }

  private saveNewColumn(): void {
    this.column.table = this.table;

    this.dataModelService.saveNewColumn(this.column, this.dataModel.data_model_base_id,
      this.table.table_model_base_id).subscribe((results) => {

      const split = results.id.split(':');
      const id = split[split.length - 1];
      this.dataModelService.getColumn(this.dataModel.data_model_base_id, this.table.table_model_base_id, id).subscribe((newColumn) => {
        this.saving = false;
        this.modalRef.close(ConfirmDialogResult.Confirm, newColumn);
      });

    });

  }


}
