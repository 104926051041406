import {Component} from '@angular/core';
import {ColumnValuesMappingBaseComponent} from '../ColumnValuesMappingBaseComponent';
import {Router} from '@angular/router';
import {LandService} from '../../../../../../services/land.service';
import {DialogService} from '../../../../../../services/dialog.service';
import {
  ColumnMapIndicator,
  ColumnMappingModel,
  MappingQueryTypes,
  ValueDataModel,
  ValueMappingQueryTypes
} from '../../../../../common/models/data.model';
import {forkJoin} from 'rxjs';
import {ModalStates} from '../../../../../common/models/modal-state.model';
import {DataModelService} from '../../../../../../services/data-model.service';
import {FormDataModelValueComponent} from '../../form-data-model-value/form-data-model-value.component';
import {LogService} from '../../../../../../services/log.service';

@Component({
  selector: 'app-column-category-values-target',
  templateUrl: './column-category-values-target.component.html',
  styleUrls: ['./column-category-values-target.component.css']
})
export class ColumnCategoryValuesTargetComponent extends ColumnValuesMappingBaseComponent {
  public newValue: string;

  constructor(
    protected router: Router,
    protected landService: LandService,
    protected dataModelService: DataModelService,
    protected dialogService: DialogService,
    protected logService: LogService) {
    super(
      router,
      landService,
      dataModelService,
      dialogService, logService);
  }

  protected setup(): void {
    if (!this.user || !this.column || !this.table || !this.dataModel) {
      return;
    }
    this.getColumnMappings();
  }

  private getColumnMappings(): void {
    this.loading = true;
    this.landService.getColumnMappings(MappingQueryTypes.target,
      this.column.column_model_id).subscribe((results: ColumnMappingModel[]) => {
      this.columnMappings = results;
      this.getColumnValues();
    }, () => {
      this.loading = false;
      this.columnMappings = [];
    });
  }

  private getColumnValues(): void {
    this.loading = true;

    const calls = [
      this.dataModelService.getColumnValues(
        this.dataModel.data_model_id, this.table.table_model_base_id, this.column.column_model_base_id),
      this.landService.getValueMappings(ValueMappingQueryTypes.target, this.column.column_model_id)
    ];

    forkJoin(calls).subscribe(results => {
      const values: any[] = results[0];
      const allValueMaps: any[] = results[1];
      this.columnValues = values;

      values.forEach((value: ValueDataModel) => {
        const valueMaps = allValueMaps.filter(v => v.target.value_model_id === value.value_model_id);

        valueMaps.forEach((valueMap) => {

          if (value[valueMap.source.column.table.data_model.data_model_id]
            && value[valueMap.source.column.table.data_model.data_model_id].length > 0) {
            value[valueMap.source.column.table.data_model.data_model_id] += ',<br>' + valueMap.source.value_model_name;
          } else {
            // tslint:disable-next-line:max-line-length
            value[valueMap.source.column.table.data_model.data_model_id] = valueMap.source.value_model_name;
          }

        });

      });
      this.loading = false;
    }, () => {
      this.columnValues = [];
      this.loading = false;
    });

  }

  public addValue(): void {
    const dialogRef = this.dialogService.openDialog('FormLandValueComponent',
      FormDataModelValueComponent,
      'land-view-value', 'xxl', {
        displayMode: ModalStates.ADD,
        column: this.column,
        dataModel: this.dataModel,
        value: {
          column: this.column,
          mapped_indicator: ColumnMapIndicator.not_mapped
        } as ValueDataModel
      });
    dialogRef.afterClosed.subscribe(() => {
      this.getColumnMappings();
    });
  }

  public editValue(value: ValueDataModel): void {
    const dialogRef = this.dialogService.openDialog('FormLandValueComponent',
      FormDataModelValueComponent,
      'land-view-value', 'xxl', {
        displayMode: ModalStates.EDIT,
        value
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }

  public deleteValue(value: ValueDataModel): void {
    this.saving = true;
    this.loading = true;

    this.dataModelService.deleteColumnValue(this.dataModel.data_model_base_id, this.table.table_model_base_id,
      this.column.column_model_base_id, value.value_model_base_id).subscribe(() => {
      this.saving = false;
      this.getColumnMappings();
    });

  }
}
