import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {endpoints} from '../utils/endpoints';
import {
  ColumnDataSlimModel,
  ColumnDataSlimModelResponse,
  ColumnMappingModel,
  ColumnMappingResponse,
  MappingQueryTypes,
  ValueDataSlimModel,
  ValueDataSlimModelResponse,
  ValueMappingModel,
  ValueMappingQueryTypes,
  ValueMappingResponse
} from '../components/common/models/data.model';
import {BaseService} from './base.service';
import {Store} from '@ngxs/store';

@Injectable({providedIn: 'root'})
export class LandService extends BaseService {

  constructor(protected http: HttpClient, protected store: Store) {
    super(http, store);
  }

  openWizard$ = new Subject<boolean>();

  onOpenWizard(): void {
    this.openWizard$.next(true);
  }

  getColumnModelChanges(): Observable<Array<ColumnDataSlimModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ColumnDataSlimModelResponse>(`${endpoints.common}/mappings/missing?mapping_class=ColumnModel`, this.httpOptions).pipe(
      map((response: ColumnDataSlimModelResponse) => {
        return response.column_models;
      })
    );
  }

  getValueModelChanges(): Observable<Array<ValueDataSlimModel>> {
    return this.http.get<any>(`${endpoints.common}/mappings/missing?mapping_class=ValueModel`, this.httpOptions).pipe(
      map((response: ValueDataSlimModelResponse) => {
        return response.value_models;
      }));
  }

  getColumnMappings(queryType: MappingQueryTypes,
                    queryValue: string): Observable<Array<ColumnMappingModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ColumnMappingResponse>(`${endpoints.common}/mappings/${this.getProfileLanguageId()}?${queryType}=${queryValue}`, this.httpOptions).pipe(
      map((response: ColumnMappingResponse) => {
        return response.mappings;
      })
    );
  }

  getValueMappings(queryType: ValueMappingQueryTypes,
                   queryValue: string): Observable<Array<ValueMappingModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ValueMappingResponse>(`${endpoints.common}/mappings/${this.getProfileLanguageId()}?mapping_type=ValueModel&${queryType}=${queryValue}`, this.httpOptions).pipe(
      map((response: ValueMappingResponse) => {
        return response.mappings;
      })
    );
  }

  saveNewColumnMapping(data: ColumnMappingModel): Observable<any> {
    return this.http.post<any>(`${endpoints.common}/mappings/${this.getLanguageId()}`, data, this.httpOptions);
  }

  saveColumnMapping(id: string,
                    data: ColumnMappingModel): Observable<any> {
    return this.http.put<any>(`${endpoints.common}/mappings/${this.getLanguageId()}/${id}`, data, this.httpOptions);
  }

  deleteColumnMapping(id: string): Observable<any> {
    return this.http.delete<any>(`${endpoints.common}/mappings/${this.getLanguageId()}/${id}`, this.httpOptions);
  }

  saveNewValueMapping(data: ValueMappingModel): Observable<any> {
    return this.http.post<any>(`${endpoints.common}/mappings/${this.getLanguageId()}`, data, this.httpOptions);
  }

  saveValueMapping(id: string,
                   data: ValueMappingModel): Observable<any> {
    return this.http.put<any>(`${endpoints.common}/mappings/${this.getLanguageId()}/${id}`, data, this.httpOptions);
  }

  uploadDataModelSchema(data: any): Observable<any> {
    return this.http.post(`${endpoints.data_model_schemas}`, data, this.httpOptions);
  }

}
