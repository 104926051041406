<div class="content-container">
  <clr-accordion [clrAccordionMultiPanel]="true">

    <clr-accordion-panel [clrAccordionPanelDisabled]="true" *ngIf="formComponent" [clrAccordionPanelOpen]="true">
      <clr-accordion-title class="evaluations-modal-control">
        <span [translate]="formHeader"></span>
      </clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <div #container>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel [clrAccordionPanelOpen]="true"
                         *ngFor="let control of sortControls()">
      <clr-accordion-title class="evaluations-modal-control" data-cy="evaluation-control">
        {{ control.control_name }}
      </clr-accordion-title>
      <clr-accordion-content *clrIfExpanded>
        <ul class="list-unstyled">
          <div *ngIf="displayMode === metadataFormMode.view">
            <ng-container *ngFor="let question of sortQuestions(control.questions)">
              <li class="p1 evaluations-modal-question" data-cy="evaluation-view-question">
                {{ question.question_text }}
              </li>
              <ng-container *ngIf="question.data_type === 'CHOICE'">
                <div *ngFor="let choice of sortChoices(question.choices)">
                  <div class="choice-container">
                    <div *ngIf="choice.color_type" class="choice-color {{choice.color_type}}"></div>
                    <clr-radio-container class="evaluations-modal-radio-container"
                                         data-cy="evaluation-question-choices">
                      <clr-radio-wrapper>
                        <input disabled type="radio" clrRadio id="{{choice.choice_id}}" name="{{question.question_id}}"
                               [(ngModel)]="question.answer.response"
                               [value]="choice.choice_id" [checked]="question.answer.choice_id"
                               data-cy="evaluation-view-answer"/>
                        <label data-cy="evaluation-view-answer-label">
                          {{choice.choice_text}}
                        </label>
                      </clr-radio-wrapper>
                    </clr-radio-container>
                  </div>
                </div>
                <div *ngFor="let choice of sortChoices(question.choices)">
                  <ng-container
                    *ngIf="question.answer?.finding === '' && (question.answer.response === choice.choice_id) && choice.comment">
                    <p class="p4 evaluations-modal-answer" [translate]="'evaluations-modal-body-finding-none'"></p>
                  </ng-container>
                </div>
                <ng-container *ngIf="question.answer?.finding?.length" data-cy="evaluation-view-finding">
                  <p class="p4 evaluations-modal-answer">
                    <span [translate]="'evaluations-modal-head-finding'"></span>
                    <span class="p2">
                        {{question.answer.finding}}
                      </span>
                  </p>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngIf="displayMode !== metadataFormMode.view">
            <ng-container *ngFor="let question of sortQuestions(control.questions)">
              <li class="p1 evaluations-modal-question" data-cy="evaluation-edit-question">
                {{ question.question_text }}
              </li>
              <ng-container *ngIf="question.data_type === 'CHOICE'">
                <div *ngFor="let choice of sortChoices(question.choices)">
                 <div class="choice-container">
                   <div *ngIf="choice.color_type" class="choice-color {{choice.color_type}}"></div>

                   <clr-radio-container class="evaluations-modal-radio-container" data-cy="evaluation-edit-radiobuttons">
                    <clr-radio-wrapper>
                      <input type="radio" clrRadio id="{{choice.choice_id}}" name="{{question.question_id}}"
                             [(ngModel)]="question.answer.response"
                             [value]="choice.choice_id" [checked]="question.answer.choice_id"
                             (ngModelChange)="question.answer.finding = ''" data-cy="evaluation-edit-answer"/>
                      <label data-cy="evaluation-edit-answer-label">
                        {{choice.choice_text}}
                      </label>
                    </clr-radio-wrapper>
                  </clr-radio-container>
                  <form class="clr-form" *ngIf="choice.comment && choice.choice_id == question.answer.response">
                    <div class="clr-control-container">
                        <textarea class="clr-textarea evaluations-modal-finding-textarea" required
                                  [(ngModel)]="question.answer.finding" name="finding"
                                  placeholder="Please add finding details." data-cy="evaluation-edit-finding">
                        </textarea>
                    </div>
                  </form>
                 </div>

                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ul>
      </clr-accordion-content>

    </clr-accordion-panel>

  </clr-accordion>
</div>
<div class="modal-footer safety-modal-actions-btn">
  <button (click)="close()" type="button" class="btn btn-outline" data-cy="metadata-close">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
  <button *ngIf="displayMode === metadataFormMode.view" (click)="edit()"
          type="button" class="btn btn-primary" data-cy="metadata-submit">
    <span [translate]="'evaluations-grid-btn-edit'"></span>
  </button>
  <button [disabled]="!isValid()" *ngIf=" displayMode !== metadataFormMode.view" (click)="save()"
          type="button" class="btn btn-primary" data-cy="metadata-submit">
    <span [translate]="'datatool-datasource-btn-save'"></span>
  </button>
</div>
