<div class="card evaluations-overview-card">
  <div class="card-header overview-title" data-cy="overview-title" [translate]="'evaluations-metrics-title'">Overview</div>
  <div class="card-block" data-cy="overview-card" #barsection>
    <ng-container *ngIf="!loading && metrics.metrics_total !== 0">
      <div class="clr-row">
        <div class="clr-col-xl" *ngIf="currentMetrics">
          <p class="p5 metric-title" data-cy="metrics-title-current" *ngIf="(currentMetrics.series_total !== 0)">{{ currentMetrics.title }}</p>
          <div class="wrap-chart" *ngIf="(currentMetrics.series_total !== 0)">
            <ng-container *ngFor="let datum of currentMetrics.series; let index = index;">
              <ng-container *ngIf="!isLabelDropped('current', index) && (datum.ratio !== 0)  && !hiddenLabels.current.includes(index)">
                <p class="bar-label label-num" [style.left.px]="calculateLabelXPos('current', index)" data-cy="metrics-label-num">{{ datum.value }}</p>
                <span [id]="index" class="bar-label label-text" [style.left.px]="calculateLabelXPos('current', index)" data-cy="metrics-label-text">{{ datum.name }}</span>
              </ng-container>
            </ng-container>
            <ngx-charts-bar-horizontal-stacked *ngIf="currentMetrics" [view]="currentMetricSize"
              [scheme]="currentMetricColors" [results]="[currentMetrics]" [gradient]="false" [xAxis]="false"
              [yAxis]="false" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false" [showGridLines]="false"
              [tooltipDisabled]="false" data-cy="metrics-bar-current">
            </ngx-charts-bar-horizontal-stacked>
          </div>
          <div class="label-dropped-flex">
            <ng-container *ngFor="let datum of currentMetrics.series; let index = index;">
              <ng-container *ngIf="hiddenLabels.current.includes(index)">
                <span class="bar-label label-dropped-text" [style.color]="setDroppedLabelColor('current', index)" data-cy="metrics-dropped-label"> {{ datum.value }} {{ datum.name }} </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="clr-col-xl" *ngIf="priorMetrics">
          <p class="p5 metric-title" data-cy="metrics-title-prior" *ngIf="(priorMetrics.series_total !== 0)">{{ priorMetrics.title }}</p>
          <div class="wrap-chart" *ngIf="(priorMetrics.series_total !== 0)">
            <ng-container *ngFor="let datum of priorMetrics.series; let index = index;">
              <ng-container *ngIf="!isLabelDropped('prior', index) && (datum.ratio !== 0)  && !hiddenLabels.prior.includes(index)">
                <p class="bar-label label-num" [style.left.px]="calculateLabelXPos('prior', index)" data-cy="metrics-label-num">{{ datum.value }}</p>
                <span [id]="index" class="bar-label label-text" [style.left.px]="calculateLabelXPos('prior', index)" data-cy="metrics-label-text">{{ datum.name }}</span>
              </ng-container>
            </ng-container>
            <ngx-charts-bar-horizontal-stacked *ngIf="priorMetrics" [view]="priorMetricSize"
              [scheme]="priorMetricColors" [results]="[priorMetrics]" [gradient]="false" [xAxis]="false"
              [yAxis]="false" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false" [showGridLines]="false"
              [tooltipDisabled]="false" data-cy="metrics-bar-prior">
            </ngx-charts-bar-horizontal-stacked>
          </div>
          <div class="label-dropped-flex">
            <ng-container *ngFor="let datum of priorMetrics.series; let index = index;">
              <ng-container *ngIf="hiddenLabels.prior.includes(index)">
                <span class="bar-label label-dropped-text" [style.color]="setDroppedLabelColor('prior', index)" data-cy="metrics-dropped-label"> {{ datum.value }} {{ datum.name }} </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="clr-col-xl" *ngIf="nextMetrics">
          <p class="p5 metric-title" data-cy="metrics-title-next" *ngIf="(nextMetrics.series_total !== 0)">{{ nextMetrics.title }}</p>
          <div class="wrap-chart" *ngIf="(nextMetrics.series_total !== 0)">
            <ng-container *ngFor="let datum of nextMetrics.series; let index = index;">
              <ng-container *ngIf="!isLabelDropped('next', index) && (datum.ratio !== 0)  && !hiddenLabels.next.includes(index)">
                <p class="bar-label label-num" [style.left.px]="calculateLabelXPos('next', index)" data-cy="metrics-label-num">{{ datum.value }}</p>
                <span [id]="index" class="bar-label label-text" [style.left.px]="calculateLabelXPos('next', index)" data-cy="metrics-label-text">{{ datum.name }}</span>
              </ng-container>
            </ng-container>
            <ngx-charts-bar-horizontal-stacked *ngIf="nextMetrics" [view]="nextMetricSize"
              [scheme]="nextMetricColors" [results]="[nextMetrics]" [gradient]="false" [xAxis]="false"
              [yAxis]="false" [legend]="false" [showXAxisLabel]="false" [showYAxisLabel]="false" [showGridLines]="false"
              [tooltipDisabled]="false" data-cy="metrics-bar-next">
            </ngx-charts-bar-horizontal-stacked>
          </div>
          <div class="label-dropped-flex">
            <ng-container *ngFor="let datum of nextMetrics.series; let index = index;">
              <ng-container *ngIf="hiddenLabels.next.includes(index)">
                <span class="bar-label label-dropped-text" [style.color]="setDroppedLabelColor('next', index)" data-cy="metrics-dropped-label"> {{ datum.value }} {{ datum.name }} </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading && metrics.metrics_total === 0">
      <p class="p3 overview-notice-nodata" [translate]="'metrics-data-none'"></p>
    </ng-container>
    <ng-container *ngIf="loading">
      <div class="overview-spinner-modal">
        <span class="spinner spinner-md"></span>
      </div>
    </ng-container>
  </div>
</div>