<div class="body">
  <div class="clr-row">
    <div class="clr-col">
      <p class="p4 form-heading" [translate]="'editor-add-link-name'">Name</p>
      <clr-input-container class="form" >
        <input required clrInput name="text" [(ngModel)]="text"/>
      </clr-input-container>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col">
      <p class="p4 form-heading" [translate]="'editor-add-link-url'">URL</p>
      <clr-input-container class="form" >
        <input class="url" required clrInput name="url" [(ngModel)]="url"/>
      </clr-input-container>
    </div>
  </div>
</div>
<div class="footer">
  <div class="fill-space"></div>
  <button (click)="finished()" [disabled]="!isValid()" type="button" class="btn btn-primary">
    <span [translate]="'editor-add-link-button'"></span>
  </button>

  <button  type="button" class="btn btn-outline"  data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
</div>

