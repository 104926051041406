import { Component } from '@angular/core';
import { MappingQueryTypes } from '../../../../../common/models/data.model';
import { LandService } from '../../../../../../services/land.service';
import { DialogService } from '../../../../../../services/dialog.service';
import { Router } from '@angular/router';
import { ColumnMappingBaseComponent } from '../ColumnMappingBaseComponent';
import {DataModelService} from '../../../../../../services/data-model.service';
import {LogService} from '../../../../../../services/log.service';

@Component({
  selector: 'app-data-model-column-mapping-source',
  templateUrl: './data-model-column-mapping-source.component.html',
  styleUrls: ['./data-model-column-mapping-source.component.css']
})
export class DataModelColumnMappingSourceComponent extends ColumnMappingBaseComponent {

  constructor(
    protected router: Router,
    protected landService: LandService,
    protected dialogService: DialogService,
    private dataModelService: DataModelService,
    protected logService: LogService) {
    super('land-column-map-source-to-target',
      MappingQueryTypes.source,
      router,
      landService,
      dialogService, logService);
  }

  public ignoreMapping(): void {
    if (!this.column) {
      return;
    }

    this.saving = true;
    this.dataModelService.ignoreColumnMapping(this.column,
      this.column.table.data_model.data_model_base_id,
      this.column.table.table_model_id).subscribe(() => {
      this.saving = false;
    }, () => {
      this.saving = false;
    });
  }

}
