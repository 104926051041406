import { Component, Input } from '@angular/core';
import { TreeHistory } from '../models/tree.model';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-sparkline',
  templateUrl: './sparkline.component.html',
  styleUrls: ['./sparkline.component.css']
})
export class SparklineComponent {
  data: TreeHistory[];
  curve = shape.curveCardinal;

  @Input()
  set series(data: TreeHistory[]) {
    this.data = data;
  }
}
