<div>

  <div class="clr-row">
    <div class="clr-col-2">
      <span [translate]="'intake-request-summary-id-label'" class="intake-summary-item-key"></span>
      <span class="intake-summary-item-key">:</span>
    </div>
    <div class="clr-col-8 intake-summary-item-value"> {{ results.id }} </div>
    <div class="clr-col-1 intake-clip-board-icon">
      <clr-tooltip>
        <clr-icon clrTooltipTrigger size="22" [attr.shape]="copiedId ? 'check' : 'copy-to-clipboard'"
          (click)="copyToClipboard(results.id, 'id')" class="clickable-icon"></clr-icon>
        <clr-tooltip-content style="width: fit-content;" clrPosition="right" clrSize="xs">
          <span
            [translate]="copiedId ? 'intake-request-summary-clipboard-copied' : 'intake-request-summary-clipboard-id'"></span>
        </clr-tooltip-content>
      </clr-tooltip>
    </div>
  </div>

  <div class="clr-row">
    <div class="clr-col-2">
      <span [translate]="'intake-request-summary-url-label'" class="intake-summary-item-key"></span>
      <span class="intake-summary-item-key">:</span>
    </div>
    <div class="clr-col-8 intake-summary-item-value">
      <a class="clickable-url" href="{{ intakeUrl }}" target="_blank">{{ intakeUrl }}</a>
    </div>
    <div class="clr-col-1 intake-clip-board-icon">
      <clr-tooltip>
        <clr-icon clrTooltipTrigger size="22" [attr.shape]="copiedUrl ? 'check' : 'copy-to-clipboard'"
          (click)="copyToClipboard(intakeUrl, 'url')" class="clickable-icon"></clr-icon>
        <clr-tooltip-content style="width: fit-content;" clrPosition="right" clrSize="xs" *clrIfOpen>
          <span
            [translate]="copiedUrl ? 'intake-request-summary-clipboard-copied' : 'intake-request-summary-clipboard-url'"></span>
        </clr-tooltip-content>
      </clr-tooltip>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline" (click)="close()">
    <span [translate]="'datatools-ok-button'"></span>
  </button>
</div>