<div class="modal-body">
  <app-data-model-model-navigation
    [datasource_level]="modelLevels.COLUMN"
    [datasource_modal_state]="modalState"
    [datasource_datamodel]="dataModel"
    [datasource_table]="table"
    [datasource_column]="column" (ModelChanged)="dataModelChanged($event)"></app-data-model-model-navigation>
  <ul class="nav" role="tablist" *ngIf="column && modalState !== modalStates.ADD">
    <li role="presentation" class="nav-item">
      <button
        [attr.data-cy]="'column-details'"
        class="btn btn-link nav-link"
        (click)="switchTabs('details')"
        [ngClass]="{'active': activeTab === 'details'}"
        type="button"><span [translate]="'land-column-details'"></span></button>
    </li>
    <li *ngIf="modalState !== modalStates.ADD && column?.column_statistics" role="presentation" class="nav-item">
      <button [attr.data-cy]="'column-stats'"
              class="btn btn-link nav-link"
              (click)="switchTabs('stats')"
              [ngClass]="{'active': activeTab === 'stats'}"
              type="button"><span [translate]="'land-column-stats'"></span></button>
    </li>
    <li *ngIf="modalState !== modalStates.ADD && showMapping" role="presentation" class="nav-item">
      <button
        [attr.data-cy]="'column-target-mappings'"
        class="btn btn-link nav-link"
        (click)="switchTabs('target-mappings')"
        [ngClass]="{'active': activeTab === 'target-mappings'}"
        type="button"><span [translate]="'land-column-mappings'"></span></button>
    </li>
    <li *ngIf="modalState !== modalStates.ADD && column.column_model_type === columnModelTypes.CATEGORY"
        role="presentation" class="nav-item">
      <button
        [attr.data-cy]="'column-target-values'"
        class="btn btn-link nav-link"
        (click)="switchTabs('category-values')"
        [ngClass]="{'active': activeTab === 'category-values'}"
        type="button"><span [translate]="'land-column-category-values'"></span></button>
    </li>
  </ul>
  <div class="tab-details" *ngIf="activeTab === 'details'">
    <div *ngIf="!column">
      Invalid column selected
    </div>

    <app-data-model-column-details *ngIf="column"
                             [datasource_datamodel]="dataModel"
                             [datasource_table]="table"
                             [datasource_column]="column"
                             [modal_state]="modalState"></app-data-model-column-details>

  </div>
  <div class="tab-statistics" *ngIf="activeTab === 'stats'">
    <app-data-model-column-statistics [datasource_column]="column"></app-data-model-column-statistics>

  </div>
  <div class="tab-mappings" *ngIf="activeTab === 'target-mappings'">
    <app-data-model-column-mappings [datasource_datamodel]="dataModel"
                              [datasource_column]="column"></app-data-model-column-mappings>
  </div>

  <div class="tab-category-values" *ngIf="activeTab === 'category-values'">
    <app-data-model-column-category-values [datasource_datamodel]="dataModel"
                                     [datasource_table]="table"
                                     [datasource_column]="column"
                                     [modal_state]="modalState"></app-data-model-column-category-values>
  </div>

</div>
<div class="modal-footer" *ngIf="activeTab !== 'category-values'">
  <button type="button" class="btn btn-outline" data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
  <button [disabled]="saving" type="button" class="btn btn-primary"
          (click)="edit()"
          *ngIf="(modalState === modalStates.VIEW && activeTab === 'details')"
          data-cy="form-edit">
    <span [translate]="'evaluations-grid-btn-edit'"></span>
  </button>
  <button type="button" class="btn btn-primary" [disabled]="!isValid() || saving"
          (click)="save()"
          *ngIf="((modalState === modalStates.EDIT || modalState === modalStates.ADD) && activeTab === 'details')"
          data-cy="form-submit">
    <span [translate]="modalState === modalStates.EDIT ? 'land-column-btn-save' : 'land-column-btn-submit'"></span>
  </button>

</div>
