import { Component, Input, OnInit } from '@angular/core';
import { RouteGroupModel } from '../models/routes.model';

@Component({
  selector: 'app-route-group-card',
  templateUrl: './route-group-card.component.html',
  styleUrls: ['./route-group-card.component.css']
})
export class RouteGroupCardComponent implements OnInit {

  @Input()
  public routeGroup: RouteGroupModel;

  constructor() {
  }

  ngOnInit(): void {
  }

}
