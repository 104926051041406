// "File" is made up of two vars: file data and the file name
// Clean and return a file object with both of the above

export class Snippet {
  constructor(public src: string, public file: File) { }
}

export function processFiles(fileInput: any) {
  let fileNames = [];
  let files = [];
  let selectedFile;
  Object.keys(fileInput.files).forEach((uploadedFile, i) => {
    let file: File = fileInput.files[i];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      selectedFile = new Snippet(event.target.result, file);
      files.push({ file_full: selectedFile.src, file_name: selectedFile.file.name });
      fileNames.push(selectedFile.file.name);
    });
    reader.readAsDataURL(file);
  });
  const fileObject = { files: files, fileNames: fileNames };
  return fileObject;
}
