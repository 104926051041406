import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SummarySearchModel } from 'app/components/common/models/exploration-summary-search.model';
import { HierarchyModel } from 'app/components/common/models/hierarchies.model';
import { ProjectModel } from 'app/components/common/models/project.model';
import { RegionModel } from 'app/components/common/models/region.model';
import { ReportingMonthModel } from 'app/components/common/models/reporting-month.model';
import { SiteModel } from 'app/components/common/models/site.model';
import { StatusModel } from 'app/components/common/models/status.model';
import { SubRegionModel } from 'app/components/common/models/sub-region.model';
import { UpdateUser } from 'app/components/common/models/update-user.model';

export interface ExplorationSearchStateModel {
  summarySearch: SummarySearchModel;
  searchStatus: boolean;
  showSearchBtns: boolean;
}

export class AddMonth {
  static readonly type = '[ExplorationSearchState] Add Month';

  constructor(public month: ReportingMonthModel) {
  }
}

export class RemoveMonth {
  static readonly type = '[ExplorationSearchState] Remove MOnth';

  constructor(public month: ReportingMonthModel) {
  }
}

export class AddProject {
  static readonly type = '[ExplorationSearchState] Add Project';

  constructor(public project: ProjectModel) {
  }
}

export class RemoveProject {
  static readonly type = '[ExplorationSearchState] Remove Project';

  constructor(public project: ProjectModel) {
  }
}

export class AddSite {
  static readonly type = '[ExplorationSearchState] Add Site';

  constructor(public site: SiteModel) {
  }
}

export class RemoveSite {
  static readonly type = '[ExplorationSearchState] Remove Site';

  constructor(public site: SiteModel) {
  }
}

export class AddSubRegion {
  static readonly type = '[ExplorationSearchState] Add SubRegion';

  constructor(public subRegion: SubRegionModel) {
  }
}

export class RemoveSubRegion {
  static readonly type = '[ExplorationSearchState] Remove SubRegion';

  constructor(public subRegion: SubRegionModel) {
  }
}

export class AddRegion {
  static readonly type = '[ExplorationSearchState] Add Region';

  constructor(public region: RegionModel) {
  }
}

export class RemoveRegion {
  static readonly type = '[ExplorationSearchState] Remove Region';

  constructor(public region: RegionModel) {
  }
}

export class AddStatus {
  static readonly type = '[ExplorationSearchState] Add Status';

  constructor(public status: StatusModel) {
  }
}

export class RemoveStatus {
  static readonly type = '[ExplorationSearchState] Remove Status';

  constructor(public status: StatusModel) {
  }
}

export class AddOwner {
  static readonly type = '[ExplorationSearchState] Add Owner';

  constructor(public owner: string) {
  }
}

export class RemoveOwner {
  static readonly type = '[ExplorationSearchState] Remove Owner';

  constructor(public owner: string) {
  }
}

export class AddHierarchy {
  static readonly type = '[ExplorationSearchState] Add Hierarchy';

  constructor(public hierarchy: HierarchyModel) {
  }
}

export class RemoveHierarchy {
  static readonly type = '[ExplorationSearchState] Remove Hierarchy';

  constructor(public hierarchy: HierarchyModel) {
  }
}

export class ClearSummary {
  static readonly type = '[ExplorationSearchState] Clear Summary';

  constructor() {
  }
}

export class ToggleStatus {
  static readonly type = '[ExplorationSearchState] Summary State Toggled';

  constructor() {
  }
}

export class SearchSummaries {
  static readonly type = '[ExplorationSearchState] Search Summaries';

  constructor() {
  }
}

@State<ExplorationSearchStateModel>({
  name: 'explorationSearchState',
  defaults: {
    summarySearch: {
      reporting_month_id: [],
      project_id: [],
      site_id: [],
      sub_region_id: [],
      region_id: [],
      status: [],
      close_user_email: [],
      hierarchies: []
    },
    searchStatus: true,
    showSearchBtns: false
  }
})
@Injectable()
export class ExplorationSearchState {

  @Selector()
  static searchStatusState(state: ExplorationSearchStateModel) {
    return state.searchStatus;
  }

  @Selector()
  static reportingMonthsState(state: ExplorationSearchStateModel) {
    return state.summarySearch.reporting_month_id;
  }

  @Selector()
  static projectsState(state: ExplorationSearchStateModel) {
    return state.summarySearch.project_id;
  }

  @Selector()
  static sitesState(state: ExplorationSearchStateModel) {
    return state.summarySearch.site_id;
  }

  @Selector()
  static subRegionsState(state: ExplorationSearchStateModel) {
    return state.summarySearch.sub_region_id;
  }

  @Selector()
  static regionsState(state: ExplorationSearchStateModel) {
    return state.summarySearch.region_id;
  }

  @Selector()
  static statusState(state: ExplorationSearchStateModel) {
    return state.summarySearch.status;
  }

  @Selector()
  static ownersState(state: ExplorationSearchStateModel) {
    return state.summarySearch.close_user_email;
  }

  @Selector()
  static hierarchyState(state: ExplorationSearchStateModel) {
    return state.summarySearch.hierarchies;
  }

  @Selector()
  static summaryState(state: ExplorationSearchStateModel) {
    return state.summarySearch;
  }

  @Selector()
  static searchBtnsState(state: ExplorationSearchStateModel) {
    return state.showSearchBtns;
  }

  @Action(AddMonth)
  addMonth(ctx: StateContext<ExplorationSearchStateModel>, action: AddMonth) {
    const state = ctx.getState();
    if (state.summarySearch.reporting_month_id.includes(action.month)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: [
            ...state.summarySearch.reporting_month_id,
            action.month
          ],
          project_id: state.summarySearch.project_id,
          site_id: state.summarySearch.site_id,
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: state.summarySearch.region_id,
          status: state.summarySearch.status,
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveMonth)
  removeMonth(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveMonth) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: [...state.summarySearch.reporting_month_id.filter(month => month.reporting_month_id
          !== action.month.reporting_month_id)],
        project_id: state.summarySearch.project_id,
        site_id: state.summarySearch.site_id,
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: state.summarySearch.region_id,
        status: state.summarySearch.status,
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: state.summarySearch.hierarchies
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
      newState.summarySearch.reporting_month_id.length === 0 &&
      newState.summarySearch.project_id.length === 0 &&
      newState.summarySearch.site_id.length === 0 &&
      newState.summarySearch.sub_region_id.length === 0 &&
      newState.summarySearch.region_id.length === 0 &&
      newState.summarySearch.status.length === 0 &&
      newState.summarySearch.close_user_email.length === 0 &&
      newState.summarySearch.hierarchies.length === 0
    ) {
      ctx.patchState({ showSearchBtns: false })
    }
  }

  @Action(AddSite)
  addSite(ctx: StateContext<ExplorationSearchStateModel>, action: AddSite) {
    const state = ctx.getState();
    if (state.summarySearch.site_id.includes(action.site)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: state.summarySearch.project_id,
          site_id: [
            ...state.summarySearch.site_id,
            action.site
          ],
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: state.summarySearch.region_id,
          status: state.summarySearch.status,
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveSite)
  removeSite(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveSite) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: state.summarySearch.project_id,
        site_id: [...state.summarySearch.site_id.filter(site => site.site_id !== action.site.site_id)],
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: state.summarySearch.region_id,
        status: state.summarySearch.status,
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: state.summarySearch.hierarchies
      }
    });
      // get new state just after patching state
      const newState = ctx.getState();
      // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
      if (
          newState.summarySearch.reporting_month_id.length === 0 &&
          newState.summarySearch.project_id.length === 0 &&
          newState.summarySearch.site_id.length === 0 &&
          newState.summarySearch.sub_region_id.length === 0 &&
          newState.summarySearch.region_id.length === 0 &&
          newState.summarySearch.status.length === 0 &&
          newState.summarySearch.close_user_email.length === 0 &&
          newState.summarySearch.hierarchies.length === 0
        ) {
          ctx.patchState({ showSearchBtns: false })
        }
  }

  @Action(AddProject)
  addProject(ctx: StateContext<ExplorationSearchStateModel>, action: AddProject) {
    const state = ctx.getState();
    if (state.summarySearch.project_id.includes(action.project)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: [
            ...state.summarySearch.project_id,
            action.project
          ],
          site_id: state.summarySearch.site_id,
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: state.summarySearch.region_id,
          status: state.summarySearch.status,
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveProject)
  removeProject(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveProject) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: [...state.summarySearch.project_id.filter(project => project.project_id !== action.project.project_id)],
        site_id: state.summarySearch.site_id,
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: state.summarySearch.region_id,
        status: state.summarySearch.status,
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: state.summarySearch.hierarchies
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
        newState.summarySearch.reporting_month_id.length === 0 &&
        newState.summarySearch.project_id.length === 0 &&
        newState.summarySearch.site_id.length === 0 &&
        newState.summarySearch.sub_region_id.length === 0 &&
        newState.summarySearch.region_id.length === 0 &&
        newState.summarySearch.status.length === 0 &&
        newState.summarySearch.close_user_email.length === 0 &&
        newState.summarySearch.hierarchies.length === 0
      ) {
        ctx.patchState({ showSearchBtns: false })
      }
  }

  @Action(AddSubRegion)
  addSubRegion(ctx: StateContext<ExplorationSearchStateModel>, action: AddSubRegion) {
    const state = ctx.getState();
    if (state.summarySearch.sub_region_id.includes(action.subRegion)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: state.summarySearch.project_id,
          site_id: state.summarySearch.site_id,
          sub_region_id: [
            ...state.summarySearch.sub_region_id,
            action.subRegion
          ],
          region_id: state.summarySearch.region_id,
          status: state.summarySearch.status,
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveSubRegion)
  removeSubRegion(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveSubRegion) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: state.summarySearch.project_id,
        site_id: state.summarySearch.site_id,
        sub_region_id: [...state.summarySearch.sub_region_id.filter(subRegion =>
          subRegion.sub_region_id !== action.subRegion.sub_region_id)],
        region_id: state.summarySearch.region_id,
        status: state.summarySearch.status,
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: state.summarySearch.hierarchies
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
        newState.summarySearch.reporting_month_id.length === 0 &&
        newState.summarySearch.project_id.length === 0 &&
        newState.summarySearch.site_id.length === 0 &&
        newState.summarySearch.sub_region_id.length === 0 &&
        newState.summarySearch.region_id.length === 0 &&
        newState.summarySearch.status.length === 0 &&
        newState.summarySearch.close_user_email.length === 0 &&
        newState.summarySearch.hierarchies.length === 0
      ) {
        ctx.patchState({ showSearchBtns: false })
      }
  }

  @Action(AddRegion)
  addRegion(ctx: StateContext<ExplorationSearchStateModel>, action: AddRegion) {
    const state = ctx.getState();
    if (state.summarySearch.region_id.includes(action.region)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: state.summarySearch.project_id,
          site_id: state.summarySearch.site_id,
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: [
            ...state.summarySearch.region_id,
            action.region
          ],
          status: state.summarySearch.status,
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveRegion)
  removeRegion(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveRegion) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: state.summarySearch.project_id,
        site_id: state.summarySearch.site_id,
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: [...state.summarySearch.region_id.filter(region => region.region_id !== action.region.region_id)],
        status: state.summarySearch.status,
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: state.summarySearch.hierarchies
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
        newState.summarySearch.reporting_month_id.length === 0 &&
        newState.summarySearch.project_id.length === 0 &&
        newState.summarySearch.site_id.length === 0 &&
        newState.summarySearch.sub_region_id.length === 0 &&
        newState.summarySearch.region_id.length === 0 &&
        newState.summarySearch.status.length === 0 &&
        newState.summarySearch.close_user_email.length === 0 &&
        newState.summarySearch.hierarchies.length === 0
      ) {
        ctx.patchState({ showSearchBtns: false })
      }
  }

  @Action(AddStatus)
  addStatus(ctx: StateContext<ExplorationSearchStateModel>, action: AddStatus) {
    const state = ctx.getState();
    if (state.summarySearch.status.includes(action.status)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: state.summarySearch.project_id,
          site_id: state.summarySearch.site_id,
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: state.summarySearch.region_id,
          status: [
            ...state.summarySearch.status,
            action.status
          ],
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveStatus)
  removeStatus(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveStatus) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: state.summarySearch.project_id,
        site_id: state.summarySearch.site_id,
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: state.summarySearch.region_id,
        status: [...state.summarySearch.status.filter(status => status.status_id !== action.status.status_id)],
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: state.summarySearch.hierarchies
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
        newState.summarySearch.reporting_month_id.length === 0 &&
        newState.summarySearch.project_id.length === 0 &&
        newState.summarySearch.site_id.length === 0 &&
        newState.summarySearch.sub_region_id.length === 0 &&
        newState.summarySearch.region_id.length === 0 &&
        newState.summarySearch.status.length === 0 &&
        newState.summarySearch.close_user_email.length === 0 &&
        newState.summarySearch.hierarchies.length === 0
      ) {
        ctx.patchState({ showSearchBtns: false })
      }
  }

  @Action(AddOwner)
  addOwner(ctx: StateContext<ExplorationSearchStateModel>, action: AddOwner) {
    const state = ctx.getState();
    if (state.summarySearch.close_user_email.includes(action.owner)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: state.summarySearch.project_id,
          site_id: state.summarySearch.site_id,
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: state.summarySearch.region_id,
          status: state.summarySearch.status,
          close_user_email: [
            ...state.summarySearch.close_user_email,
            action.owner
          ],
          hierarchies: state.summarySearch.hierarchies
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveOwner)
  removeOwner(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveOwner) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: state.summarySearch.project_id,
        site_id: state.summarySearch.site_id,
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: state.summarySearch.region_id,
        status: state.summarySearch.status,
        close_user_email: [...state.summarySearch.close_user_email.filter(owner => owner !== action.owner)],
        hierarchies: state.summarySearch.hierarchies
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
        newState.summarySearch.reporting_month_id.length === 0 &&
        newState.summarySearch.project_id.length === 0 &&
        newState.summarySearch.site_id.length === 0 &&
        newState.summarySearch.sub_region_id.length === 0 &&
        newState.summarySearch.region_id.length === 0 &&
        newState.summarySearch.status.length === 0 &&
        newState.summarySearch.close_user_email.length === 0 &&
        newState.summarySearch.hierarchies.length === 0
      ) {
        ctx.patchState({ showSearchBtns: false })
      }
  }

  @Action(AddHierarchy)
  addHierarchy(ctx: StateContext<ExplorationSearchStateModel>, action: AddHierarchy) {
    const state = ctx.getState();
    if (state.summarySearch.hierarchies.includes(action.hierarchy)) {
      return;
    } else {
      ctx.patchState({
        summarySearch: {
          reporting_month_id: state.summarySearch.reporting_month_id,
          project_id: state.summarySearch.project_id,
          site_id: state.summarySearch.site_id,
          sub_region_id: state.summarySearch.sub_region_id,
          region_id: state.summarySearch.region_id,
          status: state.summarySearch.status,
          close_user_email: state.summarySearch.close_user_email,
          hierarchies: [
            ...state.summarySearch.hierarchies,
            action.hierarchy
          ]
        },
        showSearchBtns: true
      });
    }
  }

  @Action(RemoveHierarchy)
  removeHierarchy(ctx: StateContext<ExplorationSearchStateModel>, action: RemoveHierarchy) {
    const state = ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: state.summarySearch.reporting_month_id,
        project_id: state.summarySearch.project_id,
        site_id: state.summarySearch.site_id,
        sub_region_id: state.summarySearch.sub_region_id,
        region_id: state.summarySearch.region_id,
        status: state.summarySearch.status,
        close_user_email: state.summarySearch.close_user_email,
        hierarchies: [...state.summarySearch.hierarchies.filter(hierarchy => hierarchy.hierarchy_id !== action.hierarchy.hierarchy_id)]
      }
    });
    // get new state just after patching state
    const newState = ctx.getState();
    // evaluate if summarySearch object is empty, if so change showSearchBtns state slice
    if (
        newState.summarySearch.reporting_month_id.length === 0 &&
        newState.summarySearch.project_id.length === 0 &&
        newState.summarySearch.site_id.length === 0 &&
        newState.summarySearch.sub_region_id.length === 0 &&
        newState.summarySearch.region_id.length === 0 &&
        newState.summarySearch.status.length === 0 &&
        newState.summarySearch.close_user_email.length === 0 &&
        newState.summarySearch.hierarchies.length === 0
      ) {
        ctx.patchState({ showSearchBtns: false })
      }
  }

  @Action(ClearSummary)
  clearSummary(ctx: StateContext<ExplorationSearchStateModel>) {
    ctx.getState();
    ctx.patchState({
      summarySearch: {
        reporting_month_id: [],
        project_id: [],
        site_id: [],
        sub_region_id: [],
        region_id: [],
        status: [],
        close_user_email: [],
        hierarchies: []
      },
      showSearchBtns: false
    });
  }

  @Action(ToggleStatus)
  toggleStatus(ctx: StateContext<ExplorationSearchStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ searchStatus: !state.searchStatus });
  }

}
