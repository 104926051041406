import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-support',
  templateUrl: './app-support.component.html',
  styleUrls: ['./app-support.component.css']
})
export class AppSupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
