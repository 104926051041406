import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import * as firebase from 'firebase';
import {HttpClient} from '@angular/common/http';
import {endpoints} from '../utils/endpoints';
import {UsageLogModel} from '../components/common/models/usage-log.model';
import * as moment from 'moment';

// tslint:disable-next-line:ban-types

@Injectable({providedIn: 'root'})
export class LogService {
  private initialized: boolean;


  constructor(private router: Router,
              private http: HttpClient) {
  }

  public init(): void {
    if (this.initialized) {
      return;
    }
    firebase.analytics();

    this.initialized = true;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.trackPageView(event.urlAfterRedirects);
      }
    });

  }

  public setUser(email: string): void {
    firebase.analytics().setUserId(email);
  }

  public trackPageView(url: string): void {
    firebase.analytics().logEvent('page_view', {url});
    this.logUsage(url, '', 'page_view', null);
  }

  private getIds(data: any): string[] {

    if (!data) {
      return [];
    }

    let modelIds: string[] = [];
    if (data.model) {
      modelIds = this.getIds(data.model);
    }
    const ids: string[] = [...modelIds];

    Object.keys(data).forEach(key => {

      if (key === 'language_id') {
        return;
      }
      if (!key.includes('_id')) {
        return;
      }
      const value = data[key];
      if (!value || Array.isArray(value)) {
        return;
      }

      ids.push(`${key}: ${data[key]}`);
    });

    return ids;
  }

  public logUsage(path: string,
                  area: string,
                  action: string,
                  actionData: any): void {


    const sessionId = this.getToken();
    const userId = this.getUser();
    if (!sessionId || !userId) {
      return;
    }

    const data = {
      path, area, action,
      action_data: this.getIds(actionData).join(','),
      user_id: userId,
      session_id: sessionId,
      update_date: new Date(moment().utc().toString())
    } as UsageLogModel;

    this.http.post<any>(`${endpoints.common}/usage_logs`, data).subscribe(() => {
    });
  }

  public debug(message: any, params?: any): void {
    if (params) {
      console.log(message, params);
    } else {
      console.log(message);
    }
  }


  private getToken(): string {
    const token = localStorage.getItem('uidToken');
    return token;
  }

  private getUser(): string {
    const userStore = localStorage.getItem('user');
    if (!userStore) {
      return 'not authorized';
    }
    const user = JSON.parse(userStore);
    return user.user_id;
  }

}
