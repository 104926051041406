<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>

<ng-container *ngIf="display.feedback">
  <app-feedback (navUpdateDisplay)="updateDisplay($event)"></app-feedback>
</ng-container>

<ng-container *ngIf="display.feedbackConfirm">
  <clr-modal [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'md'" data-cy="feedback-confirm-modal">
    <div class="modal-body">
      <h3 class="modal-title confirm-modal-title" [translate]="'submodal-title-success'"></h3>
      <p class="p1 confirm-text" [translate]="'feedback-submodal-confirm-body'"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary minibtn-primary" (click)="updateDisplay('root')"
              [translate]="'submodal-btn-return'"></button>
    </div>
  </clr-modal>
</ng-container>

<ng-container *ngIf="display.profileConfirm">
  <clr-modal [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'md'" data-cy="profile-confirm-modal">
    <div class="modal-body">
      <h3 class="modal-title confirm-modal-title" [translate]="'submodal-title-success'"></h3>
      <p class="p1 confirm-text" [translate]="'profile-body-success'"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary minibtn-primary" (click)="updateDisplay('root')"
              [translate]="'submodal-btn-return'" data-cy="profile-confirm-btn"></button>
    </div>
  </clr-modal>
</ng-container>

<div class="main-container main-nav-component" *ngIf="!loading">
  <header class="header-6 main-nav-background">
    <div class="branding main-nav-branding">
      <ng-container *ngIf="metadata.authState.domains.length">
        <a *ngIf="(checkRoute() === 'common')" (click)="toggleAppMenu()" class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-common.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-common">{{ returnDomainName('common') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'auditor')" (click)="toggleAppMenu()" class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-auditor.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-auditor">{{ returnDomainName('auditor') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'datatool')" (click)="toggleAppMenu()" class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-datatool.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-data">{{ returnDomainName('datatool') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'evaluations')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-risks.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-risks">{{ returnDomainName('evaluations') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'productivity')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-productivity.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-productivity">{{ returnDomainName('productivity') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'livework')" (click)="toggleAppMenu()" class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-livework.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-livework">{{ returnDomainName('livework') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'exploration')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-exploration.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-exploration">{{ returnDomainName('exploration') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'xeras')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-xeras.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-xeras">{{ returnDomainName('xeras') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'land')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-land.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-land">{{ returnDomainName('land') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'start')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-start.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-start">{{ returnDomainName('start') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'control')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-control.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-control">{{ returnDomainName('control') }}</p>
          </div>
        </a>
        <a *ngIf="(checkRoute() === 'portal')" (click)="toggleAppMenu()"
           class="nav-link main-nav-branding-container">
          <div class="main-nav-branding-assets">
            <img src="../../../assets/images/sentinel-logo-white-portal.png" class="main-nav-sentinel-logo"/>
            <p class="main-nav-branding-text main-nav-hex-portal">{{ returnDomainName('portal') }}</p>
          </div>
        </a>
      </ng-container>
    </div>
    <div class="header-nav">
      <a *ngFor="let item of getNavItems()"
         appRouterLinkMatch
         [match]="item.routerLinkMatch"
         [routerLink]="[item.url]"
         [routerLinkActiveOptions]="{exact: item.routerLinkExact}" [attr.data-cy]="item.cy"
         [translate]="item.translateId"
         routerLinkActive="active" class="nav-link nav-text main-nav-link"
         (click)="hideAppMenu(item)">
      </a>
    </div>
    <div class="header-actions">
      <a class="logo-link" href="https://newmont.com" target="_blank">
        <img class="logo" src="../../../assets/images/newmont-logo-white-alt.png"/>
      </a>
      <clr-dropdown *ngIf="authSvc.afAuth.authState | async as authUser;">
        <button clrDropdownTrigger class="main-nav-settings-icon" data-cy="settings-button"
                *ngIf="metadata.authState.domains">
          <clr-icon size="36" shape="cog"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <label *ngIf="currentUser" class="dropdown-header main-nav-settings-username">{{currentUser.name_first}}
            {{currentUser.name_last}}</label>
          <div class="dropdown-divider" role="separator"></div>
          <button clrDropdownItem class="main-nav-minibtn" (click)="updateDisplay('profile')" data-cy="profile-minibtn">
            <clr-icon shape="user"></clr-icon>
            <span>&nbsp;</span>
            <span [translate]="'nav-settings-profile'"></span>
          </button>
          <button clrDropdownItem class="main-nav-minibtn" (click)="updateDisplay('feedback')"
                  data-cy="feedback-minibtn">
            <clr-icon shape="chat-bubble"></clr-icon>
            <span>&nbsp;</span>
            <span [translate]="'nav-settings-feedback'"></span>
          </button>
          <button clrDropdownItem class="main-nav-minibtn" (click)="authSvc.userLoggedOut()" data-cy="logout-minibtn">
            <clr-icon shape="logout"></clr-icon>
            <span>&nbsp;</span>
            <span [translate]="'nav-settings-logout'"></span>
          </button>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>
  <router-outlet></router-outlet>
</div>

<ng-container *ngIf="appMenu && !loading">
  <div class="btn-group btn-primary">
    <div class="menu-tray" *ngIf="navDomains">
      <button *ngFor="let domain of navDomains" class="btn main-nav-app-btn {{domain.class}}"
              [attr.data-cy]="domain.cyLabel">
        <div class="flex-x main-nav-btn-flex" [routerLink]="[domain.url]" (click)="hideAppMenu()">
          <img src="../../../assets/images/logo-small.png" class="app-btn-icon"/>
          <span class="p1 app-btn-text">{{ returnDomainName(domain.domain.domain_id) }}</span>
        </div>
      </button>
    </div>
  </div>
  <div class="overlay" (click)="clickOutsideNav($event)"></div>
</ng-container>

<ng-container *ngIf="display.profile">
  <app-profile (navUpdateDisplay)="updateDisplay($event)"></app-profile>
</ng-container>
<div *ngIf="impersonate" class="impersonate-as">
  Impersonating as {{impersonate}}
  <button (click)="stopImpersonating()" class="btn btn-link">end</button>
</div>
