import {BaseComponent} from '../base-component';
import {Router} from '@angular/router';
import {MetadataService} from '../../../services/metadata.service';
import {LogService} from '../../../services/log.service';
import {ConfirmButtonModel, ConfirmDialogModel, ConfirmDialogTypeEnum} from '../models/dialog.model';
import {DialogService} from '../../../services/dialog.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {Sanitizer} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {Observable} from 'rxjs';

export class FileDownloadBaseComponent extends BaseComponent {

  public downloading: boolean;
  public showDownloadError: boolean;


  constructor(protected router: Router,
              protected metaSvc: MetadataService,
              protected logService: LogService,
              protected dialogService: DialogService,
              protected storage: AngularFireStorage) {
    super(router, logService);
  }

  protected init(): void {
  }

  protected setUser(user: any): void {
  }

  public viewFile(fileId: string, fileName?: string): void {
    if (this.downloading) {
      return;
    }
    if (!fileName) {
      fileName = fileId;
    }
    this.showDownloadError = false;
    this.downloading = true;

    this.metaSvc.downloadFile(fileId, fileName)
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event) {
            switch (event.type) {
              case HttpEventType.Response:
                const blob: Blob = event.body;
                const newBlob = new Blob([blob], {type: event.body.type});
                const url = window.URL.createObjectURL(newBlob);
                const a = document.createElement('a');
                a.setAttribute('target', '_blank');
                a.setAttribute('hidden', '');
                a.setAttribute('href', `${url}`);

                if (fileName.includes('.xlsm')) {
                  a.setAttribute('download', fileName);
                }

                // b.setAttribute('href', `ms-excel:ofv|u|${ url }`);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                this.downloading = false;
                break;
            }
          }
        },
        (error) => {
          this.logService.debug(`file not found : ${fileId}`, error);
          this.showDownloadError = true;
          this.downloading = false;
          this.downloadError();
        });
  }

  public downloadGoogleStorage(closeWindow: boolean, fileId: string, fileName?: string): void {

    this.showDownloadError = false;
    this.downloading = true;

    const ref = this.storage.ref(`files/${fileId}.csv`);
    const refDownloadUrl = ref.getDownloadURL();
    refDownloadUrl.subscribe((storageUrl) => {
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', storageUrl);
      a.setAttribute('download', `${fileName}`);
      this.downloading = false;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      if (closeWindow) {
        window.close();
      }
    });
  }

  public downloadFile(closeWindow: boolean, fileId: string, fileName?: string): void {

    if (this.downloading) {
      return;
    }

    if (!fileName) {
      fileName = fileId;
    }
    this.showDownloadError = false;
    this.downloading = true;
    this.metaSvc.downloadFile(fileId, fileName)
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event) {
            switch (event.type) {
              case HttpEventType.Response:
                const blob: Blob = event.body;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', `${fileName}`);
                this.downloading = false;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                if (closeWindow) {
                  window.close();
                }
                break;
            }
          }
        },
        (error) => {
          this.logService.debug(`file not found : ${fileId}`, error);
          this.showDownloadError = true;
          this.downloading = false;
          this.downloadError();
        });
  }

  public downloadFilePath(closeWindow: boolean, fileId: string, path: string): void {

    if (this.downloading) {
      return;
    }

    this.showDownloadError = false;
    this.downloading = true;
    this.metaSvc.downloadFilePath(fileId, path)
      .subscribe(
        (event: HttpEvent<any>) => {
          if (event) {
            switch (event.type) {
              case HttpEventType.Response:
                const blob: Blob = event.body;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', `${fileId}`);
                this.downloading = false;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                if (closeWindow) {
                  window.close();
                }
                break;
            }
          }
        },
        (error) => {
          this.logService.debug(`file not found : ${fileId}`, error);
          this.showDownloadError = true;
          this.downloading = false;
          this.downloadError();
        });
  }

  private downloadError(): void {

    const dialogRef = this.dialogService.openConfirmDialog({
      titleTranslateId: 'file-download-not-found',
      type: ConfirmDialogTypeEnum.Warning,
      message: 'file-download-not-found',
      confirmButton: {show: true, textTranslateId: 'file-download-error-confirm'} as ConfirmButtonModel,
    } as ConfirmDialogModel);
    dialogRef.afterClosed.subscribe(() => {
      dialogRef.dialog.isOpen = false;
    });

  }
}
