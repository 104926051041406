<div class="content-container home-component">
  <div class="content-area">

    <div class="clr-row">
      <h1 class="h1 home-heading" data-cy="home-heading" [translate]="'intake-forms-main-nav-title'"></h1>
    </div>

    <div class="clr-row home-heading-subtitles">
      <div class="clr-col-3">
        <h4 class="h4 home-heading-subtitle-1" [translate]="'intake-forms-home-new-projects'"></h4>
      </div>
      <div class="clr-col-9">
        <h4 class="h4 home-heading-subtitle-2" [translate]="'intake-forms-home-existing-projects'"></h4>
      </div>
    </div>

    <div class="clr-row intake-centered-cards">
      <div class="clr-col-3">
        <div class="card home-infocard">
          <div class="card-header home-infocard-header">
            <clr-icon shape="lightbulb" size="25" style="vertical-align: bottom;"></clr-icon>
            <span class="intake-card-header-title" [translate]="'intake-forms-home-new-project-idea'"></span>
          </div>
          <div class="card-block home-infocard-block">
            <div class="card-text">
              <span [translate]="'intake-forms-new-project-card-body'">
              </span>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="newProject()" class="btn btn-link" data-cy="intake-forms-new-project"><span
                [translate]="'intake-forms-new-project'"></span></button>
          </div>
        </div>
      </div>

      <div class="clr-col-1 intake-divider-position">
        <mat-divider class="intake-card-divider" [vertical]="true"></mat-divider>
      </div>
      
      <div class="clr-col-3">
        <div class="card home-infocard">
          <div class="card-header home-infocard-header">
            <clr-icon shape="bug" size="25" style="vertical-align: bottom;"></clr-icon>
            <span class="intake-card-header-title" [translate]="'intake-forms-home-bug-report'"></span>
          </div>
          <div class="card-block home-infocard-block">
            <div class="card-text">
              <span [translate]="'intake-forms-bug-card-body'">
              </span>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="bugReport()" class="btn btn-link" data-cy="intake-forms-home-bug-report"><span
                [translate]="'intake-forms-home-bug-report'"></span></button>
          </div>
        </div>
      </div>

      <div class="clr-col-3">
        <div class="card home-infocard">
          <div class="card-header home-infocard-header">
            <clr-icon shape="cog" size="25" style="vertical-align: bottom;"></clr-icon>
            <span class="intake-card-header-title" [translate]="'intake-forms-home-enhancement-request'"></span>
          </div>
          <div class="card-block home-infocard-block">
            <div class="card-text">
              <span [translate]="'intake-forms-user-story-card-body'">
              </span>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="enhancementRequest()" class="btn btn-link" data-cy="intake-forms-enhancement-request"><span
                [translate]="'intake-forms-enhancement-request'"></span></button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>