import {Component, Input, OnInit} from '@angular/core';
import {DataModel, TableDataModel, DataModelTypes, TableFrequencyType} from '../../../../common/models/data.model';
import {ModalStates} from '../../../../common/models/modal-state.model';
import {LandService} from '../../../../../services/land.service';
import {DataModelService} from '../../../../../services/data-model.service';
import {DomainsEnum} from '../../../../common/models/domains.enum';

@Component({
  selector: 'app-data-model-table-details',
  templateUrl: './data-model-table-details.component.html',
  styleUrls: ['./data-model-table-details.component.css']
})
export class DataModelTableDetailsComponent implements OnInit {

  public table: TableDataModel;
  public dataModel: DataModel;
  public state: ModalStates;
  public modalStates = ModalStates;
  public editable: true;
  public dataModelTypes = DataModelTypes;
  public reservedWords: string[];
  public tableFrequencyType = TableFrequencyType;

  constructor(private landService: LandService, private dataModelService: DataModelService) {
  }

  ngOnInit(): void {
    this.reservedWords = this.dataModelService.getReservedWords();
  }

  public showFrequencyType(): boolean {
    if (!this.dataModel) {
      return false;
    }
    return this.dataModel.domain.domain_id !== DomainsEnum.LAND;
  }

  @Input()
  set datasource_table(table: TableDataModel) {
    this.table = table;
    if (!this.table.table_description || !this.table.table_description.html_text) {
      this.table.table_description = {
        html_text: '',
        markdown_text: '',
        plain_text: ''
      };
    }
  }

  @Input()
  set datasource_datamodel(dataModel: DataModel) {
    this.dataModel = dataModel;
  }

  @Input()
  set modal_state(state: ModalStates) {
    this.state = state;
  }

  public validateTableName(): boolean {
    if (!this.table.table_model_name || this.table.table_model_name.length === 0) {
      return true;
    }
    return this.dataModelService.validateObjectName(this.table.table_model_name);
  }
}
