<div class="clr-row">
  <div class="clr-col add-attachment-btn">
    <button (click)="displayAddAttachment()" 
      class="btn btn-link" [translate]="'exploration-attachment-add'">
      <clr-icon style="margin-right: 0.3rem;" shape="paperclip"></clr-icon>
      Add Attachment</button>
  </div>
</div>
<app-intake-attachments-list [datasource]="model"></app-intake-attachments-list>


<ng-container *ngIf="displayUpload">
  <clr-modal class="modal-loading intake-upload-modal" 
    [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'sm'">
    <h3 class="modal-title" [translate]="'exploration-upload-attachment'"></h3>
    <div class="modal-body">
      <div class="clr-row" *ngIf="!showFileInfo">
        <div class="clr-col">

          <div class="clr-row">
            <div class="clr-col">
              <ngx-file-drop [multiple]="false" (onFileDrop)="dropped($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  <div class="clr-row">
                    <div class="clr-col">
                      <span class="drag-label" [translate]="'exploration-attachment-upload-label'">You can drag an
                        attachment from your local computer</span>
                    </div>
                  </div>
                  <div class="clr-row">
                    <div class="clr-col">
                      <button class="btn btn-primary" type="button" (click)="openFileSelector()"><span
                          [translate]="'exploration-attachment-upload-browse'">Browse</span></button>
                    </div>
                  </div>
                </ng-template>
              </ngx-file-drop>
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col">
              <div class="modal-footer">
                <button (click)="closeAddAttachments()" class="btn"><span
                    [translate]="'exploration-attachment-upload-cancel'">Cancel</span></button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="clr-row" *ngIf="showFileInfo">
        <div class="clr-col">
          <form clrForm>
            <div class="clr-row">
              <div class="clr-col">
                <p class="p4 form-heading" [translate]="'exploration-attachment-upload-name'">Name<span>:</span>
                </p>
                <ng-container>
                  <clr-input-container class="form">
                    <input class="name" clrInput name="name" [(ngModel)]="name" required/>
                  </clr-input-container>
                </ng-container>
              </div>
            </div>

            <div class="clr-row">
              <div class="clr-col">
                <p class="p4 form-heading" [translate]="'exploration-attachment-upload-description'">
                  Description<span>:</span>
                </p>
                <ng-container>
                  <clr-textarea-container class="form form-text-area">
                    <textarea required clrTextarea class="description" 
                      [(ngModel)]="description" name="description"></textarea>
                  </clr-textarea-container>
                </ng-container>
              </div>
            </div>

            <div class="clr-row">
              <div class="clr-col">
                <div class="modal-footer">
                  <button (click)="closeAddAttachments()" class="btn"><span
                      [translate]="'exploration-attachment-upload-cancel'">Cancel</span></button>
                  <button [disabled]="!isValid() || saving" (click)="save()" class="btn btn-primary"><span
                      [translate]="'exploration-attachment-upload-save'">Save</span></button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
      <div *ngIf="saving" class="clr-row">
        <div class="clr-col">
          <app-loader [displayMode]="'uploadingAttachment'"></app-loader>
        </div>
      </div>
    </div>
  </clr-modal>
</ng-container>