import { Component } from '@angular/core';
import { ConfirmDialogResult } from 'app/components/common/models/dialog.model';
import { IntakeFormType } from 'app/components/common/models/intake.model';
import { DialogService } from 'app/services/dialog.service';
import { IntakeFormsComponent } from '../intake-forms/intake-forms.component';

@Component({
  selector: 'app-intake-home',
  templateUrl: './intake-home.component.html',
  styleUrls: ['./intake-home.component.css']
})
export class IntakeHomeComponent {
  public constructor(private dialogService: DialogService) { }

  newProject(): void {
    const dialogRef = this.dialogService.openDialog('IntakeFormsComponent', IntakeFormsComponent,
      'intake-forms-new-project-request', 'xxl',
      { intake_form_type: IntakeFormType.initiative });
    dialogRef.afterClosed.subscribe((results) => {
      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }
    });
  }

  bugReport(): void {
    const dialogRef = this.dialogService.openDialog('IntakeFormsComponent', IntakeFormsComponent,
      'intake-forms-home-bug-report', 'xxl',
      { intake_form_type: IntakeFormType.bug });
    dialogRef.afterClosed.subscribe((results) => {
      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }
    });
  }

  enhancementRequest(): void {
    const dialogRef = this.dialogService.openDialog('IntakeFormsComponent', IntakeFormsComponent,
      'intake-forms-home-enhancement-request', 'xxl',
      { intake_form_type: IntakeFormType.user_story });
    dialogRef.afterClosed.subscribe((results) => {
      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }
    });
  }
}
