export enum DomainsEnum {
  COMMON= 'common',
  PRODUCTIVITY = 'productivity',
  AUDITOR = 'auditor',
  EVALUATIONS = 'evaluations',
  DATATOOL = 'datatool',
  LIVEWORK = 'livework',
  EXPLORATION = 'exploration',
  LAND = 'land',
  START = 'start',
  GAME = 'game',
  PORTAL = 'portal',
  CONTROL = 'control'
}
