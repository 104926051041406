import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';
import {endpoints} from '../utils/endpoints';
import {extractData} from '../utils/extractdata';
import {map} from 'rxjs/operators';
import {MetadataService} from './metadata.service';
import {Store} from '@ngxs/store';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({providedIn: 'root'})
export class TranslationService implements TranslateLoader {

  constructor(public http: HttpClient, private store: Store) {
  }

  // Fires automatically on app component load
  // isoCode derived from user language_id
  getTranslation(isoCode): Observable<any> {
    return this.http.get(`${endpoints.metadata}/${isoCode}?application_type=desktop`, httpOptions).pipe(
      map((data) => {
          return data;
        }
      ));
  }
}
