import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IntakeFileModel, IntakeForms } from 'app/components/common/models/intake.model';

@Component({
  selector: 'app-intake-attachments-list',
  templateUrl: './intake-attachments-list.component.html',
  styleUrls: ['./intake-attachments-list.component.css']
})
export class IntakeAttachmentsListComponent implements OnInit {
  public model: IntakeForms;
  public loading: boolean;
  deleteConfirm: boolean = false;
  deleteConfirmStates: boolean[] = [];

  constructor(protected router: Router) { }

  ngOnInit(): void { 
    this.model.attachments.forEach(() => {
      this.deleteConfirmStates.push(false);
    });    
  }

  @Input()
  set datasource(model: IntakeForms) {
    this.model = model;
    if (!this.model.attachments || this.model.attachments.length === 0) {
      this.loading = false;
      return;
    }
  }

  deleteAttachment(attachment: IntakeFileModel, index: number): void {
    if (!this.model) {
      return;
    }
  
    this.model.attachments.splice(index, 1);
    this.deleteConfirmStates.splice(index, 1);
  }
}
