<clr-modal
  [clrModalOpen]="isOpen"
  [clrModalClosable]="false"
  [clrModalSize]="model?.size"
  data-cy="modal"
  [ngClass]="{'super-size': superSize}"
  id="display-modal">
  <div class="modal-title">
    <h3 class="modal-title text-notice" > <span [translate]="model?.header"></span><span *ngIf="model?.secondHeader"> {{model?.secondHeader}}</span></h3>
  </div>
  <div class="modal-body">
    <div #container>
    </div>
  </div>

</clr-modal>
