import { BaseComponent } from '../../../common/base-component';
import { Router } from '@angular/router';
import { DialogService } from '../../../../services/dialog.service';
import { ConfirmDialogResult, DisplayModalDialogRef } from '../../../common/models/dialog.model';
import { ModalStates } from '../../../common/models/modal-state.model';
import {
  ColumnDataModel,
  ColumnMapIndicator,
  ColumnModelTypes,
  DataModel,
  DataModelTypes,
  ModelLevels,
  TableDataModel
} from '../../../common/models/data.model';
import {LogService} from '../../../../services/log.service';

export abstract class DataModelFormBaseComponent extends BaseComponent {
  protected modalRef: DisplayModalDialogRef;

  public modalState: ModalStates;
  public modalStates = ModalStates;
  public dataModel: DataModel;
  public table: TableDataModel;
  public column: ColumnDataModel;
  public dataModelTypes = DataModelTypes;
  public columnModelTypes = ColumnModelTypes;
  public dataModels: DataModel[];
  public modelLevels = ModelLevels;
  public columnMapIndicator = ColumnMapIndicator;

  protected constructor(protected router: Router,
                        protected dialogService: DialogService,
                        protected logService: LogService) {
    super(router, logService);
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }


}
