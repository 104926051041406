import { ChangeDetectorRef, Component, DoCheck, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthService } from '../../../services/auth.service';
import { LogService } from '../../../services/log.service';
import { MetadataService, metadata } from '../../../services/metadata.service';
import { PermissionService } from '../../../services/permission.service';
import { UserAuthState } from '../../../store/auth.state';
import { BaseComponent } from '../base-component';
import { FeedbackComponent } from '../feedback/feedback.component';
import { AuthorizationDomainModel } from '../models/authorization.models';
import { DomainModel } from '../models/domain.model';
import { DomainsEnum } from '../models/domains.enum';
import { NavDomainModel, SubNavItemModel } from '../models/man-nav.model';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent extends BaseComponent implements DoCheck {
  public impersonate: string;

  clickOut = false;
  currentUser;
  loading: boolean;
  loadingSubscription;
  metadata: any = metadata;
  domainsList;
  primaryDomain;
  private allDomains: DomainModel[];
  appMenu = false;
  display = {
    root: true,
    feedback: false,
    feedbackConfirm: false,
    profile: false,
    profileConfirm: false
  };
  public navDomains: NavDomainModel[];

  constructor(protected router: Router,
    private route: Router,
    public cdr: ChangeDetectorRef,
    public authSvc: AuthService,
    public mdSvc: MetadataService,
    private permissionService: PermissionService,
    private store: Store,
    protected logService: LogService) {
    super(router, logService);
  }

  @ViewChild(FeedbackComponent) modal: FeedbackComponent;

  clickOutsideNav($event) {
    this.clickOut = true;
    if (this.clickOut) {
      this.appMenu = false;
    }
  }

  protected init(): void {
  }

  protected async setUser(user: any): Promise<void> {
    this.loading = true;
    this.loadingSubscription = this.authSvc.isLoadingChange.subscribe();
    await this.authSvc.initUser();
    this.mdSvc.getDomains().subscribe((domains) => {
      this.allDomains = domains;
      this.loading = this.authSvc.isLoading;
      this.initMenu();
    });

    this.store.select(UserAuthState.userAuth).subscribe((state) => {
      this.impersonate = state?.impId;
      if (state.impLoaded === true) {
        this.loading = this.authSvc.isLoading;
        this.initMenu();
      }
    });

  }

  // *** BASIC ***

  private initMenu(): void {
    this.navDomains = [];
    const authState = metadata.authState;

    const domains: AuthorizationDomainModel[] = authState.domains;
    domains.forEach((domain: AuthorizationDomainModel) => {
      if (domain.domain.domain_id === DomainsEnum.COMMON
        && domain.role.role_id !== 'admin') {
        return;
      }

      const domainUIName = this.getDomainUIName(domain.domain);
      const subItems = this.getSubItems(domain.domain.domain_id).filter((item) =>
        this.hasAccess(domain.domain.domain_id, item));
      const navDomain = {
        url: `/${domain.domain.domain_id}`,
        cyLabel: `app-btn-${domainUIName.toLowerCase()}`,
        class: `app-btn-${domainUIName.toLowerCase()}`,
        domain: domain.domain,
        subNavItems: subItems
      } as NavDomainModel;
      this.navDomains.push(navDomain);
    });

  }

  private hasAccess(domainId: string, subNavItem: SubNavItemModel): boolean {

    if (subNavItem.roles.length === 0 && subNavItem.groups.length === 0) {
      return true;
    }

    if (subNavItem.roles.length > 0) {
      return this.permissionService.hasRole(domainId, subNavItem.roles);
    }

    if (subNavItem.groups.length > 0) {
      return this.permissionService.hasGroup(domainId, subNavItem.groups);
    }

    return false;
  }

  private getSubItems(domainId: string): SubNavItemModel[] {
    switch (domainId) {
      case 'common':
        return this.getCommonItems();
      case 'evaluations':
        return this.getEvalItems();
      case 'auditor':
        return this.getAuditorItems();
      case 'datatool':
        return this.getDatatoolItems();
      case 'productivity':
        return this.getProductivityItems();
      case 'livework':
        return this.getLiveworkItems();
      case 'exploration':
        return this.getExplorationItems();
      case 'land':
        return this.getLandItems();
      case 'start':
        return this.getStartItems();
      case 'portal':
        return this.getPortalItems();
      case 'control':
        return this.getControlItems();
    }
    return [];
  }

  private getCommonItems(): SubNavItemModel[] {
    return [
      {
        url: '/common',
        routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home',
        translateId: 'nav-home',
        roles: ['admin'],
        groups: []
      },
      {
        url: '/common/admin',
        routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-admin',
        translateId: 'prod-nav-admin',
        roles: ['admin'], groups: []
      },
    ];
  }

  private getEvalItems(): SubNavItemModel[] {
    return [
      {
        url: '/evaluations', routerLinkExact: true,
        routerLinkMatch: ['/evaluations', '/evaluations/dashboard', '/evaluations/features'],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/evaluations/forms', routerLinkExact: false,
        routerLinkMatch: ['/evaluations/forms/scheduling', '/evaluations/forms'],
        cy: 'nav-evaluations', translateId: 'nav-evaluations', roles: [], groups: []
      },
      {
        url: '/evaluations/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-metadata', translateId: 'prod-nav-metadata', roles: ['admin'], groups: []
      }
    ];
  }

  private getAuditorItems(): SubNavItemModel[] {
    return [
      {
        url: '/auditor', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/auditor/profiles', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-profiles', translateId: 'nav-profiles', roles: [], groups: []
      },
      {
        url: '/auditor/trainings', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-trainings', translateId: 'main-nav-trainings', roles: ['admin', 'site'], groups: []
      }

    ];
  }

  private getDatatoolItems(): SubNavItemModel[] {
    return [
      {
        url: '/datatool', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/datatool/safety', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-data-tool', translateId: 'datatool-nav-safety', roles: [], groups: ['safety-form-admin', 'safety-form-coach', 'safety-form-users']
      },
      {
        url: '/datatool/remp', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-data-tool', translateId: 'datatool-nav-remp', roles: [], groups: ['remp-admin', 'remp-users']
      },
      {
        url: '/datatool/extracts', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-data-tool', translateId: 'datatool-nav-admin', roles: [], groups: ['datatool-extract']
      },
      {
        url: '/datatool/game/metrics', routerLinkExact: false,
        routerLinkMatch: ['/datatool/game/metrics'],
        cy: 'nav-data-game', translateId: 'datatool-nav-game', roles: [], groups: ['game-users', 'game-admin']
      },
      {
        url: '/datatool/global-recon/bulk', routerLinkExact: false,
        routerLinkMatch: ['/datatool/global-recon/bulk', '/datatool/global-recon/single'],
        cy: 'nav-data-global-recon', translateId: 'datatool-nav-global-recon', roles: [], groups: ['datatool-global-recon']
      },
      {
        url: '/datatool/xeras', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-data-xeras', translateId: 'datatool-nav-xeras', roles: [], groups: ['datatool-xeras']
      },
      {
        url: '/datatool/quality', routerLinkExact: false,
        routerLinkMatch: ['/datatool/quality/data-models', '/datatool/quality/search'],
        cy: 'nav-data-quality', translateId: 'datatool-nav-quality', roles: [], groups: ['data-quality-users']
      },
      {
        url: '/datatool/dashboard/tableau', routerLinkExact: false,
        routerLinkMatch: ['/datatool/dashboard/tableau', '/datatool/dashboard/hauler'],
        cy: 'nav-data-dashboard', translateId: 'datatool-nav-dashboard', roles: [], groups: ['dashboard-users', 'dashboard-admin']
      },
      {
        url: '/datatool/vacuum', routerLinkExact: false,
        routerLinkMatch: ['/datatool/vacuum', '/datatool/vacuum/datasets', '/datatool/vacuum/datatypes', '/datatool/vacuum/datasources', '/datatool/vacuum/tables'],
        cy: 'nav-data-vacuum', translateId: 'datatool-nav-vacuum', roles: [], groups: ['vacuum-admin', 'vacuum-user']
      },
      {
        url: '/datatool/metadata',
        routerLinkExact: false,
        routerLinkMatch: [],
        // tslint:disable-next-line:max-line-length
        cy: 'nav-data-tool-metadata',
        translateId: 'prod-nav-metadata',
        roles: [],
        groups: ['datatool-admin', 'game-admin']
      }
    ];
  }

  private getProductivityItems(): SubNavItemModel[] {
    return [
      {
        url: '/productivity', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/productivity/data-entry', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-data-entry', translateId: 'prod-nav-dataentry', roles: [], groups: []
      },
      {
        url: '/productivity/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-metadata', translateId: 'prod-nav-metadata', roles: ['admin'], groups: []
      }
    ];
  }

  private getLiveworkItems(): SubNavItemModel[] {
    return [
      {
        url: '/livework', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: ['livework-admin', 'livework-tasks-user', 'livework-register-user']
      },
      {
        url: '/livework/tasks', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-tasks', translateId: 'livework-nav-tasks', roles: [], groups: ['livework-admin', 'livework-tasks-user']
      },
      {
        url: '/livework/register', routerLinkExact: false,
        routerLinkMatch: ['/livework/register/search', '/livework/register/registers', '/livework/register/solution'],
        cy: 'nav-register', translateId: 'livework-nav-register', roles: [], groups: ['livework-admin', 'livework-register-user']
      },
      {
        url: '/livework/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-data-tool-metadata', translateId: 'prod-nav-metadata', roles: [], groups: ['livework-admin']
      }
    ];
  }

  private getExplorationItems(): SubNavItemModel[] {
    return [
      {
        url: '/exploration', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/exploration/monthly-reports', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-monthly-reports', translateId: 'nav-monthly-reports', roles: [], groups: []
      },
      {
        url: '/exploration/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-exploration-metadata', translateId: 'prod-nav-metadata', roles: [], groups: ['exploration-admin']
      }
    ];
  }

  private getLandItems(): SubNavItemModel[] {
    return [
      {
        url: '/land', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/land/mappings', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-land-mappings', translateId: 'prod-nav-mappings', roles: [], groups: []
      },
      {
        url: '/land/models', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-land-manage-models', translateId: 'prod-nav-models', roles: [], groups: []
      },
      {
        url: '/land/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-land-metadata', translateId: 'prod-nav-metadata', roles: [], groups: ['land-admin']
      }
    ];
  }

  private getStartItems(): SubNavItemModel[] {
    return [
      {
        url: '/start', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/start/plan/plan-landing', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-start-plan', roles: [], groups: ['start-maintenance-user', 'start-mining-user', 'start-admin']
      },
      {
        url: '/start/safety/retrospective',
        routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home',
        translateId: 'nav-start-safety',
        roles: [],
        groups: ['start-mining-user', 'start-maintenance-user', 'start-admin']
      },
      {
        url: '/start/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-land-metadata', translateId: 'prod-nav-metadata', roles: [], groups: ['start-admin']
      }
    ];
  }

  private getControlItems(): SubNavItemModel[] {
    const controlDomain = this.getDomainByDomainId(DomainsEnum.CONTROL);
    if (!controlDomain) {
      return [];
    }

    const items = [
      {
        url: '/control', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      },
      {
        url: '/control/alerts', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-control-alerts', translateId: 'nav-control-alerts', roles: [], groups: []
      }
    ];
    items.push(
      {
        url: '/control/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-land-metadata', translateId: 'prod-nav-metadata', roles: [], groups: ['control-admin']
      }
    );
    return items;
  }

  private getPortalItems(): SubNavItemModel[] {

    const portalDomain = this.getDomainByDomainId(DomainsEnum.PORTAL);
    if (!portalDomain) {
      return [];
    }

    const items = [
      {
        url: '/portal', routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home', translateId: 'nav-home', roles: [], groups: []
      }];

    const subDomains = this.permissionService.getSubDomains(DomainsEnum.PORTAL);

    subDomains.forEach(subDomain => {

      items.push(
        {
          url: `/portal/dashboard/${subDomain.sub_domain_id}/home`, routerLinkExact: true,
          routerLinkMatch: [`/portal/dashboard/${subDomain.sub_domain_id}/*`],
          cy: 'nav-dashboard', translateId: `datatool-nav-dashboard-boddington`, roles: [], groups: []
        });

    });

    items.push(
      {
        url: '/portal/metadata', routerLinkExact: false,
        routerLinkMatch: [],
        cy: 'nav-land-metadata', translateId: 'prod-nav-metadata', roles: [], groups: ['boddington-dashboard-admin']
      }
    );
    return items;
  }

  private getDomainUIName(domain: DomainModel): string {
    switch (domain.domain_id) {
      case (DomainsEnum.EVALUATIONS): {
        return 'risk';
      }
      case (DomainsEnum.DATATOOL): {
        return 'data';
      }
    }
    return domain.domain_id;
  }

  // If metadata exists, unsubscribe
  ngDoCheck() {
    this.cdr.detectChanges();
    if (this.authSvc.isMetadataLoaded()) {
      this.authSvc.changeIsLoading();
      this.loading = this.authSvc.isLoading;
      this.loadingSubscription.unsubscribe();
    }
  }

  // Force app menu to hide, fires when a route changes while app menu is open preventing reverse state
  hideAppMenu(item: SubNavItemModel) {
    this.appMenu = false;
    this.logService.logUsage(this.route.url, 'Main Navigation', 'click', item?.url);
  }

  // Toggles the app menu when branding is clicked
  toggleAppMenu() {
    this.appMenu = !this.appMenu;
  }

  public getNavItems(): SubNavItemModel[] {
    if (!this.navDomains) {
      return [];
    }

    const currentRoute = this.checkRoute();
    const domain = this.navDomains.find((d: NavDomainModel) => d.domain.domain_id === currentRoute);
    if (!domain) {
      return [];
    }
    return domain.subNavItems;
  }

  // Checks the current route to decide which navigation links are displayed
  public checkRoute(): string {
    let whichRoute;
    if (this.route.url.includes('/auditor')) {
      whichRoute = 'auditor';
    } else if (this.route.url.includes('/evaluations')) {
      whichRoute = 'evaluations';
    } else if (this.route.url.includes('/datatool')) {
      whichRoute = 'datatool';
    } else if (this.route.url.includes('/productivity')) {
      whichRoute = 'productivity';
    } else if (this.route.url.includes('/common')) {
      whichRoute = 'common';
    } else if (this.route.url.includes('/livework')) {
      whichRoute = 'livework';
    } else if (this.route.url.includes('/exploration')) {
      whichRoute = 'exploration';
    } else if (this.route.url.includes('/start')) {
      whichRoute = 'start';
    } else if (this.route.url.includes('/land')) {
      whichRoute = 'land';
    } else if (this.route.url.includes('/portal')) {
      whichRoute = 'portal';
    } else if (this.route.url.includes('/control')) {
      whichRoute = 'control';
    }
    return whichRoute;
  }

  // *** UPDATE ***

  // Display modes change based on dropdown selection
  updateDisplay($displayMode) {
    switch ($displayMode) {
      case 'root':
        this.display = {
          root: true,
          feedback: false,
          feedbackConfirm: false,
          profile: false,
          profileConfirm: false
        };
        break;
      case 'feedback':
        this.display.feedback = true;
        this.display.feedbackConfirm = false;
        this.display.profile = false;
        this.display.profileConfirm = false;
        break;
      case 'feedbackConfirm':
        this.display.feedbackConfirm = true;
        this.display.feedback = false;
        this.display.profile = false;
        this.display.profileConfirm = false;
        break;
      case 'profile':
        this.display.profile = true;
        this.display.feedback = false;
        this.display.feedbackConfirm = false;
        this.display.profileConfirm = false;
        break;
      case 'profileConfirm':
        this.display.profileConfirm = true;
        this.display.profile = false;
        this.display.feedback = false;
        this.display.feedbackConfirm = false;
        break;
      default:
        break;
    }
  }

  returnDomainName(whichDomain) {
    let returnedDomain = null;

    if (this.allDomains) {
      const domain = this.allDomains.find((d) => {
        // let domain = this.metadata.authState.domains.find((domain) => {
        return d.domain_id === whichDomain;
      });
      returnedDomain = domain.domain_name;
    }
    return returnedDomain;
  }

  public stopImpersonating(): void {
    this.loading = true;
    this.impersonate = undefined;
    this.authSvc.stopImpersonate();
  }
}
