import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

export interface UserProfileStateModel {
  language_id: string;
  profile_language_id: string;
}

export class UserProfileUpdate {
  static readonly type = '[UserProfile] update';
  constructor(public languageId: string, public profileLanguageId: string) {
  }
}


@State<UserProfileStateModel>({
  name: 'userProfileState',
  defaults: { language_id: 'en', profile_language_id: 'en'}
})
@Injectable()
export class UserProfileState {

  @Selector()
  static profile(state: UserProfileStateModel) {
    return state;
  }

  @Action(UserProfileUpdate)
  update(ctx: StateContext<UserProfileStateModel>, action: UserProfileUpdate) {
    const state = ctx.getState();
    if (state.profile_language_id === action.profileLanguageId &&
    state.language_id === action.languageId) {
      return;
    }
    ctx.patchState({
      ...state,
      language_id: action.languageId,
      profile_language_id: action.profileLanguageId
    });
  }

}
