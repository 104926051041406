<div class="modal-body">

  <div class="clr-row">
    <div class="clr-col-2 step-container">

      <div data-cy="step-datamodel" class="clr-row step-row">
        <div class="clr-col"
             [ngClass]="{'step-row-active': currentStep === 'step-datamodel-details',
                'step-row-complete': dataModelValid() }">
          <button (click)="goToDataModel()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'land-map-target-datamodel'"></button>
        </div>
      </div>

      <div data-cy="step-table" class="clr-row step-row">
        <div class="clr-col"
             [ngClass]="{'step-row-active': currentStep === 'step-table-details',
                'step-row-complete': tableValid() }">
          <button (click)="goToTable()" [disabled]="!dataModelValid()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'land-map-target-table'"></button>
        </div>
      </div>

      <div data-cy="step-column" class="clr-row step-row">
        <div class="clr-col"
             [ngClass]="{'step-row-active': currentStep === 'step-column-details',
                'step-row-complete': columnValid() }">
          <button (click)="goToColumn()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link" [disabled]="!tableValid()"
                  [translate]="'land-map-target-column'"></button>
        </div>
      </div>

    </div>
    <div class="clr-col-9 details-container">

      <div class="clr-row review">
        <div class="clr-col">
          <div class="clr-row">
            <div *ngIf="!selectedDataModel">
              <span [translate]="'land-map-target-wizard-header'">land-map-target-wizard-header</span>
            </div>
            <div class="" *ngIf="selectedDataModel">
              <span (click)="goToDataModel()" class="btn btn-link not-active"> {{ selectedDataModel.data_model_name }}</span>
            </div>
            <div class="" *ngIf="selectedTableModel">
              <clr-icon class="bread-divider" shape="angle right"></clr-icon>
              <span (click)="goToTable()" class="btn btn-link not-active"> {{ selectedTableModel.table_model_name }}</span>
            </div>
            <div class="" *ngIf="selectedColumnModel">
              <clr-icon class="bread-divider" shape="angle right"></clr-icon>
              <span (click)="goToColumn()" class="btn btn-link not-active"> {{ selectedColumnModel.column_model_name }}</span>
            </div>
          </div>
        </div>
      </div>


      <form>
        <div id="step-datamodel-details" *ngIf="currentStep === 'step-datamodel-details'">

          <div class="clr-row">
            <div class="clr-col">

              <clr-select-container style="margin-top: 0">
                <label [translate]="'land-map-target-select-datamodel'" class="select-label"></label>
                <select
                  clrSelect
                  name="datamodel"
                  (change)="changeDataModel()"
                  [(ngModel)]="selectedDataModelId"
                  [disabled]="loadingDataModels">
                  <option *ngFor="let dataModel of dataModels" [ngValue]="dataModel.data_model_base_id">
                    {{ dataModel.data_model_name }}
                  </option>
                </select>
              </clr-select-container>

            </div>
          </div>
        </div>

        <div id="step-table-details" *ngIf="currentStep === 'step-table-details'">

          <div class="clr-row">
            <div class="clr-col">

              <clr-select-container style="margin-top: 0">
                <label [translate]="'land-map-target-select-table'" class="select-label"></label>
                <select
                  clrSelect
                  name="table"
                  (change)="changeTableModel()"
                  [(ngModel)]="selectedTableModelId"
                  [disabled]="loadingTables">
                  <option *ngFor="let table of tables" [ngValue]="table.table_model_base_id">
                    {{ table.table_model_name }}
                  </option>
                </select>
              </clr-select-container>

            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col description">
              <div *ngIf="selectedTableModel && selectedTableModel.table_description" [innerHTML]="selectedTableModel.table_description.html_text"></div>
            </div>
          </div>

        </div>

        <div id="step-column-details" *ngIf="currentStep === 'step-column-details'">

          <div class="clr-row">
            <div class="clr-col">

              <clr-select-container style="margin-top: 0">
                <label [translate]="'land-map-target-select-column'" class="select-label"></label>
                <select
                  clrSelect
                  name="column"
                  (change)="changeColumnModel()"
                  [(ngModel)]="selectedColumnId"
                  [disabled]="loadingColumns">
                  <option *ngFor="let column of columns" [ngValue]="column.column_model_base_id">
                    {{ column.column_model_name }}
                  </option>
                </select>
              </clr-select-container>

            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col description">
              <div *ngIf="selectedColumnModel && selectedColumnModel.column_description" [innerHTML]="selectedColumnModel.column_description.html_text"></div>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline" data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>

  <button type="button" class="btn btn-outline" (click)="back()" [disabled]="!canGoBack()">
    <span [translate]="'land-wizard-btn-back'"></span>
  </button>

  <button type="button" class="btn btn-primary"
          (click)="next()"
          [disabled]="!canGoNext() || saving"
          data-cy="wizard-next">
    <span [translate]="currentStep === 'step-column-details' ? 'feedback-btn-submit' : 'land-wizard-btn-next'"></span>
  </button>


</div>



<ng-container *ngIf="saving">
  <app-loader [displayMode]=""></app-loader>
</ng-container>
