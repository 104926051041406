<clr-modal class="loader-modal" [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'sm'">
  <p class="modal-title font-center" *ngIf="displayMode === 'loading'" [translate]="'loader-title-loading'">Loading</p>
  <p class="modal-title font-center" *ngIf="displayMode === 'loadUpdate'" [translate]="'loader-title-update'"></p>
  <p class="modal-title font-center" *ngIf="displayMode === 'summary'" [translate]="'loader-search'">Searching...</p>
  <p class="modal-title font-center" *ngIf="displayMode === 'uploadingAttachment'" [translate]="'loader-saving-attachment'">Saving...</p>
  <p class="modal-title font-center" *ngIf="displayMode === 'downloading'" [translate]="'loader-downloading'">Downloading...</p>
  <div class="modal-body spinner-modal">
    <span class="spinner"></span>
  </div>
</clr-modal>
