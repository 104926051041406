<div class="clr-row">
  <div class="clr-col-3">
    <p [translate]="'land-column-stats-total-count'" class="title-label"></p>
    <div data-cy="col-stats-total-count">{{column.column_statistics.total_count || 0 | number}}</div>
  </div>
  <div class="clr-col-3">
    <p [translate]="'land-column-stats-distinct-count'" class="title-label"></p>
    <div data-cy="col-stats-distinct-count">{{column.column_statistics.distinct_count || 0 | number}}</div>
  </div>
  <div class="clr-col-3">
    <p [translate]="'land-column-stats-unique-count'" class="title-label"></p>
    {{column.column_statistics.unique_count || 0 | number}}
  </div>
</div>
<div class="clr-row">
  <div class="clr-col-3">
    <p [translate]="'land-column-stats-missing-count'" class="title-label"></p>
    {{column.column_statistics.missing_count || 0 | number}}
  </div>
  <div class="clr-col-3">
    <p [translate]="'land-column-stats-length-min'" class="title-label"></p>
    {{column.column_statistics.length_min || 0 | number}}
  </div>
  <div class="clr-col-3">
    <p [translate]="'land-column-stats-length-max'" class="title-label"></p>
    {{column.column_statistics.length_max || 0 | number}}
  </div>
</div>

