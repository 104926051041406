import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../common/base-component';
import {ColumnDataModel, ColumnMapIndicator, DataModel, DataModelTypes, TableDataModel} from '../../../common/models/data.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LandService} from '../../../../services/land.service';
import {DialogService} from '../../../../services/dialog.service';
import {DataModelService} from '../../../../services/data-model.service';
import {
  ConfirmButtonModel,
  ConfirmDialogModel,
  ConfirmDialogResult,
  ConfirmDialogTypeEnum,
  DisplayModalDialogResults
} from '../../../common/models/dialog.model';
import {FormDataModelTableComponent} from './form-data-model-table/form-data-model-table.component';
import {ModalStates} from '../../../common/models/modal-state.model';
import {FormDataModelTableCreateComponent} from './form-data-model-table-create/form-data-model-table-create.component';
import {FormDataModelColumnComponent} from './form-data-model-column/form-data-model-column.component';
import {FormDataModelColumnMapTargetComponent} from './form-data-model-column-map-target/form-data-model-column-map-target.component';
import {DomainsEnum} from '../../../common/models/domains.enum';
import {DataModelCreateComponent} from '../data-model-create/data-model-create.component';
import {LogService} from '../../../../services/log.service';

@Component({
  selector: 'app-data-model',
  templateUrl: './data-model.component.html',
  styleUrls: ['./data-model.component.css']
})
export class DataModelComponent extends BaseComponent {
  public activeDataModel: DataModel;
  public tablesLoading: boolean;
  public tables: TableDataModel[];
  public saving: boolean;
  public columnMapIndicator = ColumnMapIndicator;
  public dataModelTypes = DataModelTypes;
  public dataModels: DataModel[];
  public showMapping: boolean;

  constructor(protected router: Router,
              private activatedRoute: ActivatedRoute,
              private landService: LandService,
              private dialogService: DialogService,
              private dataModelService: DataModelService,
              protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
  }

  protected setUser(user: any): void {
  }

  @Input()
  public datamodels(dataModels: DataModel[]) {
    this.dataModels = dataModels;
  }

  @Input()
  public set model(dataModel: DataModel) {
    this.activeDataModel = dataModel;
    this.showMapping = dataModel.domain.domain_id === DomainsEnum.LAND;
    this.getTables();
  }

  public editDataModel(): void {
    const dialogRef = this.dialogService.openDialog('DataModelCreateComponent', DataModelCreateComponent,
      'datatool-quality-data-model-edit', 'xl', {});
    dialogRef.afterClosed.subscribe(() => {
    });
  }

  private getTables(): void {
    if (!this.activeDataModel) {
      return;
    }
    this.tablesLoading = true;
    this.dataModelService.getTables(this.activeDataModel.data_model_base_id).subscribe((data: TableDataModel[]) => {
      this.tablesLoading = false;
      this.tables = data;
      this.activeDataModel.tables = this.tables;
    }, (error) => {
      this.tablesLoading = false;
      this.tables = [];
      if (error.status === 404) {
        return;
      }
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'land-no-tables',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'land-no-tables-message',
        confirmButton: {show: true, textTranslateId: 'datatools-ok-button'} as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
      });
    });
  }

  public getColumns(table: TableDataModel): void {

    if (table.columns && table.columns.length > 0) {
      return;
    }

    table.columnsLoading = true;
    this.dataModelService.getColumns(this.activeDataModel.data_model_base_id,
      table.table_model_base_id).subscribe((data: ColumnDataModel[]) => {

      table.columns = data;
      table.columnsLoading = false;
      table.columns = data;

    }, () => {
      table.columns = [];
      table.columnsLoading = false;
    });
  }

  public toggleTable(table: TableDataModel): void {
    table.expanded = !table.expanded;
    if (table.expanded) {
      this.getColumns(table);
    }
  }

  public viewTable(table: TableDataModel): void {

    const dialogRef = this.dialogService.openDialog('FormLandTableComponent', FormDataModelTableComponent,
      'land-view-table', 'xl', {
        displayMode: ModalStates.VIEW,
        model: table,
        dataModel: this.activeDataModel,
        dataModels: this.dataModels
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }

  public editTable(table: TableDataModel): void {
    const dialogRef = this.dialogService.openDialog('FormLandTableComponent', FormDataModelTableComponent,
      'land-edit-table', 'xl', {
        displayMode: ModalStates.EDIT,
        model: table,
        dataModel: this.activeDataModel,
        dataModels: this.dataModels
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }

  public deleteTable(table: TableDataModel): void {

    const dialogRef = this.dialogService.openConfirmDialog({
      titleTranslateId: 'land-table-delete-confirm-header',
      type: ConfirmDialogTypeEnum.Info,
      message: 'land-table-delete-confirm-message',
      confirmButton: {show: true, textTranslateId: 'land-table-confirm-button'} as ConfirmButtonModel,
      cancelButton: {show: true, textTranslateId: 'land-table-cancel-button'} as ConfirmButtonModel
    } as ConfirmDialogModel);
    dialogRef.afterClosed.subscribe((confirmResult) => {

      if (confirmResult === ConfirmDialogResult.Cancel) {
        return;
      }
      this.saving = true;
      this.dataModelService.deleteTable(this.activeDataModel.data_model_base_id, table.table_model_base_id).subscribe(() => {
        this.tables.splice(this.tables.indexOf(table), 1);
        this.saving = false;
        dialogRef.dialog.isOpen = false;
      }, (error) => {
        this.saving = false;
        dialogRef.dialog.isOpen = false;

        const errorDialogRef = this.dialogService.openConfirmDialog({
          titleTranslateId: 'land-delete-table',
          type: ConfirmDialogTypeEnum.Warning,
          message: 'land-delete-table-error',
          subMessage: error,
          confirmButton: {show: true, textTranslateId: 'datatools-ok-button'} as ConfirmButtonModel
        } as ConfirmDialogModel);
        errorDialogRef.afterClosed.subscribe(() => {
        });
      });
    });

  }

  public canDeleteTable(): boolean {
    return this.activeDataModel.data_model_type === DataModelTypes.TARGET;
  }

  public createTable(): void {
    const dialogRef = this.dialogService.openDialog('FormLandTableCreateComponent', FormDataModelTableCreateComponent,
      'land-create-table', 'xxl', {displayMode: ModalStates.ADD, dataModel: this.activeDataModel});
    dialogRef.afterClosed.subscribe((results: DisplayModalDialogResults) => {
      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }
      this.activeDataModel.tables.push(results.data);
    });
  }

  public viewColumn(column: ColumnDataModel, table: TableDataModel): void {
    const dialogRef = this.dialogService.openDialog('FormLandColumnComponent', FormDataModelColumnComponent,
      'land-view-column', 'xxl', {
        displayMode: ModalStates.VIEW,
        column,
        table,
        dataModel: this.activeDataModel,
        dataModels: this.dataModels
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }

  public editColumn(column: ColumnDataModel, table: TableDataModel): void {
    const dialogRef = this.dialogService.openDialog('FormLandColumnComponent', FormDataModelColumnComponent,
      'land-edit-column', 'xxl', {
        displayMode: ModalStates.EDIT,
        column,
        table,
        dataModel: this.activeDataModel,
        dataModels: this.dataModels
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }

  public canDeleteColumn(column: ColumnDataModel): boolean {
    return this.activeDataModel.data_model_type === DataModelTypes.TARGET;
  }

  public canCreateColumn(table: TableDataModel): boolean {
    return this.activeDataModel.data_model_type === DataModelTypes.TARGET;
  }

  public ignoreColumnMapping(column: ColumnDataModel): void {
    this.saving = true;
    this.dataModelService.ignoreColumnMapping(column,
      this.activeDataModel.data_model_base_id,
      column.table.table_model_base_id).subscribe(() => {
      this.saving = false;
    });
  }

  public mapColumnTarget(column: ColumnDataModel): void {

    const dialogRef = this.dialogService.openDialog('FormLandColumnMapTargetComponent', FormDataModelColumnMapTargetComponent,
      'land-column-map-target', 'xl', {
        column
      });
    dialogRef.afterClosed.subscribe((results: DisplayModalDialogResults) => {

      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }

    });
  }

  public createColumn(table: TableDataModel): void {

    const dialogRef = this.dialogService.openDialog('FormLandColumnComponent', FormDataModelColumnComponent,
      'land-add-column', 'xl', {
        displayMode: ModalStates.ADD,
        column: {table} as ColumnDataModel,
        table,
        dataModel: this.activeDataModel,
        dataModels: this.dataModels
      });
    dialogRef.afterClosed.subscribe((results: DisplayModalDialogResults) => {

      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }
      if (!table.columns) {
        table.columns = [];
      }
      if (table.table_model_id === results.data.table.table_model_id) {
        table.columns.push(results.data);
      }
    });

  }

  public deleteColumn(column: ColumnDataModel, table: TableDataModel): void {

    const dialogRef = this.dialogService.openConfirmDialog({
      titleTranslateId: 'land-column-delete-confirm-header',
      type: ConfirmDialogTypeEnum.Info,
      message: 'land-column-delete-confirm-message',
      confirmButton: {show: true, textTranslateId: 'land-column-confirm-button'} as ConfirmButtonModel,
      cancelButton: {show: true, textTranslateId: 'land-column-cancel-button'} as ConfirmButtonModel
    } as ConfirmDialogModel);
    dialogRef.afterClosed.subscribe((confirmResult) => {

      if (confirmResult === ConfirmDialogResult.Cancel) {
        return;
      }
      this.saving = true;
      this.dataModelService.deleteColumn(
        this.activeDataModel.data_model_base_id,
        table.table_model_base_id,
        column.column_model_base_id).subscribe(() => {
        dialogRef.dialog.isOpen = false;
        table.columns.splice(table.columns.indexOf(column), 1);
        this.saving = false;
      }, (error) => {
        this.saving = false;
        dialogRef.dialog.isOpen = false;

        const errorDialogRef = this.dialogService.openConfirmDialog({
          titleTranslateId: 'land-column-table',
          type: ConfirmDialogTypeEnum.Warning,
          message: 'land-delete-column-error',
          subMessage: error.message,
          confirmButton: {show: true, textTranslateId: 'datatools-ok-button'} as ConfirmButtonModel
        } as ConfirmDialogModel);
        errorDialogRef.afterClosed.subscribe(() => {
        });

      });
    });

  }
}
