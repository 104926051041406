export const environment = {
  production: false,
  tableauProd: false,
  firebase: {
    appId: '1:846950108037:web:d038a65b622ad5a24752ec',
    apiKey: 'AIzaSyC_ryuq5yNID_GBasisQMFgWWGxTMmcRJ4',
    authDomain: 'dev.sentinel.newmont.com',
    databaseURL: 'https://dev-nmc-serac.firebaseio.com',
    projectId: 'dev-nmc-serac',
    storageBucket: 'dev_nmc_serac_images',
    messagingSenderId: '846950108037',
    measurementId: 'G-ESYYL4281P',
    vapidKey: 'BOw4gMlnvbIp-v8_MSi-Np4_HjxPFuh2fnTTAeAyx0sMIwBy2NZTpk5dhJag66vYV-oiy9oezlPVBdpOJ7_ZTLM'
  },
  apiPath: 'https://dev-esp-api-3bs2jqsflq-uc.a.run.app/v1',
  agmKey: 'AIzaSyD-GcNsw-k7F9_NPE16Ya6TBA9JoerZaqA',
  tableauPath: 'https://10ay.online.tableau.com/t/newmontgoldcorp/views/ProductivityTables-WebApp_Test/ProductivityTables-WebApp',
  explorationTableauDashboard: 'https://10ay.online.tableau.com/#/site/newmontgoldcorp/views/Exploration-LaunchPage_Test/Exploration-LaunchPage?:iid=1',
  dbtUrl: 'https://dev-nmc-serac.wl.r.appspot.com/',
  liveworkTableauDashboard: 'https://10ay.online.tableau.com/#/site/newmontgoldcorp/views/LiveWorkEliminationV2_Test/LWEMainPage',
  useFirebaseAuth: false,
  prodIntake: false
};
