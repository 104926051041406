import {UpdateUser} from './update-user.model';
import {DomainModel} from './domain.model';
import {TableData} from './datatool.model';

export interface DataModelResponse {
  data_models: DataModel[];
}

export interface DatasetModelResponse {
  dataset_models: DatasetDataModel[];
}

export enum ModelLevels {
  DATAMODEL = 'datamodel',
  TABLE = 'table',
  COLUMN = 'column',
  VALUE = 'value'
}

export enum DataModelTypes {
  SOURCE = 'source',
  TARGET = 'target'
}

export interface DatasetDataModel {
  dataset_model_id: string;
  dataset_model_name: string;
  dataset_project_id: string;
}

export interface RichTextModel {
  html_text: string;
  markdown_text: string;
  plain_text: string;
}

export interface DataModel {
  data_model_base_id: string;
  data_model_id: string;
  data_model_name: string;
  data_model_type: DataModelTypes;
  effective_end: Date;
  effective_start_date: Date;
  language_id: string;
  update_user: UpdateUser;
  version: number;
  domain: DomainModel;
  dataset: DatasetDataModel;
  tables: TableDataModel[];
  primary_target_data_model_id?: string;
  tablesLoading: boolean;
  data_dictionary_file?: DataDictionaryModel;
}

export interface DataDictionaryModel {
  file_content_type: string;
  file_description: string;
  file_extension: string;
  file_id: string;
  file_link: string;
  file_name: string;
  file_path: string;
  file_status: { status_id: string, status_name: string };
  file_thumbnail?: string;
  file_thumbnail_link?: string;
  file_title: string;
}

export interface TablesDataModelResponse {
  table_models: TableDataModel[];
}

export enum TableFrequencyType {
  once = 'once',
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly'
}

export interface TableDataModel {
  table_model_base_id: string;
  table_model_id: string;
  table_model_name: string;
  table_description: RichTextModel;
  data_model: DataModel;
  column_count: number;
  row_count: number;
  language_id: string;
  effective_end: Date;
  effective_start: Date;
  update_user: UpdateUser;
  version: number;
  profile_frequency_type: TableFrequencyType;
  exploratory_web_file: ProfileFile;
  columns: ColumnDataModel[];
  columnsLoading: boolean;
  expanded: boolean;
}

export interface DataQualityData extends TableData {
  table_name: string;
  update_date: Date;
  selected?: boolean;
}


export interface ProfileFile {
  file_content_type: string;
  file_extension: string;
  file_id: string;
  file_name: string;
}


export enum ColumnModelTypes {
  FACT = 'fact',
  ID = 'id',
  CATEGORY = 'category',
  UUID = 'uuid'
}

export enum ColumnDataTypes {
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  FLOAT = 'FLOAT',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  TIMESTAMP = 'TIMESTAMP',
  BOOL = 'BOOL',
  INT64 = 'INT64',
  NUMERIC = 'NUMERIC'
}

export enum ColumnMapIndicator {
  not_mapped = 'not_mapped',
  mapped = 'mapped',
  ignored = 'ignored'
}

export interface ColumnDataModelResponse {
  column_models: ColumnDataModel[];
}

export interface ColumnDataSlimModelResponse {
  column_models: ColumnDataSlimModel[];
}

export interface ColumnDataSlimModel {
  column_model_id: string;
  column_model_name: string;
  column_model_type: ColumnModelTypes;
  column_data_type: ColumnDataTypes;
  table: TableDataModel;
  mapped_indicator: ColumnMapIndicator;
  mapped_indicator_text: string;
  version: number;
  saving: boolean;
}

export interface ColumnDataModel {
  column_model_base_id: string;
  column_model_id: string;
  column_model_name: string;
  column_model_type: ColumnModelTypes;
  column_model_type_override: boolean;
  column_description: RichTextModel;
  column_data_type_override: boolean;
  column_data_type: ColumnDataTypes;
  table: TableDataModel;
  mapped_indicator: ColumnMapIndicator;
  mapped_indicator_text: string;
  column_statistics: ColumnStatisticsModel;
  effective_end: Date;
  effective_start: Date;
  update_user: UpdateUser;
  version: number;
  language_id: string;
}

export interface ColumnStatisticsModel {
  distinct_count: number;
  missing_count: number;
  total_count: number;
  distinct_percent: number;
  length_max: number;
  length_mean: number;
  length_median: number;
  length_min: number;
  missing_percent: number;
  unique_count: number;
  unique_percent: number;
}

export interface ValueDataModelResponse {
  value_models: ValueDataModel[];
}


export interface ValueDataSlimModelResponse {
  value_models: ValueDataSlimModel[];
}

export interface ValueDataSlimModel {
  value_model_id: string;
  value_model_name: string;
  column: ColumnDataModel;
  value_count: number;
  value_length: number;
  mapped_indicator: ColumnMapIndicator;
  version: number;
  saving: boolean;
}

export interface ValueDataModel {
  value_model_id: string;
  value_model_base_id: string;
  value_model_name: string;
  column: ColumnDataModel;
  value_count: number;
  value_description: RichTextModel;
  value_length: number;
  mapped_indicator: ColumnMapIndicator;
  update_user: UpdateUser;
  effective_end: Date;
  effective_start: Date;
  version: number;
  language_id: string;

  mapped_indicator_text: string;
}

export interface ModelChangesResponse {
  change: ModelChangesModel[];
}

export interface ModelChangesModel {
  source: string;
  table: string;
  column: string;
  description: string;
}

export interface DataModelNavigationEvent {
  dataModel: DataModel;
  table: TableDataModel;
  column: ColumnDataModel;
}

export enum MappingTypes {
  datamodel = 'DataModel',
  table = 'TableModel',
  column = 'ColumnModel',
  value = 'ValueModel'
}

export enum MappingQueryTypes {
  target = 'target.column_model_id',
  source = 'source.column_model_id'
}

export enum ValueMappingQueryTypes {
  target = 'target.column.column_model_id',
  source = 'source.column.column_model_id'
}

export interface ColumnMappingResponse {
  mappings: ColumnMappingModel[];
}

export interface ColumnMappingModel {
  mapping_id: string;
  mapping_type: MappingTypes;
  source: ColumnDataModel;
  target: ColumnDataModel;
  effective_end: Date;
  effective_start: Date;
  update_user: UpdateUser;
  version: number;
  language_id: string;
}

export interface ValueMappingResponse {
  mappings: ValueMappingModel[];
}

export interface ValueMappingModel {
  mapping_id: string;
  mapping_type: MappingTypes;
  source: ValueDataModel;
  target: ValueDataModel;
  effective_end: Date;
  effective_start: Date;
  update_user: UpdateUser;
  version: number;
  language_id: string;
}


export interface DataModelSearch {
  class_name: MappingTypes;
  data_model_id: string;
  data_model_name: string;
  dataset_model_name: string;
  dataset_project_id: string;
  domain_id: string;
  model_id: string;
  model_name: string;

  model_base_id: string;
  column_base_id: string;
  table_base_id: string;
  parent: string;
}

export interface MapTargetValues {
  columnId: string;
  loading: boolean;
  values: ValueDataModel[];
}

export interface DataModelInitiate {
  data_model_name: string;
  domain_id: string;
  project_id: string;
  dataset_model_name: string;
  profile_frequency_type: TableFrequencyType,
  tables: string[];
}
