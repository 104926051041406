import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base-component';
import { DialogService } from '../../../services/dialog.service';
import { VersionHistoryModel } from '../models/version-history.model';
import { ConfirmDialogResult, DisplayModalDialogRef } from '../models/dialog.model';
import {LogService} from '../../../services/log.service';

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.css']
})
export class VersionHistoryComponent extends BaseComponent {
  public model: VersionHistoryModel;
  private modalRef: DisplayModalDialogRef;

  constructor(protected router: Router,
              private dialogService: DialogService,
              protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.model = this.dialogService.displayModal.current.data.model;
  }

  protected setUser(user: any): void {
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

}
