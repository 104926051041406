import { AgmCoreModule } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { AgmOverlays } from 'agm-overlays';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ShowdownModule } from 'ngx-showdown';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppPrivacyComponent } from './components/common/app-privacy/app-privacy.component';
import { AppSupportComponent } from './components/common/app-support/app-support.component';
import { ConfirmModalComponent } from './components/common/confirm-modal/confirm-modal.component';
import { DisplayModalComponent } from './components/common/display-modal/display-modal.component';
import { LoginFbComponent } from './components/common/login-fb/login-fb.component';
import { LoginComponent } from './components/common/login/login.component';
import { LogoutComponent } from './components/common/logout/logout.component';
import { NoDomainAccessComponent } from './components/common/no-domain-access/no-domain-access.component';
import { WildcardComponent } from './components/common/wildcard/wildcard.component';
import { IntakeAttachmentsListComponent } from './components/intake/intake-attachments-list/intake-attachments-list.component';
import { IntakeAttachmentsComponent } from './components/intake/intake-attachments/intake-attachments.component';
import { IntakeFormsComponent } from './components/intake/intake-forms/intake-forms.component';
import { IntakeHomeComponent } from './components/intake/intake-home/intake-home.component';
import { httpInterceptorProviders } from './interceptors';
import { MessagingService } from './services/messaging.service';
import { TranslationService } from './services/translation.service';
import { SharedModule } from './shared.module';
import { ExplorationSearchMonthState, ExplorationSearchState, ExplorationViewState, MessagesState, UserAuthState } from './store';
import { DialogViewState } from './store/dialog.state';
import { LiveworkSearchState } from './store/livework-search.state';
import { MetadataCountryState } from './store/metadata-country.state';
import { MetadataDatabaseTypeState } from './store/metadata-database-types.state';
import { MetadataDepartmentState } from './store/metadata-department.state';
import { MetadataDomainState } from './store/metadata-domain.state';
import { MetadataFactState } from './store/metadata-facts.state';
import { MetadataFleetState } from './store/metadata-fleet.state';
import { MetadataMetricAreaState } from './store/metadata-metric-area.state';
import { MetadataMetricCategoryState } from './store/metadata-metric-category.state';
import { MetadataMetricState } from './store/metadata-metric.state';
import { MetadataMineState } from './store/metadata-mine.state';
import { MetadataRegionState } from './store/metadata-region.state';
import { MetadataRoleState } from './store/metadata-role.state';
import { MetadataShiftsState } from './store/metadata-shifts.state';
import { MetadataSiteState } from './store/metadata-sites.state';
import { MetadataStatusState } from './store/metadata-status.state';
import { MetadataSubregionState } from './store/metadata-subregions.state';
import { MetadataTagsState } from './store/metadata-tags.state';
import { MetadataThingState } from './store/metadata-thing.state';
import { MetadataToolState } from './store/metadata-tool.state';
import { MetadataUnitOfMeasureState } from './store/metadata-unit-of-measure.state';
import { MetadataUnitState } from './store/metadata-unit.state';
import { MetadataShiftState } from './store/start-shift.state';
import { MetadataTeamState } from './store/start-team.state';
import { TableauJwtState } from './store/tableau-jwt.state';
import { UserProfileState } from './store/user.profile.state';
import { IntakeSummaryComponent } from './components/intake/intake-summary/intake-summary.component';

const agmKey = environment.agmKey;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFbComponent,
    LogoutComponent,
    WildcardComponent,
    AppSupportComponent,
    AppPrivacyComponent,
    ConfirmModalComponent,
    DisplayModalComponent,
    NoDomainAccessComponent,
    IntakeHomeComponent,
    IntakeFormsComponent,
    IntakeAttachmentsComponent,
    IntakeAttachmentsListComponent,
    IntakeSummaryComponent
  ],
  exports: [
    TranslateModule,
    ConfirmModalComponent,
    DisplayModalComponent,
    NoDomainAccessComponent
  ],
  imports: [
    OrganizationChartModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot(),
    ShowdownModule.forRoot({
      simpleLineBreaks: true,
      requireSpaceBeforeHeadingText: true,
      emoji: false, noHeaderId: true, flavor: 'github'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.agmKey
    }),
    AgmOverlays,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    NgxsModule.forRoot([
      MetadataMineState,
      UserProfileState,
      MetadataTagsState,
      LiveworkSearchState,
      MetadataStatusState,
      MetadataToolState,
      MetadataDepartmentState,
      MetadataShiftState,
      MetadataShiftsState,
      MetadataDatabaseTypeState,
      MetadataUnitState,
      MetadataMetricState,
      MetadataSiteState,
      MetadataFactState,
      MetadataFleetState,
      MetadataMetricCategoryState,
      MetadataMetricAreaState,
      MetadataUnitOfMeasureState,
      MetadataThingState,
      MetadataRoleState,
      MetadataCountryState,
      MetadataSubregionState,
      MetadataRegionState,
      MetadataDomainState,
      MetadataTeamState,
      MessagesState,
      ExplorationViewState,
      ExplorationSearchState,
      ExplorationSearchMonthState,
      UserAuthState,
      DialogViewState,
      TableauJwtState
    ], {
      developmentMode: !environment.production
    }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxFileDropModule
  ],
  providers: [
    httpInterceptorProviders,
    MessagingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
