import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { endpoints } from '../../../utils/endpoints';
import { metadata, MetadataService } from '../../../services/metadata.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  error = '';
  feedback = {
    types: [
      'issue',
      'request',
      'comment',
    ]
  };
  loading;
  model = {
    application_type: 'desktop',
    feedback_text: '',
    feedback_type: '',
    user_id: ''
  };
  selections = {
    type: '',
    comment: '',
    state: null,
  };
  user;

  constructor(public mdSvc: MetadataService) { }
  @Output() navUpdateDisplay = new EventEmitter();

  // *** BASIC ***

  ngOnInit() {
    this.resetSelections();
    this.user = JSON.parse(localStorage.getItem('user')); 
  }

  resetSelections() {
    this.selections = {
      type: this.feedback.types[0],
      comment: '',
      state: null,
    }
  }

  // *** CLEAN ***

  // Cleans feedback request to be sent in service call
  createRequestBody() {
    this.model.user_id = this.user.user_id;
    this.model.feedback_type = this.selections.type;
    this.model.feedback_text = this.selections.comment;
    return this.model;
  }

  // *** HTTP ***

  // Post feedback to service and update the main nav component on success
  postFeedback() {
    this.loading = true;
    const reqBody = this.createRequestBody();
    return this.mdSvc.postFeedback(`${endpoints.feedback}?login_user_id=${this.user.user_id}`, reqBody).subscribe((res) => {
    }, (error) => {
      this.error = error.message;
      this.loading = false;
      console.log('Error: ', error);
    }, () => {
      this.loading = false;
      this.updateParentDisplay('feedbackConfirm');
    });
  }

  // *** SHARE ***

  // Shared with main nav component, tracks display mode
  updateParentDisplay($event) {
    this.resetSelections();
    this.navUpdateDisplay.emit($event);
  }

}
