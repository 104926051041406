import { Component } from '@angular/core';
import { BaseComponent } from '../base-component';
import { Router } from '@angular/router';
import { ConfirmDialogResult, DisplayModalDialogRef } from '../models/dialog.model';
import { DialogService } from '../../../services/dialog.service';
import { MessagingService } from '../../../services/messaging.service';
import { FirebasePermission } from '../models/firebase-message.model';
import {LogService} from '../../../services/log.service';

@Component({
  selector: 'app-firebase-confirm',
  templateUrl: './firebase-confirm.component.html',
  styleUrls: ['./firebase-confirm.component.css']
})
export class FirebaseConfirmComponent extends BaseComponent {
  public modalRef: DisplayModalDialogRef;
  public showDenied: boolean;
  public requesting: boolean;
  public requested: boolean;

  constructor(protected router: Router,
              private messagingService: MessagingService,
              private dialogService: DialogService,
              protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.requesting = false;
    this.modalRef = this.dialogService.displayModal.current.ref;
  }

  protected setUser(user: any): void {
  }

  public cancel(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Confirm);
  }

  public request(): void {
    this.requesting = true;
    this.showDenied = false;
    this.messagingService.requestPermission().then((results) => {
      this.requesting = false;
      this.requested = true;
      this.showDenied = results === FirebasePermission.denied;
    });
  }
}
