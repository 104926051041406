import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogService } from './log.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FirebasePermission } from '../components/common/models/firebase-message.model';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { MessageObject, MessagesState, MessageType, ReceivedMessage, ReceivedSourceMessage, TimeoutMessage } from '../store';

@Injectable({ providedIn: 'root' })
export class MessagingService {
  public token: string;
  private ready: boolean;
  private messageCheckInterval: any;

  constructor(private logService: LogService,
              private store: Store,
              private angularFireMessaging: AngularFireMessaging) {
  }

  public currentPermissions(): FirebasePermission {
    if (Notification.permission === 'default') {
      return FirebasePermission.ask;
    }
    return Notification.permission === 'granted' ? FirebasePermission.granted : FirebasePermission.denied;
  }

  public async requestPermission(): Promise<FirebasePermission> {
    try {
      this.logService.debug('requesting notification permission');
      await this.angularFireMessaging.requestPermission.toPromise();
    } catch (error) {
      this.logService.debug('request was denied');
      this.logService.debug(error);
      return FirebasePermission.denied;
    }
    return FirebasePermission.granted;
  }

  public requestToken(): Observable<string> {
    return this.angularFireMessaging.requestToken.pipe(
      map((results: string) => {
        this.token = results;
        return results;
      })
    );
  }

  public setup() {
    if (this.ready) {
      return;
    }
    this.ready = true;
    this.subscribe();
  }

  private subscribe(): void {
    this.logService.debug('subscribe to firebase messages');

    // check for timeouts every 1 min.
    this.messageCheckInterval = setInterval(() => {
      this.logService.debug('message timeout check');
      this.checkMessages();
    }, 60000);


    this.angularFireMessaging.messages.subscribe(
      (message: any) => {
        this.logService.debug('message arrived', message);
        this.dispatchMessage(message);
      });
  }

  private checkMessages(): void {

    const messages = this.store.selectSnapshot(MessagesState.notReceivedMessages);

    if (!messages || messages.length === 0) {
      return;
    }
    const now = moment();
    messages.forEach((message) => {
      const minSent = now.diff(moment(message.sentOn), 'minutes');
      this.logService.debug(`minSent:${ minSent };${ minSent >= 10 }`);
      if (minSent >= 5) {
        this.store.dispatch(new TimeoutMessage(message.id));
      }
    });
  }


  private dispatchMessage(message: any): void {

    if (message.data.sentinel_message_object === 'summary') {
      this.handleSummaryMessage(message);
      return;
    }

    if (message.data.sentinel_message_object === 'data_model') {
      this.logService.debug('handling data_model message');
      this.store.dispatch(
        new ReceivedMessage(MessageObject.land, message.data.data_model_id, message.data)
      );
      return;
    }
    if (message.data.datasource_id) {
      this.logService.debug('handling datatool message');
      this.store.dispatch(
        new ReceivedMessage(MessageObject.dataTool, message.data.datasource_id, message.data));
      return;
    }
  }

  private handleSummaryMessage(message): void {
    if (message.data.sentinel_message_type === MessageType.rollup) {
      if (message.data.rollup_complete === 'true') {
        this.logService.debug('rollup_complete:true');
        this.store.dispatch(
          new ReceivedMessage(MessageObject.summary, message.data.parent_summary_id, message.data));
      } else {
        this.logService.debug('rollup_complete:false');
        this.store.dispatch(
          new ReceivedSourceMessage(MessageObject.summary, message.data.summary_id, message.data));
      }
      return;
    }

    if (message.data.sentinel_message_type === MessageType.report) {
      this.store.dispatch(
        new ReceivedMessage(MessageObject.summary, message.data.summary_id, message.data));
      return;
    }
    this.logService.debug('not handled summary message');
  }

}
