import { Injectable } from '@angular/core';
import { AuthorizationDomainModel, AuthorizationModel } from '../components/common/models/authorization.models';
import { metadata } from './metadata.service';
import { ExplorationSummaryLevel } from '../components/common/models/exploration-monthly-reports.model';
import { DomainsEnum } from '../components/common/models/domains.enum';
import { RoleModel } from '../components/common/models/role.model';
import { GroupModel, SecurableModel, SecureableMap } from '../components/common/models/group.model';
import { SubDomainModel } from '../components/common/models/subdomain.model';


export enum PermissionLevelEnum {
  None = 0,
  View = 1,
  Edit = 2
}

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private authorizations: AuthorizationModel;
  private userPermissions: { [key: number]: SecureableMap };

  constructor() {
  }

  public setPermissions(authorizations: AuthorizationModel): void {

    this.authorizations = authorizations;
    this.userPermissions = {};
    this.authorizations.domains.forEach((domain: AuthorizationDomainModel) => {
      if (domain.role) {
        this.addRole(domain.domain.domain_id, domain.role);
      }
      if (domain.groups) {
        domain.groups.forEach((group: GroupModel) => {
          this.addGroup(domain.domain.domain_id, group);
        });
      }
    });
  }

  private addGroup(domainId: string, group: GroupModel): void {

    if (!group.securables) {
      return;
    }

    if (!this.userPermissions[domainId]) {
      this.userPermissions[domainId] = {};
    }

    if (!this.userPermissions[domainId].securables) {
      this.userPermissions[domainId].securables = {};
    }

    group.securables.forEach((securable: SecurableModel) => {
      this.userPermissions[domainId].securables[securable.thing.thing_id] = securable;
    });
  }

  private addRole(domainId: string, role: RoleModel): void {
    // only setting this for older domains until they are refactored to use permission service
    metadata.roles[domainId] = role.role_id;
    if (!this.userPermissions[domainId]) {
      this.userPermissions[domainId] = {};
    }
    this.userPermissions[domainId].role = role;
  }

  public checkLevelAccess(domain: DomainsEnum, level: ExplorationSummaryLevel): PermissionLevelEnum {

    if (!this.userPermissions || !this.userPermissions[domain]
      || !this.userPermissions[domain].securables
      || !this.userPermissions[domain].securables[level.level_type.thing_id]) {
      return PermissionLevelEnum.None;
    }

    const securables = this.userPermissions[domain].securables[level.level_type.thing_id];

    if (securables.role.role_id === 'viewer') {
      return PermissionLevelEnum.View;
    }

    if (securables.role.role_id === 'admin' || securables.role.role_id === 'editor' || securables.role.role_id === 'owner') {
      return PermissionLevelEnum.Edit;
    }
    return PermissionLevelEnum.None;
  }

  public getRoleAccess(domain: DomainsEnum, level: ExplorationSummaryLevel): string {
    if (!this.userPermissions || !this.userPermissions[domain]
      || !this.userPermissions[domain].securables
      || !this.userPermissions[domain].securables[level.level_type.thing_id]) {
      return 'none';
    }

    const securables = this.userPermissions[domain].securables[level.level_type.thing_id];

    return securables.role.role_id;
  }

  public hasRole(domainId: string, roles: string[]): boolean {
    if (!this.userPermissions || !this.userPermissions[domainId] || !this.userPermissions[domainId].role) {
      return false;
    }

    if (!roles || roles.length === 0) {
      return false;
    }

    return roles.includes(this.userPermissions[domainId].role.role_id);
  }

  public hasGroup(domainId: string, groups: string[]): boolean {
    if (!this.authorizations.domains) {
      return false;
    }

    const domain = this.authorizations.domains.find((d: AuthorizationDomainModel) => d.domain.domain_id === domainId);
    if (!domain) {
      return false;
    }

    let hasGroup = false;

    groups.forEach(groupId => {
      const group = domain.groups.find((g: GroupModel) => g.group_id === groupId);
      if (group) {
        hasGroup = true;
      }
    });
    return hasGroup;
  }


  public getSubDomains(domainId: string): SubDomainModel[] {
    const domain = this.authorizations.domains.find((d: AuthorizationDomainModel) => d.domain.domain_id === domainId);
    if (!domain) {
      return [];
    }

    if (!domain.groups || domain.groups.length === 0) {
      return [];
    }
    const subDomains = [];
    const groups = domain.groups;
    groups.forEach((group) => {

      if (!group.securables) {
        return;
      }

      const securables = group.securables;
      securables.forEach(secureable => {
        if (!secureable.thing || !secureable.thing.sub_domain) {
          return;
        }
        const existing = subDomains.find(x => x.sub_domain_id === secureable.thing.sub_domain.sub_domain_id);
        if (!existing) {
          subDomains.push(secureable.thing.sub_domain);
        }
      });
    });
    return subDomains;
  }

}
