<div
  data-cy="view-table-detail-container" class="view-table-container padded-container"
  *ngIf="state === modalStates.VIEW">
  <div class="clr-row padded-row">
    <div class="clr-col-8">
      <div class="clr-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-table-id'" class="title-label"></p>
          <p class="label-overflow">{{ table.table_model_base_id }}</p>
        </div>
      </div>
      <div class="clr-row padded-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-table-name'" class="title-label"></p>
          <p class="label-overflow">{{ table.table_model_name }}</p>
        </div>
      </div>
    </div>
    <div class="clr-col">
      <div class="clr-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-column-count'" class="title-label"></p>
          <p class="label-overflow">{{ table.column_count }}</p>
        </div>
      </div>
      <div class="clr-row padded-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-row-count'" class="title-label"></p>
          <p class="label-overflow">{{ table.row_count | number }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-row padded-row" *ngIf="showFrequencyType()">
    <div class="clr-col">
      <p [translate]="'datatool-quality-frequency'" class="title-label"></p>
      <p class="label-overflow" [translate]="'datatool-quality-frequency-type-' + table.profile_frequency_type"></p>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col-7">
      <p [translate]="'land-table-details-table-description'" class="title-label"></p>
      <p *ngIf="table.table_description?.html_text.length > 0" [innerHTML]="table.table_description.html_text"></p>
      <p *ngIf="table.table_description?.html_text.length === 0"><span [translate]="'land-no-description'">land-no-description</span>
      </p>
    </div>
    <!-- Removing for now the translate button until story is added -->
    <!-- <div class="clr-col-3">
      <button disabled *ngIf="table.table_description?.html_text.length > 0"
              class="btn btn-sm btn-link"
              [translate]="'table-details-translate-btn'"
      ></button>
    </div> -->
  </div>
</div>

<div
  class="edit-table-container padded-container"
  *ngIf="state === modalStates.EDIT"
  data-cy="edit-table-detail-container"
>
  <div class="clr-row padded-row">
    <div class="clr-col-8">
      <div class="clr-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-table-id'" class="title-label"></p>
          <p class="label-overflow">{{ table.table_model_base_id }}</p>
        </div>
      </div>
      <div class="clr-row padded-row">
        <div class="clr-col">
          <span [translate]="'land-table-details-table-name'" class="title-label"></span>
          <p *ngIf="table.data_model.data_model_type !== dataModelTypes.TARGET" class="label-overflow">
            {{ table.table_model_name }}
          </p>
          <clr-tooltip class="tooltip-container" *ngIf="table.data_model.data_model_type === dataModelTypes.TARGET">
            <clr-icon shape="help" clrTooltipTrigger></clr-icon>
            <clr-tooltip-content [clrSize]="'lg'">
              <span>Table name can not include spaces or any of these reserved words:</span>
              <div class="reserved-words">
                <span class="reserved-word" *ngFor="let word of reservedWords"> {{word}}</span>
              </div>
            </clr-tooltip-content>
          </clr-tooltip>
          <clr-input-container
            *ngIf="table.data_model.data_model_type === dataModelTypes.TARGET"
            style="margin-top: 0">
            <input
              clrInput
              [(ngModel)]="table.table_model_name"
              class="table_name"
              name="table_name"
            />
          </clr-input-container>
          <div class="validation" *ngIf="table.data_model.data_model_type === dataModelTypes.TARGET">
            <span class="invalid-table" *ngIf="!validateTableName()">Invalid table name</span>
          </div>
        </div>

      </div>
    </div>
    <div class="clr-col">
      <div class="clr-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-column-count'" class="title-label"></p>
          <p class="label-overflow">{{ table.column_count }}</p>
        </div>
      </div>
      <div class="clr-row padded-row">
        <div class="clr-col">
          <p [translate]="'land-table-details-row-count'" class="title-label"></p>
          <p class="label-overflow">{{ table.row_count | number }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-row padded-row" *ngIf="showFrequencyType()">
    <div class="clr-col">
      <p [translate]="'datatool-quality-frequency'" class="title-label"></p>
      <clr-radio-container clrInline class="radio-form">
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.once" type="radio" clrRadio value="{{tableFrequencyType.once}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.once"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.hourly}}" type="radio" clrRadio value="{{tableFrequencyType.hourly}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.hourly"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.daily}}" type="radio" clrRadio value="{{tableFrequencyType.daily}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.daily"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.weekly}}" type="radio" clrRadio value="{{tableFrequencyType.weekly}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.weekly"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.monthly}}" type="radio" clrRadio value="{{tableFrequencyType.monthly}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.monthly"></span></label>
        </clr-radio-wrapper>
      </clr-radio-container>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col-8">
      <p [translate]="'land-table-details-table-description'" class="title-label"></p>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col-10">
      <app-text-editor
        [model]="table.table_description"
        [editable]="true"
      ></app-text-editor>
    </div>
  </div>
</div>

<div
  class="add-table-container padded-container"
  *ngIf="state === modalStates.ADD"
  data-cy="add-table-detail-container"
>
  <div class="clr-row padded-row">
    <div class="clr-col-3">
      <span [translate]="'land-table-details-table-name'" class="title-label"></span>
      <clr-tooltip class="tooltip-container">
        <clr-icon shape="help" clrTooltipTrigger></clr-icon>
        <clr-tooltip-content [clrSize]="'lg'">
          <span>Table name can not include spaces or any of these reserved words:</span>
          <div class="reserved-words">
            <span class="reserved-word" *ngFor="let word of reservedWords"> {{word}}</span>
          </div>
        </clr-tooltip-content>
      </clr-tooltip>
      <clr-input-container style="margin-top: 0">
        <input
          clrInput
          [(ngModel)]="table.table_model_name"
          name="table_name"
          class="table_name"
        />
      </clr-input-container>
      <div class="validation">
        <span class="invalid-table" *ngIf="!validateTableName()">Invalid table name</span>
      </div>
    </div>
  </div>
  <div class="clr-row padded-row" *ngIf="showFrequencyType()">
    <div class="clr-col">
      <p [translate]="'datatool-quality-frequency'" class="title-label"></p>
      <clr-radio-container clrInline class="radio-form">
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.none}}" type="radio" clrRadio value="{{tableFrequencyType.none}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.none"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.hourly}}" type="radio" clrRadio value="{{tableFrequencyType.hourly}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.hourly"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.daily}}" type="radio" clrRadio value="{{tableFrequencyType.daily}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.daily"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.weekly}}" type="radio" clrRadio value="{{tableFrequencyType.weekly}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.weekly"></span></label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input name="frequency-type-{{tableFrequencyType.monthly}}" type="radio" clrRadio value="{{tableFrequencyType.monthly}}" [(ngModel)]="table.profile_frequency_type"/>
          <label><span [translate]="'datatool-quality-frequency-type-' + tableFrequencyType.monthly"></span></label>
        </clr-radio-wrapper>
      </clr-radio-container>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col-8">
      <p [translate]="'land-table-details-table-description'" class="title-label"></p>
    </div>
  </div>
  <div class="clr-row padded-row">
    <div class="clr-col-10">
      <app-text-editor
        [model]="table.table_description"
        [editable]="true"
      ></app-text-editor>
    </div>
  </div>
</div>
