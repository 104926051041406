import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ClarityModule, ClrIconModule, ClrModalModule, ClrTooltipModule, ClrVerticalNavModule } from '@clr/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CronEditorModule } from 'ngx-cron-editor';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BUTTONS, NgxWigModule } from 'ngx-wig';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { RouterLinkMatchDirective } from './components/common/directives/router-link-match.directive';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FileDownloadLinkComponent } from './components/common/file/file-download-link/file-download-link.component';
import { FileDownloadComponent } from './components/common/file/file-download/file-download.component';
import { FirebaseConfirmComponent } from './components/common/firebase-confirm/firebase-confirm.component';
import { FormRiskComponent } from './components/common/form-risk/form-risk.component';
import { LoaderComponent } from './components/common/loader/loader.component';
import { MainNavComponent } from './components/common/main-nav/main-nav.component';
import { OverviewComponent } from './components/common/overview/overview.component';
import { ProfileComponent } from './components/common/profile/profile.component';
import { ReportingMonthPickerComponent } from './components/common/reporting-month-picker/reporting-month-picker.component';
import { RouteGroupCardComponent } from './components/common/route-group-card/route-group-card.component';
import { SafeHtmlPipe } from './components/common/safeHtml/safe-html';
import { SparklineComponent } from './components/common/sparkline/sparkline.component';
import { TextEditorComponent } from './components/common/text-editor/text-editor.component';
import { VdtComponent } from './components/common/vdt/vdt.component';
import { VersionHistoryComponent } from './components/common/version-history/version-history.component';
import { DataModelSearchComponent } from './components/data/data-quality/data-model-search/data-model-search.component';
import { ColumnCategoryValueComponent } from './components/data/data-quality/data-model/data-model-column-category-values/column-category-value/column-category-value.component';
import { ColumnCategoryValuesSourceComponent } from './components/data/data-quality/data-model/data-model-column-category-values/column-category-values-source/column-category-values-source.component';
import { ColumnCategoryValuesTargetComponent } from './components/data/data-quality/data-model/data-model-column-category-values/column-category-values-target/column-category-values-target.component';
import { DataModelColumnCategoryValuesComponent } from './components/data/data-quality/data-model/data-model-column-category-values/data-model-column-category-values.component';
import { DataModelColumnDetailsComponent } from './components/data/data-quality/data-model/data-model-column-details/data-model-column-details.component';
import { DataModelColumnMappingSourceComponent } from './components/data/data-quality/data-model/data-model-column-mappings/data-model-column-mapping-source/data-model-column-mapping-source.component';
import { DataModelColumnMappingTargetComponent } from './components/data/data-quality/data-model/data-model-column-mappings/data-model-column-mapping-target/data-model-column-mapping-target.component';
import { DataModelColumnMappingsComponent } from './components/data/data-quality/data-model/data-model-column-mappings/data-model-column-mappings.component';
import { DataModelColumnStatisticsComponent } from './components/data/data-quality/data-model/data-model-column-statistics/data-model-column-statistics.component';
import { DataModelModelNavigationComponent } from './components/data/data-quality/data-model/data-model-model-navigation/data-model-model-navigation.component';
import { DataModelTableDetailsComponent } from './components/data/data-quality/data-model/data-model-table-details/data-model-table-details.component';
import { DataModelValueDetailsComponent } from './components/data/data-quality/data-model/data-model-value-details/data-model-value-details.component';
import { DataModelComponent } from './components/data/data-quality/data-model/data-model.component';
import { FormDataModelColumnMapTargetComponent } from './components/data/data-quality/data-model/form-data-model-column-map-target/form-data-model-column-map-target.component';
import { FormDataModelColumnComponent } from './components/data/data-quality/data-model/form-data-model-column/form-data-model-column.component';
import { FormDataModelTableColListComponent } from './components/data/data-quality/data-model/form-data-model-table-col-list/form-data-model-table-col-list.component';
import { FormDataModelTableCreateComponent } from './components/data/data-quality/data-model/form-data-model-table-create/form-data-model-table-create.component';
import { FormDataModelTableComponent } from './components/data/data-quality/data-model/form-data-model-table/form-data-model-table.component';
import { FormDataModelValueComponent } from './components/data/data-quality/data-model/form-data-model-value/form-data-model-value.component';
import { IntakeMainNavComponent } from './components/intake/intake-main-nav/intake-main-nav.component';
import { formatEvaluationDataPipe } from './pipes/formatEvaluationData';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MarkdownService } from './services/markdown.service';

@NgModule({
  exports: [
    SafeHtmlPipe,
    FeedbackComponent,
    ProfileComponent,
    formatEvaluationDataPipe,
    TruncatePipe,
    CommonModule,
    ClarityModule,
    ClrModalModule,
    ClrIconModule,
    ClrVerticalNavModule,
    ClrTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MainNavComponent,
    IntakeMainNavComponent,
    NgxChartsModule,
    HttpClientModule,
    RouterModule,
    OverviewComponent,
    TranslateModule,
    RouterLinkMatchDirective,
    FileDownloadComponent,
    FirebaseConfirmComponent,
    FileDownloadLinkComponent,
    VersionHistoryComponent,
    ReportingMonthPickerComponent,
    TextEditorComponent,
    SparklineComponent,
    OrganizationChartModule,
    VdtComponent,
    LoaderComponent,
    RouteGroupCardComponent,
    DataModelComponent,
    DataModelSearchComponent,
    FormDataModelColumnComponent,
    FormDataModelColumnMapTargetComponent,
    FormDataModelTableComponent,
    FormDataModelTableCreateComponent,
    FormDataModelTableColListComponent,
    FormDataModelValueComponent,
    DataModelColumnCategoryValuesComponent,
    DataModelColumnDetailsComponent,
    DataModelColumnStatisticsComponent,
    DataModelModelNavigationComponent,
    DataModelTableDetailsComponent,
    DataModelValueDetailsComponent,
    DataModelColumnMappingsComponent,
    DataModelColumnMappingTargetComponent,
    DataModelColumnMappingSourceComponent,
    ColumnCategoryValuesSourceComponent,
    ColumnCategoryValuesTargetComponent,
    ColumnCategoryValueComponent,
    FormRiskComponent,
    MatDividerModule,
    CronEditorModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule
  ],
  declarations: [
    SafeHtmlPipe,
    LoaderComponent,
    RouterLinkMatchDirective,
    MainNavComponent,
    IntakeMainNavComponent,
    FeedbackComponent,
    ProfileComponent,
    formatEvaluationDataPipe,
    TruncatePipe,
    OverviewComponent,
    FileDownloadComponent,
    FirebaseConfirmComponent,
    FileDownloadLinkComponent,
    VersionHistoryComponent,
    ReportingMonthPickerComponent,
    TextEditorComponent,
    SparklineComponent,
    VdtComponent,
    RouteGroupCardComponent,
    DataModelComponent,
    DataModelSearchComponent,
    FormDataModelColumnComponent,
    FormDataModelColumnMapTargetComponent,
    FormDataModelTableComponent,
    FormDataModelTableCreateComponent,
    FormDataModelTableColListComponent,
    FormDataModelValueComponent,
    DataModelColumnCategoryValuesComponent,
    DataModelColumnDetailsComponent,
    DataModelColumnStatisticsComponent,
    DataModelModelNavigationComponent,
    DataModelTableDetailsComponent,
    DataModelValueDetailsComponent,
    DataModelColumnMappingsComponent,
    DataModelColumnMappingTargetComponent,
    DataModelColumnMappingSourceComponent,
    ColumnCategoryValuesSourceComponent,
    ColumnCategoryValuesTargetComponent,
    ColumnCategoryValueComponent,
    FormRiskComponent
  ],
  providers: [
    { provide: BUTTONS, multi: true, useValue: MarkdownService.WIG_PLUGIN }
  ],
  imports: [
    TranslateModule,
    RouterModule,
    HttpClientModule,
    NgxChartsModule,
    OrganizationChartModule,
    CommonModule,
    ClarityModule,
    ClrModalModule,
    ClrIconModule,
    ClrVerticalNavModule,
    ClrTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxWigModule.forRoot(),
    NgxFileDropModule,
    MatDividerModule,
    CronEditorModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule
  ]
})
export class SharedModule {
}
