<div class="modal-body">
  <app-data-model-model-navigation
    [datasource_level]="modelLevels.TABLE"
    [datasource_modal_state]="modalState"
    [datasource_datamodel]="dataModel"
    [datasource_table]="table"
     (ModelChanged)="dataModelChanged($event)"></app-data-model-model-navigation>
  <ul class="nav" role="tablist">
    <li role="presentation" class="nav-item">
      <button [attr.data-cy]="'table-details'"
              class="btn btn-link nav-link"
              [ngClass]="{ 'active': activeTab === tabIdEnum.DETAILS }"
              (click)="switchTabs(tabIdEnum.DETAILS)"
              type="button"><span [translate]="'land-table-details'"></span></button>
    </li>
    <li role="presentation" class="nav-item">
      <button [attr.data-cy]="'column-list'"
              class="btn btn-link nav-link"
              [ngClass]="{ 'active': activeTab === tabIdEnum.COL_LIST }"
              (click)="switchTabs(tabIdEnum.COL_LIST)"
              type="button"><span [translate]="'land-col-list'"></span></button>
    </li>
  </ul>
  <div id="tab-details" *ngIf="activeTab === tabIdEnum.DETAILS">

    <div class="clr-row">
      <div class="clr-col-10">
        <app-data-model-table-details [modal_state]="modalState" [datasource_datamodel]="dataModel" [datasource_table]="table"></app-data-model-table-details>
      </div>
      <div class="clr-col"  *ngIf="dataModel.data_model_type === dataModelTypes.SOURCE">
        <button class="btn btn-link profile-information" [disabled]="!hasProfileData() || loadingProfile" (click)="viewProfile()"><span [translate]="'land-table-profile'"></span></button>
      </div>
    </div>

  </div>

  <div id="tab-col-list" *ngIf="activeTab === tabIdEnum.COL_LIST">

    <div class="clr-row">
      <div class="clr-col">
        <app-form-data-model-table-col-list [tableInput]="table"></app-form-data-model-table-col-list>
      </div>
    </div>

  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline"  data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>
  <button [disabled]="saving || activeTab === tabIdEnum.COL_LIST" type="button" class="btn btn-primary"
          (click)="edit()"
          *ngIf="(modalState === modalStates.VIEW)"
          data-cy="form-edit">
    <span [translate]="'evaluations-grid-btn-edit'"></span>
  </button>
  <button type="button" class="btn btn-primary"  [disabled]="!isValid() || saving || activeTab === tabIdEnum.COL_LIST"
          (click)="save()"
          *ngIf="(modalState === modalStates.EDIT)"
          data-cy="form-submit">
    <span [translate]="'exploration-project-btn-save'"></span>
  </button>
</div>
