import { environment } from '../../environments/environment';
const apiPath = environment.apiPath;

export const endpoints = {
  userAuth: `${apiPath}/common/authorizations`,
  common: `${apiPath}/common`,
  datatool: `${apiPath}/datatool`,
  datasets: `${apiPath}/datatool/datasets`,
  vacuum_datasets: `${apiPath}/common/vacuum_datasets`,
  servers: `${apiPath}/datatool/servers`,
  databases: 'databases',
  schemas: 'schemas',
  tables: 'tables',
  fields: 'fields',
  departments: `${apiPath}/common/departments`,
  domains: `${apiPath}/common/domains`,
  evaluations: `${apiPath}/evaluations/forms`,
  extracts: `${apiPath}/datatool/extracts`,
  globalrecon: `${apiPath}/datatool/global_recon`,
  bulkGlobalRecon: `${apiPath}/datatool/global_recon_bulk`,
  feedback: `${apiPath}/common/feedback`,
  files: `${apiPath}/common/files`,
  formSchedules: `${apiPath}/evaluations/forms/schedules`,
  languages: `${apiPath}/common/languages`,
  locations: `${apiPath}/common/locations`,
  metadata: `${apiPath}/common/metadata`,
  metrics: `${apiPath}/evaluations/ops_metrics`,
  profiles: `${apiPath}/auditor/profiles`,
  metricsAuditor: `${apiPath}/auditor/ops_metrics`,
  metricsProductivity: `${apiPath}/productivity/ops_metrics`,
  months: 'assets/local-metadata/monthsData.json',
  localMetadata: 'assets/local-metadata/localMetaData.json',
  productivity: `${apiPath}/productivity`,
  riskCategories: 'assets/local-metadata/riskCategoriesData.json',
  risks: `${apiPath}/evaluations/risks`,
  regions: `${apiPath}/common/regions`,
  sub_regions: `${apiPath}/common/sub_regions`,
  schedules: `${apiPath}/evaluations/schedules`,
  sites: `${apiPath}/common/sites`,
  standards: `${apiPath}/auditor/standards`,
  trainings: `${apiPath}/auditor/trainings`,
  users: `${apiPath}/common/users`,
  livework: `${apiPath}/livework`,
  datasources: `${apiPath}/common/datasources`,
  control: `${apiPath}/control`,
  databasetypes: `${apiPath}/common/database_types`,
  exploration: `${apiPath}/exploration`,
  xeras: `${apiPath}/datatool/xeras`,
  start: `${apiPath}/start`,
  portal: `${ apiPath }/portal`,
  subdomains: `${apiPath}/common/sub_domains`,
  hierarchies: `${apiPath}/common/hierarchies`,
  statuses: `${apiPath}/common/statuses`,
  land: `${apiPath}/land`,
  data_model_schemas: `${apiPath}/common/data_models/files`,
  trees : `${apiPath}/common/trees`,
  metricsCommon: `${apiPath}/common/metrics`,
  intakeForms: `${apiPath}/datatool/intake_forms`,
  vacuum_tables: `${apiPath}/common/manifests`,
  vacuum_executions: `${apiPath}/common/vacuum_executions`,
  async_states: `${apiPath}/common/async_states`
};
