<div class="modal-body">
  <div class="clr-row">

    <div class="clr-col-2 step-container">

      <div data-cy="step-table-details" class="clr-row step-row">
        <div class="clr-col"
             [ngClass]="{'step-row-active': currentStep === 'step-table-details',
                'step-row-complete': tableDetailsValid() }">
          <button (click)="goToDetails()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'land-table-create-details'"></button>
        </div>
      </div>

      <div data-cy="step-table-columns" class="clr-row step-row">
        <div class="clr-col"
             [ngClass]="{'step-row-active': currentStep === 'step-table-columns',
                'step-row-complete': tableColumnsValid() }">
          <button (click)="goToColumns()" [disabled]="!tableDetailsValid()"
                  class="step-nav-text btn btn-link clr-wizard-stepnav-link"
                  [translate]="'land-table-create-columns'"></button>
        </div>
      </div>

    </div>
    <div class="clr-col-9">
      <div id="step-table-details" *ngIf="currentStep === 'step-table-details'">
        <app-data-model-table-details [modal_state]="modalStates.ADD" [datasource_table]="table"
                                [datasource_datamodel]="dataModel"></app-data-model-table-details>
      </div>
      <div id="id-step-columns" *ngIf="currentStep === 'step-table-columns'">
        <div class="clr-row">
          <div class="clr-col">
            <app-data-model-column-details [modal_state]="modalStates.ADD" [datasource_column]="column"
                                     [datasource_table]="table"
                                     [datasource_datamodel]="dataModel"></app-data-model-column-details>
          </div>
        </div>
        <div  class="clr-row">
          <div class="clr-col">
            <button [disabled]="!isCurrentColumnValid()" (click)="addColumn()" type="button" class="btn btn-outline"
                    data-cy="form-close">
              <span [translate]="'land-table-column-add'"></span>
            </button>
          </div>
        </div>
        <div class="clr-row columns-added-list" *ngIf="columns?.length > 0">
          <div class="clr-col">
            <div class="clr-row ">
              <div class="clr-col columns-added-header">
                <span [translate]="'land-column-details-col-name'"></span>
              </div>
              <div class="clr-col columns-added-header">
                <span [translate]="'land-column-details-model-type'"></span>
              </div>
              <div class="clr-col columns-added-header">
                <span [translate]="'land-column-details-data-type'"></span>
              </div>
              <div class="clr-col columns-added-header">
              </div>
            </div>

            <div class="clr-row">
              <div class="clr-col scroll-wrapper">
                <div class="clr-row " *ngFor="let column of columns">
                  <div class="clr-col columns-added-col">
                    {{ column.column_model_name }}
                  </div>
                  <div class="clr-col columns-added-col">
                    {{ column.column_model_type }}
                  </div>
                  <div class="clr-col columns-added-col">
                    {{ column.column_data_type }}
                  </div>
                  <div class="clr-col columns-added-col">
                    <button (click)="removeColumn(column)" class="btn btn-link btn-sm" data-cy="datagrid-remove-button">
                      <span [translate]="'land-column-remove'"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline" data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>

  <button type="button" class="btn btn-outline" (click)="back()" [disabled]="!canGoBack()">
    <span [translate]="'land-wizard-btn-back'"></span>
  </button>

  <button type="button" class="btn btn-primary"
          (click)="next()"
          [disabled]="!canGoNext() || saving"
          data-cy="wizard-next">
    <span [translate]="currentStep === 'step-table-columns' ? 'feedback-btn-submit' : 'land-wizard-btn-next'"></span>
  </button>

</div>
