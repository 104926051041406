import {AfterViewInit, Component} from '@angular/core';
import {Router} from '@angular/router';
import {DialogService} from '../../../../../services/dialog.service';
import {MetadataService} from '../../../../../services/metadata.service';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {DataModelNavigationEvent} from '../../../../common/models/data.model';
import {
  ConfirmButtonModel,
  ConfirmDialogModel,
  ConfirmDialogResult,
  ConfirmDialogTypeEnum
} from '../../../../common/models/dialog.model';
import {ModalStates} from '../../../../common/models/modal-state.model';
import {LandService} from '../../../../../services/land.service';
import {DataModelService} from '../../../../../services/data-model.service';
import {DataModelFormBaseComponent} from '../data-model-form-base.component';
import {DomainsEnum} from '../../../../common/models/domains.enum';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-form-data-model-table',
  templateUrl: './form-data-model-table.component.html',
  styleUrls: ['./form-data-model-table.component.css']
})
export class FormDataModelTableComponent extends DataModelFormBaseComponent implements AfterViewInit {
  public showMapping: boolean;
  public activeTab: string;
  public saving: boolean;
  public tabIdEnum = {
    DETAILS: 'details',
    COL_LIST: 'colList'
  };

  public loadingProfile: boolean;

  constructor(protected router: Router,
              protected dialogService: DialogService,
              private metadataService: MetadataService,
              private landService: LandService,
              private dataModelService: DataModelService,
              protected logService: LogService) {
    super(router, dialogService, logService);
  }

  protected init(): void {
    this.modalState = this.dialogService.displayModal.current.data.displayMode;
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.dataModels = this.dialogService.displayModal.current.data.dataModels;
    this.dataModel = this.dialogService.displayModal.current.data.dataModel;
    this.table = this.dialogService.displayModal.current.data.model;
    this.showMapping = this.dataModel.domain.domain_id === DomainsEnum.LAND;

    this.switchTabs(this.dialogService.displayModal.current.activeTab
      ? this.dialogService.displayModal.current.activeTab : this.tabIdEnum.DETAILS);
  }

  protected setUser(user: any): void {
  }


  ngAfterViewInit(): void {
  }

  public dataModelChanged(data: DataModelNavigationEvent): void {
    this.dataModel = data.dataModel;
    this.table = data.table;
  }

  public switchTabs(tabId: string): void {
    this.dialogService.displayModal.current.activeTab = tabId;
    this.activeTab = tabId;
  }

  public hasProfileData(): boolean {
    return !(!this.table || !this.table.exploratory_web_file || !this.table.exploratory_web_file.file_id);
  }

  public viewProfile(): void {
    if (!this.hasProfileData()) {
      return;
    }

    this.loadingProfile = true;

    this.metadataService.downloadFile(this.table.exploratory_web_file.file_id, this.table.exploratory_web_file.file_name).subscribe(
      (event: HttpEvent<any>) => {
        if (event) {
          switch (event.type) {
            case HttpEventType.Response:
              const blob: Blob = event.body;
              const newBlob = new Blob([blob], {type: 'text/html'});
              const url = window.URL.createObjectURL(newBlob);
              const a = document.createElement('a');
              a.setAttribute('target', '_blank');
              a.setAttribute('hidden', '');
              a.setAttribute('href', url);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              this.loadingProfile = false;
          }
        }
      });


  }

  public edit(): void {
    this.modalState = ModalStates.EDIT;
  }

  public isValid(): boolean {
    return this.table.table_model_name?.length > 0;
  }

  public save(): void {
    if (!this.isValid()) {
      return;
    }
    this.saving = true;
    this.dataModelService.saveTable(this.table, this.dataModel.data_model_base_id).subscribe(() => {
      this.saving = false;
      this.modalRef.close(ConfirmDialogResult.Confirm);
    }, (error) => {
      this.saving = false;
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'land-table-save-error-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'land-table-save-error-message',
        subMessage: error,
        confirmButton: {show: true, textTranslateId: 'datatools-ok-button'} as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
      });
    });

  }

}
