<div class="clr-row">
  <div class="clr-col">

    <clr-select-container *ngIf="mappedDataModels?.length > 1">
      <label [translate]="'column-target-datamodel'" class="select-label"></label>
      <select
        clrSelect
        name="datamodel"
        [(ngModel)]="selectedDataModelId"
        (change)="changeDataModel()"
        [disabled]="loadingDataModels">
        <option *ngFor="let dataModel of mappedDataModels" [ngValue]="dataModel.data_model_id">
          {{ dataModel.data_model_name }}
        </option>
      </select>
    </clr-select-container>

  </div>
</div>

<div class="clr-row">
  <div class="clr-col">
    <ng-container>
      <clr-datagrid [clrDgLoading]="loading">
        <clr-dg-column>
          <span [translate]="'evaluations-grid-col-options'">Options</span>
        </clr-dg-column>

        <clr-dg-column [clrDgField]="'value_model_name'">
          <span [translate]="'column-value'">column-value</span>
        </clr-dg-column>
        <clr-dg-column *ngFor="let col of columnMappings" [clrDgField]="col.target.column_model_id">
          <span>{{ col.target.column_model_name }}</span>
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let value of columnValues">

          <clr-dg-cell>
            <div class="actions">

              <button (click)="editValue(value)" class="btn btn-link btn-sm">
                <span [translate]="'evaluations-grid-btn-edit'">Edit</span>
              </button>

            </div>
          </clr-dg-cell>

          <clr-dg-cell>{{ value.value_model_name }}</clr-dg-cell>
          <clr-dg-cell *ngFor="let col of columnMappings">
            <div *ngIf="state === stateEnum.VIEW">
              {{ value[col.target.column_model_id] }}
            </div>
            <div *ngIf="state === stateEnum.EDIT">
              <span *ngIf="targetValues[col.target.column_model_id]?.loading" [translate]="'column-value-loading-target-values'"></span>
              <span *ngIf="!targetValues[col.target.column_model_id]?.loading && targetValues[col.target.column_model_id]?.values?.length === 0"
                    [translate]="'column-value-no-target-values'"></span>
              <select *ngIf="!targetValues[col.target.column_model_id]?.loading && targetValues[col.target.column_model_id]?.values?.length > 0"
                      [(ngModel)]="value[col.target.column_model_id + '_id']"
                      class="target-value" name="{{'target-value' + value.value_model_id}}"
                      [disabled]="targetValues[col.target.column_model_id]?.loading || saving">
                <option *ngFor="let value of targetValues[col.target.column_model_id].values" [ngValue]="value.value_model_id">
                  {{ value.value_model_name }}
                </option>
              </select>
            </div>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                              [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
            <span [translate]="'evaluations-grid-foot-label-1'"></span>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            <span [translate]="'evaluations-grid-foot-label-2'"></span>
            {{ pagination.totalItems }}
            <span [translate]="'category-values-project-footer'"></span>
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </ng-container>
  </div>

</div>

<div class="clr-row">
  <div class="clr-col">
    <div class="modal-footer">
      <button (click)="close()" type="button" class="btn btn-outline" data-cy="form-close">
        <span [translate]="'submodal-btn-close'"></span>
      </button>
      <button [disabled]="saving" type="button" class="btn btn-primary"
              (click)="edit()"
              *ngIf="(state === stateEnum.VIEW)"
              data-cy="form-edit">
        <span [translate]="'evaluations-grid-btn-edit'"></span>
      </button>
      <button [disabled]="saving" type="button" class="btn btn-primary"
              *ngIf="state === stateEnum.EDIT"
              (click)="save()">
        <span [translate]="'land-column-btn-save'"></span>
      </button>

    </div>
  </div>
</div>
