import {Component} from '@angular/core';
import {BaseComponent} from '../../../common/base-component';
import {Router} from '@angular/router';
import {
  ConfirmButtonModel,
  ConfirmDialogModel,
  ConfirmDialogResult,
  ConfirmDialogTypeEnum,
  DisplayModalDialogRef
} from '../../../common/models/dialog.model';
import {DialogService} from '../../../../services/dialog.service';
import {DataQualityDataModelWizardModel} from '../../../common/models/data-quality-datamodel-wizard.model';
import {DataService} from '../../../../services/data.service';
import {DatasetData} from '../../../common/models/datatool.model';
import {DataModelInitiate, DataQualityData, TableFrequencyType} from '../../../common/models/data.model';
import {LogService} from '../../../../services/log.service';

@Component({
  selector: 'app-data-model-create',
  templateUrl: './data-model-create.component.html',
  styleUrls: ['./data-model-create.component.css']
})
export class DataModelCreateComponent extends BaseComponent {
  public currentStep: string;
  public saving: boolean;
  public loading: boolean;
  protected modalRef: DisplayModalDialogRef;
  public datasets: DatasetData[];
  public tables: DataQualityData[];
  public model: DataQualityDataModelWizardModel;
  public datasetLoading: boolean;
  public tableLoading: boolean;
  public selectAllTables: boolean;
  public frequencies: TableFrequencyType[];
  public setAllFrequency: TableFrequencyType;

  constructor(protected router: Router,
              protected dialogService: DialogService,
              private dataService: DataService,
              protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.frequencies = [
      TableFrequencyType.once,
      TableFrequencyType.hourly,
      TableFrequencyType.daily,
      TableFrequencyType.weekly,
      TableFrequencyType.monthly
    ];
  }

  protected setUser(user: any): void {
    this.showDetails();
    this.model = {dataset_name: '', tables: []} as DataQualityDataModelWizardModel;
  }

  public detailsValid(): boolean {
    if (!this.model.name || this.model.name.length === 0) {
      return false;
    }
    if (!this.model.dataset_name || this.model.dataset_name.length === 0) {
      return false;
    }

    if (!this.model.profile_frequency_type || this.model.profile_frequency_type.length === 0) {
      return false;
    }

    return true;
  }

  public tablesValid(): boolean {
    if (!this.tables || this.tables.length === 0) {
      return false;
    }

    const selected = this.tables.filter(x => x.selected === true);
    return selected?.length > 0;
  }

  public showDetails(): void {
    this.getDatasets();
    this.currentStep = 'step-details';
  }

  public showTables(): void {
    if (!this.detailsValid()) {
      return;
    }
    this.currentStep = 'step-tables';
  }

  public showReview(): void {
    this.currentStep = 'step-review';
    this.model.tables = this.tables.filter(t => t.selected === true);
  }

  public back(): void {
    if (this.currentStep === 'step-review') {
      this.showTables();
    } else if (this.currentStep === 'step-tables') {
      this.showDetails();
    }
  }

  public canGoBack(): boolean {
    if (this.currentStep === 'step-details') {
      return false;
    }
    return true;
  }

  public canGoNext(): boolean {

    if (this.currentStep === 'step-details') {
      return this.detailsValid();
    } else if (this.currentStep === 'step-tables') {
      return this.tablesValid();
    }

    return this.detailsValid() && this.tablesValid();
  }

  public next(): void {
    if (this.currentStep === 'step-details') {
      this.showTables();
    } else if (this.currentStep === 'step-tables') {
      this.showReview();
    } else if (this.currentStep === 'step-review') {
      this.save();
    }
  }

  public save(): void {
    this.saving = true;

    const initiateModel = {
      data_model_name: this.model.name,
      project_id: 'dev-nmc-dna',
      domain_id: 'datatool',
      dataset_model_name: this.model.dataset_name,
      profile_frequency_type: this.model.profile_frequency_type,
      tables: []
    } as DataModelInitiate;

    this.tables.forEach(t => {
      if (t.selected) {
        initiateModel.tables.push(t.table_name);
      }
    });

    this.dataService.initiateDataModel(initiateModel).subscribe((results) => {
      this.dataService.getDataModel(results.id).subscribe((dataModel) => {

        this.saving = false;
        this.modalRef.close(ConfirmDialogResult.Confirm, dataModel);
      });

    }, (error) => {
      this.saving = false;

      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'datatool-quality-initiate-error-title',
        type: ConfirmDialogTypeEnum.Warning,
        subMessage: error.message,
        message: 'datatool-quality-initiate-error--message',
        confirmButton: {show: true, textTranslateId: 'datatools-ok-button'} as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
      });
    });
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

  private getDatasets(): void {
    this.datasetLoading = true;
    this.dataService.getDataSets().subscribe((data: any) => {
      this.datasets = data;
      this.datasetLoading = false;
    }, () => {
      this.datasets = [];
      this.datasetLoading = false;
    });
  }

  public datasetChanged(): void {
    this.getTables();
  }

  public getTables(): void {
    if (!this.model || !this.model.dataset_name || this.model.dataset_name.length === 0) {
      return;
    }

    this.tableLoading = true;

    setTimeout(() => {
      this.dataService.getBiqQueryTables(this.model.dataset_name).subscribe(data => {
        this.tables = [];
        data.forEach((table) => {
          this.tables.push({
            ...table,
            selected: false
          });
        });
        this.tableLoading = false;
      }, () => {
        this.tables = [];
        this.tableLoading = false;
      });
    }, 100);

  }

  public allTablesSelected(): void {
    this.tables.forEach((table) => table.selected = this.selectAllTables);
  }

}
