import {Component, OnInit, Input} from '@angular/core';
import {ColumnDataModel, TableDataModel} from 'app/components/common/models/data.model';
import {ModalStates} from '../../../../common/models/modal-state.model';
import {DialogService} from '../../../../../services/dialog.service';
import {DataModelService} from '../../../../../services/data-model.service';
import {FormDataModelColumnComponent} from '../form-data-model-column/form-data-model-column.component';
import {DomainsEnum} from '../../../../common/models/domains.enum';

@Component({
  selector: 'app-form-data-model-table-col-list',
  templateUrl: './form-data-model-table-col-list.component.html',
  styleUrls: ['./form-data-model-table-col-list.component.css']
})
export class FormDataModelTableColListComponent implements OnInit {

  public columns: ColumnDataModel[];
  public table: TableDataModel;
  public loadingCols: boolean;
  public showMapping: boolean;

  constructor(private dataModelService: DataModelService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
  }

  @Input() set tableInput(table: TableDataModel) {
    this.table = table;
    this.showMapping = this.table.data_model.domain.domain_id === DomainsEnum.LAND;

    this.loadColumns();
  }

  private loadColumns(): void {
    this.loadingCols = true;
    this.dataModelService.getColumns(this.table.data_model.data_model_id, this.table.table_model_base_id)
      .subscribe(columns => {
        this.columns = columns;
        this.loadingCols = false;
      }, (error) => {
        console.error(error.message);
        this.columns = [];
        this.loadingCols = false;
      });
  }

  public viewColumn(column: ColumnDataModel): void {
    this.showColumn(column, ModalStates.VIEW);
  }

  public editColumn(column: ColumnDataModel): void {
    this.showColumn(column, ModalStates.EDIT);
  }

  private showColumn(column: ColumnDataModel, state: ModalStates): void {
    const dialogRef = this.dialogService.openDialog('FormDataModelColumnComponent', FormDataModelColumnComponent,
      'land-view-column', 'xxl', {
        displayMode: state,
        column,
        table: column.table,
        dataModel: column.table.data_model,
        dataModels: []
      });
    dialogRef.afterClosed.subscribe(() => {
    });
  }
}
