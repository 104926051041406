import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DomainModel } from '../components/common/models/domain.model';


export interface MetadataDomainStateModel {
  domains: DomainModel[];
}

export class MetadataDomainLoaded {
  static readonly type = '[MetadataDomains] loaded';

  constructor(public domains: DomainModel[]) {
  }
}

@State<MetadataDomainStateModel>({
  name: 'metadataDomainState',
  defaults: { domains: [] }
})
@Injectable()
export class MetadataDomainState {

  constructor() {
  }

  @Selector()
  static domains(state: MetadataDomainStateModel) {
    return state.domains;
  }

  @Action(MetadataDomainLoaded)
  loaded(ctx: StateContext<MetadataDomainStateModel>, action: MetadataDomainLoaded) {

    ctx.patchState({
      domains: action.domains
    });

  }
}
