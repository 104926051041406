<div class="clr-row bread-crumb">
  <div *ngIf="!dataModels || dataModels.length === 0">
    <span class="btn btn-link not-active"> {{ dataModel.data_model_name }}</span>
  </div>
  <div *ngIf="dataModels?.length > 0">
    <select (change)="dataModelChanged(dataModel)" class="btn btn-link" name="dataModels" [(ngModel)]="dataModel">
      <option *ngFor="let dataModel of dataModels" [ngValue]="dataModel">{{dataModel.data_model_name}}</option>
    </select>
  </div>
  <clr-icon *ngIf="table" class="bread-divider" shape="angle right"></clr-icon>
  <div *ngIf="table && level !== modelLevels.VALUE">
    <span *ngIf="dataModel?.tables?.length === 1 " class="btn btn-link not-active">{{ table.table_model_name }}</span>
    <span *ngIf="dataModel.tablesLoading" class="btn btn-link not-active" [translate]="'land-tables-loading'"></span>
    <select *ngIf="!dataModel.tablesLoading && dataModel?.tables?.length > 1 " (change)="tableChanged(table)"
            class="btn btn-link" name="tables" [(ngModel)]="table">
      <option *ngFor="let table of dataModel.tables" [ngValue]="table">{{table.table_model_name}}</option>
    </select>
  </div>
  <div *ngIf="level === modelLevels.VALUE">
    <span class="btn btn-link not-active">{{ table.table_model_name }}</span>
  </div>
  <clr-icon *ngIf="level === modelLevels.COLUMN || level === modelLevels.VALUE" class="bread-divider"
            shape="angle right"></clr-icon>
  <div *ngIf="level === modelLevels.COLUMN && modalState === modalStates.ADD">
    <span class="btn btn-link not-active"> {{ column.column_model_name || 'New Column' }}</span>
  </div>
  <div *ngIf="level === modelLevels.VALUE">
    <span class="btn btn-link not-active"> {{ column.column_model_name}}</span>
  </div>
  <div *ngIf="level === modelLevels.COLUMN && modalState !== modalStates.ADD">
    <span *ngIf="table.columnsLoading" class="btn btn-link not-active"
          [translate]="'land-table-columns-loading'"></span>
    <span *ngIf="!table.columnsLoading && table.columns?.length === 0" class="btn btn-link not-active"
          [translate]="'land-table-no-columns'"></span>
    <select *ngIf="!table.columnsLoading && table.columns?.length > 0" (change)="columnChanged(column)"
            class="btn btn-link" name="columns" [(ngModel)]="column">
      <option *ngFor="let column of table.columns" [ngValue]="column">{{column.column_model_name}}</option>
    </select>
  </div>
  <clr-icon *ngIf="level === modelLevels.VALUE" class="bread-divider" shape="angle right"></clr-icon>
  <div *ngIf="level === modelLevels.VALUE">
    <span class="btn btn-link not-active"> {{ value.value_model_name }}</span>
  </div>
</div>
