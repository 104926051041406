import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { DomainsEnum } from 'app/components/common/models/domains.enum';
import { FactModel } from 'app/components/common/models/fact.model';

export interface MetadataDomainFactModel {
    domainId: string;
    facts: FactModel[];
  }

export interface MetadataFactStateModel {
    domainFacts: MetadataDomainFactModel[];
  }

export class MetadataFactLoaded {
    static readonly type = '[MetadataFact] loaded';

    constructor(public domainId: string, public facts: FactModel[]) {
    }
  }

@State<MetadataFactStateModel>({
    name: 'metadataFactState',
    defaults: { domainFacts: [] }
  })
  @Injectable()
  export class MetadataFactState {

    constructor() {
    }

    @Selector()
    static game(state: MetadataFactStateModel) {
      return state.domainFacts.find((item) => item.domainId === DomainsEnum.GAME)?.facts;
    }

    @Action(MetadataFactLoaded)
    loaded(ctx: StateContext<MetadataFactStateModel>, action: MetadataFactLoaded) {
      const state = ctx.getState();

      let target = state.domainFacts.find(x => x.domainId === action.domainId);
      const other = state.domainFacts.filter(x => x.domainId !== action.domainId);

      if (!target) {
        target = { domainId: action.domainId, facts: action.facts };
      } else {
        target.facts = action.facts;
      }

      ctx.patchState({
        domainFacts: [
          ...other,
          target
        ]
      });

    }
  }
