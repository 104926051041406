import {
  ColumnDataModel,
  ColumnMappingModel,
  DataModel,
  TableDataModel,
  ValueDataModel
} from '../../../../common/models/data.model';
import { Router } from '@angular/router';
import { LandService } from '../../../../../services/land.service';
import { DialogService } from '../../../../../services/dialog.service';
import { BaseComponent } from '../../../../common/base-component';
import { Directive, Input } from '@angular/core';
import { ConfirmDialogResult, DisplayModalDialogRef } from '../../../../common/models/dialog.model';
import {DataModelService} from '../../../../../services/data-model.service';
import {LogService} from '../../../../../services/log.service';

@Directive()
export abstract class ColumnValuesMappingBaseComponent extends BaseComponent {
  public column: ColumnDataModel;
  public table: TableDataModel;
  public dataModel: DataModel;

  public loading: boolean;
  public saving: boolean;
  public columnValues: ValueDataModel[];
  public columnMappings: ColumnMappingModel[];

  protected modalRef: DisplayModalDialogRef;

  constructor(
    protected router: Router,
    protected landService: LandService,
    protected dataModelService: DataModelService,
    protected dialogService: DialogService,
    protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.loading = true;
  }

  protected setUser(user: any): void {
    this.setup();
  }

  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
    this.setup();
  }

  @Input()
  set datasource_table(table: TableDataModel) {
    this.table = table;
    this.setup();
  }

  @Input()
  set datasource_datamodel(dataModel: DataModel) {
    this.dataModel = dataModel;
    this.setup();
  }

  protected abstract setup(): void;

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

}
