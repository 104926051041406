export enum MetadataFormMode {
  add= 'add',
  edit = 'edit',
  view = 'view'
}


export interface MetadataFormModel {
  formId: string;
  formComponent: any;
  editTitle: string;
  viewTitle: string;
  addTitle: string;
}
