import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface MetadataShiftStateModel {
  shiftId: string;
  shiftName: string;
  siteId: string;
}

export class MetadataShiftsSelected {
  static readonly type = '[MetadataShift] selected';

  constructor(public shiftId: string, public shiftName: string, public siteId: string) {
  }
}

@State<MetadataShiftStateModel>({
  name: 'metadataShiftState',
  defaults: { shiftId: '', shiftName: '', siteId: ' ' }
})
@Injectable()
export class MetadataShiftState {

  constructor() {
  }

  @Selector()
  static selected(state: MetadataShiftStateModel) {
    return state;
  }

  @Action(MetadataShiftsSelected)
  loaded(ctx: StateContext<MetadataShiftStateModel>, action: MetadataShiftsSelected) {

    const state = ctx.getState();
    if (state.shiftId === action.shiftId) {
      return;
    }
    ctx.patchState({
      shiftId: action.shiftId,
      shiftName: action.shiftName,
      siteId: action.siteId
    });

  }
}
