import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MetadataFactLoaded } from 'app/store/metadata-facts.state';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainsEnum } from '../components/common/models/domains.enum';
import { FactModel, FactResponse } from '../components/common/models/fact.model';
import { FleetModel, FleetResponse } from '../components/common/models/fleet.model';
import { MineModel, MineResponse } from '../components/common/models/mine.model';
import { StatusModel, StatusModelResponse } from '../components/common/models/status.model';
import { TargetModel, TargetResponse } from '../components/common/models/target.model';
import { TreeModel, TreeResponse } from '../components/common/models/tree.model';
import { MetadataFleetLoaded } from '../store/metadata-fleet.state';
import { MetadataMineLoaded } from '../store/metadata-mine.state';
import { MetadataStatusLoaded, MetadataStatusState } from '../store/metadata-status.state';
import { endpoints } from '../utils/endpoints';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class CommonService extends BaseService {

  constructor(protected http: HttpClient, protected store: Store) {
    super(http, store);
  }

  getTree(languageId: string, treeId: string, startDate: string, endDate: string, nodeName: string = null): Observable<TreeModel> {
    let baseUrl = `${endpoints.common}/trees/${languageId}/${treeId}/viz?start_date=${startDate}&end_date=${endDate}&viz_version=v2`;
    if (nodeName != null) {
      baseUrl = baseUrl + `&node_name=${nodeName}`;
    }
    return this.http.get<TreeResponse>(baseUrl, this.httpOptions)
      .pipe(
        map((response: TreeResponse) => {
          console.log(response.tree);
          return response.tree;
        }));
  }

  getTargets(languageId: string, domainId: string): Observable<Array<TargetModel>> {
    return this.http.get<TargetResponse>(`${endpoints.common}/targets/${languageId}?sub_domain.sub_domain_id=${domainId}`, this.httpOptions)
      .pipe(
        map((response: TargetResponse) => {
          return response.targets;
        }));
  }

  saveTarget(languageId: string, target: TargetModel): Observable<any> {
    return this.http.put<TargetModel>(`${endpoints.common}/targets/${languageId}/${target.target_id}`, target, this.httpOptions);
  }

  saveNewTarget(languageId: string, target: TargetModel): Observable<any> {
    return this.http.post<TargetModel>(`${endpoints.common}/targets/${languageId}`, target, this.httpOptions);
  }

  public getFacts(domainId: string): Observable<Array<FactModel>> {
    // tslint:disable-next-line:max-line-length
    const domain = (domainId == DomainsEnum.PRODUCTIVITY) ? DomainsEnum.PRODUCTIVITY : (domainId == DomainsEnum.DATATOOL ? DomainsEnum.GAME : domainId);
    return this.http.get<FactResponse>(`${endpoints.common}/facts/${this.getProfileLanguageId()}?sub_domain.sub_domain_id=${domain}&nocache=${true}`, this.httpOptions)
      .pipe(
        map((response: FactResponse) => {

          const state = JSON.parse(JSON.stringify(response.facts));
          this.store.dispatch(new MetadataFactLoaded(domain, state));
          return response.facts;
        }));
  }

  public saveFact(fact: FactModel): Observable<any> {
    return this.http.put<FactModel>(`${endpoints.common}/facts/${this.getLanguageId()}/${fact.fact_id}`, fact, this.httpOptions);
  }

  public saveNewFact(fact: FactModel): Observable<any> {
    return this.http.post<FactModel>(`${endpoints.common}/facts/${this.getLanguageId()}`, fact, this.httpOptions);
  }

  public getFleets(domain: DomainsEnum = DomainsEnum.PRODUCTIVITY): Observable<Array<FleetModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<FleetResponse>(`${endpoints.common}/fleets/${this.getProfileLanguageId()}?sub_domain.sub_domain_id=${domain}`, this.httpOptions)
      .pipe(
        map((response: FleetResponse) => {
          const state = JSON.parse(JSON.stringify(response.fleets));
          this.store.dispatch(new MetadataFleetLoaded(state));
          return response.fleets;
        }));
  }

  public saveFleet(fleet: FleetModel): Observable<any> {
    return this.http.put<FleetModel>(`${endpoints.common}/fleets/${this.getLanguageId()}/${fleet.fleet_id}`, fleet, this.httpOptions);
  }

  public saveNewFleet(fleet: FleetModel): Observable<any> {
    return this.http.post<FleetModel>(`${endpoints.common}/fleets/${this.getLanguageId()}`, fleet, this.httpOptions);
  }

  public getStatuses(): Observable<Array<StatusModel>> {

    const status = this.store.selectSnapshot(MetadataStatusState.all);
    if (status?.length > 0) {
      return of(status);
    }

    return this.http.get<StatusModelResponse>(`${endpoints.statuses}/${this.getProfileLanguageId()}`, this.httpOptions).pipe(
      map((response: StatusModelResponse) => {
        const state = JSON.parse(JSON.stringify(response.statuses));
        this.store.dispatch(new MetadataStatusLoaded(state));

        return response.statuses;
      })
    );
  }

  public getMines(): Observable<Array<MineModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<MineResponse>(`${endpoints.common}/mines/${this.getProfileLanguageId()}`, this.httpOptions)
      .pipe(
        map((response: MineResponse) => {
          const state = JSON.parse(JSON.stringify(response.mines));
          this.store.dispatch(new MetadataMineLoaded(state));
          return response.mines;
        }));
  }

}
