import {StatusModel} from '../components/common/models/status.model';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

export interface MetadataStatusStateModel {
  status: StatusModel[];
}

export class MetadataStatusLoaded {
  static readonly type = '[MetadataStatus] Loaded';

  constructor(public status: StatusModel[]) {
  }
}

@State<MetadataStatusStateModel>({
  name: 'metadataStatusState',
  defaults: {status: []}
})
@Injectable()
export class MetadataStatusState {
  constructor() {
  }

  @Selector()
  static all(state: MetadataStatusStateModel) {
    return state.status;
  }

  @Selector()
  static liveworkRegister(state: MetadataStatusStateModel) {
    if (!state.status) {
      return [];
    }
    const states = state.status.filter(s => {
      return s.status_id === 'identified' || s.status_id === 'inprogress' ||  s.status_id === 'implemented';
    });

    return states;
  }

  @Action(MetadataStatusLoaded)
  loaded(ctx: StateContext<MetadataStatusStateModel>, action: MetadataStatusLoaded) {
    ctx.patchState({
      status: action.status
    });
  }
}
