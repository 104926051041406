import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UnitModel } from '../components/common/models/unit.model';


export interface MetadataUnitStateModel {
  units: UnitModel[];
}

export class MetadataUnitLoaded {
  static readonly type = '[MetadataUnit] loaded';

  constructor(public units: UnitModel[]) {
  }
}

@State<MetadataUnitStateModel>({
  name: 'metadataUnitState',
  defaults: { units: [] }
})
@Injectable()
export class MetadataUnitState {

  constructor() {
  }

  @Selector()
  static units(state: MetadataUnitStateModel) {
    return state.units;
  }

  @Action(MetadataUnitLoaded)
  loaded(ctx: StateContext<MetadataUnitStateModel>, action: MetadataUnitLoaded) {

    ctx.patchState({
      units: action.units
    });

  }
}
