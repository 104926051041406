import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface DialogStateViewModel {
  open: boolean;
  componentId: string;
  componentIds: string[];
}

export class DialogDisplayComponent {
  static readonly type = '[Dialog] Display';

  constructor(public componentId: string) {
  }

}

export class DialogComponentUnloadComponent {
  static readonly type = '[Dialog] ComponentUnload';

  constructor(public componentId: string) {
  }

}

@State<DialogStateViewModel>({
  name: 'dialogViewState',
  defaults: { open: false, componentId: undefined, componentIds: [] }
})
@Injectable()
export class DialogViewState {

  @Selector()
  static dialogState(state: DialogStateViewModel) {
    return state;
  }

  @Action(DialogDisplayComponent)
  display(ctx: StateContext<DialogStateViewModel>, action: DialogDisplayComponent) {
    const state = ctx.getState();

    ctx.patchState({
      open: true,
      componentId: action.componentId,
      componentIds: [
        ...state.componentIds,
        action.componentId
      ]
    });
  }

  @Action(DialogComponentUnloadComponent)
  unload(ctx: StateContext<DialogStateViewModel>, action: DialogComponentUnloadComponent) {
    const state = ctx.getState();
    const ids = state.componentIds.filter(x => x !== action.componentId);

    const componentId: string = ids.length > 0 ? ids[ids.length - 1] : undefined;
    ctx.patchState({
      open: ids.length > 0,
      componentIds: ids,
      componentId
    });
  }

}
