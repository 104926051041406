export class CleanHtml {
  constructor() {
  }

  public clean(html: string): string {
    let result = this.removeNonApprovedASCII(html);
    result = this.cleanStyles(result);
    result = this.replaceSpan(result);
    result = this.replacePre(result);
    result = this.replaceHtmlBlankDivLine(result);
    result = this.cleanList(result);
    result = this.trimStrongTag(result);
    return this.replaceDiv(result);
  }

  private applyExpression(input: string, expression: string, options?: any): RegExpExecArray {
    const regex = options ? new RegExp(expression, options) : new RegExp(expression);
    return regex.exec(input);
  }

  private isRoot(anchorNode: any): boolean {

    if (!anchorNode || !anchorNode.classList) {
      return false;
    }

    let anchorTag: any;
    anchorNode.classList.forEach(x => {
      if (x === 'nw-editor__res') {
        anchorTag = x;
      }
    });

    return !!anchorTag;
  }

  public scrubUp(goToRoot: boolean, content: string, anchorNode: any, addNewLineBefore: boolean, addNewLineAfter: boolean): string {

    if (anchorNode.nodeName.toLowerCase() === 'div' || anchorNode.nodeName.toLowerCase() === '#document-fragment') {
      if (goToRoot) {
        if (this.isRoot(anchorNode)) {
          return content;
        }
      } else if (!anchorNode.parentNode) {
        return content;
      }
    }
    const changedContent = this.removeContainer(content, anchorNode, addNewLineBefore, addNewLineAfter);
    if (!changedContent) {
      return content;
    }
    return this.scrubUp(goToRoot, changedContent, anchorNode.parentNode, addNewLineBefore, addNewLineAfter);
  }

  public removeContainer(content: string, anchorNode: any, addNewLineBefore: boolean, addNewLineAfter: boolean): string {

    if (!content) {
      return content;
    }
    const nodeName = anchorNode.nodeName.toLowerCase();

    if (nodeName === 'h3' || nodeName === 'h2'
      || nodeName === 'h1' || nodeName === 'p'
      || nodeName === 'b' || nodeName === 'i'
      || nodeName === 'li'
      || nodeName === 'div') {

      let text = anchorNode.innerHTML;
      const replacementText = anchorNode.outerHTML;

      if (nodeName === 'h3' || nodeName === 'h2' || nodeName === 'h1' || nodeName === 'p' || nodeName === 'div') {
        if (addNewLineBefore) {
          text = '<br />' + text;
        }
        if (addNewLineAfter && !text.includes('<br')) {
          console.log(`addNewLineAfter: ${ text }`);
          text += '<br />';
        }
        content = content.replace(replacementText, text);
      } else if (nodeName === 'li') {
        document.execCommand('insertUnorderedList');
        return undefined;
      } else {
        content = content.replace(replacementText, text + '');
      }

    }
    return content;
  }


  public replaceHtmlBlankDivLine(html: string): string {
    if (!html) { return ''; }
    const matches = this.applyExpression(html, '\\<div.+\\<br\\/><\\/div\\>');
    if (!matches || matches.length === 0) {
      return html;
    }

    matches.forEach((match) => {
      const clean = match.replace(match, '<br />');
      html = html.replace(match, clean);
    });

    return this.replaceHtmlBlankDivLine(html);
  }

  public replaceDiv(html: string): string {
    if (!html) { return ''; }
    const fragment = document.createRange().createContextualFragment(html);
    const divs = fragment.querySelectorAll('div');
    if (!divs) {
      return html;
    }
    let prevAfter = false;
    let prevBefore = false;
    divs.forEach((div) => {
      console.log(div.outerHTML);
      let addLineAfter = false;
      if (div.nextElementSibling) {
        const nextSibling = div.nextElementSibling;
        console.log(`next-sibling: ${ nextSibling.nodeName.toLowerCase() }`);
        if (nextSibling.nodeName.toLowerCase() === 'div') {
          addLineAfter = true;
        } else if (nextSibling.nodeName.toLowerCase() !== 'h1'
          || nextSibling.nodeName.toLowerCase() !== 'h2'
          || nextSibling.nodeName.toLowerCase() !== 'h3'
          || nextSibling.nodeName.toLowerCase() !== 'ul'
          // || nextSibling.nodeName.toLowerCase() !== 'li'
          || nextSibling.nodeName.toLowerCase() !== 'ol') {
          addLineAfter = true;
        }
      }
      let addLineBefore = false;
      if (!prevAfter && div.previousElementSibling || div.previousSibling) {
        const previousSibling = div.previousElementSibling || div.previousSibling;
        console.log(`prev-sibling: ${ previousSibling.nodeName.toLowerCase() }`);
        if (previousSibling.nodeName.toLowerCase() !== 'h1'
          && previousSibling.nodeName.toLowerCase() !== 'h2'
          && previousSibling.nodeName.toLowerCase() !== 'h3'
          && previousSibling.nodeName.toLowerCase() !== 'div'
          && previousSibling.nodeName.toLowerCase() !== 'ul'
          // && previousSibling.nodeName.toLowerCase() !== 'li'
          && previousSibling.nodeName.toLowerCase() !== 'ol') {
          addLineBefore = true;
        }
      }
      html = this.scrubUp(false, html, div, addLineBefore, addLineAfter);
      prevAfter = addLineAfter;
      prevBefore = addLineBefore;
    });
    return html;
  }

  public replacePre(html: string): string {
    const matches = this.applyExpression(html, '\\<pre.+\\<\\/pre\\>');
    if (!matches || matches.length === 0) {
      return html;
    }

    matches.forEach((match) => {
      let clean = match.replace('pre', 'p');
      clean = clean.replace('pre', 'p');
      html = html.replace(match, clean);
    });

    return this.replacePre(html);
  }

  public cleanStyles(html: string): string {
    if (!html) {
      return '';
    }
    html = this.cleanTags(html, 'dir');
    html = this.cleanTags(html, 'role');
    return this.cleanTags(html, 'style');
  }

  public cleanTags(html: string, attribute: string): string {
    if (!html) {
      return '';
    }
    const matches = this.applyExpression(html, `${ attribute }=".*?"`);
    if (!matches || matches.length === 0) {
      return html;
    }

    matches.forEach((match) => {
      html = html.replace(match, '');
    });

    return this.cleanTags(html, attribute);
  }

  public replaceSpan(html: string): string {
    const matches = this.applyExpression(html, '\\<span.+\\<\\/span\\>');
    if (!matches || matches.length === 0) {
      return html;
    }
    matches.forEach((match) => {
      let clean = match.replace('span', 'div');
      clean = clean.replace('span', 'div');
      html = html.replace(match, clean);
    });

    return this.replaceSpan(html);
  }

  public cleanList(html: string): string {
    const matches = this.applyExpression(html, '\\<p.+?\\<\\/p\\>');
    if (!matches || matches.length === 0) {
      return html;
    }

    matches.forEach((match) => {
      let clean = match.replace('<p>', '');
      if (clean.includes('<br')) {
        clean = clean.replace('</p>', '');
      } else {
        clean = clean.replace('</p>', '<br />');
      }
      html = html.replace(match, clean);
    });
    return this.cleanList(html);
  }

  public trimStrongTag(html: string): string {
    if (!html) { return ''; }

    if (!html.includes(' </b>')
      && !html.includes('<b> ')
      && !html.includes('&nbsp;</b>')
      && !html.includes('<b>&nbsp;')
    ) {
      return html;
    }

    if (html.includes('<b> ')) {
      console.log('found space after <b>');
      html = html.replace('<b> ', '&nbsp;<b>');
    }
    if (html.includes('<b>&nbsp;')) {
      console.log('found &nbsp; after <b>');
      html = html.replace('<b>&nbsp;', '&nbsp;<b>');
    }

    if (html.includes(' </b>')) {
      console.log('found space before </b>');
      html = html.replace(' </b>', '</b>&nbsp;');
    }
    if (html.includes('&nbsp;</b>')) {
      console.log('found &nbsp; before </b>');
      html = html.replace('&nbsp;</b>', '</b>&nbsp;');
    }

    return this.trimStrongTag(html);
  }

  public removeNonApprovedASCII(html): string {
    if (!html) { return ''; }

    // Allowed cchar codes
    // 10, 13, 32-167, 181-183, 198-199, 201, 241, 209-212, 224-237, 248, 252-253
    for (let i = 0; i < html.length; i++) {
      const code = html.charCodeAt(i);
      if (code === 10 || code === 12
        || (code >= 32 && code <= 167)
        || (code >= 181 && code <= 183)
        || (code >= 198 && code <= 199)
        || (code === 201)
        || (code === 241)
        || (code >= 209 && code <= 212)
        || (code >= 224 && code <= 237)
        || (code === 248)
        || (code >= 252 && code <= 253)) {
        continue;
      }
      console.log(`removing ${ code }: ${ html[i] }`);
      html = html.substring(0, i) + ' ' + html.substring(i + 1);
    }

    return html;
  }

}
