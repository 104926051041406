import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';

export interface MetadataTagsModel {
  tags: any;
}

export class MetadataTagsLoaded {
  static readonly type = '[MetadataTags] loaded';

  constructor(public tags: any) {
  }
}

@State<MetadataTagsModel>({
  name: 'metadataTagsState',
  defaults: {tags: {}}
})
@Injectable()
export class MetadataTagsState {
  constructor() {
  }

  @Selector()
  static tags(state: MetadataTagsModel) {
    return state.tags;
  }

  @Action(MetadataTagsLoaded)
  loaded(ctx: StateContext<MetadataTagsModel>, action: MetadataTagsLoaded) {
    ctx.patchState({tags: action.tags});
  }
}
