import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MetricCategoryModel } from '../components/common/models/metric-category.model';


export interface MetadataMetricCategoryStateModel {
  categories: MetricCategoryModel[];
}

export class MetadataMetricCategoryLoaded {
  static readonly type = '[MetadataMetricCategory] loaded';

  constructor(public categories: MetricCategoryModel[]) {
  }
}

@State<MetadataMetricCategoryStateModel>({
  name: 'metadataMetricCategoryState',
  defaults: { categories: [] }
})
@Injectable()
export class MetadataMetricCategoryState {

  constructor() {
  }

  @Selector()
  static categories(state: MetadataMetricCategoryStateModel) {
    return state.categories;
  }

  @Action(MetadataMetricCategoryLoaded)
  loaded(ctx: StateContext<MetadataMetricCategoryStateModel>, action: MetadataMetricCategoryLoaded) {

    ctx.patchState({
      categories: action.categories
    });

  }
}
