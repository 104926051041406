import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ToolModel} from '../components/common/models/livework.model';

export interface MetadataToolStateModel {
  tools: ToolModel[];
}

export class MetadataToolLoaded {
  static readonly type = '[MetadataTool] loaded';

  constructor(public tools: ToolModel[]) {
  }
}

@State<MetadataToolStateModel>({
  name: 'metadataToolState',
  defaults: { tools: [] }
})
@Injectable()
export class MetadataToolState {
  constructor() {
  }

  @Selector()
  static tools(state: MetadataToolStateModel) {
    return state.tools;
  }

  @Action(MetadataToolLoaded)
  loaded(ctx: StateContext<MetadataToolStateModel>, action: MetadataToolLoaded) {

    ctx.patchState({
      tools: action.tools
    });
  }
}
