import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../services/dialog.service';
import {
  ColumnDataModel,
  ColumnMapIndicator,
  ColumnMappingModel,
  DataModel,
  DataModelTypes,
  MappingTypes,
  TableDataModel
} from '../../../../common/models/data.model';
import { LandService } from '../../../../../services/land.service';
import { ConfirmButtonModel, ConfirmDialogModel, ConfirmDialogResult, ConfirmDialogTypeEnum } from '../../../../common/models/dialog.model';
import {DataModelService} from '../../../../../services/data-model.service';
import {DataModelFormBaseComponent} from '../data-model-form-base.component';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-form-data-model-column-map-target',
  templateUrl: './form-data-model-column-map-target.component.html',
  styleUrls: ['./form-data-model-column-map-target.component.css']
})
export class FormDataModelColumnMapTargetComponent extends DataModelFormBaseComponent {

  public currentStep: string;
  public saving: boolean;

  public loadingDataModels: boolean;
  public loadingTables: boolean;
  public loadingColumns: boolean;

  public dataModels: DataModel[];
  public selectedDataModelId: string;
  public selectedDataModel: DataModel;

  public tables: TableDataModel[];
  public selectedTableModelId: string;
  public selectedTableModel: TableDataModel;

  public columns: ColumnDataModel[];
  public selectedColumnId: string;
  public selectedColumnModel: ColumnDataModel;

  private mapping: ColumnMappingModel;

  constructor(
    protected landService: LandService,
    protected router: Router,
    protected dialogService: DialogService,
    private dataModelService: DataModelService,
    protected logService: LogService) {
    super(router, dialogService, logService);
  }

  protected init(): void {
    this.currentStep = 'step-datamodel-details';
    this.modalState = this.dialogService.displayModal.current.data.displayMode;
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.column = this.dialogService.displayModal.current.data.column;
    this.mapping = this.dialogService.displayModal.current.data.mapping;
    this.dialogService.displayModal.current.secondHeader = `::${ this.column.column_model_name }`;

    console.log(this.mapping);


    if (!this.mapping) {
      console.log('no mapping');
      this.selectedDataModelId = undefined;
      this.selectedTableModelId = undefined;
      this.selectedColumnId = undefined;
      return;
    }
    console.log('looking for mapping');
    const mappingColumn =
      this.column.table.data_model.data_model_type === DataModelTypes.SOURCE ? this.mapping.target : this.mapping.source;

    console.log(mappingColumn);
    this.selectedDataModel = mappingColumn.table.data_model;
    this.selectedTableModel = mappingColumn.table;
    this.selectedColumnModel = mappingColumn;
  }

  protected setUser(user: any): void {
    console.log('setUsers');
    setTimeout(() => {
      this.getDataModels();
    });
  }

  public changeDataModel(): void {
    this.selectedDataModel = this.dataModels.find(x => x.data_model_base_id === this.selectedDataModelId);
    this.selectedTableModelId = undefined;
    this.selectedTableModel = undefined;
    this.selectedColumnId = undefined;
    this.selectedColumnModel = undefined;
  }

  public changeTableModel(): void {
    this.selectedTableModel = this.tables.find(t => t.table_model_base_id === this.selectedTableModelId);
    this.selectedColumnId = undefined;
    this.selectedColumnModel = undefined;
  }

  public changeColumnModel(): void {
    this.selectedColumnModel = this.columns.find(c => c.column_model_base_id === this.selectedColumnId);
  }

  public dataModelValid(): boolean {
    if (this.loadingDataModels) {
      return false;
    }

    return this.selectedDataModelId !== undefined;
  }

  public tableValid(): boolean {
    if (this.loadingTables) {
      return false;
    }

    return this.selectedTableModelId !== undefined;
  }

  public columnValid(): boolean {
    if (this.loadingColumns) {
      return false;
    }

    return this.selectedColumnId !== undefined;
  }

  private isModelValid(): boolean {
    return this.dataModelValid() && this.tableValid() && this.columnValid();
  }

  public goToDataModel(): void {
    this.currentStep = 'step-datamodel-details';
  }

  public goToTable(): void {
    this.getTables();
    this.currentStep = 'step-table-details';
  }

  public goToColumn(): void {
    this.getColumns();
    this.currentStep = 'step-column-details';
  }

  public canGoBack(): boolean {
    return this.currentStep !== 'step-datamodel-details';
  }

  public canGoNext(): boolean {

    if (this.currentStep === 'step-column-details') {
      return this.columnValid();
    }
    if (this.currentStep === 'step-table-details') {
      return this.tableValid();
    }
    if (this.currentStep === 'step-datamodel-details') {
      return this.dataModelValid();
    }
    return false;
  }

  public next(): void {
    if (!this.canGoNext()) {
      return;
    }

    if (this.currentStep === 'step-column-details') {
      this.saveMappings();
      return;
    }

    if (this.currentStep === 'step-table-details') {
      this.goToColumn();
      return;
    }
    if (this.currentStep === 'step-datamodel-details') {
      this.goToTable();
    }
  }

  public back(): void {

    if (this.currentStep === 'step-table-details') {
      this.goToDataModel();
      return;
    }

    if (this.currentStep === 'step-column-details') {
      this.goToTable();
      return;
    }

  }

  private saveMappings(): void {

    if (!this.isModelValid()) {
      return;
    }

    const sourceColumn = this.column.table.data_model.data_model_type === DataModelTypes.SOURCE ? this.column : this.selectedColumnModel;
    const targetColumn = this.column.table.data_model.data_model_type === DataModelTypes.TARGET ? this.column : this.selectedColumnModel;

    if (!this.mapping) {
      this.mapping = {
        mapping_type: MappingTypes.column,
        source: sourceColumn,
        target: targetColumn
      } as ColumnMappingModel;
    } else {
      this.mapping.source = sourceColumn;
      this.mapping.target = targetColumn;
    }

    this.saving = true;
    const saveCall = !this.mapping.mapping_id ? this.landService.saveNewColumnMapping(this.mapping) :
      this.landService.saveColumnMapping(this.mapping.mapping_id, this.mapping);

    saveCall.subscribe(() => {
      this.saving = false;
      sourceColumn.mapped_indicator = ColumnMapIndicator.mapped;
      sourceColumn.mapped_indicator_text = 'Mapped';

      targetColumn.mapped_indicator = ColumnMapIndicator.mapped;
      targetColumn.mapped_indicator_text = 'Mapped';

      this.modalRef.close(ConfirmDialogResult.Confirm);
    }, (error => {

      this.saving = false;
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'land-table-save-error-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'land-table-save-error-message',
        subMessage: error,
        confirmButton: { show: true, textTranslateId: 'datatools-ok-button' } as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
      });

    }));

  }


  private getDataModels(): void {
    this.loadingDataModels = true;

    const modelType =
      this.column.table.data_model.data_model_type === DataModelTypes.SOURCE ? DataModelTypes.TARGET : DataModelTypes.SOURCE;

    this.dataModelService.getDataModels(this.getCurrentDomain().domain.domain_id).subscribe((dataModels: DataModel[]) => {
      this.dataModels = dataModels.filter((dataModel: DataModel) =>
        dataModel.data_model_type === modelType);

      if (this.selectedDataModel) {
        this.selectedDataModel = this.dataModels.find(x => x.data_model_id === this.selectedDataModel.data_model_id);
        this.selectedDataModelId = this.selectedDataModel.data_model_base_id;
        this.getTables();
      }

      this.loadingDataModels = false;
    });
  }

  private getTables(): void {
    if (!this.selectedDataModelId) {
      return;
    }
    this.loadingTables = true;
    this.dataModelService.getTables(this.selectedDataModelId).subscribe((tables: TableDataModel[]) => {
      this.tables = tables;

      if (this.selectedTableModel) {
        this.selectedTableModel = this.tables.find(x => x.table_model_id === this.selectedTableModel.table_model_id);
        this.selectedTableModelId = this.selectedTableModel.table_model_base_id;
        this.getColumns();
      }

      this.loadingTables = false;
    });
  }

  private getColumns(): void {
    if (!this.selectedTableModelId) {
      return;
    }
    this.loadingColumns = true;
    this.dataModelService.getColumns(this.selectedDataModelId,
      this.selectedTableModelId).subscribe((columns: ColumnDataModel[]) => {
      this.columns = columns;

      if (this.selectedColumnModel) {
        this.selectedColumnModel = this.columns.find(x => x.column_model_id === this.selectedColumnModel.column_model_id);
        this.selectedColumnId = this.selectedColumnModel.column_model_base_id;
      }

      this.loadingColumns = false;
    });
  }
}
