<section class="columns-container">

  <div *ngIf="!loadingCols && !columns">
    <p [translate]="'no-columns'"></p>
  </div>

  <div class="col-list-container">
    <clr-datagrid [clrDgLoading]="loadingCols" data-cy="land-grid-cols" #grid class="datagrid-compact">
      <clr-dg-column [clrDgField]="'column_model_name'">
        <span [translate]="'column_name'"></span>
      </clr-dg-column>
      <clr-dg-column [clrDgField]="'column_data_type'">
        <span [translate]="'column_data_type'"></span>
      </clr-dg-column>
      <clr-dg-column [clrDgField]="'column_model_type'">
        <span [translate]="'column_model_type'"></span>
      </clr-dg-column>
      <clr-dg-column *ngIf="showMapping" [clrDgField]="'mapping_status'">
        <span [translate]="'mapping_status'"></span>
      </clr-dg-column>
      <clr-dg-column>
        <span [translate]="'evaluations-grid-col-options'"></span>
      </clr-dg-column>
      <clr-dg-row *clrDgItems="let column of columns">
        <clr-dg-cell [attr.data-cy]="'column-name-'+ column.column_model_name">{{ column.column_model_name }}</clr-dg-cell>
        <clr-dg-cell data-cy="grid-column-data-type">{{ column.column_data_type }}</clr-dg-cell>
        <clr-dg-cell>{{ column.column_model_type }}</clr-dg-cell>
        <clr-dg-cell *ngIf="showMapping">{{ column.mapped_indicator_text }}</clr-dg-cell>
        <clr-dg-cell>
          <div style="display: flex; max-width: 250px;">
            <button (click)="viewColumn(column)" class="btn btn-link btn-sm" data-cy="datagrid-view-button" type="button">
              <span [translate]="'evaluations-grid-btn-view'"></span>
            </button>
            <button (click)="editColumn(column)" class="btn btn-link btn-sm" data-cy="datagrid-edit-button" type="button">
              <span [translate]="'evaluations-grid-btn-edit'"></span>
            </button>
          </div>
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="5">
          <span [translate]="'evaluations-grid-foot-label-1'"></span>
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
          <span [translate]="'evaluations-grid-foot-label-2'"></span>
          {{ pagination.totalItems }}
          <span [translate]="'land-col-footer'"></span>
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>
  </div>

</section>


