import {Injectable, Injector} from '@angular/core';
import {NgxWigComponent} from 'ngx-wig';
import {ShowdownConverter} from 'ngx-showdown';
import {DialogService} from './dialog.service';
import {ConfirmDialogResult} from '../components/common/models/dialog.model';
import {
  ExplorationAttachmentModel,
  ExplorationSummaryModel,
  ExplorationSummaryStep
} from '../components/common/models/exploration-monthly-reports.model';
import {CleanHtml} from './clean-html.helper';
import {ExplorationUploadComponent} from '../components/exploration/exploration-monthly-reports/exploration-upload/exploration-upload.component';
import {LinkComponentFormComponent} from '../components/control/control-alerts/control-alert-form/link-component-form/link-component-form.component';
import {EditorImageFormComponent} from '../components/control/control-alerts/control-alert-form/editor-image-form/editor-image-form.component';

const removeMd = require('remove-markdown');


@Injectable({providedIn: 'root'})
export class MarkdownService {
  public static injectorInstance: Injector;

  constructor(private showdownConverter: ShowdownConverter, public dialogService: DialogService, private injector: Injector) {
    MarkdownService.injectorInstance = injector;
  }

  public static WIG_PLUGIN = {
    n: {
      label: 'N',
      title: 'Normalize Text',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.removeMarkup(ctx);
      },
      styleClass: 'nw-button'
    },
    h1: {
      label: 'H1',
      title: 'Header 1',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.execBlockCommand(ctx, 'h1', '<h1>');
      },
      styleClass: 'nw-button'
    },
    h2: {
      label: 'H2',
      title: 'Header 2',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.execBlockCommand(ctx, 'h2', '<h2>');
      },
      styleClass: 'nw-button'
    },
    h3: {
      label: 'H3',
      title: 'Header 3',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.execBlockCommand(ctx, 'h3', '<h3>');
      },
      styleClass: 'nw-button'
    },
    ul: {
      label: 'UL',
      title: 'Bullet list',
      command: () => {
        document.execCommand('insertUnorderedList');
      },
      icon: 'icon-list-ul',
      styleClass: 'nw-button'
    },
    ol: {
      label: 'OL',
      title: 'Numbered list',
      command: () => {
        document.execCommand('insertOrderedList');
      },
      icon: 'icon-list-ol',
      styleClass: 'nw-button'
    },
    attachment: {
      label: 'A',
      title: 'Add Attachment',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.displayAddAttachment(ctx);
      },
      styleClass: 'nw-button attachment-dropdown',
      icon: 'icon-link'
    },
    link: {
      label: 'L',
      title: 'Add Link',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.displayAddLink(ctx);
      },
      styleClass: 'nw-button attachment-dropdown',
      icon: 'icon-link'
    },
    picture: {
      label: 'P',
      title: 'Add Picture',
      command: (ctx: NgxWigComponent) => {
        MarkdownService.displayAddImage(ctx);
      },
      styleClass: 'nw-button',
      icon: 'icon-test'
    }
  };


  public static insertContent(content, type: string): void {
    if (type === 'text/plain') {
      document.execCommand('inserttext', false, content);
      return;
    }
    document.execCommand('insertHTML', false, content);
  }

  public static displayAddImage(ctx: NgxWigComponent): void {
    const dialogService = MarkdownService.injectorInstance.get<DialogService>(DialogService);
    const ticks = ((new Date().getTime() * 10000) + 621355968000000000);
    const id = `link-${ticks}`;
    document.execCommand('insertHTML', true, `<img class="editor-image"  id="${id}" src="url-${ticks}"/>`);

    setTimeout(() => {
      const dialogRef = dialogService.openDialog('EditorImageFormComponent', EditorImageFormComponent, 'editor-add-image', 'md', {});

      dialogRef.afterClosed.subscribe((results) => {
        setTimeout(() => {
          const image: any = document.getElementById(id);
          if (results.action === ConfirmDialogResult.Cancel) {
            if (image) {
              image.remove();
            }
            return;
          }

          if (image) {
            const src = results.data.url;

            let content = ctx.container.innerHTML;
            content = content.replace(`url-${ticks}`, src);
            ctx.writeValue(content);
            setTimeout(() => {
              ctx.onContentChange(content);
            })
          }

        }, 500);

      });
    }, 200);
  }

  public static displayAddLink(ctx: NgxWigComponent): void {
    const dialogService = MarkdownService.injectorInstance.get<DialogService>(DialogService);
    const ticks = ((new Date().getTime() * 10000) + 621355968000000000);
    const anchorId = `link-${ticks}`;
    document.execCommand('insertHTML', true, `<a target="_blank" id="${anchorId}" href="url-${ticks}">text-${ticks}</a>`);

    setTimeout(() => {
      const dialogRef = dialogService.openDialog('LinkComponentFormComponent', LinkComponentFormComponent, 'editor-add-link', 'md', {});

      dialogRef.afterClosed.subscribe((results) => {
        setTimeout(() => {
          const anchor: any = document.getElementById(anchorId);
          if (results.action === ConfirmDialogResult.Cancel) {
            if (anchor) {
              anchor.remove();
            }
            return;
          }

          if (anchor) {
            const url = results.data.url;
            const text = results.data.text.length === 0 ? url : results.data.text;

            let content = ctx.container.innerHTML;
            content = content.replace(`text-${ticks}`, text).replace(`url-${ticks}`, url);
            ctx.writeValue(content);
            setTimeout(() => {
              ctx.onContentChange(content);
            })
          }

        }, 500);

      });
    }, 200);

  }

  public static displayAddAttachment(ctx: NgxWigComponent): void {
    const dialogService = MarkdownService.injectorInstance.get<DialogService>(DialogService);
    const ticks = ((new Date().getTime() * 10000) + 621355968000000000);

    const ref = dialogService.openAddAttachment();

    if (!ref.dialog.isOpen) {
      return;
    }

    document.execCommand('insertHTML', false, `<a id="attachment-${ticks}" href="/download?file_name=&file_id=">New Attachment ...</a>`);
    ref.afterClosed.subscribe((results) => {
      const anchor: any = document.getElementById(`attachment-${ticks}`);

      if (results.action === ConfirmDialogResult.Cancel) {
        if (anchor) {
          anchor.remove();
        }
        return;
      }

      if (anchor) {
        setTimeout(() => {
          anchor.target = '_blank';
          anchor.innerText = `${results.data.file.file_title}`;
          anchor.href = `/download?file_name=${results.data.file.file_name}&file_id=${results.data.file.file_id}`;
          anchor.id = `attachment-${results.data.file.file_id}`;
          const content = ctx.container.innerHTML;
          ctx.writeValue(content);
          ctx.onContentChange(content);
        }, 500);
      }

    });
  }

  public static execBlockCommand(ctx: NgxWigComponent, type: string, command: string): void {
    const selectedText = window.getSelection().toString();
    if (!selectedText) {
      document.execCommand('formatBlock', false, command);
      return;
    }
    document.execCommand('insertHTML', false, `<${type}>${selectedText}</${type}>`);
  }

  public static removeMarkup(ctx: NgxWigComponent): void {
    const selection: any = window.getSelection();
    let addNewLine = false;
    if (selection.anchorNode && selection.anchorNode.parentElement && selection.anchorNode.parentElement.nextSibling
      && selection.anchorNode.parentElement.nextSibling.nodeName === '#text') {
      addNewLine = true;
    }
    console.log('newline:' + addNewLine);

    let content = ctx.container.innerHTML;
    const clean = new CleanHtml();
    content = clean.cleanStyles(content);
    content = clean.replaceSpan(content);
    content = clean.replacePre(content);
    content = clean.scrubUp(true, content, selection.anchorNode, false, addNewLine);
    if (content) {
      ctx.writeValue(content);
      ctx.onContentChange(content);
    }
  }


  public static applyExpression(input: string, expression: string, options?: any): RegExpExecArray {
    const regex = options ? new RegExp(expression, options) : new RegExp(expression);
    return regex.exec(input);
  }


  public static cleanHtml(html: string): string {
    if (!html) {
      return html;
    }
    const clean = new CleanHtml();
    return clean.clean(html);
  }

  public removeMarkDown(markdown: string): string {
    if (!markdown) {
      return '';
    }
    return removeMd(markdown);
  }

  public validateAttachments(step: ExplorationSummaryStep, summary: ExplorationSummaryModel): void {
    if (!step.validation) {
      step.validation = {valid: true, message: ''};
    }

    if (!step.html_text) {
      return;
    }
    const fragment = new DOMParser().parseFromString(step.html_text, 'text/html');
    const anchors = fragment.querySelectorAll('a');

    if (!anchors || anchors.length === 0) {
      return;
    }

    anchors.forEach((anchor: HTMLAnchorElement) => {
      this.validateAttachment(step, summary, anchor);
    });
    step.html_text = fragment.body.innerHTML;
  }

  private validateAttachment(step: ExplorationSummaryStep, summary: ExplorationSummaryModel,
                             anchor: HTMLAnchorElement): void {
    const anchorHtml = anchor.outerHTML;
    const fileId = this.getFileIdFromAnchor(anchorHtml);
    anchor.target = '_blank';
    if (!fileId) {
      anchor.title = 'invalid attachment';
      anchor.classList.add('attachment-invalid');
      this.addValidation(step, false, 'Text contains an invalid reference to an attachment');
    } else {
      const attachment = summary.attachments.find((a: ExplorationAttachmentModel) => a.file_id === fileId);
      if (!attachment) {
        anchor.title = 'missing attachment';
        anchor.classList.add('attachment-invalid');
        this.addValidation(step, false, 'Text contains a reference to an attachment that has been deleted');
      } else {
        anchor.href += '&close=1';
      }
    }
  }

  public removeAttachments(step: ExplorationSummaryStep, fileId: string, index?: number): void {
    if (!index) {
      index = 0;
    }

    const matches = MarkdownService.applyExpression(step.html_text.substr(index), '<a.+?<\\/a>');
    if (!matches) {
      return;
    }

    matches.forEach((match) => {
      index = step.html_text.indexOf(match, index) + match.length;
      const foundFileId = this.getFileIdFromAnchor(match);
      if (foundFileId === fileId) {
        step.html_text = step.html_text.replace(match, '');
      }
    });

    this.removeAttachments(step, fileId, index);
  }


  private addValidation(step: ExplorationSummaryStep, valid: boolean, message: string): void {
    step.validation = {message, valid};
  }

  public getFileIdFromAnchor(html: string): string {
    const matches = MarkdownService.applyExpression(html, 'file_id=[0-9a-fA-F\\-]{36}');
    if (!matches || matches.length === 0) {
      return undefined;
    }
    return matches[0].replace('file_id=', '');
  }

  public convertMarkdownToHtml(markdown: string): string {
    const cleanMarkdown = this.cleanMarkdown(markdown);
    const html = this.showdownConverter.makeHtml(cleanMarkdown);
    const clean = new CleanHtml();
    return clean.clean(html);
  }

  public convertHtmlToMarkdown(html: string): string {
    const clean = MarkdownService.cleanHtml(html);
    return this.showdownConverter.makeMarkdown(clean);
  }

  private cleanMarkdownEnd(markdown: string): string {
    const matches = MarkdownService.applyExpression(markdown, '<!-- -->');
    if (!matches || matches.length === 0) {
      return markdown;
    }

    matches.forEach((match) => {
      markdown = markdown.replace(match, '');
    });

    return this.cleanMarkdownEnd(markdown);
  }


  private cleanMarkdown(markdown: string): string {
    markdown = this.cleanMarkdownEnd(markdown);
    const clean = new CleanHtml();
    markdown = clean.cleanStyles(markdown);
    markdown = clean.replaceSpan(markdown);
    return this.replaceMarkdownAttachment(markdown);
  }

  private replaceMarkdownAttachment(markdown: string): string {
    const matches = MarkdownService.applyExpression(markdown, '\\[\\[See Figure #\\S+\\]\\]');
    if (!matches || matches.length === 0) {
      return markdown;
    }

    matches.forEach((match) => {
      const attachmentId = MarkdownService.applyExpression(match, '(?<=#)(\\S+)(?=]])');

      if (!attachmentId || attachmentId.length === 0) {
        markdown = markdown.replace(match, `**See Figure Invalid Figure**`);
      } else {
        // TODO: real url  `/download?file_name=${file_name}&file_id=${file_id}`;
        markdown = markdown.replace(match, `[See Figure ${attachmentId[0]}](https://dev.sentinel.newmont.com/download/${attachmentId[0]})`);
      }
    });

    return this.replaceMarkdownAttachment(markdown);
  }


}
