import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

export interface MetadataTeamStateModel {
  teamId: string;
  teamName: string;
}

export class MetadataTeamSelected {
  static readonly type = '[MetadataTeam] selected';

  constructor(public teamId: string, public teamName: string) {
  }
}

@State<MetadataTeamStateModel>({
  name: 'metadataTeamState',
  defaults: {teamId: '', teamName: ''}
})
@Injectable()
export class MetadataTeamState {

  constructor() {
  }

  @Selector()
  static selected(state: MetadataTeamStateModel) {
    return state;
  }


  @Action(MetadataTeamSelected)
  loaded(ctx: StateContext<MetadataTeamStateModel>, action: MetadataTeamSelected) {
    const state = ctx.getState();
    if (state.teamId === action.teamId) {
      return;
    }
    ctx.patchState({
      teamId: action.teamId,
      teamName: action.teamName
    });
  }

}
