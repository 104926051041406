<div class="clr-row" *ngIf="column?.mapped_indicator === columnMapIndicator.not_mapped">
  <div class="clr-col">
    <span
      [translate]="'land-column-map-missing-label-1'">This column has not been mapped to a target data model. </span>
    <span [translate]="'land-column-map-missing-label-2'">You can either </span>
    <button (click)="mapTarget()" class="btn btn-link"><span [translate]="'land-column-map-target'"></span></button>
    <span [translate]="'land-column-map-missing-label-3'">or</span>
    <button (click)="ignoreMapping()" class="btn btn-link"><span [translate]="'land-column-map-ignore'">Ignore</span>
    </button>
    <span [translate]="'land-column-map-missing-label-4'"></span>
  </div>
</div>

<div class="clr-row" *ngIf="column?.mapped_indicator === columnMapIndicator.ignored">
  <div class="clr-col">
    <span>This column has been set to ignore column mappings.  If this is incorrect you can </span>
    <button (click)="mapTarget()" class="btn btn-link"><span [translate]="'land-column-map-target'"></span></button>
    <span [translate]="'land-column-map-missing-label-4'"></span>
  </div>
</div>


<div class="clr-row" *ngIf="column?.mapped_indicator === columnMapIndicator.mapped">
  <div class="clr-col">
    <div class="clr-row">
      <div class="clr-col">
        <button class="btn" (click)="mapTarget()"><span [translate]="'land-column-map-add'"></span></button>
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col">
        <ng-container>
          <clr-datagrid [clrDgLoading]="loading" data-cy="land-grid-col-map-src">
            <clr-dg-column [clrDgField]="'target.table.data_model.data_model_name'">
              <span [translate]="'land-column-map-target-datamodel'">land-datamodel</span>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'target.table.table_model_name'">
              <span [translate]="'land-column-map-target-table'">Table</span>
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'target.column_model_name'">
              <span [translate]="'land-column-map-target-column'">Column</span>
            </clr-dg-column>
            <clr-dg-column>
              <span [translate]="'evaluations-grid-col-options'"></span>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let mapping of columnMappings">
              <clr-dg-cell data-cy="land-grid-map-model-name">{{ mapping.target.table.data_model.data_model_name }}</clr-dg-cell>
              <clr-dg-cell>{{ mapping.target.table.table_model_name }}</clr-dg-cell>
              <clr-dg-cell>{{ mapping.target.column_model_name }}</clr-dg-cell>
              <clr-dg-cell>
                <div style="display: flex;">
                  <button (click)="deleteMapping(mapping)" class="btn btn-link btn-sm delete" data-cy="datagrid-delete-button"
                          [translate]="'land-mapping-grid-btn-delete'">
                  </button>
                </div>
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                                  [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
                <span [translate]="'evaluations-grid-foot-label-1'"></span>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                <span [translate]="'evaluations-grid-foot-label-2'"></span>
                {{ pagination.totalItems }}
                <span [translate]="'exploration-project-footer'"></span>
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="saving">
  <app-loader [displayMode]=""></app-loader>
</ng-container>

