import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatEvaluationData' })
export class formatEvaluationDataPipe implements PipeTransform {
    transform(value: string) {
      let newValue = value;
      if (value == 'adhoc') {
        newValue = 'Ad hoc';
      } else if (value == 'frm') {
        newValue = 'Fatality Risk Management';
      } else if (value == 'tsf') {
        newValue = 'Tailing Storage Facility';
      } else if (value == 'inprogress') {
        newValue = 'In Progress';
      }
      return newValue;
    }; 
}