<div
  class="view-colum-container padded-container"
  *ngIf="state === stateEnum.VIEW"
  data-cy="view-column-detail-container"
>
  <div class="clr-row">
    <div class="clr-col">
      <div class="clr-row padded-sub-row">
        <div class="clr-col-3 first-col">
          <p [translate]="'land-column-details-col-name'" class="title-label"></p>
          <p data-cy="col-detail-col-name">{{ column.column_model_name }}</p>
        </div>
        <div class="clr-col-3">
          <p [translate]="'land-column-details-data-type'" class="title-label"></p>
          <p data-cy="col-detail-col-data-type">{{ column.column_data_type }}</p>
        </div>
      </div>
      <div class="clr-row padded-sub-row">
        <div class="clr-col-3 first-col">
          <p [translate]="'land-column-details-model-type'" class="title-label"></p>
          <p data-cy="col-detail-col-model-type">{{ column.column_model_type }}</p>
        </div>
      </div>
      <div class="clr-row padded-sub-row last-sub-row">
        <div class="clr-col-7 first-col">
          <p [translate]="'land-table-details-table-description'" class="title-label"></p>
        </div>
         <!-- Removing for now the translate button until story is added -->
        <!-- <div
        class="clr-col-3"
        style="
          display: flex;
          justify-content: flex-end;
        "
      >
        <button
          class="btn btn-sm btn-link"
          [translate]="'table-details-translate-btn'"
          style="margin-bottom: 0; margin-top: 0;"
          disabled
        ></button>
        </div> -->
      </div>
      <div class="clr-row padded-sub-row">
        <div class="clr-col-10 first-col">
          <p *ngIf="column.column_description.html_text.length > 0" [innerHTML]="column.column_description.html_text"></p>
          <p *ngIf="column.column_description.html_text.length === 0" > <span [translate]="'land-no-description'"></span></p>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="edit-colum-container padded-container"
  *ngIf="state === stateEnum.EDIT"
  data-cy="edit-column-detail-container"
>
  <div class="clr-row">
    <div class="clr-col">
      <div class="clr-row padded-row">
        <div class="clr-col-3 first-col">
          <span [translate]="'land-column-details-col-name'" class="title-label"></span>
          <clr-tooltip class="tooltip-container">
            <clr-icon shape="help" clrTooltipTrigger></clr-icon>
            <clr-tooltip-content [clrSize]="'lg'">
              <span>Column name can not include spaces or any of these reserved words:</span>
              <div class="reserved-words">
                <span class="reserved-word" *ngFor="let word of reservedWords"> {{word}}</span>
              </div>
            </clr-tooltip-content>
          </clr-tooltip>
          <clr-input-container
            *ngIf="table.data_model.data_model_type === dataModelTypes.TARGET"
            style="margin-top: 0"
          >
            <input
              clrInput
              [(ngModel)]="column.column_model_name"
              class="column_name"
              name="column_name"
            />
          </clr-input-container>
          <div class="validation" *ngIf="!validateColumnName()">
            <span class="invalid-table" >Invalid column name</span>
          </div>
          <p *ngIf="table.data_model.data_model_type == dataModelTypes.SOURCE">
            {{ column.column_model_name }}
          </p>
        </div>
        <div class="clr-col-3">
          <clr-select-container
            style="margin-top: 0"
            *ngIf="table.data_model.data_model_type === dataModelTypes.TARGET"
          >
            <label
              [translate]="'land-column-details-data-type'"
              class="select-label"
            ></label>
            <select
              clrSelect
              name="dataType_options"
              [(ngModel)]="column.column_data_type"
              [disabled]="
                table.data_model.data_model_type !== dataModelTypes.TARGET ||
                column.column_model_type === columnModelTypes.CATEGORY
              "
            >
              <option
                *ngFor="let dataType of columnDataTypesOptions"
                [value]="dataType"
              >
                {{ dataType }}
              </option>
            </select>
          </clr-select-container>
          <p
            [translate]="'land-column-details-data-type'"
            *ngIf="table.data_model.data_model_type === dataModelTypes.SOURCE"
            class="title-label"
          ></p>
          <p *ngIf="table.data_model.data_model_type === dataModelTypes.SOURCE">
            {{ column.column_data_type }}
          </p>
        </div>
      </div>
      <div class="clr-row padded-sub-row">
        <div class=".clr-col-auto first-col">
          <div class="model-type-header">
            <p [translate]="'land-column-details-model-type'" class="title-label"></p>
            <clr-tooltip class="tooltip-container">
              <clr-icon shape="help" clrTooltipTrigger></clr-icon>
              <clr-tooltip-content [clrSize]="'lg'">
                <span>If model type "category" is selected, the data type will automatically be changed to STRING</span>
              </clr-tooltip-content>
            </clr-tooltip>
          </div>
          <clr-select-container style="margin-top: 0">
            <select
              clrSelect
              name="options"
              [(ngModel)]="column.column_model_type"
              [disabled]="!column.column_model_type_override"
              (change)="checkColumnModelType()"
            >
              <option
                *ngFor="let modelType of columnModelTypesOptions"
                [value]="modelType"
              >
                {{ modelType }}
              </option>
            </select>
          </clr-select-container>
        </div>
        <div class="clr-col-1 override-container">
          <p [translate]="'land-column-details-model-type-override'" class="title-label"></p>
          <clr-checkbox-wrapper>
            <input
              type="checkbox"
              clrCheckbox
              name="column_override"
              value="column_override"
              [(ngModel)]="column.column_model_type_override"
            />
          </clr-checkbox-wrapper>
        </div>
      </div>
      <div class="clr-row padded-sub-row">
        <div class="clr-col-7 first-col">
          <p [translate]="'land-table-details-table-description'" class="title-label"></p>
        </div>
         <!-- Removing for now the translate button until story is added -->
        <!-- <div
          class="clr-col-3"
          style="
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          "
        >
          <button
            class="btn btn-sm btn-link"
            [translate]="'table-details-translate-btn'"
            style="margin-bottom: 0"
            disabled
          ></button>
        </div> -->
      </div>
      <div class="clr-row padded-row">
        <div class="clr-col-10 first-col">
          <app-text-editor
            [model]="column.column_description"
            [editable]="editable"
            style="width: 97%"
          ></app-text-editor>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="add-colum-container padded-container"
  *ngIf="state === stateEnum.ADD"
  data-cy="add-column-detail-container"
>
  <div class="clr-row">
    <div class="clr-col">
      <div class="clr-row padded-row">
        <div class="clr-col-3 first-col">
          <span [translate]="'land-column-details-col-name'" class="title-label"></span>
          <clr-tooltip class="tooltip-container">
            <clr-icon shape="help" clrTooltipTrigger></clr-icon>
            <clr-tooltip-content [clrSize]="'lg'">
              <span>Column name can not include spaces or any of these reserved words:</span>
              <div class="reserved-words">
                <span class="reserved-word" *ngFor="let word of reservedWords"> {{word}}</span>
              </div>
            </clr-tooltip-content>
          </clr-tooltip>
          <clr-input-container style="margin-top: 0">
            <input
              clrInput
              [(ngModel)]="column.column_model_name"
              class="column_name"
              name="column_name"
            />
          </clr-input-container>
          <div class="validation" *ngIf="!validateColumnName()">
            <span class="invalid-table" >Invalid column name</span>
          </div>
        </div>
        <div class="clr-col-3">
          <clr-select-container style="margin-top: 0">
            <label
              [translate]="'land-column-details-data-type'"
              class="select-label"
            ></label>
            <select
              clrSelect
              name="dataType_options"
              [(ngModel)]="column.column_data_type"
              [disabled]="column.column_model_type === columnModelTypes.CATEGORY"
            >
              <option
                *ngFor="let dataType of columnDataTypesOptions"
                [value]="dataType"
              >
                {{ dataType }}
              </option>
            </select>
          </clr-select-container>
        </div>
        <div class="clr-col-3">
          <div class="model-type-header">
            <p [translate]="'land-column-details-model-type'" class="title-label"></p>
            <clr-tooltip class="tooltip-container">
              <clr-icon shape="help" clrTooltipTrigger></clr-icon>
              <clr-tooltip-content [clrSize]="'lg'">
                <span>If model type "category" is selected, the data type will automatically be changed to STRING</span>
              </clr-tooltip-content>
            </clr-tooltip>
          </div>
          <clr-select-container style="margin-top: 0">
            <select
              clrSelect
              name="options"
              [(ngModel)]="column.column_model_type"
              (change)="checkColumnModelType()"
            >
              <option
                *ngFor="let modelType of columnModelTypesOptions"
                [value]="modelType"
              >
                {{ modelType }}
              </option>
            </select>
          </clr-select-container>
        </div>
      </div>
      <div class="clr-row padded-sub-row">
        <div class="clr-col-5 first-col">
          <p [translate]="'land-table-details-table-description'" class="title-label"></p>
        </div>

      </div>
      <div class="clr-row padded-row">
        <div class="clr-col-8 first-col">
          <app-text-editor
            [model]="column.column_description"
            [editable]="editable"
            style="width: 97%"
          ></app-text-editor>
        </div>
      </div>
    </div>
  </div>
</div>
