import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RoleModel } from '../components/common/models/role.model';


export interface MetadataRoleStateModel {
  roles: RoleModel[];
}

export class MetadataRoleLoaded {
  static readonly type = '[MetadataRole] loaded';

  constructor(public roles: RoleModel[]) {
  }
}

@State<MetadataRoleStateModel>({
  name: 'metadataRoleState',
  defaults: { roles: [] }
})
@Injectable()
export class MetadataRoleState {

  constructor() {
  }

  @Selector()
  static roles(state: MetadataRoleStateModel) {
    return state.roles;
  }

  @Action(MetadataRoleLoaded)
  loaded(ctx: StateContext<MetadataRoleStateModel>, action: MetadataRoleLoaded) {

    ctx.patchState({
      roles: action.roles
    });

  }
}
