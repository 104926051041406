import { AuthService } from '../../../services/auth.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { endpoints } from '../../../utils/endpoints';
import { metadata, MetadataService } from '../../../services/metadata.service';
import { LanguageModel } from '../models/language.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  selectedDomain;
  selectedDepartment;
//  currentDomain;
  departments;
  domains;
  error = '';
  loading;
  loadingUser;
  metadata: any = metadata;
  model = {
    language_id: '',
    profile_language_id: '',
    mobile: '',
    name_first: '',
    name_last: '',
    update_user: null
  };
  siteData = {
    active: [],
    default: ''
  };
  user;
  public languages: LanguageModel[];

  constructor(public mdSvc: MetadataService, public authSvc: AuthService) {
  }

  @Output() navUpdateDisplay = new EventEmitter();

  // *** BASIC ***

  ngOnInit() {
    this.authSvc.sharedLoadingStatus.subscribe(state => {
      this.loadingUser = state;
    });
    this.loading = true;
    this.setUser();
    this.getLanguages();
  }

  resetModel() {
    this.model = {
      language_id: '',
      profile_language_id: '',
      mobile: '',
      name_first: '',
      name_last: '',
      update_user: null
    };
  }

  setUser() {
    this.user = JSON.parse(localStorage.getItem('user'));
  }

  logModel() {
    console.log('Model: ', this.model, 'selectedDomain: ', this.selectedDomain);
  }

  // *** INITIALIZE ***

  // Pre-populate model data based on user object
  initModel() {
    this.selectedDomain = this.initSelectedDomain();
    this.selectedDepartment = this.metadata.authState.department.department_id;
    this.model.profile_language_id = this.user.profile_language_id;
    this.model.language_id = this.user.language_id;
    this.model.mobile = this.user.mobile;
    this.model.name_first = this.user.name_first;
    this.model.name_last = this.user.name_last;
    this.model.update_user = this.user;
    this.initMetadata();
  }

  // Fill out/clean component vars for template display
  initMetadata() {
    // this.setCurrentDomain();
    this.getDepartments();
    this.domains = this.initDomainsList();
  }

  // Remove the 'common' domain from the list of domains (user should not be able to select it)
  initDomainsList() {
    const domainsList = this.metadata.authState.domains.filter((domain) => {
      return domain.domain.domain_id !== 'common';
    });
    console.log('domainsList: ', domainsList);
    return domainsList;
  }

  // Iterate through user domains to find which is the primary
  initSelectedDomain() {
    let foundDomain;
    foundDomain = this.metadata.authState.domains.find((domain) => {
      return domain.primary;
    });
    return foundDomain.domain.domain_id;
  }

  // Sort departments alphabetically, stick the user's default department at the top
  sortDepartments() {
    const allDepartments = metadata.departments;
    const sortedDepartments = allDepartments.filter((department) => {
      return department.department_id !== 'default';
    });
    const defaultDepartment = allDepartments.find((department) => {
      return department.department_id === 'default';
    });
    sortedDepartments.unshift(defaultDepartment);
    return sortedDepartments;
  }

  // *** UPDATE ***

  // Fires on successful user update, accounts for language change to re-fetch data
  refreshMetadata(reqBody) {
    this.authSvc.getCurrentUser(reqBody.user_id);
    this.updateParentDisplay('profileConfirm');
  }

  // *** CLEAN ***

  // Clean request body for put call to update user object
  createUserRequestBody() {
    const reqBody = this.user;
    reqBody.language_id = this.model.language_id;
    reqBody.profile_language_id = this.model.profile_language_id;
    // this.model.mobile && (reqBody.mobile = this.model.mobile);
    // this.model.name_first && (reqBody.name_first = this.model.name_first);
    // this.model.name_last && (reqBody.name_last = this.model.name_last);
    return reqBody;
  }

  // Clean request body for put call to update authentication object
  createAuthRequestBody() {
    const reqBody = metadata.authState;
    reqBody.language_id = this.model.language_id;
    reqBody.domains = this.updatePrimaryDomain();
    reqBody.department = this.updateDepartment();
    return reqBody;
  }

  updateDepartment() {
    return this.departments.find((department) => {
      return department.department_id === this.selectedDepartment;
    });
  }

  // Iterate through domains to and set the selected domain to default
  // Set all domains to false first and then find the primary selected domain
  // Add the common domain back into the list
  updatePrimaryDomain() {
    const falseDomains = this.domains.map((domain) => {
      domain.primary = false;
      return domain;
    });
    const updatedDomains = falseDomains.map((domain) => {
      if (domain.domain.domain_id === this.selectedDomain) {
        domain.primary = true;
      }
      return domain;
    });
    const commonDomain = this.metadata.authState.domains.find((domain) => {
      return domain.domain.domain_id === 'common';
    });
    updatedDomains.unshift(commonDomain);
    return updatedDomains;
  }

  // *** HTTP ***

  // Fetch list of available app languages (displays in language select dropdown)
  getLanguages() {
    return this.mdSvc.getSentinelLanguages(this.user.email).subscribe((languages: LanguageModel[]) => {
      this.languages = languages;
    }, (error) => {
      this.error = error.message;
      console.log('Error: ', error);
    }, () => {
      this.initModel();
    });
  }

  getDepartments() {
    return this.mdSvc.getMetadata(`${ endpoints.departments }/${ this.user.language_id }?sentinel_support=true`).subscribe((res) => {
      metadata.departments = res.departments;
    }, (error) => {
      this.error = error.message;
      console.log('Error: ', error);
    }, () => {
      this.departments = this.sortDepartments();
      this.loading = false;
    });
  }

  // Put service call to update user
  updateUser() {
    this.loading = true;
    this.authSvc.dataIsLoading();
    const reqBody = this.createUserRequestBody();
    console.log('User reqBody: ', reqBody);
    // tslint:disable-next-line:max-line-length
    return this.mdSvc.putUser(`${ endpoints.users }/${ this.user.user_id }?login_user_id=${ this.user.user_id }`, reqBody).subscribe(() => {
    }, (error) => {
      this.error = error.message;
      this.loading = false;
      console.log('Error: ', error);
    }, () => {
      this.refreshMetadata(reqBody);
    });
  }

  // Put service call to update authorization
  updateAuthorization() {
    this.loading = true;
    this.authSvc.dataIsLoading();
    const reqBody = this.createAuthRequestBody();
    console.log('Auth Reqbody: ', reqBody);
    return this.mdSvc.putUser(`${ endpoints.userAuth }/${ this.model.language_id }/${ this.user.user_id }?login_user_id=${ this.user.user_id }`, reqBody).subscribe(() => {
    }, (error) => {
      this.error = error.message;
      this.loading = false;
      console.log('Error: ', error);
    }, () => {
      this.updateUser();
    });
  }

  // *** SHARE ***

  // Clear component vars and update main nav with new display mode
  updateParentDisplay($event) {
    this.resetModel();
    this.navUpdateDisplay.emit($event);
  }

}
