<ng-container *ngIf="!loading && !this.error.length">
  <clr-modal [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'xl'">
    <div class="modal-title">
      <div style="display: flex; justify-content: space-between;">
        <p class="profile-title" data-cy="profile-header" [translate]="'profile-title'"></p>
        <clr-icon shape="times" size="24" class="x-icon" (click)="updateParentDisplay('root')"></clr-icon>
      </div>
    </div>
    <div class="modal-body">
      <div class="clr-row">
        <div class="clr-col-5">
          <div class="profile-subtitle">
            <clr-icon shape="user" size="34" class="subtitle-icon"></clr-icon>
            <span>&nbsp;</span>
            <span [translate]="'profile-subtitle-user'"></span>
          </div>
          <p class="p4 profile-custom-label" [translate]="'profile-label-email'"></p>
          <span class="profile-custom-body" data-cy="profile-email">{{ user.email }}</span>
          <p class="p4 profile-custom-label" [translate]="'profile-label-role'" data-cy="profile-role"></p>
          <ng-container *ngFor="let domain of domains;">
            <p class="profile-custom-body" *ngIf="domain?.role"><strong>{{ domain.domain.domain_name }}:</strong>
              <span *ngIf="domain.role">{{ domain?.role?.role_name }}</span>
            </p>
            <div class="security-groups">
              <span class="group" *ngFor="let group of domain.groups"> {{ group.group_name }}</span>
            </div>
          </ng-container>
          <form clrForm #firstname="ngForm" class="profile-form clr-form-vertical">
            <clr-input-container>
              <label for="firstname" class="clr-col-12 clr-col-xl profile-label" [translate]="'profile-label-name-first'"></label>
              <input clrInput name="firstname" [(ngModel)]="model.name_first" class="profile-input"
                data-cy="profile-firstname" />
              <clr-control-helper class="profile-helper">
                <span [translate]="'profile-helper-name-first'"></span>
              </clr-control-helper>
            </clr-input-container>
          </form>
          <form clrForm #lastname="ngForm" class="profile-form">
            <clr-input-container>
              <label for="lastname" class="clr-col-12 clr-col-xl profile-label" [translate]="'profile-label-name-last'"></label>
              <input clrInput name="lastname" [(ngModel)]="model.name_last" class="profile-input"
                data-cy="profile-lastname" />
              <clr-control-helper class="profile-helper">
                <span [translate]="'profile-helper-name-last'"></span>
              </clr-control-helper>
            </clr-input-container>
          </form>
          <form clrForm #mobile="ngForm" class="profile-form">
            <clr-input-container>
              <label for="mobile" class="clr-col-12 clr-col-xl profile-label" [translate]="'profile-label-mobile'"></label>
              <input clrInput name="mobile" [(ngModel)]="model.mobile" class="profile-input" data-cy="profile-mobile"
                [value]="model.mobile" />
              <clr-control-helper class="profile-helper">
                <span [translate]="'profile-helper-mobile'"></span>
              </clr-control-helper>
            </clr-input-container>
          </form>
        </div>
        <div class="clr-col-1"></div>
        <div class="clr-col-5">
          <div class="profile-subtitle">
            <clr-icon shape="world" size="34" class="subtitle-icon"></clr-icon>
            <span>&nbsp;</span>
            <span [translate]="'profile-subtitle-site'"></span>
          </div>
          <div *ngFor="let domain of domains">
            <span class="p4 profile-sites-label">{{domain.domain.domain_name}}:</span>
            <span
            data-cy="profile-activesites"
            *ngFor="let site of domain.sites"
            class="profile-sites"
            [ngClass]="{'primary': site.primary }"
            >{{ site.site.site_name }}</span>
          </div>
<!--          <p class="p4 profile-custom-label" [translate]="'profile-label-site-primary'"></p>-->
<!--          <span class="profile-custom-body">{{ siteData.default }}</span>-->
<!--          <p class="p4 profile-custom-label" [translate]="'profile-label-site-active'" data-cy="profile-primarysite"></p>-->
<!--          <ng-container *ngFor="let site of siteData.active; let last = last;"><span class="profile-custom-body" data-cy="profile-activesites">{{ site }}</span><span class="profile-custom-body">{{ last ? '' : ', ' }}</span></ng-container>-->
          <form clrForm #department="ngForm" class="profile-form">
            <clr-select-container>
              <label for="department" class="clr-col-12 clr-col-xl profile-label" [translate]="'profile-label-department'"></label>
              <select clrSelect name="department" [(ngModel)]="selectedDepartment" class="profile-dropdown"
                data-cy="profile-department" [ngClass]="{'error-container': selectedDepartment === 'default'}">
                <option *ngFor="let department of departments; let i = index;" [ngValue]="department.department_id"
                  [disabled]="i === 0">
                  {{ department.department_name }}
                </option>
              </select>
              <clr-control-helper class="profile-helper">
                <span [translate]="'profile-helper-department'"></span>
              </clr-control-helper>
            </clr-select-container>
            <span
              *ngIf="selectedDepartment === 'default'"
              [translate]="'profile-error-department'"
              class="error-message"></span>
          </form>
          <div class="profile-subtitle">
            <clr-icon shape="cog" size="34" class="subtitle-icon"></clr-icon>
            <span>&nbsp;</span>
            <span [translate]="'profile-subtitle-settings'"></span>
          </div>
          <form clrForm #language="ngForm" class="profile-form" *ngIf="metadata.languagesUx">
            <clr-select-container>
              <label for="language" class="clr-col-12 clr-col-xl profile-label" [translate]="'profile-label-language'"></label>
              <select clrSelect name="language" [(ngModel)]="model.profile_language_id" class="profile-dropdown"
                data-cy="profile-language">
                <option *ngFor="let language of languages; let i = index;" [ngValue]="language.language_id">
                  {{ language.language_name }}
                </option>
              </select>
              <clr-control-helper class="profile-helper">
                <span [translate]="'profile-helper-language'"></span>
              </clr-control-helper>
            </clr-select-container>
          </form>
          <form clrForm #domain="ngForm" class="profile-form" *ngIf="domains">
            <clr-select-container>
              <label for="domain" class="clr-col-12 clr-col-xl profile-label" [translate]="'profile-label-default-app'"></label>
              <select clrSelect name="domain" [(ngModel)]="selectedDomain" class="profile-dropdown" data-cy="profile-domain">
                <option *ngFor="let domain of domains; let i = index;" [ngValue]="domain.domain.domain_id">
                  {{ domain.domain.domain_name }}
                </option>
              </select>
            </clr-select-container>
          </form>
        </div>
        <div class="clr-col-1"></div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="updateParentDisplay('root')" data-cy="profile-return">
        <span [translate]="'submodal-btn-return'"></span>
      </button>
      <button type="button" class="btn btn-primary minibtn-primary" (click)="updateAuthorization()"
        [disabled]="(selectedDepartment === 'default') || (selectedDepartment === '') || (model.language_id === '')"
        data-cy="profile-submit">
        <span [translate]="'profile-btn-update'"></span>
      </button>
    </div>
  </clr-modal>
</ng-container>

<ng-container *ngIf="this.error.length">
  <clr-modal [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'md'">
    <div class="modal-body">
      <h3 class="modal-title" [translate]="'submodal-title-error'"></h3>
      <p class="p1" [translate]="'feedback-error-body'"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary minibtn-primary" (click)="updateParentDisplay('root')">
        <span [translate]="'submodal-btn-return'"></span>
      </button>
    </div>
  </clr-modal>
</ng-container>

<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>
