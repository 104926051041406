<div *ngIf="!saving" class="content-container">
  <div class="clr-row">
    <div class="clr-col">
      <div class="legend">
        <small><span [translate]="'metadata-required-field-legend'">* required field</span></small>
      </div>
    </div>
  </div>
  <clr-accordion [clrAccordionMultiPanel]="true">
    <clr-accordion-panel [clrAccordionPanelDisabled]="true" [clrAccordionPanelOpen]="true">
      <clr-accordion-title>
        <span *ngIf="intakeType === IntakeFormType.initiative" [translate]="'intake-form-project-information'"></span>
        <span *ngIf="intakeType === IntakeFormType.bug" [translate]="'intake-forms-home-bug-report'"></span>
        <span *ngIf="intakeType === IntakeFormType.user_story"
          [translate]="'intake-forms-home-enhancement-request'"></span>
      </clr-accordion-title>
      <clr-accordion-content>
        <ng-container *ngIf="intakeType === IntakeFormType.initiative">
          <div class="clr-row intake-row-mt intake-row-mb">
            <div class="clr-col">
              <label for="input-project-title" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-project-title'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-project-title'"></span>
                  <span>:</span>
                </p>
              </label>
              <form clrForm>
                <clr-input-container class="form">
                  <input id="input-project-title" clrInput [placeholder]="'intake-forms-project-title' | translate"
                    [(ngModel)]="model.title" name="title" class="intake-form-input-width" [disabled]="loading"
                    required />
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-input-container>
              </form>
            </div>

            <div class="clr-col">
              <label for="input-functional-area" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-functional-area'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-functional-area'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-select-container class="form">
                  <select id="input-functional-area" clrSelect name="functional_area"
                    [(ngModel)]="model.project.functional_area_type" [disabled]="loading" required>
                    <option ngValue="mining-osn">
                      <span [translate]="'intake-forms-func-area-mining-osn'"></span>
                    </option>
                    <option ngValue="processing_osn">
                      <span [translate]="'intake-forms-func-area-processing-osn'"></span>
                    </option>
                    <option ngValue="fixed_asset_mobile_osn">
                      <span [translate]="'intake-forms-func-area-fixed-mobile-osn'"></span>
                    </option>
                    <option ngValue="full-potential">
                      <span [translate]="'intake-forms-func-area-full-potential'"></span>
                    </option>
                    <option ngValue="platform_and_infrastructure">
                      <span [translate]="'intake-forms-func-area-platf-infra'"></span>
                    </option>
                    <option ngValue="other">
                      <span [translate]="'intake-forms-func-area-other'"></span>
                    </option>
                  </select>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-select-container>
              </form>
            </div>
          </div>

          <div class="clr-row intake-row-mt">
            <div class="clr-col">
              <label for="input-target-audience" style="cursor: pointer;">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-target-audience'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-target-audience'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-input-container class="form">
                  <input id="input-target-audience" clrInput [placeholder]="'intake-forms-target-audience' | translate"
                    [(ngModel)]="model.project.audience" name="audience" [disabled]="loading"
                    class="intake-form-input-width" required />
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-input-container>
              </form>
            </div>

            <div class="clr-col">
              <label for="input-start-date" class="intake-inputs-pointer">
                <p class="p4 form-heading">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-desired-timeline'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-desired-timeline'"></span>
                  <span>:</span>
                </p>
              </label>

              <div class="clr-row intake-date-container">
                <div class="clr-col-3">
                  <clr-date-container class="date-range-container">
                    <label [translate]="'intake-forms-timeline-start-date'"></label>
                    <input id="input-start-date" type="date" clrDate name="startDate" [disabled]="loading"
                      [(ngModel)]="model.desired_start_date" (ngModelChange)="desiredDateChange()"
                      [max]="formattedEndDate" class="intake-form-input-width">
                  </clr-date-container>
                </div>
                <div class="clr-col-3">
                  <clr-date-container class="date-range-container">
                    <label [translate]="'intake-forms-timeline-end-date'"></label>
                    <input type="date" clrDate name="endDate" [disabled]="loading" [(ngModel)]="model.desired_end_date"
                      (ngModelChange)="desiredDateChange()" [min]="formattedStartDate" class="intake-form-input-width">
                  </clr-date-container>
                </div>
              </div>
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-problem-statement" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content" [translate]="'intake-tooltip-problem-statement'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-problem-statement'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-problem-statement" clrTextarea [(ngModel)]="model.description"
                    [placeholder]="'intake-forms-problem-statement' | translate" name="description"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>

          <div class="clr-row intake-row-mt">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-proposed-solution" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content" [translate]="'intake-tooltip-proposed-solution'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-proposed-solution'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-proposed-solution" clrTextarea [(ngModel)]="model.project.solution"
                    [placeholder]="'intake-forms-proposed-solution' | translate" name="proposed-solution"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>

          <div class="clr-row intake-row-mt intake-row-mb">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-benefits-and-justification" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content"
                            [translate]="'intake-tooltip-benefits-and-justification'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-benefits-and-justification'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-benefits-and-justification" clrTextarea [(ngModel)]="model.impact"
                    [placeholder]="'intake-forms-benefits-and-justification' | translate" name="impact"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="intakeType === IntakeFormType.bug">
          <div class="clr-row intake-row-mt intake-row-mb" style="align-items: center;">
            <div class="clr-col-3">
              <label for="input-project-name" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-project-name'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-project-name'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-select-container class="form">
                  <select id="input-project-name" clrSelect style="width: 100%;" name="project_name" [(ngModel)]="model.project_name"
                    [disabled]="loading || !projects.length" required>
                    <option *ngFor="let project of projects" [ngValue]="project">
                      {{ project }}
                    </option>
                  </select>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-select-container>
              </form>
            </div>

            <div class="clr-col-4">
              <div class="alert alert-info" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                      <span [translate]="'intake-forms-project-name-info'" class="alert-text"></span>
                    </div>
                </div>
              </div>
            </div>

            <div class="clr-offset-1 clr-col-4">
              <label for="input-project-title" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-bug-title'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-bug-title'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-input-container class="form">
                  <input id="input-project-title" clrInput [placeholder]="'intake-forms-bug-title' | translate"
                    [(ngModel)]="model.title" name="title" class="intake-form-input-width" [disabled]="loading"
                    required />
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-input-container>
              </form>
            </div>
          </div>

          <div class="clr-row intake-row-mt intake-row-mb">
            <div class="clr-col-3">
              <label for="input-bug-frequency-type" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-reproducible-or-intermittent'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-reproducible-or-intermittent'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-select-container class="form">
                  <select id="input-bug-frequency-type" style="width: 100%;" clrSelect name="frequency_type"
                    [(ngModel)]="model.bug.frequency_type" [disabled]="loading" required>
                    <option ngValue="constant">
                      <span [translate]="'intake-forms-bug-freq-type-constant'"></span>
                    </option>
                    <option ngValue="intermittent">
                      <span [translate]="'intake-forms-bug-freq-type-intermittent'"></span>
                    </option>
                  </select>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-select-container>
              </form>
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-bug-description" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content" [translate]="'intake-tooltip-bug-description'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-bug-description'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-bug-description" clrTextarea [(ngModel)]="model.description"
                    [placeholder]="'intake-forms-bug-description' | translate" name="description"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="input-bug-reproduce" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content" [translate]="'intake-tooltip-bug-reproduce'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-steps-to-reproduce'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="input-bug-reproduce" clrTextarea [(ngModel)]="model.bug.reproduce"
                    [placeholder]="'intake-forms-steps-to-reproduce' | translate" name="reproduce"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>

          <div class="clr-row intake-row-mt intake-row-mb">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-impact" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content" [translate]="'intake-tooltip-bug-impact'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-bug-impact'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-impact" clrTextarea [(ngModel)]="model.impact"
                    [placeholder]="'intake-forms-bug-impact' | translate" name="impact"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="intakeType === IntakeFormType.user_story">
          <div class="clr-row intake-row-mt intake-row-mb" style="align-items: center;">
            <div class="clr-col-3">
              <label for="input-enhance-project-name" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-enhancement-project-name'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-project-name'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-select-container class="form">
                  <select id="input-enhance-project-name" clrSelect style="width: 100%;" name="project_name" [(ngModel)]="model.project_name"
                    [disabled]="loading" required>
                    <option *ngFor="let project of projects" [ngValue]="project">
                      {{ project }}
                    </option>
                  </select>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-select-container>
              </form>
            </div>

            <div class="clr-col-4">
              <div class="alert alert-info" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                      <span [translate]="'intake-forms-project-name-info'" class="alert-text"></span>
                    </div>
                </div>
              </div>
            </div>

            <div class="clr-offset-1 clr-col-4">
              <label for="input-project-title" class="intake-inputs-pointer">
                <p class="p4 form-heading required">
                  <span>
                    <a role="tooltip" aria-haspopup="true"
                      class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                      <clr-icon shape="info-circle" size="24"></clr-icon>
                      <span class="tooltip-content" [translate]="'intake-tooltip-enhancement-request-title'"></span>
                    </a>
                  </span>
                  <span [translate]="'intake-forms-enhancement-title'"></span>
                  <span>:</span>
                </p>
              </label>

              <form clrForm>
                <clr-input-container class="form">
                  <input id="input-project-title" clrInput [placeholder]="'intake-forms-enhancement-title' | translate"
                    [(ngModel)]="model.title" name="title" class="intake-form-input-width" [disabled]="loading"
                    required />
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-input-container>
              </form>
            </div>
          </div>

          <div class="clr-row">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-enhancement-description" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content" [translate]="'intake-tooltip-enhancement-description'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-enhancement-description'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-enhancement-description" clrTextarea [(ngModel)]="model.description"
                    [placeholder]="'intake-forms-enhancement-description' | translate" name="description"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>

          <div class="clr-row intake-row-mt intake-row-mb">
            <div class="clr-col">
              <form clrForm>
                <clr-textarea-container>
                  <label for="textarea-impact" class="intake-inputs-pointer">
                    <p class="p4 form-heading required">
                      <span>
                        <a role="tooltip" aria-haspopup="true"
                          class="tooltip tooltip-lg tooltip-bottom-right intake-form-input-tooltip">
                          <clr-icon shape="info-circle" size="24"></clr-icon>
                          <span class="tooltip-content"
                            [translate]="'intake-tooltip-benefits-and-justification'"></span>
                        </a>
                      </span>
                      <span [translate]="'intake-forms-benefits-impact-justification'"></span>
                      <span>:</span>
                    </p>
                  </label>
                  <textarea id="textarea-impact" clrTextarea [(ngModel)]="model.impact"
                    [placeholder]="'intake-forms-benefits-impact-justification' | translate" name="impact"
                    style="width: -webkit-fill-available;" [disabled]="loading" required></textarea>
                  <clr-control-error [translate]="'datatool-tree-required-field'"></clr-control-error>
                </clr-textarea-container>
              </form>
            </div>
          </div>
        </ng-container>
      </clr-accordion-content>
    </clr-accordion-panel>

    <clr-accordion-panel [clrAccordionPanelDisabled]="true" [clrAccordionPanelOpen]="true">
      <clr-accordion-title>
        <span [translate]="'intake-forms-enhancement-attachments'"></span>
      </clr-accordion-title>
      <clr-accordion-content>
        <div class="clr-row">
          <div class="clr-col">
            <p *ngIf="intakeType === IntakeFormType.initiative" class="p4 form-heading">
              <span>
                <a role="tooltip" aria-haspopup="true"
                  class="tooltip tooltip-lg tooltip-right intake-form-input-tooltip">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content" [translate]="'intake-tooltip-attachment-project-idea'"></span>
                </a>
              </span>
              <span [translate]="'intake-forms-supporting-documentation'"></span>
              <span>:</span>
            </p>

            <p *ngIf="intakeType === IntakeFormType.bug" class="p4 form-heading">
              <span>
                <a role="tooltip" aria-haspopup="true"
                  class="tooltip tooltip-lg tooltip-right intake-form-input-tooltip">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content" [translate]="'intake-tooltip-bug-attachments'"></span>
                </a>
              </span>
              <span [translate]="'intake-forms-attachments-screenshots'"></span>
              <span>:</span>
            </p>

            <p *ngIf="intakeType === IntakeFormType.user_story" class="p4 form-heading">
              <span>
                <a role="tooltip" aria-haspopup="true"
                  class="tooltip tooltip-lg tooltip-right intake-form-input-tooltip">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content" [translate]="'intake-tooltip-enhancement-attachments'"></span>
                </a>
              </span>
              <span [translate]="'intake-forms-enhancement-attachments'"></span>
              <span>:</span>
            </p>
          </div>
        </div>
        <div class="clr-row">
          <div class="clr-col">
            <app-intake-attachments [datasource]="model"></app-intake-attachments>
          </div>
        </div>
      </clr-accordion-content>
    </clr-accordion-panel>
  </clr-accordion>
</div>

<div class="modal-footer intake-action-buttons">
  <button type="button" class="btn btn-outline" (click)="close()" data-cy="metadata-close">
    <span [translate]="'submodal-btn-close'"></span>
  </button>

  <button (click)="submitForm()" [disabled]="!isModelValid() || saving" type="button" class="btn btn-primary"
    data-cy="metadata-submit">
    <span [translate]="'datatool-datasource-btn-save'"></span>
  </button>
</div>

<div *ngIf="saving" class="loading-body">
  <span class="spinner"></span>
</div>