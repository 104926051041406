import {Component, EventEmitter, Input, NgZone, Output} from '@angular/core';
import {BaseComponent} from '../base-component';
import {Router} from '@angular/router';
import {ReportingMonthModel} from '../models/reporting-month.model';
import * as moment from 'moment';
import {MetadataService} from '../../../services/metadata.service';
import {LogService} from '../../../services/log.service';

@Component({
  selector: 'app-reporting-month-picker',
  templateUrl: './reporting-month-picker.component.html',
  styleUrls: ['./reporting-month-picker.component.css']
})
export class ReportingMonthPickerComponent extends BaseComponent {

  public showPicker: boolean;
  public reportingMonths: ReportingMonthModel[];
  public monthMap: any;
  public currentReportingMonth: ReportingMonthModel;
  public year: number;
  public month: number;
  public presentMonth: number;
  public presentYear: number;
  public years: number[];
  public selectedReportingMonth: ReportingMonthModel;

  @Input()
  public validateDomain: boolean;

  @Input()
  public isModal: boolean;

  @Output() selected = new EventEmitter();

  public constructor(protected router: Router,
                     private metadataService: MetadataService, private zone: NgZone,
                     protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.initMonthMap();
    const currentDate = moment();
    this.reportingMonths = [];
    if (!this.isModal) {
      if (currentDate.month() === 0) {
        this.presentMonth = 12;
        this.presentYear = currentDate.year() - 1;
      } else {
        this.presentMonth = currentDate.month();
        this.presentYear = currentDate.year();
      }

      this.year = this.presentYear;
      this.month = this.presentMonth;

    } else {
      this.presentMonth = currentDate.month() + 1;
      this.presentYear = currentDate.year();
      this.year = this.presentYear;
      this.month = this.presentMonth;
    }
    this.initYears();
  }

  private initYears(): void {
    this.years = [];
    for (let y = this.presentYear + 5; y--; y >= 2015) {
      this.years.push(y);
    }
  }

  private initMonthMap(): void {
    this.monthMap = {};
    for (let x = 1; x <= 12; x++) {
      this.monthMap[x] = undefined;
    }
  }

  protected setUser(user: any): void {
    this.showPicker = false;
    if (!this.isModal) {
      this.getReportingMonths();
    }
  }

  public displayPicker(): void {
    this.showPicker = true;
  }

  public close(): void {
    this.showPicker = false;
  }

  @Input()
  set datasource(reportingMonth: ReportingMonthModel) {
    if (!reportingMonth) {
      return;
    }

    this.currentReportingMonth = reportingMonth;
    this.zone.run(() => {
      this.month = this.currentReportingMonth.reporting_month;
      this.year = this.currentReportingMonth.reporting_year;
      this.getReportingMonths();
    });
  }

  private createMonthMap(): void {
    this.initMonthMap();
    if (!this.reportingMonths) {
      return;
    }
    const currentDomain = this.getCurrentDomain();
    const yearMonths = this.reportingMonths.filter(x => x.reporting_year === this.year);
    for (let x = 1; x <= 12; x++) {
      const reportingMonth = yearMonths.find(rm => rm.reporting_month === x);
      let valid = false;
      if (reportingMonth) {
        if (this.validateDomain === false) {
          valid = true;
        } else {
          const domain = reportingMonth.domains.find(y => y.domain.domain_id === currentDomain.domain.domain_id);
          if (domain && domain.enabled === true) {
            valid = true;
          }
        }
      } else {
        if (this.validateDomain === false) {
          valid = true;
        }
      }
      this.monthMap[x] = valid ? yearMonths.find(rm => rm.reporting_month === x) : undefined;
    }
  }

  private getReportingMonths(): void {
    this.initMonthMap();
    this.reportingMonths = [];
    this.metadataService.getReportingMonthsByYear(this.user.language_id, this.year).subscribe((results: ReportingMonthModel[]) => {
      this.reportingMonths = results.sort(
        (a: ReportingMonthModel, b: ReportingMonthModel) =>
          a.reporting_month < b.reporting_month ? -1 : a.reporting_month > b.reporting_month ? 1 : 0);
      this.createMonthMap();

      if (!this.isModal) {
        this.monthChanged();
      }
    }, (() => {
      this.reportingMonths = [];
      this.createMonthMap();
    }));
  }

  public previousYear(): void {
    this.year--;
    this.getReportingMonths();
  }

  public nextYear(): void {
    this.year++;
    this.getReportingMonths();
  }

  public yearChanged(): void {
    this.getReportingMonths();
    this.monthChanged();
  }

  public monthChanged(): void {
    this.selectedReportingMonth = this.reportingMonths.find(x => x.reporting_year === this.year && x.reporting_month === this.month);
    this.selected.emit(this.selectedReportingMonth);
  }

  public selectDate(reportingMonth: ReportingMonthModel): void {
    if (!reportingMonth) {
      return;
    }
    this.showPicker = false;
    this.selected.emit(reportingMonth);
  }

}
