<div class="content-container logout-component">
  <div class="content-area ">
    <div class="clr-row">
      <div class="clr-col-4"></div>
      <div class="clr-col-4">
        <div class="card login-card">
          <div class="card-block">
            <div class="card-header">
              <div class="clr-row">
                <div class="clr-col">
                  <img src="../../../assets/images/sentinel-logo-base.png" class="login-sentinel-logo"/>
                </div>
              </div>
            </div>
            <div class="card-block">
              <div class="card-text">
                <form clrForm id="loginForm" class="form-container">
                  <h4 class="h4 login-notice">You have been logged out of the Sentinel Application.</h4>
                  <button class="btn btn-primary minibtn-primary login-btn-submit" (click)="login()" data-cy="login-button">
                    Log in
                    <clr-icon shape="login" class="is-solid login-btn-icon login-btn-icon-webkit" size="24"></clr-icon>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="clr-col-4"></div>
      </div>
    </div>
  </div>
</div>
