import { DatabaseTypeModel } from '../components/common/models/datasources.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export interface MetadataDatabaseTypesStateModel {
  databaseTypes: DatabaseTypeModel[];
}

export class MetadataDatabaseTypesLoaded {
  static readonly type = '[MetadataDatabaseTypes] loaded';

  constructor(public databaseTypes: DatabaseTypeModel[]) {
  }
}

@State<MetadataDatabaseTypesStateModel>({
  name: 'metadataDatabaseTypes',
  defaults: { databaseTypes: [] }
})
@Injectable()
export class MetadataDatabaseTypeState {
  constructor() {
  }

  @Selector()
  static databaseTypes(state: MetadataDatabaseTypesStateModel) {
    return state.databaseTypes;
  }

  @Action(MetadataDatabaseTypesLoaded)
  loaded(ctx: StateContext<MetadataDatabaseTypesStateModel>, action: MetadataDatabaseTypesLoaded) {
    ctx.patchState({
      databaseTypes: action.databaseTypes
    });
  }

}
