import { Component, OnInit } from '@angular/core';
import { ConfirmButtonModel, ConfirmDialogModel, ConfirmDialogResult, ConfirmDialogTypeEnum, DisplayModalDialogRef } from 'app/components/common/models/dialog.model';
import { IntakeFormType, IntakeForms, IntakeSummaryResult } from 'app/components/common/models/intake.model';
import { UpdateUser } from 'app/components/common/models/update-user.model';
import { DataService } from 'app/services/data.service';
import { DialogService } from 'app/services/dialog.service';
import * as moment from 'moment';
import { IntakeSummaryComponent } from '../intake-summary/intake-summary.component';

@Component({
  selector: 'app-intake-forms',
  templateUrl: './intake-forms.component.html',
  styleUrls: ['./intake-forms.component.css']
})
export class IntakeFormsComponent implements OnInit {
  public saving: boolean;
  public loading: boolean;
  public model: IntakeForms;
  public IntakeFormType = IntakeFormType;
  modalRef: DisplayModalDialogRef;
  intakeType: string = "";
  intakeUser: UpdateUser;
  projects: string[] = [];

  public formattedStartDate: string;
  public formattedEndDate: string;

  public constructor(private dialogService: DialogService,
    private dataService: DataService) { }

  ngOnInit(): void {
    this.dialogService.displayModal.current.size = "xl";
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.intakeType = this.dialogService.displayModal.current.data.intake_form_type;
    this.intakeUser = JSON.parse(localStorage.getItem('intake'));

    this.model = {
      attachments: [],
      bug: {
        frequency_type: "",
        reproduce: ""
      },
      description: "",
      external_id: null,
      impact: "",
      intake_form_id: "",
      intake_form_type: this.intakeType,
      language_id: this.intakeUser?.language_id,
      project: {
        audience: "",
        functional_area_type: "",
        solution: ""
      },
      project_name: "",
      title: "",
      desired_start_date: null,
      desired_end_date: null,
      update_date: null,
      update_user: this.intakeUser ? this.intakeUser : null,
    } as IntakeForms;

    if (this.intakeType === IntakeFormType.bug || this.intakeType === IntakeFormType.user_story) {
      this.dataService.getIntakeProjects().subscribe((projects: string[]) => {
        this.projects = projects;
      }, (error) => {
        const dialogRef = this.dialogService.openConfirmDialog({
          titleTranslateId: 'metadata-load-error-title',
          type: ConfirmDialogTypeEnum.Warning,
          message: 'metadata-load-error-message',
          subMessage: error.error?.message ? error.error?.message : error.error,
          confirmButton: { show: true, textTranslateId: 'metadata-load-error-ok-button' } as ConfirmButtonModel
        } as ConfirmDialogModel);
        dialogRef.afterClosed.subscribe(() => {
          dialogRef.dialog.isOpen = false;
          this.dialogService.displayModal.current.size = "xxl";
        });
      });
    }
  }

  close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

  submitForm(): void {
    if (!this.isModelValid()) {
      return;
    }

    if (this.intakeType === IntakeFormType.initiative) {
      this.model.desired_start_date = moment(this.model.desired_start_date, "MM/DD/YYYY").toISOString();
      this.model.desired_end_date = moment(this.model.desired_end_date, "MM/DD/YYYY").toISOString();
    }
    this.model.update_date = new Date(moment().utc().toString());

    this.saving = true;
    this.dataService.saveIntakeForm(this.model).subscribe((response: IntakeSummaryResult) => {
      this.model.attachments.forEach((attachment) => {
        this.uploadFiles(response.id, attachment);
      });
      this.saving = false;
      this.close();
      const dialogRef = this.dialogService.openDialog('IntakeSummaryComponent', IntakeSummaryComponent,
        'intake-request-summary', 'lg',
        {
          results: response,
          intake_form_type: this.intakeType,
          model: this.model
        });
      dialogRef.afterClosed.subscribe((results) => {
        if (results.action === ConfirmDialogResult.Cancel) {
          return;
        }
      });
    }, (error) => {
      this.saving = false;
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'metadata-load-error-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'metadata-load-error-message',
        subMessage: error.error?.message ? error.error?.message : error.error,
        confirmButton: { show: true, textTranslateId: 'metadata-load-error-ok-button' } as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
        this.dialogService.displayModal.current.size = "xxl";
      });
    });
  }

  uploadFiles(intake_id, data) {
    this.dataService.uploadFile(intake_id, data).subscribe((response: any) => {}, (error) => {
      this.saving = false;
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'exploration-file-upload-error',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'xploration-file-upload-error-message',
        subMessage: error.error.message,
        confirmButton: { show: true, textTranslateId: 'datatools-ok-button' } as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
        this.dialogService.displayModal.current.size = "xxl";
      });
    });
  }

  isModelValid(): boolean {
    if (!this.model) {
      return false;
    }

    if (!this.model.intake_form_type) {
      return false;
    }

    switch (this.intakeType) {
      case IntakeFormType.initiative:
        return this.checkInitiativeModel();
      case IntakeFormType.bug:
        return this.checkBugModel();
      case IntakeFormType.user_story:
        return this.checkUserStoryModel();
    }
    return false;
  }

  checkInitiativeModel(): boolean {
    if (!this.model.title
      || !this.model.description
      || !this.model.project.solution
      || !this.model.project.audience
      || !this.model.impact
      || !this.model.project.functional_area_type) {
      return false;
    }
    return true;
  }

  checkBugModel(): boolean {
    if (!this.model.project_name
      || !this.model.title
      || !this.model.description
      || !this.model.bug.frequency_type
      || !this.model.bug.reproduce
      || !this.model.impact) {
      return false;
    }
    return true;
  }

  checkUserStoryModel(): boolean {
    if (!this.model.project_name
      || !this.model.title
      || !this.model.description
      || !this.model.impact
    ) {
      return false;
    }
    return true;
  }

  desiredDateChange(): void {
    // Convert and format the start and end dates using explicit formats
    this.formattedStartDate = moment(this.model.desired_start_date, 'MM/DD/YYYY').isValid()
      ? moment(this.model.desired_start_date, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';

    this.formattedEndDate = moment(this.model.desired_end_date, 'MM/DD/YYYY').isValid()
      ? moment(this.model.desired_end_date, 'MM/DD/YYYY').format('YYYY-MM-DD')
      : '';

    // Ensure the end date is not before the start date
    if (this.formattedEndDate && moment(this.formattedEndDate).isBefore(this.formattedStartDate)) {
      this.formattedEndDate = this.formattedStartDate;
      this.model.desired_end_date = moment(this.formattedEndDate).format('MM/DD/YYYY');
    }

    // Ensure the start date is not after the end date
    if (this.formattedStartDate && moment(this.formattedStartDate).isAfter(this.formattedEndDate)) {
      this.formattedStartDate = this.formattedEndDate;
      this.model.desired_start_date = moment(this.formattedStartDate).format('MM/DD/YYYY');
    }
  }
}
