import { UpdateUser } from "./update-user.model";

export interface IntakeForms {
  attachments: any[];
  bug: Bug;
  description: string;
  external_id: number;
  impact: string;
  intake_form_id: string;
  intake_form_type: string;
  language_id: string;
  project: Project;
  project_name: string;
  title: string;
  desired_start_date : string,
  desired_end_date: string,
  update_date: Date;
  update_user: UpdateUser;
}

export interface Bug {
  frequency_type: string;
  reproduce: string;
}

export interface Project {
  audience: string;
  functional_area_type: string;
  solution: string;
}

export enum IntakeFormType {
  initiative= 'initiative',
  bug = 'bug',
  user_story = 'user_story'
}

export interface IntakeSummaryResult {
  status: string;
  message: string;
  id: string;
  external_id; string;
}

// Upload File
export interface IntakeFileUploadModel {
  bytestring: string;
  file: IntakeFileModel;
}

export interface IntakeFileModel {
  file_id: string;
  file_name: string;
  file_title: string;
  file_description: string;
  file_content_type: string;
  file_extension: string;
}

export interface IntakeAttachmentModel {
  file_content_type: string;
  file_description: string;
  file_extension: string;
  file_id: string;
  file_link: string;
  file_name: string;
  file_path: string;
  file_thumbnail: string;
  file_title: string;
  file_status: IntakeAttachmentStatus;
  deleting: boolean;
}

export interface IntakeAttachmentStatus {
  status_id: string;
  status_name: string;
}
