import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {endpoints} from '../utils/endpoints';
import {
  ColumnDataModel,
  ColumnDataModelResponse,
  ColumnMapIndicator,
  DataModel,
  DataModelResponse,
  DataModelSearch,
  DatasetDataModel,
  DatasetModelResponse,
  MappingTypes,
  RichTextModel,
  TableDataModel,
  TablesDataModelResponse,
  ValueDataModel,
  ValueDataModelResponse,
} from '../components/common/models/data.model';
import {MarkdownService} from './markdown.service';
import {BaseService} from './base.service';
import {Store} from '@ngxs/store';
import {DomainsEnum} from '../components/common/models/domains.enum';

@Injectable({providedIn: 'root'})
export class DataModelService extends BaseService {

  constructor(protected http: HttpClient, protected store: Store,
              private markDownService: MarkdownService) {
    super(http, store);
  }

  private processRichText(richText: RichTextModel): void {
    if (!richText) {
      return;
    }

    if (richText.html_text?.length === 0) {
      richText.markdown_text = '';
      richText.plain_text = '';
    } else {
      richText.markdown_text = this.markDownService.convertHtmlToMarkdown(richText.html_text);
      richText.plain_text = this.markDownService.removeMarkDown(richText.markdown_text);
    }

  }

  getDataModels(domain: string): Observable<Array<DataModel>> {
    return this.http.get<DataModelResponse>(`${endpoints.common}/data_models?domain.domain_id=${domain}`, this.httpOptions).pipe(
      map((response: DataModelResponse) => {
        return response.data_models.sort((a: DataModel, b: DataModel) =>
          (a.data_model_type > b.data_model_type) ? 1 : ((b.data_model_type > a.data_model_type) ? -1 : 0));
      })
    );
  }

  getDataModel(dataModelId: string): Observable<DataModel> {
    return this.http.get<DataModel>(`${endpoints.common}/data_models/${dataModelId}`, this.httpOptions);
  }

  updateDataModel(dataModelId: string, updatedDataModel: DataModel): Observable<any> {
    return this.http.put<any>(`${endpoints.common}/data_models/${dataModelId}`, updatedDataModel, this.httpOptions);
  }

  updateDataDictionary(dataModelId: string, registrationToken: string): Observable<any> {
    return this.http.post<any>(`${endpoints.common}/data_models/dictionary/initiate`,
      {
        data_model_id: dataModelId,
        registration_token: registrationToken
      }, this.httpOptions);
  }

  addDataModel(newDataModel: DataModel): Observable<any> {
    return this.http.post<any>(`${endpoints.common}/data_models`, newDataModel, this.httpOptions);
  }

  deleteDataModel(dataModelId: string): Observable<any> {
    return this.http.delete<any>(`${endpoints.common}/data_models/${dataModelId}`, this.httpOptions);
  }

  getDatasets(): Observable<Array<DatasetDataModel>> {
    return this.http.get<DatasetModelResponse>(`${endpoints.common}/dataset_models/${this.getProfileLanguageId()}`, this.httpOptions).pipe(
      map((response: DatasetModelResponse) => {
        return response.dataset_models;
      })
    );
  }

  saveNewDataset(dataset: DatasetDataModel): Observable<any> {
    return this.http.post<DatasetDataModel>(`${endpoints.common}/dataset_models/${this.getLanguageId()}`, dataset, this.httpOptions);
  }

  deleteDataset(datasetModelId: string): Observable<any> {
    return this.http.delete<any>(`${endpoints.common}/dataset_models/${this.getLanguageId()}/${datasetModelId}`, this.httpOptions);
  }

  getTables(dataModelId: string): Observable<Array<TableDataModel>> {
    return this.http.get<TablesDataModelResponse>(`${endpoints.common}/data_models/${dataModelId}/tables`, this.httpOptions).pipe(
      map((response: TablesDataModelResponse) => {
        return response.table_models;
      })
    );
  }

  saveTable(table: TableDataModel, dataModelId: string): Observable<any> {
    this.processRichText(table.table_description);
    // tslint:disable-next-line:max-line-length
    return this.http.put<TableDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${table.table_model_id}`, table, this.httpOptions);
  }

  saveNewTable(table: TableDataModel, dataModelId: string): Observable<any> {
    this.processRichText(table.table_description);
    return this.http.post<TableDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables`, table, this.httpOptions);
  }

  deleteTable(dataModelId: string, tableId: string): Observable<any> {
    return this.http.delete<any>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}`, this.httpOptions);
  }


  getTable(dataModelId: string, tableId: string): Observable<TableDataModel> {
    return this.http.get<TableDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}`, this.httpOptions);
  }

  getColumns(dataModelId: string, tableId: string): Observable<Array<ColumnDataModel>> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ColumnDataModelResponse>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns`, this.httpOptions).pipe(
      map((response: ColumnDataModelResponse) => {
        response.column_models.forEach((column: ColumnDataModel) => {
          this.formatColumn(column);
        });
        return response.column_models;
      })
    );
  }

  ignoreColumnMapping(column: ColumnDataModel, dataModelId: string, tableId: string): Observable<any> {
    column.mapped_indicator = ColumnMapIndicator.ignored;
    this.formatColumn(column);
    return this.http.put<ColumnDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${column.column_model_id}`, column, this.httpOptions);
  }

  saveColumn(column: ColumnDataModel, dataModelId: string, tableId: string): Observable<any> {
    this.processRichText(column.column_description);
    // tslint:disable-next-line:max-line-length
    return this.http.put<ColumnDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${column.column_model_id}`, column, this.httpOptions);
  }

  saveNewColumn(column: ColumnDataModel, dataModelId: string, tableId: string): Observable<any> {
    this.processRichText(column.column_description);
    // tslint:disable-next-line:max-line-length
    return this.http.post<ColumnDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns`, column, this.httpOptions);
  }

  deleteColumn(dataModelId: string, tableId: string, columnId: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.delete<any>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}`, this.httpOptions);
  }

  getColumn(dataModelId: string, tableId: string, columnId: string): Observable<ColumnDataModel> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<ColumnDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}`, this.httpOptions);
  }

  private formatColumn(column: ColumnDataModel): void {
    switch (column.mapped_indicator) {
      case ColumnMapIndicator.not_mapped:
        column.mapped_indicator_text = 'Not Mapped';
        break;
      case ColumnMapIndicator.ignored:
        column.mapped_indicator_text = 'Ignored';
        break;
      case ColumnMapIndicator.mapped:
        column.mapped_indicator_text = 'Mapped';
        break;
    }
  }

  getColumnValue(dataModelId: string, tableId: string, columnId: string, valueId: string): Observable<ValueDataModel> {
    return this.http.get<ValueDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}/values/${valueId}`, this.httpOptions);
  }

  ignoreValueMapping(value: ValueDataModel, dataModelId: string, tableId: string, columnId: string): Observable<any> {
    value.mapped_indicator = ColumnMapIndicator.ignored;
    return this.http.put<ValueDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}/values/${value.value_model_base_id}`, value, this.httpOptions);
  }

  saveNewColumnValue(dataModelId: string, tableId: string, columnId: string, value: ValueDataModel): Observable<any> {
    this.processRichText(value.value_description);
    // tslint:disable-next-line:max-line-length
    return this.http.post<ValueDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}/values`, value, this.httpOptions);
  }

  saveColumnValue(dataModelId: string, tableId: string, columnId: string, valueId: string, value: ValueDataModel): Observable<any> {
    this.processRichText(value.value_description);
    // tslint:disable-next-line:max-line-length
    return this.http.put<ValueDataModel>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}/values/${valueId}`, value, this.httpOptions);
  }


  deleteColumnValue(dataModelId: string, tableId: string, columnId: string, valueId: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.delete<any>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}/values/${valueId}`, this.httpOptions);
  }

  getColumnValues(dataModelId: string, tableId: string, columnId: string): Observable<Array<ValueDataModel>> {
    return this.http.get<ValueDataModelResponse>(`${endpoints.common}/data_models/${dataModelId}/tables/${tableId}/columns/${columnId}/values`, this.httpOptions).pipe(
      map((response: ValueDataModelResponse) => {
        response.value_models.forEach(value => {

          switch (value.mapped_indicator) {
            case ColumnMapIndicator.not_mapped:
              value.mapped_indicator_text = 'Not Mapped';
              break;
            case ColumnMapIndicator.ignored:
              value.mapped_indicator_text = 'Ignored';
              break;
            case ColumnMapIndicator.mapped:
              value.mapped_indicator_text = 'Mapped';
              break;
          }


        });

        return response.value_models;
      })
    );
  }


  getReservedWords(): string[] {
    return ['DATA_MODELS', 'TABLES', 'COLUMNS', 'VALUES',
      'ALL', 'AND', 'ANY', 'ARRAY', 'AS', 'ASC', 'ASSERT_ROWS_MODIFIED', 'AT', 'BETWEEN', 'BY',
      'CASE', 'CAST', 'COLLATE', 'CONTAINS', 'CREATE', 'CROSS', 'CUBE', 'CURRENT', 'DEFAULT', 'DEFINE', 'DESC',
      'DISTINCT', 'ELSE', 'END', 'ENUM', 'ESCAPE', 'EXCEPT', 'EXCLUDE', 'EXISTS', 'EXTRACT',
      'FALSE', 'FETCH', 'FOLLOWING', 'FOR', 'FROM', 'FULL', 'GROUP',
      'GROUPING', 'GROUPS', 'HASH', 'HAVING', 'IF', 'IGNORE',
      'IN', 'INNER', 'INTERSECT', 'INTERVAL', 'INTO', 'IS',
      'JOIN', 'LATERAL', 'LEFT', 'LIKE', 'LIMIT', 'LOOKUP',
      'MERGE', 'NATURAL', 'NEW', 'NO', 'NOT', 'NULL', 'NULLS', 'OF', 'ON', 'OR', 'ORDER', 'OUTER',
      'OVER', 'PARTITION', 'PRECEDING', 'PROTO', 'RANGE', 'RECURSIVE', 'RESPECT',
      'RIGHT', 'ROLLUP', 'ROWS', 'SELECT', 'SET', 'SOME', 'STRUCT', 'TABLESAMPLE',
      'THEN', 'TO', 'TREAT', 'TRUE', 'UNBOUNDED', 'UNION', 'UNNEST',
      'USING', 'WHEN', 'WHERE', 'WINDOW', 'WITH', 'WITHIN'
    ];
  }

  validateObjectName(name: string): boolean {

    if (!name || name.length === 0) {
      return false;
    }

    if (name.includes(' ')) {
      return false;
    }

    const groups = name.match('[^A-Za-z_]');
    if (groups && groups.length > 0) {
      return false;
    }

    const reservedWords = this.getReservedWords();

    let valid = true;
    reservedWords.forEach((key) => {
      if (key === name.toUpperCase()) {
        valid = false;
      }
    });
    return valid;
  }

  search(search: string, domain: string): Observable<Array<DataModelSearch>> {
    const stringObject = JSON.stringify({model_name: search, domain_id: domain});
    const query = btoa(stringObject);
    return this.http.get<Array<DataModelSearch>>(`${endpoints.common}/data_models/search?query=${query}`, this.httpOptions).pipe(
      map(results => {

        results.forEach(item => {
          const split = item.model_id.split('::');
          item.parent = split[split.length - 2];

          switch (item.class_name) {
            case MappingTypes.value:
              item.model_base_id = split[split.length - 1];
              item.column_base_id = split[split.length - 2];
              item.table_base_id = split[split.length - 3];
              break;
            case MappingTypes.column:
              item.model_base_id = split[split.length - 1];
              item.column_base_id = split[split.length - 1];
              item.table_base_id = split[split.length - 2];
              break;
            case MappingTypes.table:
              item.model_base_id = split[split.length - 1];
              item.table_base_id = split[split.length - 1];
              break;
          }

        });

        return results;
      })
    );
  }


}
