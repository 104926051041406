<div class="clr-row">
  <div class="clr-col">
    <div class="alert alert-info" role="alert">
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
          </div>
          <span *ngIf="!requested" class="alert-text" [translate]="'firebase-message-request-message'"></span>
          <span *ngIf="requested && !showDenied" class="alert-text"
                [translate]="'firebase-message-request-success-message'"></span>
          <span *ngIf="requested && showDenied" class="alert-text"
                [translate]="'firebase-message-request-blocked-message'"></span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col">
    <div class="modal-footer">
      <button data-cy="firebase-message-request-cancel" *ngIf="!requested" (click)="cancel()" class="btn"><span [translate]="'firebase-message-request-cancel'">Cancel</span>
      </button>
      <button *ngIf="!requested" [disabled]="requesting" (click)="request()" class="btn btn-primary"><span
        [translate]="'firebase-message-request'">Request</span></button>
      <button *ngIf="requested" (click)="close()" class="btn btn-primary"><span
        [translate]="'firebase-message-request-close'">Close</span></button>
    </div>
  </div>
</div>
