import { Component, ChangeDetectorRef, OnInit, Output, EventEmitter, DoCheck, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { endpoints } from '../../../utils/endpoints';
import { metadata, MetadataService } from '../../../services/metadata.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit, DoCheck, AfterViewInit {
  barWidths = { current: [], prior: [], next: [] };
  currentMetricColors;
  currentMetricSize;
  currentMetrics;
  filters;
  hiddenLabels = { current: [], prior: [], next: [] };
  loading = true;
  metrics;
  nextMetricColors;
  nextMetricSize;
  nextMetrics;
  paddings = { global: 30, left: 36 };
  priorMetricColors;
  priorMetricSize;
  priorMetrics;
  rowWidth;
  user;

  constructor( public cdr: ChangeDetectorRef, public mdSvc: MetadataService ) { }
  @Output() checkLoadEvent = new EventEmitter();
  @ViewChild('barsection') barsection: ElementRef;
  ngOnInit() { this.user = JSON.parse(localStorage.getItem('user')); }

  ngDoCheck() { this.cdr.detectChanges(); }
  ngAfterViewInit() { setTimeout(() => {this.rowWidth = (this.barsection.nativeElement.offsetWidth - 144);}) }

  // *** INITIALIZE ***

  // Set component vars based on what was returned from service call
  setMetricVars() {
    this.metrics.current && (this.currentMetrics = this.metrics.current);
    this.metrics.prior && (this.priorMetrics = this.metrics.prior);
    this.metrics.next && (this.nextMetrics = this.metrics.next);
    this.calculateBarWidths();
  }

  // Use the screen width variable to calculate the bar widths
  calculateBarWidths() {
    if (this.currentMetrics) {
      this.currentMetricSize = [((this.rowWidth * this.currentMetrics.series_ratio) + 40), 80];
      this.barWidths.current = this.currentMetricSize[0];
    }
    if (this.priorMetrics) {
      this.priorMetricSize = [((this.rowWidth * this.priorMetrics.series_ratio) + 40), 80];
      this.barWidths.prior = this.priorMetricSize[0];
    }
    if (this.nextMetrics) {
      this.nextMetricSize = [((this.rowWidth * this.nextMetrics.series_ratio) + 40), 80];
      this.barWidths.next = this.nextMetricSize[0];
    }
    this.orderSeries();
  }

  // Sort the series data alphabetically
  orderSeries() {
    let metrics = [];
    this.currentMetrics && (metrics.push(this.currentMetrics));
    this.priorMetrics && (metrics.push(this.priorMetrics));
    this.nextMetrics && (metrics.push(this.nextMetrics));
    metrics.forEach((metric) => {
      metric.series.sort((a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
    });
    this.setMetricColors();
  }

  // Create local variable of colors for template display
  setMetricColors() {
    let currentColors, priorColors, nextColors;
    if (this.currentMetrics) {
      currentColors = this.currentMetrics.series.map((datum) => { return datum.color; });
      this.currentMetricColors = { domain: currentColors };
    }
    if (this.priorMetrics) {
      priorColors = this.priorMetrics.series.map((datum) => { return datum.color; });
      this.priorMetricColors = { domain: priorColors };
    }
    if (this.nextMetrics) {
      nextColors = this.nextMetrics.series.map((datum) => { return datum.color; });
      this.nextMetricColors = { domain: nextColors };
    }
    this.loading = false;
    this.checkLoadEvent.emit(this.loading);
  }

  // *** TEMPLATE CALCS ***

  // Find x coords of absolutely positioned label elements
  calculateLabelXPos(whichMetric, index) {
    let returnPos;
    let labelXPos = 0;
    const metricData = this.lookupMetricData(whichMetric);
    metricData.metric.series.forEach((datum, i) => {
      if (i !== 0) {
        labelXPos = (((metricData.metric.series[i-1].ratio/metricData.metric.series_ratio) * (metricData.barWidth - this.paddings.left)) + labelXPos);
        index === i && (returnPos = (labelXPos + this.paddings.global));
      }
    });
    if (index === 0) {
      returnPos = ((this.paddings.global / 2) + (this.paddings.left / 2));
    }
    return returnPos;
  }

  // Return a bool determining whether or not to drop the label into the legend
  isLabelDropped(whichMetric, index) {
    let showDroppedLabel = false;
    let metricData = this.lookupMetricData(whichMetric);
    if (document.getElementById(index)) {
      if (
        ((metricData.metric.series[index].ratio/metricData.metric.series_ratio) * (metricData.barWidth - this.paddings.left)) < ((document.getElementById(index).offsetWidth) + 24)
        && !metricData.hiddenLabels.includes(index)) {
        showDroppedLabel = true;
        metricData.hiddenLabels.push(index)
      }
    }
    return showDroppedLabel;
  }

  // Legend label text color should match the bar color from where it was dropped
  setDroppedLabelColor(whichMetric, index) {
    const metricData = this.lookupMetricData(whichMetric);
    return metricData.colorScheme.domain.find((color, i) => { return index === i; });
  }

  // Useful for iterating methods, returns a cleaned object based on specified metric
  lookupMetricData(whichMetric) {
    let metricData;
    if (whichMetric === 'current') {
      metricData = { metric: this.currentMetrics, barWidth: this.barWidths.current, hiddenLabels: this.hiddenLabels.current, colorScheme: this.currentMetricColors };
    } else if (whichMetric === 'prior') {
      metricData = { metric: this.priorMetrics, barWidth: this.barWidths.prior, hiddenLabels: this.hiddenLabels.prior, colorScheme: this.priorMetricColors };
    } else if (whichMetric = 'next') {
      metricData = { metric: this.nextMetrics, barWidth: this.barWidths.next, hiddenLabels: this.hiddenLabels.next, colorScheme: this.nextMetricColors };
    }
    return metricData;
  }

  // *** HTTP ***

  getMetrics($metricsCategory, $filters, $filterParams) {
    this.loading = true;
    this.filters = $filters;
    this.hiddenLabels = { current: [], prior: [], next: [] };
    this.checkLoadEvent.emit(this.loading);
    const endpoint = `${endpoints.metrics}?login_user_id=${this.user.user_id}&metrics_category=${$metricsCategory}&${$filterParams}`;
    return this.mdSvc.getMetadata(endpoint).subscribe((data) => {
      this.metrics = data.metrics;
    }, (error) => {
      console.log('Error: ', error);
    }, () => {
      this.setMetricVars();
    });
  }

  getAuditorMetrics($metricsCategory) {
    this.loading = true;
    this.hiddenLabels = { current: [], prior: [], next: [] };
    this.checkLoadEvent.emit(this.loading);
    const endpoint = `${endpoints.metricsAuditor}?login_user_id=${this.user.user_id}&metrics_category=${$metricsCategory}`;
    return this.mdSvc.getMetadata(endpoint).subscribe((data) => {
      this.metrics = data.metrics;
    }, (error) => {
      console.log('Error: ', error);
    }, () => {
      this.setMetricVars();
    });
  }

  getProductivityMetrics($metricsCategory) {
    this.loading = true;
    this.hiddenLabels = { current: [], prior: [], next: [] };
    this.checkLoadEvent.emit(this.loading);
    const endpoint = `${endpoints.metricsProductivity}?login_user_id=${this.user.user_id}&metrics_category=${$metricsCategory}`;
    return this.mdSvc.getMetadata(endpoint).subscribe((data) => {
      this.metrics = data.metrics;
      console.log(this.metrics);
    }, (error) => {
      console.log('Error: ', error);
    }, () => {
      this.setMetricVars();
    });
  }

}
