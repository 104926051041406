import { Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialog, DisplayDialog } from './components/common/models/dialog.model';
import { AuthService } from './services/auth.service';
import { ClarityIconsService } from './services/clarity-icons.service';
import { DialogService } from './services/dialog.service';
import { LogService } from './services/log.service';

// Google Analytics
// tslint:disable-next-line:ban-types
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, DoCheck {
  title = 'sentinel-ui';
  public confirmDialog: ConfirmDialog;
  public displayDialog: DisplayDialog;
  private user: any;
  constructor(private logService: LogService,
    private router: Router,
    private translate: TranslateService,
    private authSvc: AuthService,
    private dialogService: DialogService,
    private clarityIconService: ClarityIconsService) {

  }
  
  ngDoCheck(): void {
    if (this.router.url.includes('/intake')) {
      this.translate.use('en');
    }
  }

  ngOnInit() {
    // Localization
    this.translate.addLangs(['en', 'es', 'fr', 'nl']);
    this.logService.init();
    this.confirmDialog = this.dialogService.initConfirmModal();
    this.displayDialog = this.dialogService.initDisplayModal();
    this.clarityIconService.load();
    this.user = JSON.parse(localStorage.getItem('user'));
    //     if (this.user && this.user.profile_language_id) {
    //       console.log(`set translation ${this.user.profile_language_id}`);
    //       this.translate.setDefaultLang(this.user.profile_language_id);
    // //      this.translate.use(this.user.profile_language_id);
    //     } else {
    //       this.translate.setDefaultLang('en');
    //       console.log(`set translation en`);
    //   //    this.translate.use('en');
    //     }

    this.authSvc.sharedUser.subscribe((state: any) => {
      this.authSvc.restoreImpersonate();
      console.log(`from authSvc - set translation ${state.profile_language_id}`);
      //    this.translate.use(state.profile_language_id);
    });

  }
}
