<ngx-charts-line-chart
  [results]="data"
  [view]="[145, 37]"
  [scheme]="{domain: ['#232623']}"
  [legend]="false"
  [showXAxisLabel]="false"
  [showYAxisLabel]="false"
  [xAxis]="false"
  [yAxis]="false"
  [showGridLines]="false"
  [autoScale]="true"
  [timeline]="false"
  [gradient]="false"
  [curve]="curve">
</ngx-charts-line-chart>
