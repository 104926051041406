import {Component} from '@angular/core';
import {BaseComponent} from '../../../common/base-component';
import {Router} from '@angular/router';
import {LandService} from '../../../../services/land.service';
import {
  ColumnDataModel,
  ColumnMapIndicator,
  DataModelSearch,
  MappingTypes,
  TableDataModel,
  ValueDataModel
} from '../../../common/models/data.model';
import {ModalStates} from '../../../common/models/modal-state.model';
import {DialogService} from '../../../../services/dialog.service';
import {ConfirmButtonModel, ConfirmDialogModel, ConfirmDialogTypeEnum} from '../../../common/models/dialog.model';
import {DataModelService} from '../../../../services/data-model.service';
import {FormDataModelColumnComponent} from '../data-model/form-data-model-column/form-data-model-column.component';
import {FormDataModelValueComponent} from '../data-model/form-data-model-value/form-data-model-value.component';
import {FormDataModelTableComponent} from '../data-model/form-data-model-table/form-data-model-table.component';
import {LogService} from '../../../../services/log.service';

@Component({
  selector: 'app-data-model-search',
  templateUrl: './data-model-search.component.html',
  styleUrls: ['./data-model-search.component.css']
})
export class DataModelSearchComponent extends BaseComponent {
  public searching: boolean;
  public query: string;
  public results: DataModelSearch[];
  public searched: boolean;

  constructor(protected router: Router,
              private dialogService: DialogService,
              private landService: LandService,
              private dataModelService: DataModelService,
              protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
  }

  protected setUser(user: any): void {
  }

  public view(item: DataModelSearch): void {

    switch (item.class_name) {
      case MappingTypes.column:
        this.viewColumn(item);
        break;
      case MappingTypes.value:
        this.viewValue(item);
        break;
      case MappingTypes.table:
        this.viewTable(item);
        break;
      case MappingTypes.datamodel:
        break;
    }

  }

  private viewTable(item: DataModelSearch): void {

    this.searching = true;

    this.dataModelService.getTable(item.data_model_id, item.table_base_id).subscribe((table: TableDataModel) => {

      this.searching = false;
      const dataModel = table.data_model;
      const dialogRef = this.dialogService.openDialog('FormDataModelTableComponent', FormDataModelTableComponent,
        'land-view-table', 'xxl', {
          displayMode: ModalStates.VIEW,
          model: table,
          dataModel
        });
      dialogRef.afterClosed.subscribe(() => {
      });

    }, () => {
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'search-not-found-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'search-not-found-message',
        cancelButton: {show: true, textTranslateId: 'land-column-cancel-button'} as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        this.searching = false;
      });

    });
  }

  private viewColumn(item: DataModelSearch): void {

    this.searching = true;

    this.dataModelService.getColumn(item.data_model_id, item.table_base_id, item.column_base_id).subscribe((column: ColumnDataModel) => {

      this.searching = false;
      const table = column.table;
      const dataModel = table.data_model;
      const dialogRef = this.dialogService.openDialog('FormLandColumnComponent', FormDataModelColumnComponent,
        'land-view-column', 'xxl', {
          displayMode: ModalStates.VIEW,
          column,
          table,
          dataModel
        });
      dialogRef.afterClosed.subscribe(() => {
      });

    }, () => {

      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'search-not-found-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'search-not-found-message',
        cancelButton: {show: true, textTranslateId: 'land-column-cancel-button'} as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        this.searching = false;
      });

    });
  }

  private viewValue(item: DataModelSearch): void {

    this.searching = true;
    this.dataModelService.getColumnValue(item.data_model_id, item.table_base_id,
      item.column_base_id, item.model_base_id).subscribe((value: ValueDataModel) => {

      switch (value.mapped_indicator) {
        case ColumnMapIndicator.not_mapped:
          value.mapped_indicator_text = 'Not Mapped';
          break;
        case ColumnMapIndicator.ignored:
          value.mapped_indicator_text = 'Ignored';
          break;
        case ColumnMapIndicator.mapped:
          value.mapped_indicator_text = 'Mapped';
          break;
      }

      this.searching = false;
      const dialogRef = this.dialogService.openDialog('FormLandValueComponent', FormDataModelValueComponent,
        'land-view-value', 'xxl', {
          displayMode: ModalStates.VIEW,
          value
        });
      dialogRef.afterClosed.subscribe(() => {
      });

    }, () => {
      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'search-not-found-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'search-not-found-message',
        cancelButton: {show: true, textTranslateId: 'land-column-cancel-button'} as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        this.searching = false;
      });

    });
  }


  public search(): void {
    this.searched = true;
    this.searching = true;
    this.dataModelService.search(this.query, this.getCurrentDomain().domain.domain_id).subscribe((results: DataModelSearch[]) => {
      this.searching = false;
      this.results = results;
    }, () => {
      this.results = [];
      this.searching = false;
    });
  }

  public reset(): void {
    this.searched = false;
    this.query = '';
  }

}
