<div  class="clr-row no-attachments" *ngIf="!model?.attachments.length && !loading">
  <div class="clr-col" [translate]="'exploration-attachment-none'">
      No attachments added to summary
  </div>
</div>

<ng-container *ngIf="model?.attachments?.length && !loading">
  <div class="clr-row" *ngFor="let attachment of model?.attachments; let i = index">
    <div class="clr-col-10 attachment-col" style="display: flex;">
      <div class="clr-col-4">
        <clr-tooltip>
          <clr-icon clrTooltipTrigger size="24" class="attachments-icon" shape="file"></clr-icon>
          <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
              <span>{{attachment.file.file_name}}</span>
          </clr-tooltip-content>
        </clr-tooltip>
        <span>{{attachment.file.file_name | truncate: 35}}</span>
      </div>

      <div class="clr-col-6">
        <clr-tooltip>
          <clr-icon clrTooltipTrigger size="24" class="attachments-icon" shape="details"></clr-icon>
          <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen>
              <span>{{attachment.file.file_description}}</span>
          </clr-tooltip-content>
        </clr-tooltip>
        <span>{{attachment.file.file_description | truncate: 75}}</span>
      </div>

      <div class="clr-col-2" style="text-align: right;">

        <div *ngIf="deleteConfirmStates[i]" class="flex-buttons">
          <div class="controlpanel">
            <span [translate]="'Delete Attachment?'"></span>
            <button class="btn btn-icon btn-primary intake-conf-btn"
              (click)="deleteConfirmStates[i] = false;">
              <clr-icon class="intake-conf-icon" shape="times"></clr-icon>
            </button>
            <button class="btn btn-icon btn-danger minibtn-danger intake-conf-btn"
              (click)="deleteAttachment(attachment.file, i)">
              <clr-icon class="intake-conf-icon" shape="trash"></clr-icon>
            </button>
          </div>
        </div>

        <clr-icon *ngIf="!deleteConfirmStates[i]" (click)="deleteConfirmStates[i] = true;" class="del-icon" shape="times"></clr-icon>
      </div>

    </div>
  </div>
</ng-container>

<div *ngIf="loading" class="loading">
  <span class="spinner"></span>
</div>

