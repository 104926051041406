<div class="mapping-container">

  <app-column-category-values-source *ngIf="dataModel?.data_model_type === dataModelTypes.SOURCE"
                                     [datasource_column]="column"
                                     [datasource_table]="table"
                                     [datasource_datamodel]="dataModel"
                                     [modal_state]="state"
  ></app-column-category-values-source>
  <app-column-category-values-target *ngIf="dataModel?.data_model_type === dataModelTypes.TARGET"
                                     [datasource_column]="column"
                                     [datasource_table]="table"
                                     [datasource_datamodel]="dataModel"
  ></app-column-category-values-target>

</div>
