import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {MineModel} from '../components/common/models/mine.model';


export interface MetadataMineStateModel {
  mines: MineModel[];
}

export class MetadataMineLoaded {
  static readonly type = '[MetadataMine] loaded';

  constructor(public mines: MineModel[]) {
  }
}

@State<MetadataMineStateModel>({
  name: 'metadataMineState',
  defaults: { mines: [] }
})
@Injectable()
export class MetadataMineState {

  constructor() {
  }

  @Selector()
  static mines(state: MetadataMineStateModel) {
    return state.mines;
  }

  @Action(MetadataMineLoaded)
  loaded(ctx: StateContext<MetadataMineStateModel>, action: MetadataMineLoaded) {

    ctx.patchState({
      mines: action.mines
    });

  }
}
