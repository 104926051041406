import { ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavDomainModel, SubNavItemModel } from 'app/components/common/models/man-nav.model';
import { AuthService } from 'app/services/auth.service';
import { MetadataService } from 'app/services/metadata.service';

@Component({
  selector: 'app-intake-main-nav',
  templateUrl: './intake-main-nav.component.html',
  styleUrls: ['./intake-main-nav.component.css']
})
export class IntakeMainNavComponent implements OnInit, DoCheck {
  currentUser;
  loading: boolean;
  loadingSubscription;
  public navDomains: NavDomainModel[];

  constructor(public cdr: ChangeDetectorRef,
              private mdSvc: MetadataService,
              public authSvc: AuthService,
              private route: Router) {  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.loadingSubscription = this.authSvc.isLoadingChange.subscribe();
    await this.authSvc.initUserNoAuth(); 
    this.loading = this.authSvc.isLoading;

    this.navDomains = [];

    const subItems = this.getSubItems();
    const domain = {
      domain_id: 'intake',
      domain_name: 'Intake',
      domain_color: '#9B6CD1',
    }
    const navDomain = {
      url: '/intake',
      cyLabel: 'app-btn-intake',
      class: 'app-btn-intake',
      domain: domain,
      subNavItems: subItems
    } as NavDomainModel;
    this.navDomains.push(navDomain);
  }

  ngDoCheck(): void {
    this.cdr.detectChanges();
    if (this.mdSvc.isMetadataTagsLoaded()) {
      this.authSvc.changeIsLoading();
      this.loading = this.authSvc.isLoading;
      this.loadingSubscription.unsubscribe();
    }
  }

  getSubItems(): SubNavItemModel[] {
    return [
      {
        url: '/intake',
        routerLinkExact: true,
        routerLinkMatch: [],
        cy: 'nav-home',
        translateId: 'intake-forms-home-title',
        roles: [],
        groups: []
      }
    ];
  }

  public getNavItems(): SubNavItemModel[] {
    if (!this.navDomains) {
      return [];
    }

    const currentRoute = this.route.url.includes('/intake') ? "intake" : "";
    const domain = this.navDomains.find((d: NavDomainModel) => d.domain.domain_id === currentRoute);
    if (!domain) {
      return [];
    }
    return domain.subNavItems;
  }
}
