import {Component, NgZone} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {BaseComponent} from '../../../../common/base-component';
import {Router} from '@angular/router';
import {DialogService} from '../../../../../services/dialog.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {
  ConfirmDialogResult,
  DisplayModalDialogRef
} from '../../../../common/models/dialog.model';
import {Snippet} from '../../../../../utils/processFiles';
import {Guid} from 'guid-typescript';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-editor-image-form',
  templateUrl: './editor-image-form.component.html',
  styleUrls: ['./editor-image-form.component.css']
})
export class EditorImageFormComponent extends BaseComponent {
  public file: NgxFileDropEntry;
  public modalRef: DisplayModalDialogRef;


  constructor(protected router: Router,
              private dialogService: DialogService,
              private zone: NgZone,
              private storage: AngularFireStorage,
              protected logService: LogService) {
    super(router, logService);
  }


  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
  }

  protected setUser(user: any): void {
  }


  public dropped(files: NgxFileDropEntry[]) {
    if (files.length === 0) {
      return;
    }
    this.file = files[0];
    if (!this.file.fileEntry.isFile) {
      return;
    }

    const fileEntry = this.file.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {

      const reader = new FileReader();
      reader.addEventListener('load', (event: any) => {
        const selectedFile = new Snippet(event.target.result, file);

        const fileName = `${ Guid.create() }_${selectedFile.file.name}`;
        const ref = this.storage.ref('files');
        ref.child(fileName).put(file).then(() => {

          const childRef = this.storage.ref(`files/${ fileName}`);
          const refDownloadUrl = childRef.getDownloadURL();
          refDownloadUrl.subscribe((storageUrl) => {
            this.modalRef.close(ConfirmDialogResult.Confirm, { url: storageUrl});
          });
        });

      });
      reader.readAsDataURL(file);

    });
  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

}
