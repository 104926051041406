<p-organizationChart [value]="treeData" selectionMode="single" styleClass="company" [preserveSpace]="false">
  <ng-template let-node pTemplate="person">
    <div>
      <table style="border:1px darkgrey solid; border-radius:4px; table-layout: fixed; width: 250px; height: 80px;">
        <tr>
          <td style="width:5px;" [ngStyle]="{'background-color': node.data.fill}"></td>
          <td style="width: 245px">
            <table style="width: 100%">
              <tr style="height: 40px">
                <td>
                  <table style="width: 100%">
                    <tr style="height: 40px">
                      <td style="vertical-align: top; padding-left: 5px; font-weight: bold; font-size: 10pt; text-align: left; width: 65%">{{node.data.name}}</td>
                      <td style="vertical-align: top; padding-right: 5px; font-weight: bold; font-size: 12pt; text-align: right; width: 35%">{{node.data.actual}}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style="height: 40px">
                <td>
                  <table style="width: 100%">
                    <tr style="height: 40px">
                      <td style="width: 50%;">
                        <app-sparkline [series]="node.data.history"></app-sparkline>
                      </td>
                      <td style="padding-right: 5px; width: 50%">
                        <table style="width: 100%">
                          <tr>
                            <td style="font-size: 8pt; text-align: right;">Target: {{node.data.target}}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </ng-template>
</p-organizationChart>
