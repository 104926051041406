import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CountryModel } from '../components/common/models/country.model';


export interface MetadataCountryStateModel {
  countries: CountryModel[];
}

export class MetadataCountryLoaded {
  static readonly type = '[MetadataCountry] loaded';

  constructor(public countries: CountryModel[]) {
  }
}

@State<MetadataCountryStateModel>({
  name: 'metadataCountryState',
  defaults: { countries: [] }
})
@Injectable()
export class MetadataCountryState {

  constructor() {
  }

  @Selector()
  static countries(state: MetadataCountryStateModel) {
    return state.countries;
  }

  @Action(MetadataCountryLoaded)
  loaded(ctx: StateContext<MetadataCountryStateModel>, action: MetadataCountryLoaded) {

    ctx.patchState({
      countries: action.countries
    });

  }
}
