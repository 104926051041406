import {Component} from '@angular/core';
import {BaseComponent} from '../../../../../common/base-component';
import {Router} from '@angular/router';
import {DialogService} from '../../../../../../services/dialog.service';
import {LandService} from '../../../../../../services/land.service';
import {ConfirmDialogResult, DisplayModalDialogRef} from '../../../../../common/models/dialog.model';
import {
  ColumnMappingModel,
  DataModelTypes,
  MappingQueryTypes,
  MappingTypes,
  ValueDataModel,
  ValueMappingModel,
  ValueMappingQueryTypes
} from '../../../../../common/models/data.model';
import {DataModelService} from '../../../../../../services/data-model.service';
import {LogService} from '../../../../../../services/log.service';

@Component({
  selector: 'app-column-category-value',
  templateUrl: './column-category-value.component.html',
  styleUrls: ['./column-category-value.component.css']
})
export class ColumnCategoryValueComponent extends BaseComponent {
  protected modalRef: DisplayModalDialogRef;
  public loadingTargetValues: boolean;
  public value: ValueDataModel;
  public columnMappings: ColumnMappingModel[];
  public selectedDataModelId: string;
  public loadingDataModels: boolean;
  public selectedColumnMapping: ColumnMappingModel;
  public targetColumnValues: ValueDataModel[];
  public saving: boolean;
  public selectedValueId: string;
  public dataModelTypes = DataModelTypes;
  public sourceValueMaps: ValueMappingModel[];
  public loadingSourceValues: boolean;

  constructor(
    protected router: Router,
    private dialogService: DialogService,
    private landService: LandService,
    private dataModelService: DataModelService,
    protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.value = this.dialogService.displayModal.current.data.value;
    this.dialogService.displayModal.current.secondHeader = ` for ${this.value.column.table.table_model_name}::${this.value.column.column_model_name}::${this.value.value_model_name}`;
  }

  protected setUser(user: any): void {
    this.getColumnMappings();
  }

  private getColumnMappings(): void {
    this.loadingDataModels = true;

    this.landService.getColumnMappings(this.value.column.table.data_model.data_model_type === DataModelTypes.SOURCE ?
        MappingQueryTypes.source : MappingQueryTypes.target,
      this.value.column.column_model_id).subscribe((results: ColumnMappingModel[]) => {

      this.columnMappings = results;

      if (this.columnMappings.length > 0) {
        this.selectedDataModelId = this.columnMappings[0].target.table.data_model.data_model_id;
        this.changeSelectedMapping();
      }
      this.loadingDataModels = false;
    }, () => {
      this.columnMappings = [];
      this.loadingDataModels = false;
    });
  }

  public changeSelectedMapping(): void {
    this.selectedColumnMapping = this.columnMappings.find(x => x.target.table.data_model.data_model_id === this.selectedDataModelId);
    this.getTargetValues();
  }

  public getTargetValues(): void {

    this.loadingTargetValues = true;

    const split = this.selectedColumnMapping.target.column_model_id.split('::');
    const dataModelId = this.selectedColumnMapping.target.table.data_model.data_model_id;
    const tableId = split[split.length - 2];
    const columnId = split[split.length - 1];

    if (this.value.column.table.data_model.data_model_type === DataModelTypes.SOURCE) {
      this.getSourceValueMaps();
    } else {
      this.getTargetValueMaps();
    }

    this.dataModelService.getColumnValues(dataModelId, tableId, columnId).subscribe(values => {
      this.targetColumnValues = values;
      this.loadingTargetValues = false;

    }, () => {
      this.targetColumnValues = [];
      this.loadingTargetValues = false;
    });

  }

  private getSourceValueMaps(): void {

    this.landService.getValueMappings(ValueMappingQueryTypes.source, this.value.column.column_model_id)
      .subscribe((valueMaps: ValueMappingModel[]) => {

        const valueMap = valueMaps.find(v => v.source.value_model_id === this.value.value_model_id);
        if (valueMap) {
          this.selectedValueId = valueMap.target.value_model_id;
        }

      });
  }

  private getTargetValueMaps(): void {
    this.loadingSourceValues = true;
    this.landService.getValueMappings(ValueMappingQueryTypes.target, this.value.column.column_model_id)
      .subscribe((valueMaps: ValueMappingModel[]) => {
        this.loadingSourceValues = false;

        this.sourceValueMaps = valueMaps.filter(v => v.target.value_model_id === this.value.value_model_id);

      });
  }

  private createNewColumnMapping(value: ValueDataModel, targetValue: ValueDataModel): ValueMappingModel {
    return {
      mapping_type: MappingTypes.value,
      source: value,
      target: targetValue,
    } as ValueMappingModel;
  }

  public save(): void {
    if (!this.selectedValueId) {
      return;
    }

    const targetValue = this.targetColumnValues.find(x => x.value_model_id === this.selectedValueId);

    if (!targetValue) {
      return;
    }

    this.saving = true;

    const valueMap = this.createNewColumnMapping(this.value, targetValue);

    this.landService.saveNewValueMapping(valueMap).subscribe(() => {
      this.saving = false;
      this.modalRef.close(ConfirmDialogResult.Confirm);
    });

  }

  public close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

}
