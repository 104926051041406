import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MetadataService } from '../../../../services/metadata.service';
import { LogService } from '../../../../services/log.service';
import { FileDownloadBaseComponent } from '../FileDownloadBaseComponent';
import { DialogService } from '../../../../services/dialog.service';
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})
export class FileDownloadComponent extends FileDownloadBaseComponent {


  constructor(protected router: Router,
              protected metaSvc: MetadataService,
              protected logService: LogService,
              protected dialogService: DialogService,
              protected storage: AngularFireStorage,
              private activatedRoute: ActivatedRoute
  ) {
    super(router, metaSvc, logService, dialogService, storage);
  }

  protected setUser(user: any): void {
    this.activatedRoute.queryParams.subscribe((params: Params) => {

      if (!params.file_id) {
        this.logService.debug('no fileId specified');
        this.showDownloadError = true;
        return;
      }

      const fileId = params.file_id;
      const fileName = params.file_name;
      const close = params.close;
     // this.downloadFile(close === '1', fileId, fileName);
      this.downloadGoogleStorage(close === '1', fileId, fileName);
    });
  }


}
