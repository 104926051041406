import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../services/dialog.service';
import { LogService } from '../../../services/log.service';
import { BaseComponent } from '../base-component';
import { ConfirmDialogResult, DisplayModalDialogRef } from '../models/dialog.model';
import { FormRiskModel } from '../models/form-risk.model';
import { MetadataFormMode } from '../models/metadata-form-model';
import { RiskAnswer, RiskChoice, RiskControlModel, RiskModel, RiskQuestion } from '../models/risk.model';

@Component({
  selector: 'app-form-risk',
  templateUrl: './form-risk.component.html',
  styleUrls: ['./form-risk.component.css']
})
export class FormRiskComponent extends BaseComponent implements AfterViewInit {
  protected dialogRef: DisplayModalDialogRef;
  public formHeader: string;
  public formComponent: any;
  public formModel: any;
  public model: FormRiskModel;
  public displayMode: MetadataFormMode;
  public metadataFormMode = MetadataFormMode;
  private componentRef: ComponentRef<any>;
  public risks: RiskModel[];
  public selectRisk: boolean;
  public selectedRisk: string;
  @ViewChild('container') container: ElementRef<HTMLInputElement>;

  constructor(protected router: Router,
              protected dialogService: DialogService,
              private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              private cd: ChangeDetectorRef,
              protected logService: LogService
  ) {
    super(router, logService);
  }

  protected init(): void {
    this.dialogRef = this.dialogService.displayModal.current.ref;
    this.model = this.dialogService.displayModal.current.data.model;
    this.formHeader = this.dialogService.displayModal.current.data.formHeader;
    this.formModel = this.dialogService.displayModal.current.data.formModel;
    this.formComponent = this.dialogService.displayModal.current.data.formComponent;
    this.displayMode = this.dialogService.displayModal.current.data.displayMode;
    this.model.answers = this.formModel.answers;
    this.risks = this.dialogService.displayModal.current.data.model.risks;
    this.cd.detectChanges();
    this.populateAnswersOnQuestions();
  }

  protected setUser(user: any): void {
  }


  ngAfterViewInit(): void {
    this.appendDialogComponentToBody();
  }


  public sortControls(): RiskControlModel[] {
    return this.model.risk.controls.sort((a, b) => a.control_order < b.control_order ? -1 : a.control_order > b.control_order ? 1 : 0);
  }

  public sortQuestions(questions): RiskQuestion[] {
    return questions.sort((a, b) => a.question_order < b.question_order ? -1 : a.question_order > b.question_order ? 1 : 0);
  }

  public sortChoices(choices): RiskChoice[] {
    return choices.sort((a, b) => a.choice_order < b.choice_order ? -1 : a.choice_order > b.choice_order ? 1 : 0);
  }

  public close(): void {
    this.dialogRef.close(ConfirmDialogResult.Cancel);
  }

  public edit(): void {
    this.displayMode = MetadataFormMode.edit;
    this.componentRef.instance.displayMode = MetadataFormMode.edit;
  }

  public isValid(): boolean {
    if (!this.componentRef || !this.componentRef.instance) {
      return true;
    }

    const componentValid = this.componentRef.instance.isValid();
    if (!componentValid) {
      return false;
    }
    return true;
  }

  public showForm(): void {
    if (!this.selectedRisk) {
      return;
    }
    this.model.risk = this.risks.find(x => x.risk_id === this.selectedRisk);
    this.dialogService.displayModal.current.size = 'xl';
    this.selectRisk = false;
    this.populateAnswersOnQuestions();
  }

  public save(): void {
    if (!this.isValid()) {
      return;
    }
    const preppedModel = this.prepSave();
    this.dialogRef.close(ConfirmDialogResult.Confirm, preppedModel);
  }

  private getFormModel(): any {
    if (!this.componentRef || !this.componentRef.instance) {
      return {};
    }
    return this.componentRef.instance.getModel();
  }

  private prepSave(): any {
    this.model.answers = [];
    this.model.risk.controls.forEach(control => {
      control.questions.forEach(question => {
        this.model.answers.push(question.answer);
      });
    });
    const formModel = this.getFormModel();
    formModel.answers = this.model.answers;
    return {risk: this.model, formModel};
  }

  private populateAnswersOnQuestions(): void {
    this.model.risk.controls.forEach((control) => {
      control.questions.forEach((question) => {
        if (!this.model.answers) {
          this.model.answers = [];
        }
        const existingAnswer = this.model.answers.find(x => x.question_id === question.question_id);
        this.setAnswer(question, existingAnswer);
      });
    });
  }

  private setAnswer(question: RiskQuestion, existingAnswer: RiskAnswer): void {
    if (!existingAnswer) {
      existingAnswer = {
        finding: '',
        question_id: question.question_id,
        response: '',
        choice_id: ''
      };
    }

    question.answer = {
      finding: existingAnswer.finding,
      question_id: existingAnswer.question_id,
      response: existingAnswer.response,
      choice_id: null
    };

    question.choices.forEach((choice) => {
      if (question.answer.response === '' && choice.default_value) {
        question.answer.choice_id = choice.choice_id;
        question.answer.response = choice.choice_id;
      }
    });
  }


  private appendDialogComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.formComponent);
    this.componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    this.container.nativeElement.appendChild(domElem);

    this.componentRef.instance.displayMode = this.displayMode;
    this.componentRef.instance.datasource = this.formModel;
  }


}
