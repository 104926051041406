import { FactModel } from './fact.model';
import { UpdateUser } from './update-user.model';
import { SiteModel } from './site.model';
import { DomainModel } from './domain.model';

export enum ResultsScenarioTypeEnum {
  actual = 'actual',
  budget = 'budget',
  forcast = 'forecast',
  estimate = 'estimate'
}

export enum ResultsTypeEnum {
  template = 'template',
  value = 'value'
}

export enum ResultDateType {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export interface ResultsResponse {
  results: ResultsModel[];
}

export interface ResultsModel {
  result_id: string;
  result_type: ResultsTypeEnum;
  result_date_type: ResultDateType;
  sub_domain: SubDomain;
  scenario_type: ResultsScenarioTypeEnum;
  result_date: string;
  period: string;
  site: SiteModel;
  fact: FactModel;
  value: string;
  update_user: UpdateUser;
  update_date: UpdateUser;
  update_user_formatted;
  edit: boolean;
  value_edit: string;
}


export interface FactResult {
  fact: FactModel;
  templates: ResultsModel[];
}

export interface SubDomain {
  sub_domain_id: string;
  sub_domain_name: string;
  domain: DomainModel;
}
