import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UnitOfMeasureModel } from '../components/common/models/unit-of-measure.model';


export interface MetadataUnitOfMeasureStateModel {
  unitofmeasures: UnitOfMeasureModel[];
}

export class MetadataUnitOfMeasureLoaded {
  static readonly type = '[MetadataUnitOfMeasure] loaded';

  constructor(public unitofmeasures: UnitOfMeasureModel[]) {
  }
}

@State<MetadataUnitOfMeasureStateModel>({
  name: 'metadataUnitOfMeasureState',
  defaults: { unitofmeasures: [] }
})
@Injectable()
export class MetadataUnitOfMeasureState {

  constructor() {
  }

  @Selector()
  static unitofmeasures(state: MetadataUnitOfMeasureStateModel) {
    return state.unitofmeasures;
  }

  @Action(MetadataUnitOfMeasureLoaded)
  loaded(ctx: StateContext<MetadataUnitOfMeasureStateModel>, action: MetadataUnitOfMeasureLoaded) {

    ctx.patchState({
      unitofmeasures: action.unitofmeasures
    });

  }
}
