import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EmbeddedViewRef,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import { DialogService } from '../../../services/dialog.service';
import { DialogComponent } from '../models/dialog.model';
import { Store } from '@ngxs/store';
import { DialogStateViewModel, DialogViewState } from '../../../store/dialog.state';

@Component({
  selector: 'app-display-modal',
  templateUrl: './display-modal.component.html',
  styleUrls: ['./display-modal.component.css']
})
export class DisplayModalComponent implements OnInit, AfterViewInit {
  public model: DialogComponent;
  public superSize: boolean;
  public isOpen: boolean;
  @ViewChild('container') container: ElementRef<HTMLInputElement>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private dialogService: DialogService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.isOpen = false;
  }

  ngAfterViewInit(): void {
    this.isOpen = false;

    this.store.select(DialogViewState.dialogState).subscribe((state: DialogStateViewModel) => {

      if (this.isOpen && !state.open) {
        this.destroyComponents();
      } else if (this.isOpen && state.open) {
        this.detachComponent(this.model);
      }

      if (!this.container) {
        return;
      }

      this.isOpen = state.open;
      if (this.isOpen) {
        this.loadComponent(state.componentId);
      }
    });
  }

  private loadComponent(id: string): void {
    this.model = this.dialogService.displayModal.components.find(c => c.id === id);
    this.dialogService.displayModal.current = this.model;
    if (!this.model) {
      return;
    }
    if (this.model.size === 'xxl') {
      this.model.size = 'xl';
      this.superSize = true;
    } else {
      this.superSize = false;
    }
    this.appendDialogComponentToBody();
  }

  private appendDialogComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.model.component);
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    this.container.nativeElement.appendChild(domElem);

    this.model.componentRef = componentRef;
  }

  private destroyComponents(): void {

    const components = this.dialogService.displayModal.components;
    if (!components) {
      return;
    }

    components.forEach((component: DialogComponent) => {
      this.destroyComponent(component);
    });

    this.dialogService.displayModal.components = [];
  }

  private destroyComponent(component: DialogComponent): void {
    this.detachComponent(component);
    component.componentRef.destroy();
  }

  private detachComponent(component: DialogComponent): void {
    this.appRef.detachView(component.componentRef.hostView);
  }


}
