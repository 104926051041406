import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

export interface TableauJwtStateModel {
  jwt: string;
}

export class UserTableauUpdated {
  static readonly type = '[UserTableau] JWT Updated';

  constructor(public jwt: string) {
  }
}

@State<TableauJwtStateModel>({
  name: 'userTableauJwt',
  defaults: { jwt: '' } as TableauJwtStateModel
})
@Injectable()
export class TableauJwtState {

  @Selector()
  static userJWT(state: TableauJwtStateModel) {
    return state;
  }

  @Action(UserTableauUpdated)
  update(ctx: StateContext<TableauJwtStateModel>, action: UserTableauUpdated) {
    ctx.patchState({
      jwt: action.jwt
    });
  }

}
