<div class="content-container login-component" *ngIf="!loading">
  <div class="content-area ">
    <div class="clr-row">
      <div class="clr-col-4"></div>
      <div class="clr-col-4">
        <div class="card login-card">
          <div class="card-block">
            <div class="card-header">
              <div class="clr-row">
                <div class="clr-col">
                  <img alt="Sentinel" src="../../../assets/images/sentinel-logo-base.png" class="login-sentinel-logo"/>
                </div>
              </div>
            </div>
            <div class="card-block" *ngIf="isSupported()">
              <div class="card-text">
                <form clrForm id="loginForm" class="form-container">
                  <h4 class="h4 login-notice">Please log in to continue.</h4>
                  <button class="btn btn-primary minibtn-primary login-btn-submit" type="submit" (click)="login()" data-cy="login-button">
                      Log In
                      <clr-icon shape="login" class="is-solid login-btn-icon login-btn-icon-webkit" size="24"></clr-icon>
                  </button>
                </form>
              </div>
            </div>
            <div class="card-block" *ngIf="!isSupported()">
              <div class="card-text">
                <p class="p1 login-notice" style="font-size: 18px;">
                  You are currently using the <strong>{{ deviceInfo.browser }}</strong> browser.
                </p>
                <p class="p1" style="font-size: 16px;">
                  Sentinel is only supported in Google Chrome. Please use Google Chrome to access the application.
                </p>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button class="btn btn-link login-reset-passlink" *ngIf="!isSupported()">
              <a href="https://www.google.com/chrome/" target="_blank">
                Download Chrome
              </a>
            </button>
          </div>
        </div>
        <div class="clr-col-4"></div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="loading">
    <app-loader></app-loader>
</ng-container>
<ng-container *ngIf="error?.length">
  <clr-modal class="modal-loading" [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'md'" data-cy="reset-modal-error">
    <h3 class="modal-title login-title-error">Error</h3>
    <div class="modal-body">
        <p class="p1 login-text-error">{{ error }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary minibtn-primary login-btn-return" (click)="closeErrorModal()">Return</button>
    </div>
  </clr-modal>
</ng-container>
