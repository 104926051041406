import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '../../../../../services/dialog.service';
import { ColumnDataModel, TableDataModel } from '../../../../common/models/data.model';
import { LandService } from '../../../../../services/land.service';
import { ConfirmButtonModel, ConfirmDialogModel, ConfirmDialogResult, ConfirmDialogTypeEnum } from '../../../../common/models/dialog.model';
import { forkJoin } from 'rxjs';
import {DataModelService} from '../../../../../services/data-model.service';
import {DataModelFormBaseComponent} from '../data-model-form-base.component';
import {LogService} from '../../../../../services/log.service';

@Component({
  selector: 'app-form-data-model-table-create',
  templateUrl: './form-data-model-table-create.component.html',
  styleUrls: ['./form-data-model-table-create.component.css']
})
export class FormDataModelTableCreateComponent extends DataModelFormBaseComponent {

  public currentStep: string;
  public columns: ColumnDataModel[];
  public saving: boolean;

  constructor(protected router: Router,
              protected dialogService: DialogService,
              private landService: LandService,
              private dataModelService: DataModelService,
              protected logService: LogService) {
    super(router, dialogService, logService);
  }

  protected init(): void {
    this.currentStep = 'step-table-details';
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.dataModel = this.dialogService.displayModal.current.data.dataModel;
    this.table = { data_model: this.dataModel } as TableDataModel;
    this.column = { table: this.table } as ColumnDataModel;
    this.columns = [];
  }

  protected setUser(user: any): void {
  }

  public isCurrentColumnValid(): boolean {
    return this.isColumnValid(this.column);
  }

  private isColumnValid(column: ColumnDataModel): boolean {

    if (!column) {
      return false;
    }

    if (!this.dataModelService.validateObjectName(column.column_model_name)) {
      return false;
    }

    if (!column.column_data_type) {
      return false;
    }

    if (!column.column_model_type) {
      return false;
    }

    return true;
  }

  public removeColumn(column: ColumnDataModel): void {
    this.columns.splice(this.columns.indexOf(column), 1);
  }

  public addColumn(): void {
    if (!this.isColumnValid(this.column)) {
      return;
    }
    this.columns.push(this.column);
    this.column = {} as ColumnDataModel;
  }

  public goToDetails(): void {
    this.currentStep = 'step-table-details';
  }

  public goToColumns(): void {
    this.currentStep = 'step-table-columns';
  }

  public tableDetailsValid(): boolean {
    return this.dataModelService.validateObjectName(this.table.table_model_name);
  }

  public tableColumnsValid(): boolean {

    if (!this.columns || this.columns.length === 0) {
      return true;
    }

    let valid = true;
    this.columns.forEach((column) => {
      if (!this.isColumnValid(column)) {
        valid = false;
      }
    });

    return valid;
  }

  public back(): void {
    if (this.currentStep === 'step-table-columns') {
      this.goToDetails();
    }
  }

  public canGoBack(): boolean {
    return this.currentStep === 'step-table-columns';
  }

  public next(): void {
    if (this.currentStep === 'step-table-details') {
      this.goToColumns();
      return;
    }
    this.save();
  }

  public canGoNext(): boolean {
    if (this.currentStep === 'step-table-details') {
      return this.tableDetailsValid();
    }
    return this.tableColumnsValid();
  }

  public save(): void {
    this.saving = true;
    this.dataModelService.saveNewTable(this.table, this.dataModel.data_model_base_id).subscribe((results) => {
      const split = results.id.split(':');
      const id = split[split.length - 1];
      this.dataModelService.getTable(this.dataModel.data_model_base_id, id).subscribe((table) => {

        if (!this.columns || this.columns.length === 0) {
          this.saving = false;
          this.modalRef.close(ConfirmDialogResult.Confirm, table);
          return;
        }

        const columnsCall = [];
        this.columns.forEach((column) => {
          column.table = table;
          columnsCall.push(this.dataModelService.saveNewColumn(column, this.dataModel.data_model_base_id, table.table_model_id));
        });

        forkJoin(columnsCall).subscribe(() => {
          this.saving = false;
          table.expanded = false;
          table.columns = undefined;
          this.modalRef.close(ConfirmDialogResult.Confirm, table);
        });

      });
    }, (error) => {
      this.saving = false;

      const dialogRef = this.dialogService.openConfirmDialog({
        titleTranslateId: 'land-table-save-error-title',
        type: ConfirmDialogTypeEnum.Warning,
        message: 'land-table-save-error-message',
        subMessage: error,
        confirmButton: { show: true, textTranslateId: 'datatools-ok-button' } as ConfirmButtonModel
      } as ConfirmDialogModel);
      dialogRef.afterClosed.subscribe(() => {
        dialogRef.dialog.isOpen = false;
      });
    });
  }

}
