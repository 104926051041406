export interface TreeResponse {
  tree: TreeModel;
}

export interface TreeModel {
  id: string;
  name: string;
  name_only: string;
  name_group: string;
  actual: string;
  target: string;
  fill: string;
  svg: string;
  url: string;
  styleClass: string;
  expanded: boolean;
  label: string;
  data: TreeData;
  children: TreeModel[];
}

export interface TreeData {
  name: string;
  actual: string;
  target: string;
  fill: string;
  history: TreeHistory[];
}

export interface TreeHistory {
  name: string;
  series: TreeHistoryItem[];
}

export interface TreeHistoryItem {
  name: string;
  value: number;
}
