<div class="modal-body">
  <app-data-model-model-navigation
    [datasource_level]="modelLevels.VALUE"
    [datasource_modal_state]="modalStates.VIEW"
    [datasource_datamodel]="dataModel"
    [datasource_table]="table"
    [datasource_value]="value"
    [datasource_column]="column"></app-data-model-model-navigation>
  <ul *ngIf="displayMode !== modalStates.ADD" class="nav" role="tablist">
    <li role="presentation" class="nav-item">
      <button
        [attr.data-cy]="'column-details'"
        class="btn btn-link nav-link"
        (click)="switchTabs('details')"
        [ngClass]="{'active': activeTab === 'details'}"
        type="button"><span [translate]="'land-column-details'"></span></button>
    </li>
    <li role="presentation" class="nav-item">
      <button [attr.data-cy]="'column-stats'"
              class="btn btn-link nav-link"
              (click)="switchTabs('column-details')"
              [ngClass]="{'active': activeTab === 'column-details'}"
              type="button"><span [translate]="'land-value-column-details'"></span></button>
    </li>
    <li role="presentation" class="nav-item">
      <button
        [attr.data-cy]="'column-target-mappings'"
        class="btn btn-link nav-link"
        (click)="switchTabs('value-mappings')"
        [ngClass]="{'active': activeTab === 'value-mappings'}"
        type="button"><span [translate]="'land-column-mappings'"></span></button>
    </li>
  </ul>
  <div class="tab-details" *ngIf="activeTab === 'details'">
    <app-data-model-value-details [modal_state]="displayMode" [datasource_value]="value"></app-data-model-value-details>
  </div>
  <div class="tab-statistics" *ngIf="activeTab === 'column-details'">
    <app-data-model-column-details *ngIf="column"
                             [datasource_datamodel]="dataModel"
                             [datasource_table]="table"
                             [datasource_column]="column"
                             [modal_state]="modalStates.VIEW"></app-data-model-column-details>

  </div>
  <div class="tab-mappings" *ngIf="activeTab === 'value-mappings'">
    <app-column-category-value></app-column-category-value>
  </div>

</div>
<div class="modal-footer" *ngIf="activeTab !== 'value-mappings' && activeTab !== 'tab-details'">
  <button [disabled]="saving" type="button" class="btn btn-outline" data-cy="form-close" (click)="close()">
    <span [translate]="'submodal-btn-close'"></span>
  </button>


  <button [disabled]="saving" type="button" class="btn btn-primary"
          (click)="edit()"
          *ngIf="activeTab === 'details' && displayMode === modalStates.VIEW"
          data-cy="form-edit">
    <span [translate]="'evaluations-grid-btn-edit'"></span>
  </button>
  <button (click)="save()"
    type="button" class="btn btn-primary" [disabled]="saving"
    *ngIf="activeTab === 'details' && displayMode !== modalStates.VIEW"
    data-cy="form-submit">
    <span [translate]="'exploration-project-btn-save'"></span>
  </button>

</div>
<ng-container *ngIf="saving">
  <app-loader [displayMode]=""></app-loader>
</ng-container>
