import { BaseComponent } from '../../../../common/base-component';
import { Router } from '@angular/router';
import { LandService } from '../../../../../services/land.service';
import { DialogService } from '../../../../../services/dialog.service';
import { ColumnDataModel, ColumnMapIndicator, ColumnMappingModel, MappingQueryTypes } from '../../../../common/models/data.model';
import { Directive, Input } from '@angular/core';
import {
  ConfirmButtonModel, ConfirmDialogModel,
  ConfirmDialogResult,
  ConfirmDialogTypeEnum,
  DisplayModalDialogResults
} from '../../../../common/models/dialog.model';
import {FormDataModelColumnMapTargetComponent} from '../form-data-model-column-map-target/form-data-model-column-map-target.component';
import {LogService} from '../../../../../services/log.service';

@Directive()
export abstract class ColumnMappingBaseComponent extends BaseComponent {
  public column: ColumnDataModel;
  public columnMappings: ColumnMappingModel[];
  public columnMapIndicator = ColumnMapIndicator;
  public loading: boolean;
  public saving: boolean;


  constructor(
    private editMapTranslateId: string,
    private mappingQueryType: MappingQueryTypes,
    protected router: Router,
    protected landService: LandService,
    protected dialogService: DialogService,
    protected logService: LogService) {
    super(router, logService);
  }

  protected init(): void {
  }

  protected setUser(user: any): void {
    this.setup();
  }


  @Input()
  set datasource_column(column: ColumnDataModel) {
    this.column = column;
    this.setup();
  }

  private setup(): void {
    if (!this.user || !this.column) {
      return;
    }

    if (this.column.mapped_indicator === ColumnMapIndicator.mapped) {
      this.getColumnMappings();
    }
  }

  private getColumnMappings(): void {
    this.loading = true;
    this.landService.getColumnMappings(this.mappingQueryType,
      this.column.column_model_id).subscribe((results: ColumnMappingModel[]) => {
      this.columnMappings = results;
      this.loading = false;
    }, () => {
      this.loading = false;
      this.columnMappings = [];
    });
  }

  public mapTarget(): void {
    console.log('mapTarget');
    console.log(this.column);
    const dialogRef = this.dialogService.openDialog('FormDataModelColumnMapTargetComponent', FormDataModelColumnMapTargetComponent,
      this.editMapTranslateId, 'xl', {
        column: this.column,
      });
    dialogRef.afterClosed.subscribe((results: DisplayModalDialogResults) => {

      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }

    });

  }

  public editMapping(mapping: ColumnMappingModel): void {

    const dialogRef = this.dialogService.openDialog('FormDataModelColumnMapTargetComponent', FormDataModelColumnMapTargetComponent,
      this.editMapTranslateId, 'xl', {
        column: this.column,
        mapping
      });
    dialogRef.afterClosed.subscribe((results: DisplayModalDialogResults) => {

      if (results.action === ConfirmDialogResult.Cancel) {
        return;
      }

    });

  }

  public deleteMapping(mapping: ColumnMappingModel): void {

    const dialogRef = this.dialogService.openConfirmDialog({
      titleTranslateId: 'land-mapping-delete-confirm-header',
      type: ConfirmDialogTypeEnum.Info,
      message: 'land-mapping-delete-confirm-message',
      confirmButton: { show: true, textTranslateId: 'land-column-confirm-button' } as ConfirmButtonModel,
      cancelButton: { show: true, textTranslateId: 'land-column-cancel-button' } as ConfirmButtonModel
    } as ConfirmDialogModel);
    dialogRef.afterClosed.subscribe((confirmResult) => {

      if (confirmResult === ConfirmDialogResult.Cancel) {
        return;
      }
      this.saving = true;

      this.landService.deleteColumnMapping(mapping.mapping_id).subscribe(() => {
        this.getColumnMappings();
        this.saving = false;

      }, (error) => {
        this.saving = false;
        const errorDialogRef = this.dialogService.openConfirmDialog({
          titleTranslateId: 'land-mapping-delete-confirm-header',
          type: ConfirmDialogTypeEnum.Warning,
          message: 'land-delete-map-error',
          subMessage: error.message,
          confirmButton: { show: true, textTranslateId: 'datatools-ok-button' } as ConfirmButtonModel
        } as ConfirmDialogModel);
        errorDialogRef.afterClosed.subscribe(() => {
          dialogRef.dialog.isOpen = false;
        });
      });

    });

  }

}
