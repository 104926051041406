import { Component, Input } from '@angular/core';
import { FileDownloadBaseComponent } from '../FileDownloadBaseComponent';
import { Router } from '@angular/router';
import { MetadataService } from '../../../../services/metadata.service';
import { LogService } from '../../../../services/log.service';
import { DialogService } from '../../../../services/dialog.service';
import {AngularFireStorage} from '@angular/fire/storage';

@Component({
  selector: 'app-file-download-link',
  templateUrl: './file-download-link.component.html',
  styleUrls: ['./file-download-link.component.css']
})
export class FileDownloadLinkComponent extends FileDownloadBaseComponent {

  fileIdProp: string;
  fileNameProp: string;
  @Input() translateId: string;
  @Input() relativePath: string;
  @Input() disabled: boolean;
  @Input() view: boolean;

  constructor(protected router: Router,
              protected metaSvc: MetadataService,
              protected logService: LogService,
              protected dialogService: DialogService,
              protected storage: AngularFireStorage
  ) {
    super(router, metaSvc, logService, dialogService, storage);
  }

  protected setUser(user: any): void {
  }

  @Input() set fileId(fileId: string) {
    this.fileIdProp = fileId;
  }

  @Input() set fileName(fileName: string) {
    this.fileNameProp = fileName;
  }

  public isValid(): boolean {
    if (!this.fileIdProp || this.fileIdProp.length === 0) {
      return false;
    }

    if (!this.fileNameProp || this.fileNameProp.length === 0) {
      return false;
    }
    return true;
  }

  public download(): void {
    if (!this.isValid()) {
      return;
    }
    if (this.view) {
      this.viewFile(this.fileIdProp, this.fileNameProp);
      return;
    }

    if (this.relativePath) {
      this.downloadFilePath(false, this.fileIdProp, this.relativePath);
    } else {
      this.downloadFile(false, this.fileIdProp, this.fileNameProp);
    }
  }

}
