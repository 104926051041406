import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SiteModel} from '../components/common/models/site.model';
import {DomainsEnum} from '../components/common/models/domains.enum';

export interface MetadataDomainSiteModel {
  domainId: string;
  sites: SiteModel[];
}

export interface MetadataSiteStateModel {
  domainSites: MetadataDomainSiteModel[];
}

export class MetadataSiteLoaded {
  static readonly type = '[MetadataSite] loaded';

  constructor(public domainId: string, public sites: SiteModel[]) {
  }
}

@State<MetadataSiteStateModel>({
  name: 'metadataSiteState',
  defaults: {domainSites: []}
})
@Injectable()
export class MetadataSiteState {

  constructor() {
  }

  @Selector()
  static start(state: MetadataSiteStateModel) {
    return state.domainSites.find((item) => item.domainId === DomainsEnum.START)?.sites;
  }

  @Selector()
  static datatool(state: MetadataSiteStateModel) {
    return state.domainSites.find((item) => item.domainId === DomainsEnum.DATATOOL)?.sites;
  }

  @Selector()
  static productivity(state: MetadataSiteStateModel) {
    return state.domainSites.find((item) => item.domainId === DomainsEnum.PRODUCTIVITY)?.sites;
  }

  @Selector()
  static sites(state: MetadataSiteStateModel) {
    return (domainId: string) => {
      const domain = state.domainSites.find((item) => item.domainId === domainId);
      return domain?.sites;
    };
  }

  @Action(MetadataSiteLoaded)
  loaded(ctx: StateContext<MetadataSiteStateModel>, action: MetadataSiteLoaded) {
    const state = ctx.getState();

    let target = state.domainSites.find(x => x.domainId === action.domainId);
    const other = state.domainSites.filter(x => x.domainId !== action.domainId);

    if (!target) {
      target = {domainId: action.domainId, sites: action.sites};
    } else {
      target.sites = action.sites;
    }

    ctx.patchState({
      domainSites: [
        ...other,
        target
      ]
    });

  }
}
