<ng-container *ngIf="!loading && !this.error.length">
  <clr-modal class="feedback-modal" [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'md'"
    data-cy="feedback-modal">
    <p class="modal-title feedback-modal-title" data-cy="feedback-header" [translate]="'feedback-title'"></p>
    <div class="modal-body">
      <form clrForm #feedbacktype="ngForm">
        <clr-select-container>
          <label for="feedbacktype" [translate]="'feedback-label-type'"></label>
          <select clrSelect required name="feedbacktype" [(ngModel)]="selections.type" style="font-size: 16px;"
            data-cy="feedback-type">
            <option *ngFor="let type of feedback.types;" [value]="type">{{ type | titlecase }}</option>
          </select>
          <clr-control-error [translate]="'feedback-error-type'"></clr-control-error>
        </clr-select-container>
      </form>
      <form clrForm>
        <clr-textarea-container>
          <label [translate]="'feedback-label-comment'"></label>
          <textarea clrTextarea [(ngModel)]="selections.comment" name="feedbackcomment" required
            class="feedback-textarea" data-cy="feedback-comment"></textarea>
          <clr-control-helper [translate]="'feedback-helper-comment'"></clr-control-helper>
          <clr-control-error [translate]="'feedback-error-comment'"></clr-control-error>
        </clr-textarea-container>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="updateParentDisplay('root')" data-cy="feedback-return"
        [translate]="'submodal-btn-cancel'"></button>
      <button type="button" class="btn btn-primary minibtn-primary" (click)="postFeedback()"
        [disabled]="!selections.comment.length" data-cy="feedback-submit" [translate]="'feedback-btn-submit'"></button>
    </div>
  </clr-modal>
</ng-container>

<ng-container *ngIf="this.error.length">
  <clr-modal [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'md'">
    <div class="modal-body">
      <h3 class="modal-title" [translate]="'submodal-title-error'"></h3>
      <p class="p1" [translate]="'feedback-error-body'"></p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary minibtn-primary" (click)="updateParentDisplay('root')"
        [translate]="'submodal-btn-return'"></button>
    </div>
  </clr-modal>
</ng-container>

<ng-container *ngIf="loading">
  <app-loader></app-loader>
</ng-container>