<clr-modal [clrModalOpen]="true" [clrModalClosable]="false" [clrModalSize]="'lg'">
  <div data-cy="confirmation-modal-title" class="modal-title" style="display: flex; justify-content: space-between; align-items: flex-end;">
    <h3 class="modal-title text-notice" [translate]="confirmModal?.titleTranslateId" ></h3>
  </div>
  <div [attr.data-cy]="'confirmation-modal-' + confirmModal.type" class="modal-body" *ngIf="!confirmDialog.model.loading">
      <div class="alert alert-{{confirmModal.type}}" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <clr-icon *ngIf="confirmModal.type === confirmType.Warning" class="alert-icon" shape="exclamation-triangle"></clr-icon>
              <clr-icon *ngIf="confirmModal.type === confirmType.Info" class="alert-icon" shape="info-circle"></clr-icon>
              <clr-icon *ngIf="confirmModal.type === confirmType.Success" class="alert-icon" shape="check-circle"></clr-icon>
            </div>
            <span *ngIf="confirmModal.message" class="alert-text" [translate]="confirmModal.message"></span>
            <span *ngIf="confirmModal.html" class="alert-text" [innerHTML]="confirmModal.html"></span>
          </div>
        </div>
        <button (click)="toggleSubMessage()" *ngIf="confirmDialog.model.subMessage" class="btn btn-link"><span [translate]="'confirm-dialog-view-error'"></span></button>
        <button type="button" class="close" aria-label="Close">
          <clr-icon aria-hidden="true" shape="close"></clr-icon>
        </button>
      </div>
      <div class="sub-message" *ngIf="showSubMessage" [innerHTML]="confirmDialog.model.subMessage">
    </div>
  </div>

  <div *ngIf="confirmDialog.model.loading === true" class="modal-body loading-body">
    <span class="spinner"></span>
  </div>

  <div class="modal-footer">
    <button
      data-cy="confirm-modal-cancel"
      (click)="cancel()"
      *ngIf="confirmModal?.cancelButton?.show && !confirmDialog.model.loading"
      class="btn">
      <span [translate]="confirmModal.cancelButton.textTranslateId"></span>
    </button>
    <button
      (click)="confirmed()"
      data-cy="confirm-modal-ok"
      *ngIf="confirmModal?.confirmButton?.show && !confirmDialog.model.loading"
      class="btn btn-primary">
        <span [translate]="confirmModal.confirmButton.textTranslateId"></span>
    </button>
  </div>
</clr-modal>

