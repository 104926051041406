import {Store} from '@ngxs/store';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserProfileState} from '../store/user.profile.state';


export abstract class BaseService {

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  protected constructor(protected http: HttpClient, protected store: Store) {
  }

  protected getProfileLanguageId(): string {
    const state = this.store.selectSnapshot(UserProfileState.profile);
    return state.profile_language_id;
  }

  protected getLanguageId(): string {
    const state = this.store.selectSnapshot(UserProfileState.profile);
    return state.language_id;
  }
}
