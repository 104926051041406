<div class="clr-row">
  <div class="clr-col">
    <button (click)="mapTarget()" class="btn"><span [translate]="'land-column-map-add'"></span></button>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col">
    <ng-container>
      <clr-datagrid [clrDgLoading]="loading" data-cy="land-grid-changes">
        <clr-dg-column [clrDgField]="'target.table.data_model.data_model_name'">
          <span [translate]="'land-column-map-source-datamodel'">land-datamodel</span>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'target.table.table_model_name'">
          <span [translate]="'land-column-map-source-table'">Table</span>
        </clr-dg-column>
        <clr-dg-column [clrDgField]="'target.column_model_name'">
          <span [translate]="'land-column-map-source-column'">Column</span>
        </clr-dg-column>
        <clr-dg-column>
          <span [translate]="'evaluations-grid-col-options'"></span>
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let mapping of columnMappings">
          <clr-dg-cell data-cy="land-grid-map-model-name">{{ mapping.source.table.data_model.data_model_name }}</clr-dg-cell>
          <clr-dg-cell>{{ mapping.source.table.table_model_name }}</clr-dg-cell>
          <clr-dg-cell>{{ mapping.source.column_model_name }}</clr-dg-cell>
          <clr-dg-cell>
            <div style="display: flex;">
              <button (click)="deleteMapping(mapping)" class="btn btn-link btn-sm delete" data-cy="datagrid-delete-button"
                      [translate]="'land-mapping-grid-btn-delete'">
              </button>

            </div>
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50]"
                              [translate]="'evaluations-grid-foot-numpage'"></clr-dg-page-size>
            <span [translate]="'evaluations-grid-foot-label-1'"></span>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            <span [translate]="'evaluations-grid-foot-label-2'"></span>
            {{ pagination.totalItems }}
            <span [translate]="'exploration-project-footer'"></span>
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </ng-container>
  </div>
</div>
