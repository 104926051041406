import {Injectable} from '@angular/core';
import {
  ConfirmDialog,
  ConfirmDialogModel,
  ConfirmDialogRef, DialogComponent, DisplayDialog, DisplayModalDialogRef, GenericComponentDialogRef, GenericComponentInteraction
} from '../components/common/models/dialog.model';
import {Store} from '@ngxs/store';
import {DialogDisplayComponent} from '../store/dialog.state';
import {Router} from '@angular/router';
import {LogService} from './log.service';

@Injectable({providedIn: 'root'})
export class DialogService {
  public confirmModal: ConfirmDialog;
  public displayModal: DisplayDialog;
  public addAttachmentModal: GenericComponentInteraction;

  constructor(private store: Store,
              private router: Router,
              private logService: LogService) {
  }

  public initAttachmentModal(): GenericComponentInteraction {
    this.addAttachmentModal = {isOpen: false} as GenericComponentInteraction;
    return this.addAttachmentModal;
  }

  public openAddAttachment(): GenericComponentDialogRef {
    if (!this.addAttachmentModal.ref) {
      this.addAttachmentModal.ref = new GenericComponentDialogRef(this.addAttachmentModal);
    }
    this.addAttachmentModal.isOpen = !this.addAttachmentModal.isOpen;
    return this.addAttachmentModal.ref;
  }


  public initConfirmModal(): ConfirmDialog {
    this.confirmModal = {isOpen: false} as ConfirmDialog;
    return this.confirmModal;
  }

  public initDisplayModal(): DisplayDialog {
    this.displayModal = {components: []} as DisplayDialog;
    return this.displayModal;
  }

  public openConfirmDialog(model: ConfirmDialogModel): ConfirmDialogRef {
    this.confirmModal.ref = new ConfirmDialogRef(this.confirmModal);
    this.confirmModal.model = model;
    this.confirmModal.isOpen = true;
    return this.confirmModal.ref;
  }

  public openDialog(id: string, component: any, header: string, size: string, data?: any): DisplayModalDialogRef {
    this.logService.logUsage(this.router.url, 'Dialog', 'Open', {dialog_id: id});
    const existing = this.displayModal.components.find(c => c.id === id);
    if (existing) {
      existing.header = header;
      existing.size = size;
      existing.data = data;
      existing.ref = new DisplayModalDialogRef(this.displayModal, this.store, this.logService, this.router);

      this.displayModal.current = existing;
      this.store.dispatch(new DialogDisplayComponent(existing.id));
      return existing.ref;
    }
    const dialogComponent = {
      id,
      header,
      size,
      component,
      data,
      ref: new DisplayModalDialogRef(this.displayModal, this.store, this.logService, this.router)
    } as DialogComponent;
    this.displayModal.components.push(dialogComponent);
    this.displayModal.current = dialogComponent;
    this.store.dispatch(new DialogDisplayComponent(dialogComponent.id));
    return dialogComponent.ref;
  }
}
