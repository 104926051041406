import { Component, OnInit } from '@angular/core';
import { ConfirmDialogResult, DisplayModalDialogRef } from 'app/components/common/models/dialog.model';
import { IntakeFormType, IntakeForms, IntakeSummaryResult } from 'app/components/common/models/intake.model';
import { DialogService } from 'app/services/dialog.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-intake-summary',
  templateUrl: './intake-summary.component.html',
  styleUrls: ['./intake-summary.component.css']
})
export class IntakeSummaryComponent implements OnInit {
  modalRef: DisplayModalDialogRef;
  results: IntakeSummaryResult;
  intakeUrl: string;
  intakeType: string = "";
  model: IntakeForms;
  copiedId: boolean = false;
  copiedUrl: boolean = false;

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
    this.modalRef = this.dialogService.displayModal.current.ref;
    this.results = this.dialogService.displayModal.current.data.results;
    this.intakeType = this.dialogService.displayModal.current.data.intake_form_type;
    this.model = this.dialogService.displayModal.current.data.model;
    const baseURL = 'https://dev.azure.com/NewmontAnalytics/';
    const intakeTarget = environment.prodIntake ? 'PMO - Data and Analytics' : 'PMO - Data and Analytics (dev)';
    const urlParams = (this.intakeType === IntakeFormType.bug || this.intakeType === IntakeFormType.user_story)
      ? `${encodeURIComponent(this.model.project_name)}/_workitems/edit/${encodeURIComponent(this.results.external_id)}`
      : `${encodeURIComponent(intakeTarget)}/_workitems/edit/${encodeURIComponent(this.results.external_id)}`;
    this.intakeUrl = baseURL + urlParams;
  }

  close(): void {
    this.modalRef.close(ConfirmDialogResult.Cancel);
  }

  async copyToClipboard(value: string, type: 'id' | 'url'): Promise<void> {
    try {
      await navigator.clipboard.writeText(value);
      this.setCopied(type);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }
  
  setCopied(type: 'id' | 'url'): void {
    if (type === 'id') {
      this.copiedId = true;
      setTimeout(() => this.copiedId = false, 1800);
    } else {
      this.copiedUrl = true;
      setTimeout(() => this.copiedUrl = false, 1800);
    }
  }
}
