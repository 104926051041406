// Return all the risks under the given category
export function filterRisksByCategory(risksMetadata, riskCategoryId) {
  return risksMetadata.filter((risk) => {
    return risk.risk_category_id === riskCategoryId;
  });
}

// Return the previous month, current month, and next month
export function filterCurrentMonths(datesMetadata) {
  const currentDate = new Date(Date.now());
  const currentMonth = currentDate.getMonth();
  let filteredMonths = datesMetadata.filter((month, i) => {
    return i === currentMonth || i === currentMonth - 1 || i === currentMonth + 1;
  });
  return filteredMonths;
}

// Return the current month
export function findMonth(datesMetadata, date) {
  let foundMonth = datesMetadata.find((month) => {
    return month.month === date;
  });
  return foundMonth;
}

// Return user domain objects based on domains array
export function findUserDomains(domainsMetadata, userDomains) {
  let domains = [];
  userDomains.forEach((userDomain) => {
    domainsMetadata.forEach((domain) => {
      if (domain.domain_id === userDomain) {
        domains.push(domain);
      }
    });
  });
  return domains;
}

// Return the user object based on cleaned name, usually sent from the DOM
export function findUserByName(usersMetadata, userName) {
  const foundUser = usersMetadata.find((user) => {
    let combinedUserString = `${user.name_first} ${user.name_last}`;
    return combinedUserString === userName;
  });
  return foundUser;
}

// Find matching department ID
export function findDepartmentById(departmentsMetadata, departmentId) {
  const foundDepartment = departmentsMetadata.find((department) => {
    return department.department_id === departmentId;
  });
  return foundDepartment;
}

// Find matching language ID
export function findLanguageById(languagesMetadata, languageId) {
  const foundLanguage = languagesMetadata.find((language) => {
    return language.language_id === languageId;
  });
  return foundLanguage;
}

// Find matching location ID
export function findLocationById(locationsMetadata, locationId) {
  const foundLocation = locationsMetadata.find((location) => {
    return location.location_id === locationId;
  });
  return foundLocation;
}

// Find matching region ID
export function findRegionById(regionsMetadata, regionId) {
  const foundRegion = regionsMetadata.find((region) => {
    return region.region_id === regionId;
  });
  return foundRegion;
}

// Find matching site ID
export function findSiteById(sitesMetadata, siteId) {
  const foundSite = sitesMetadata.find((site) => {
    return site.site_id === siteId;
  });
  return foundSite;
}

// Find matching standard ID
export function findStandardById(standardsMetadata, standardId) {
  const foundStandard = standardsMetadata.find((standard) => {
    return standard.standard_id === standardId;
  });
  return foundStandard;
}

// Find the primary site of the user
export function findPrimarySite(authSites) {
  console.log(authSites)
  const foundSite = authSites.find((site) => {
    return site.primary;
  });
  console.log('foundSite', foundSite);
  return foundSite;
}